/* eslint-disable no-lone-blocks */
import React, {useState, useEffect} from 'react'
import {
    Form,
    FormGroup,
    Input,
    Button,
    Card,
    Modal
} from 'reactstrap'
import './communityStyles.css'
import {withSnackbar} from 'notistack';
import avatar from '../../assets/img/theme/avatarnew.png';

const Services = require('../../RemoteServices/RemoteServices.js');

const AnnouncementField = (props) => {
    let clickHandler = null
    const user = JSON.parse(localStorage.getItem('user'));
    const [discardModal, setDiscardModal] = useState(false);
    const [loading, setLoading] = useState(false)
    let [announcementData, setAnnouncementData] = useState({
        message: props?.showEditItem?.message || '',
        image: props?.showEditItem?.image || '',
        // user:2
        // pin:false,
        // status:true,
        // have_i_like:false,
    })
    let [ImagePreview, setImagePreview] = useState('')
    const [addPost, setAddPost] = useState(props?.showEdit || false)

    const checkMessageField = () => {
        if (announcementData.message || announcementData.image) {
            setDiscardModal(true)
        } else {
            setAddPost(false)
        }
    }

    const clearAdd = async () => {
        await setAnnouncementData({
            message: '',
            image: ''
        })
        await setDiscardModal(false);
        await setAddPost(false)
    }

    const setImageInPost = (event) => {
        const {files} = event.target
        // setAnnouncementData({...announcementData,image:files[0]})
        var reader = new FileReader();
        reader.readAsDataURL(files[0]);
        setImagePreview(window.URL.createObjectURL(files[0]))
        reader.onload = function () {
            console.log(reader.result)
            setAnnouncementData({...announcementData, image: reader.result})
        }


    }

    const removeImage = () => {
        setAnnouncementData({...announcementData, image: ""})
    }

    const uploadCommunityPost = () => {
        setLoading(true)
        {!props.showEdit ?
                Services.addCommunityPost(announcementData).then((response) => {
                    setAddPost(!addPost);
                    setLoading(false)
                    setAnnouncementData({
                        image: '',
                        message: '',
                    })
                    props.enqueueSnackbar('Post Added', {
                            variant: 'success',
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'right',
                            }
                        }
                    )
                    props.getCommunity()
                    setAnnouncementData({image: "", message: ""})

                }).catch = (err) => {
                    props.enqueueSnackbar(err, {
                            variant: 'danger',
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'right',
                            }
                        }
                    )
                } : (
                    Services.updateCommunity({
                        id: props?.showEditItem?.user?.id,
                        message: props?.showEditItem.message,
                        image: props?.showEditItem?.image ? props?.showEditItem?.image : announcementData.image
                    }).then((response) => {
                        props.getCommunity();
                        setAddPost(!addPost);
                        setLoading(false)
                        setAnnouncementData({
                            image: '',
                            message: '',
                        })
                        props.enqueueSnackbar('Post Added', {
                                variant: 'success',
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }
                            }
                        )
                        props.getCommunity()
                        setAnnouncementData({image: "", message: ""})

                    }).catch = (err) => {
                        setLoading(false)
                        props.enqueueSnackbar(err, {
                                variant: 'danger',
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }
                            }
                        )
                    }
                )
        }
        //         } :
        //         setLoading(false)
        //         props.enqueueSnackbar("Please Enter Message Or Upload Image", {
        //                 variant: 'danger',
        //                 anchorOrigin: {
        //                     vertical: 'bottom',
        //                     horizontal: 'right',
        //                 }
        //             }
        //         )
        // }

    }


    return (
        <>
            {!props.showEdit &&<div className="annFieldContainer">
                <Form>
                    <FormGroup style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: '10px',
                    }}>
                        <img
                            height={50}
                            width={50}
                            alt="..."
                            className="rounded-circle"
                            src={user.profile_picture!==null?window.config.imgUrl.concat(user.profile_picture):avatar}
                        />
                        <Input type="text" style={{borderRadius: '20px'}}
                               onClick={(e) => setAddPost(true)}
                               placeholder={`What's on Your Mind? ${user.full_name}`}></Input>
                    </FormGroup>
                </Form>
            </div>}

            {addPost &&
                <Modal
                    keyboard={false}
                    backdrop={false}
                    className="modal-dialog-centered modal-lg"
                    isOpen={addPost}
                    toggle={() => setAddPost(!addPost)}
                >
                    <div className="modal-header"
                         style={{
                             borderBottom: '1px solid lightgrey',
                             display: 'flex', justifyContent: 'center', margin: '5px',
                             padding: '10px',
                             paddingLeft: '300px'
                         }}>
                        <h2 style={{textAlign: 'center'}} className="modal-title" id="modal-title-default">
                            {props?.showEdit ? 'Edit A Post' : 'Create A Post'}
                        </h2>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => checkMessageField()}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>

                    <div className={'modal-body'}
                         style={{
                             paddingLeft: '16px',
                             paddingRight: '16px',
                             paddingTop: '5px',
                             paddingBottom: '5px'
                         }}>
                        <div className="announcementFieldContainer">
                            <Form>
                                <FormGroup style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    gap: '20px',
                                    marginBottom: '5px'
                                }}>
                                    <img
                                        height={50}
                                        width={50}
                                        alt="..."
                                        className="rounded-circle"
                                        src={user.profile_picture!==null?window.config.imgUrl.concat(user.profile_picture):avatar}
                                    />
                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                        <span className={'font-weight-bold'}>{user.full_name}</span>
                                        <span><i className={'fa fa-user'}></i> Filter</span>
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <Input className={'post-textarea'} type="textarea" rows={5}
                                           style={{borderRadius: '10px'}}
                                           value={announcementData.message}
                                           onChange={(e) => {
                                               setAnnouncementData({...announcementData, message: e.target.value})
                                           }} placeholder={`What's on Your Mind? ${user.full_name}`}></Input>
                                </FormGroup>
                            </Form>
                            {announcementData.image !== "" &&
                                <div className="announcementImageContainer">
                                    {!props?.showEdit && <img alt="preview" src={ImagePreview}></img>}
                                    {props?.showEdit && <img alt="preview" src={announcementData.image}></img>}
                                    <i className="mt-2 fas fa-times-circle" onClick={() => removeImage()}></i>
                                </div>}
                            <input
                                type='file'
                                name='logo'
                                hidden={true}
                                onChange={setImageInPost}
                                ref={(input) => {
                                    clickHandler = input
                                }}
                            />
                            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                {announcementData.image === '' &&
                                    <i className="fas fa-image" onClick={() => clickHandler.click()}></i>}
                                <Button disabled={announcementData.message === '' && announcementData.image === ''}
                                        className={'mt-2'}
                                        type="button"
                                        style={{backgroundColor: '#6C32A0', color: 'white', float: 'right'}}
                                        onClick={() => uploadCommunityPost()}>{!loading ? 'Post' : 'Posting .....'}</Button>
                            </div>

                        </div>
                    </div>
                </Modal>
            }

            {discardModal &&
                <Modal
                    keyboard={false}
                    backdrop={true}
                    className="modal-dialog-centered modal-md"
                    isOpen={discardModal}
                    toggle={() => setDiscardModal(!discardModal)}
                >
                    <div className="modal-header"
                         style={{
                             borderBottom: '1px solid lightgrey',
                             display: 'flex', justifyContent: 'center', margin: '5px',
                             padding: '10px',
                         }}>
                        <h2 style={{textAlign: 'center'}} className="modal-title" id="modal-title-default">
                            Are You Sure You want to discard?
                        </h2>
                    </div>

                    <div className={'modal-body'}>
                        <span>Are you sure you want to discard the content???</span>
                    </div>

                    <div className={'modal-footer'}>
                        <Button onClick={() => clearAdd()}
                                className={'float-right btn btn-success'}>Confirm</Button>

                        <Button onClick={() => setDiscardModal(!discardModal)}
                                className={'float-right btn btn-danger'}>Close</Button>
                    </div>
                </Modal>
            }

        </>

    )
}

export default withSnackbar(AnnouncementField)