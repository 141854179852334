import React from "react";
// node.js library that concatenates classes (strings)
// javascipt plugin for creating charts
// react plugin used to create charts
// reactstrap components
import {
    Button,
    Card,
    CardBody,

    Container,
    Row,
    Col,
    Modal, 
} from "reactstrap";
import YouTube from "react-youtube";
import "./styles.css";
import '../views/examples/styles.scss'

// core components


import UserUpdateModal from "../components/Modals/UserUpdateModal";
import ReactPlayer from "react-player/lazy";
import Loading from "../components/Loading/Loading";
import img from "../assets/img/subscribe-our-youtube-channel.png";
import CommonDescription from "../components/Modals/CommonDescription";

const Services = require("../RemoteServices/RemoteServices.js");

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultModal: false,
            videoLink: "",
            width: window.innerWidth,
            articles: [],
            selectedArticle: null,
            updateProfile: false,
            createAccount: false,
            userDetails: '',
            thumbnail: '',
            loading: false,
            loadingError: false
        };
    }
    getUserDetails = async (id) => {
        const response = await Services.getUserDetails(id);
        localStorage.setItem('paid', JSON.stringify(response.results[0].payment_status))
        const localStorageData = localStorage.getItem('user');

        // Check if the key exists in localStorage
        if (localStorageData) {
            // Step 2: Parse the JSON data
            const parsedData = JSON.parse(localStorageData);

            // Step 3: Modify the data (change the value of the referred_count key)
            parsedData.referred_count = response.results[0]?.user.referred_count;
            parsedData.email_verified = response.results[0]?.user.email_verified;
            // Replace 15 with the new value you want

            // Step 4: Set the updated data back to localStorage
            localStorage.setItem('user', JSON.stringify(parsedData));
            console.log('asdfasdf', 'i am updated')
        } else {
            console.error('The key does not exist in localStorage.');
        }
    }

    toggleModal = (state) => {
        this.setState({
            [state]: !this.state[state],
        });
    };
    async componentDidMount() {
        this.setState({
            loading: true,
        })
        const userDetail = JSON.parse(localStorage.getItem('user'));
        this.getUserDetails(userDetail.id)
        if (!userDetail.phone_number) {
            await this.setState({
                updateProfile: true
            })
        }
        await Services.getLatestLessonDashboard()
            .then((response) => {
                this.setState({ videoLink: response.youtube_link, thumbnail: response.thumbnail });
                this.setState({ loading: false })
            })
            .catch((error) => {
                this.setState({ loading: false, loadingError: true })
            });
        await Services.getArticles()
            .then((response) => {
                this.setState({ articles: response });
            })
            .catch((error) => {
            });
    }


    render() {
        const optsDesktop = {
            width: "100%",
            height: "100%",
            playerVars: {
                autoplay: 0,
                frameborder: 0,
                modestbranding: 1,
                rel: 0,
                showinfo: 0,
            },
        };
        const video = this.state.videoLink;
        const youtubeVideoId = video.split("/").pop();
        const userDetails = this.state.userDetails;
        return (
            <>
                
                {!this.state.loading && !this.state.loadingError ?
                    <Container fluid className={'container-dashboard'}>
                        <Row className={'dashboard'}>
                            <Col xs={12} md={9} xl={10}>
                                <Row>
                                    <Col xs={12}>
                                        {this.state.thumbnail ?
                                            <div className={'player-change'}>
                                                <ReactPlayer
                                                    url={this.state.videoLink}
                                                    playIcon={<div></div>}
                                                    light={
                                                        <div style={{
                                                            position: 'relative',
                                                            width: '100%',
                                                            height: '100%',
                                                            borderRadius:10,
                                                        }}>
                                                            <img
                                                                alt={'thumbnail'}
                                                                style={{
                                                                    height: '100%',
                                                                    width: '100%',
                                                                    objectFit: 'stretch'
                                                                }}
                                                                src={this.state.thumbnail} />
                                                            <div style={{
                                                                position: 'absolute',
                                                                top: '50%',
                                                                left: '50%',
                                                                transform: 'translate(-50%, -50%)'
                                                            }}>
                                                                <div style={{
                                                                    border: '4px solid indigo',
                                                                    borderRadius: '50%',
                                                                    width: '60px',
                                                                    height: '60px',
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    color: 'indigo'
                                                                }}>
                                                                    <i style={{ fontSize: 20 }}
                                                                        className="fa fa-play"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    className="react-player"
                                                    width={'100%'}
                                                    height={'100%'}
                                                    playing={true}
                                                    controls={true}
                                                />
                                            </div>
                                            :
                                            <div
                                                style={{
                                                    width: "100%",
                                                    position: "relative",
                                                    paddingBottom: "56.25%",
                                                }}
                                            >
                                                <YouTube
                                                    containerClassName="YouTubeContainer"
                                                    videoId={youtubeVideoId}
                                                    opts={optsDesktop}
                                                    onReady={this._onReady}
                                                    onStateChange={this._onStateChange}
                                                />
                                            </div>
                                        }
                                    </Col>
                                </Row>

                            </Col>
                            <Col xs={12} md={3} xl={2} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: '10px', marginBottom: '10px' }}>
                                <a
                                    href="https://chat.whatsapp.com/KoisdyiMLua0p8TUzCcceb"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    className="d-flex mb-4"
                                >
                                    <img
                                        alt="WhatsApp"
                                        src={require("assets/img/WhatsApp_Logo_8.png")}
                                        style={{ width: "100%" }}
                                    ></img>
                                </a>
                                <a
                                    href="https://bit.ly/3hRMspo"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    className="d-flex mb-4"
                                >
                                    <img
                                        alt="viber"
                                        src={require("assets/img/join-us-viber-icon.png")}
                                        style={{ width: "100%" }}
                                    ></img>
                                </a>
                                <a
                                    href="https://bit.ly/3kIGlFQ"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    className="d-flex mb-4"
                                >
                                    <img
                                        alt="youtube"
                                        src={require("assets/img/subscribe-our-youtube-channel.png")}
                                        style={{ width: "100%" }}
                                    ></img>
                                </a>
                                <Card>
                                    <CardBody style={{ padding: "1rem" }}>
                                        <h3 style={{ textAlign: "center" }}>Download Free Now</h3>
                                        <a
                                            href="https://bit.ly/3BtjYKo"
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            className="d-flex mb-4"
                                        >
                                            <img
                                                alt="google-play"
                                                src={require("assets/img/google-play-badge.png")}
                                                style={{ width: "100%" }}
                                            ></img>
                                        </a>
                                        <a
                                            href="https://apple.co/36ScgeM"
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            className="d-flex"
                                        >
                                            <img
                                                alt="App_Store"
                                                src={require("assets/img/Download_on_the_App_Store_Badge.svg")}
                                                style={{ width: "100%" }}
                                            ></img>
                                        </a>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <div className="youtube-list">
                            {this.state.articles.map((value) => {
                                return (
                                    <div key={value.id} className={'youtube-list-item'}>
                                        <Card className={'history-card'}
                                            onClick={(e) => {
                                                this.setState({ selectedArticle: value });
                                                this.toggleModal("defaultModal");
                                            }}
                                        >
                                            <div onClick={(e) => {
                                                this.setState({ selectedArticle: value });
                                                this.toggleModal("defaultModal");
                                            }}
                                                className={'youtube-content'}>
                                                <img style={{
                                                    height: '100%',
                                                    width: '100%',
                                                    contain: 'cover'
                                                }}
                                                    src={value.image}
                                                    alt={'playbutton'} />
                                            </div>
                                            <div className={'youtube-title'}>
                                                <span
                                                    className={'pt-2 pb-2 mt-2 font-weight-bold'}>{value.title}</span>
                                            </div>
                                        </Card>
                                    </div>
                                );
                            })}
                        </div>
                    </Container>
                    : this.state.loading ?
                        <>
                            <Loading />
                        </>
                        :
                        'No Internet Connectin'
                }

                {this.state.selectedArticle && (
                    <Modal
                        className="modal-dialog-centered modal-lg"
                        isOpen={this.state.defaultModal}
                        toggle={() => this.toggleModal("defaultModal")}
                        width="100%"
                    >
                        <div className="modal-header"
                            style={{
                                borderBottom: '1px solid lightgrey',
                                display: 'flex',
                                justifyContent: 'center',
                                margin: '5px',
                                padding: '10px',
                            }}>
                            <h2 className="modal-title" id="modal-title-default">
                                {this.state.selectedArticle.title}
                            </h2>
                            <button
                                aria-label="Close"
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => this.toggleModal("defaultModal")}
                            >
                                <span aria-hidden={true}>×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <CommonDescription
                                arrayObject={this.state?.selectedArticle?.objects} />
                        </div>
                        <div className="modal-footer">
                            <Button
                                className="ml-auto"
                                color="link"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => this.toggleModal("defaultModal")}
                            >
                                Close
                            </Button>
                        </div>
                    </Modal>
                )}

                {this.state.updateProfile && (
                    <UserUpdateModal
                        type={'Dashboard'}
                        toggleModal={this.toggleModal}
                        updateProfile={this.state.updateProfile}
                    />
                )}
            </>
        );
    }
}

export default Index;
