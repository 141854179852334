import React, {memo, useEffect, useRef, useState} from "react";
import {v4 as uuidv4} from "uuid";
// reactstrap components
import {Alert, Button, Card, CardBody, Col, Input, Label, Modal, Row} from "reactstrap";
import KhaltiCheckout from "khalti-checkout-web";
import {prabhupay} from "RemoteServices/ePayment";
import {useHistory} from "react-router-dom";

const Services = require("RemoteServices/RemoteServices.js");

const Payment = ({isOpen, toggle}) => {
    const history = useHistory();
    const esewaRef = useRef(null);
    const [paymentDetail, setPaymentDetail] = useState({});
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [paymentError, setPaymentError] = useState(false);
    const [khaltiResponse, setKhaltiResponse] = useState('');
    const [paymentResponse, setPaymentResponse] = useState();
    const [promoCode, setPromoCode] = useState('');
    const [promo, setPromo] = useState(false);
    const [promoCodeError, setPromoCodeError] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                const data = await Services.getPaymentDetail();
                setPaymentDetail(data);
            } catch (error) {
            }
        })();
    }, []);

    const checkPromoCode = async () => {
        try {
            const data = await Services.checkPromoCode({promocode: promoCode});
            setPaymentDetail(data);
            await localStorage.setItem('promoCode', data.promocode_id)
            setPromo(true);
            setPromoCodeError(false);
        } catch (error) {
            setPromoCodeError(true);
        }
    }

    const khaltiConfig = {
        publicKey: "live_public_key_adebfca5a4f74cb8b4a8c1d762f27415",
        productIdentity: "ee2c3ca1-696b-4cc5-a6be-2c40d929d453",
        productName: "englishhub68",
        productUrl: "https://englishhub68.com/",
        eventHandler: {
            onSuccess(payload) {
                setKhaltiResponse(payload);
                (async () => {
                    try {
                        const data = await Services.savePayment({
                            payment_method: "KHALTI",
                            amount_paid: Number(payload.amount),
                            transaction_id: payload.idx,
                            reference_no: payload.idx,
                            promocode: payload.promoCode ? payload.promoCode : ''
                        });
                        setPaymentResponse(data);
                        setPaymentSuccess(true);
                    } catch (error) {
                        setPaymentError(true)
                    }
                })();
            },
            onError(error) {
                setPaymentError(true)
            },
        },
        paymentPreference: [
            "KHALTI",
            "EBANKING",
            "MOBILE_BANKING",
            // "CONNECT_IPS",
            "SCT",
        ],
    };

    const khaltiCheckout = new KhaltiCheckout(khaltiConfig);

    const handleprabhupay = async () => {
        const response = await prabhupay({
            totalAmount: paymentDetail.calculated_amount,
            merchantId: "ENGLISH001",
            password: "wvidjxin",
            invoiceNo: uuidv4(),
            productDetails: [
                {
                    productName: "englishhub68",
                    quantity: 1,
                    rate: paymentDetail.calculated_amount,
                    total: paymentDetail.calculated_amount,
                },
            ],
            remarks: "",
            returnUrl: "http://localhost:3000/admin/prabhupay",
        });
        console.log(response);
        if (response.success && response.status === "00") {
            window.location.assign(response.data.redirectionUrl);
        }
    };

    const handleKhaltiPay = async () => {
        let reqDto = {
            return_url: "https://uat-user.englishhub.app/admin/khalti",
            website_url: "https://uat-user.englishhub.app/",
            // return_url: "https://user.englishhub.app/admin/khalti",
            // website_url: "https://user.englishhub.app/",
            // amount:10*100,
            amount: Number(paymentDetail.calculated_amount) * 100,
            purchase_order_id: uuidv4(),
            purchase_order_name: 'English Hub',
        }
        await Services.saveKhaltiPayment(reqDto).then((response) => {
            console.log('asdfasdf', response)
            return window.location.href = response.Response.payment_url
        });
    }

    return (
        <Modal className="modal-dialog-centered" isOpen={isOpen} toggle={toggle}>
            <div className="modal-header m-0">
                <h2 className="modal-title">Payment Confirmation</h2>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggle}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="modal-body m-0 p-0 mb-2">
                {!paymentSuccess && !paymentError && paymentDetail.calculated_amount && (
                    <Row>
                        <Col xs={12} className="mb-3">
                            <div
                                style={{
                                    padding: "1.25rem",
                                    border: "1px solid #e6ecf1",
                                    borderRadius: ".25rem",
                                    background: "#f5f7f9",
                                }}
                            >
                                <table style={{width: '100%', fontWeight: 'bold'}}>
                                    <tbody>
                                    {promo ?
                                        <tr>
                                            <td style={{width: '100%', textAlign: 'left'}}>Amount:</td>
                                            <td>Rs.&nbsp;{((paymentDetail.calculated_amount) / (1 - (paymentDetail.promo_discount / 100))).toFixed(2)}</td>
                                        </tr> :
                                        <tr>
                                            <td style={{width: '100%', textAlign: 'left'}}>Amount:</td>
                                            <td>Rs.&nbsp;{paymentDetail.amount.toFixed(2)}</td>
                                        </tr>
                                    }


                                    {promo &&
                                        <>
                                            <tr style={{borderBottom: '3px solid lightgrey'}}>
                                                <td style={{width: '100%', textAlign: 'left'}}>Discount
                                                    ({paymentDetail.promo_discount}%):
                                                </td>
                                                <td>Rs.&nbsp;{((paymentDetail.calculated_amount) - (paymentDetail.calculated_amount) / (1 - (paymentDetail.promo_discount / 100))).toFixed(2)}</td>
                                            </tr>

                                            {/*<tr>*/}
                                            {/*    <td style={{width: '100%', textAlign: 'left'}}>*/}
                                            {/*        Discounted Total:*/}
                                            {/*    </td>*/}
                                            {/*    <td>Rs.&nbsp;{paymentDetail.calculated_amount.toFixed(2)}</td>*/}
                                            {/*</tr>*/}
                                            {/*<tr>*/}
                                            {/*    <td style={{width: '100%', textAlign: 'left'}}>*/}
                                            {/*        Total Amount:*/}
                                            {/*    </td>*/}
                                            {/*    <td>Rs.&nbsp;{paymentDetail.amount.toFixed(2)}</td>*/}
                                            {/*</tr>*/}
                                        </>
                                    }
                                    {!promo &&
                                        <tr style={{borderBottom: '3px solid lightgrey'}}>
                                            <td style={{width: '100%', textAlign: 'left'}}>VAT (13%):</td>
                                            <td>Rs.&nbsp;{((paymentDetail.calculated_amount) - (paymentDetail.calculated_amount / 113 * 100)).toFixed(2)}</td>
                                        </tr>
                                    }
                                    <tr>
                                        <td style={{width: '100%', textAlign: 'left'}}>
                                            Grand Total:
                                        </td>
                                        <td>Rs.&nbsp;{paymentDetail.calculated_amount.toFixed(2)}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                        <Col xs={12}>
                            {!promo ?
                                <>
                                    <Label className={'ml-2'} style={{
                                        width: '100%',
                                        textAlign: 'center',
                                        fontWeight: 'bold',
                                        color: 'black',
                                        fontSize: '18px'
                                    }}>Have a Promo Code?</Label>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        padding: '15px',
                                        border: '1px solid lightgrey',
                                        gap: '5px'
                                    }}>
                                        <Input type={'text'}
                                               style={{
                                                   border: promoCodeError ? '1px solid red' : '1px solid lightgrey',
                                               }}
                                               onChange={(e) => {
                                                   setPromoCode(e.target.value)
                                               }} placeholder={'Promo Code'}></Input>
                                        <Button className={'all-btn'} onClick={() => checkPromoCode()}>Apply</Button>
                                    </div>
                                </>
                                :
                                <>
                                    {!promoCodeError &&
                                        <div style={{padding: '5px 10px 15px 10px'}}>
                                            <span
                                                style={{color: 'indigo'}}>Congratulations! You've unlocked a {paymentDetail.promo_discount}% discount. This special offer is applied to your total amount. Please note that the promo code {promoCode} is valid for this transaction only and may expire soon. Make the most of your savings!</span>
                                        </div>
                                    }
                                </>
                            }
                            {promoCodeError &&
                                <div style={{padding: '5px 10px 15px 10px'}}>
                                    <span style={{color: 'red'}}>Invalid Promo Code !!!!</span>
                                </div>
                            }
                        </Col>
                        <hr/>
                        <Col className={'mt-3'} xs={12} style={{textAlign: 'center'}}>
                            <Label style={{fontWeight: 'bold', fontSize: '22px', color: 'black'}}>Choose Payment
                                Method</Label>
                        </Col>
                        <hr/>
                        <Col xs={6} className="mb-3">
                            <form
                                action="https://esewa.com.np/epay/main"
                                method="POST"
                                ref={esewaRef}
                            >
                                <input
                                    value={paymentDetail.calculated_amount}
                                    //  value={10}
                                    name="tAmt"
                                    type="hidden"
                                />
                                <input
                                    // value={10}
                                    value={paymentDetail.calculated_amount}
                                    name="amt" type="hidden"/>
                                <input
                                    value={0}
                                    // value={paymentDetail.vat_amount}
                                    name="txAmt"
                                    type="hidden"
                                />
                                <input value="0" name="psc" type="hidden"/>
                                <input value="0" name="pdc" type="hidden"/>
                                <input value="NP-ES-EEAPHPL" name="scd" type="hidden"/>
                                <input value="ee2c3ca1-696b-4cc5-a6be-2c40d929d453" name="pid" type="hidden"/>
                                <input
                                    value="https://uat-user.englishhub.app/admin/esewa?q=su"
                                    //  value="https://user.englishhub.app/admin/esewa?q=su"
                                    // value="https://localhost:3001/admin/esewa?q=su"
                                    type="hidden"
                                    name="su"
                                />
                                <input
                                    // value="http://localhost:3001/admin/esewa?q=fu"
                                    value="https://uat-user.englishhub.app/admin/esewa?q=fu"
                                    // value="https://user.englishhub.app/admin/esewa?q=fu"
                                    type="hidden"
                                    name="fu"
                                />
                            </form>

                            {/*<form ref={esewaRef} action="https://rc-epay.esewa.com.np/api/epay/main/v2/form" method="POST">*/}
                            {/*    <input type="hidden" id="amount" name="amount" value="100"></input>*/}
                            {/*    <input type="hidden" id="tax_amount" name="tax_amount" value="10" required></input>*/}
                            {/*    <input type="hidden" id="total_amount" name="total_amount" value="110" required></input>*/}
                            {/*    <input type="hidden" id="transaction_uuid" name="transaction_uuid"  required></input>*/}
                            {/*    <input type="hidden" id="product_code" name="product_code" value="EPAYTEST"*/}
                            {/*           required></input>*/}
                            {/*    <input type="hidden" id="product_service_charge" name="product_service_charge" value="0"*/}
                            {/*           required></input>*/}
                            {/*    <input type="hidden" id="product_delivery_charge" name="product_delivery_charge" value="0"*/}
                            {/*           required></input>*/}
                            {/*    <input type="hidden" id="success_url" name="success_url" value="https://esewa.com.np"*/}
                            {/*           required></input>*/}
                            {/*    <input type="hidden" id="failure_url" name="failure_url" value="https://google.com"*/}
                            {/*           required></input>*/}
                            {/*    <input type="hidden" id="signed_field_names" name="signed_field_names"*/}
                            {/*           value="total_amount,transaction_uuid,product_code" required></input>*/}
                            {/*    <input type="hidden" id="signature" name="signature" required></input>*/}
                            {/*</form>*/}

                            <Card
                                className="card-stats"
                                style={{height: "100%", cursor: "pointer"}}
                                onClick={() => esewaRef.current.submit()}
                            >
                                <CardBody style={{display: "flex", alignItems: "center"}}>
                                    <img
                                        alt="esewa"
                                        src={require("assets/img/esewa.png")}
                                        style={{width: "100%"}}
                                    ></img>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xs={6} className="mb-3">
                            <Card
                                className="card-stats"
                                style={{height: "100%", cursor: "pointer"}}
                                // onClick={() =>
                                //     khaltiCheckout.show({
                                //         amount: paymentDetail.calculated_amount * 100,
                                //         // amount: 10 * 100,
                                //     })
                                // }
                                onClick={() => {
                                    handleKhaltiPay();
                                }}
                            >
                                <CardBody style={{display: "flex", alignItems: "center"}}>
                                    <img
                                        alt="khalti"
                                        src={require("assets/img/khalti.svg")}
                                        style={{width: "100%"}}
                                    ></img>
                                </CardBody>
                            </Card>
                        </Col>
                        {/*<Col xs={6} className="mb-3">*/}
                        {/*  <Card*/}
                        {/*    className="card-stats"*/}
                        {/*    style={{ height: "100%", cursor: "pointer" }}*/}
                        {/*    onClick={handleprabhupay}*/}
                        {/*  >*/}
                        {/*    <CardBody style={{ display: "flex", alignItems: "center" }}>*/}
                        {/*      <img*/}
                        {/*        alt="prabhupay"*/}
                        {/*        src={require("assets/img/prabhupay.png")}*/}
                        {/*        style={{ width: "100%" }}*/}
                        {/*      ></img>*/}
                        {/*    </CardBody>*/}
                        {/*  </Card>*/}
                        {/*</Col>*/}
                        {/*<Col xs={6} className="mb-3">*/}
                        {/*  <Card*/}
                        {/*    className="card-stats"*/}
                        {/*    style={{ height: "100%", cursor: "pointer" }}*/}
                        {/*  >*/}
                        {/*    <CardBody*/}
                        {/*      style={{*/}
                        {/*        display: "flex",*/}
                        {/*        alignItems: "center",*/}
                        {/*        justifyContent: "center",*/}
                        {/*        padding: 0,*/}
                        {/*      }}*/}
                        {/*    >*/}
                        {/*      <img*/}
                        {/*        alt="connectips"*/}
                        {/*        src={require("assets/img/connectips.png")}*/}
                        {/*        style={{ width: "100%" }}*/}
                        {/*      ></img>*/}
                        {/*    </CardBody>*/}
                        {/*  </Card>*/}
                        {/*</Col>*/}
                        {/*<Col xs={6} className="mb-3">*/}
                        {/*  <Card*/}
                        {/*    className="card-stats"*/}
                        {/*    style={{ height: "100%", cursor: "pointer" }}*/}
                        {/*  >*/}
                        {/*    <CardBody style={{ display: "flex", alignItems: "center" }}>*/}
                        {/*      <img*/}
                        {/*        alt="imepay"*/}
                        {/*        src={require("assets/img/imepay.png")}*/}
                        {/*        style={{ width: "100%" }}*/}
                        {/*      ></img>*/}
                        {/*    </CardBody>*/}
                        {/*  </Card>*/}
                        {/*</Col>*/}
                    </Row>
                )}

                {paymentSuccess &&
                    <Row>
                        <Col xs={12}>
                            <div style={{
                                paddingLeft: '20px',
                                paddingRight: '20px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}>
                                <span style={{color: 'green', textAlign: 'center'}}>Congratulation Your Payment was successfully done with payment method KHALTI.</span>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '85%',
                                    gap: '5px',
                                    justifyContent: 'flex-start'
                                }}>
                                <span
                                    className={'mt-2 font-weight-bold'}>Payment Method: KHALTI</span>
                                    <span
                                        className={'font-weight-bold'}>Reference No: <strong>{khaltiResponse.idx || '30'}</strong></span>
                                    <span
                                        className={'font-weight-bold'}>Transaction Id: <strong>{khaltiResponse.idx || ''}</strong></span>
                                    <span
                                        className={'font-weight-bold'}>Total Amount : Rs. <strong>{khaltiResponse.amount / 100 || ''}</strong></span>
                                </div>
                                <Button className={'all-btn'}
                                        style={{marginTop: '30px', marginBotton: '30px', width: '50%'}} onClick={() => {
                                    toggle()
                                    history.push('/admin/Lessons')
                                }}>Go To Lesson</Button>
                            </div>
                        </Col>
                    </Row>
                }

                {paymentError &&
                    <Row>
                        <Col xs={12} className="mb-3">
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%'
                            }}>
                                <Alert color="warning">
                                    <strong>Payment Failed!</strong> Please Try again later
                                </Alert>
                                <Button className={'all-btn'} style={{width: '50%'}}
                                        onClick={() => {
                                            toggle()
                                            history.push('/admin/Lessons')
                                        }
                                        }>Go To Lesson</Button>

                            </div>
                        </Col>
                    </Row>
                }

            </div>
        </Modal>
    );
};

export default memo(Payment);
