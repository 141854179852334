
import React from "react";

// reactstrap components
import { AvFeedback, AvForm, AvGroup, AvInput } from "availity-reactstrap-validation";
import { withSnackbar } from "notistack";
import {
    Button,
    Card,
    CardBody,
    Col,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
    Modal,
} from "reactstrap";

const Services = require("../../RemoteServices/RemoteServices.js");

class ForgotPassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            snackBar: false,
            loggingIn: false,
            showOtpModal: false,
            email: '',
            otp: '',
            otpLoading: false,
            emailError:false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        if (localStorage.getItem("user")) {
            this.props.history.push("/admin/dashboard");
        }
    }

    async handleSubmit(event, values) {
        event.preventDefault();
        this.setState({
            emailError:false
        })
        if (this.validateEmail(this.state.email) === false) {
            this.setState({ emailError: true })
        } else {
            this.setState((prevState) => ({
                ...prevState,
                loggingIn: true,
                email:this.state.email
            }));
            await Services.verifyEmail(values)
                .then((response) => {
                    this.setState((prevState) => ({
                        showOtpModal: true,
                        loggingIn: false,
                    }))
                })
                .catch((error) => {
                    this.setState((prevState) => ({ ...prevState, snackBar: true, loggingIn: false, }));
                    this.props.enqueueSnackbar(error.message, {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                    });
                });
        }
    }

    handleInvalidSubmit = (event, errors) => {
        this.props.enqueueSnackbar(`Please enter ${errors.join(" and ")}`, {
            variant: "error",
            anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
            },
        });
    };

    handleOtpSubmit = async (event, values) => {
        event.preventDefault();
        this.setState((prevState) => ({
            ...prevState,
            otpLoading: true
        }));
        await Services.verifyOtp({
            otp: values.otp,
            email: this.state.email
        })
            .then((response) => {
                this.setState({
                    showOtpModal: false,
                    otpLoading: false,
                })
                this.props.history.push("/auth/changePassword", { email: this.state.email, otp: this.state.otp });
            })
            .catch((error) => {
                this.setState({ otpLoading: false })
                this.props.enqueueSnackbar("Invalid OTP", {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                });
            });
    }

    handleEmailChange = (event) => {
        // Update the email value in the component's state
        this.setState({ email: event.target.value });
    };

    handleOtpChange = (event) => {
        this.setState({ otp: event.target.value })
    }


    validateEmail = (email) => {
        // Regular expression for basic email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // Test the email against the regular expression
        console.log('asdfasdf', emailRegex.test(email))
        return emailRegex.test(email);
    }

    handleInput = (e) => {
        const { name, value } = e.target
        this.setState({ [name]: value.trim('') });
    };

    render() {
        return (
            <>
                <Col lg="5" md="7" style={{ minHeight: "calc(100vh - 76px - 118px)" }}>
                    <Card className="bg-secondary shadow border-0">
                        <CardBody className="px-lg-5 py-lg-5 text-center">
                            <img alt="..." src={require("assets/img/brand/argon-logo.png")} />
                            <AvForm
                                onValidSubmit={this.handleSubmit}
                                onInvalidSubmit={this.handleInvalidSubmit}
                            >
                                <AvGroup className="mb-3 mt-3">
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-email-83" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Email"
                                            type="text"
                                            name="email"
                                            value={this.state.email}
                                            onChange={this.handleInput}
                                            required
                                            errorMessage="Invalid email address"
                                        />
                                    </InputGroup>
                                    {this.state.emailError && <span style={{ color: 'red' }}>Invalid email address</span>}
                                </AvGroup>

                                <div className="text-center mt-0">
                                    <Button className="my-4" color="primary">
                                        {this.state.loggingIn ? "Verifying Email ..." : "Verify Email"}
                                    </Button>
                                </div>
                            </AvForm>
                        </CardBody>
                    </Card>
                </Col>
                {this.state.showOtpModal &&
                    <Modal
                        keyboard={false}
                        backdrop={'static'}
                        className="modal-dialog-centered modal-md"
                        isOpen={this.state.showOtpModal}
                        toggle={() => this.setState({ showOtpModal: false })}
                    >
                        <div className="modal-header">
                            <h2 className="modal-title" id="modal-title-default">
                                Enter Your OTP
                            </h2>
                            <button
                                aria-label="Close"
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => this.setState({ showOtpModal: false })}
                            >
                                <span aria-hidden={true}>×</span>
                            </button>
                        </div>
                        <div className={'modal-body'}>
                            <AvForm
                                onValidSubmit={this.handleOtpSubmit}
                                onInvalidSubmit={this.handleInvalidSubmit}
                            >
                                <AvGroup className="mb-3">
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fa fa-user" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <AvInput
                                            placeholder="Otp"
                                            type="text"
                                            name="otp"
                                            value={this.state.otp}
                                            errorMessage={'Invalid OTP'}
                                            onChange={this.handleOtpChange}
                                            required
                                            autoFocus
                                        />
                                    </InputGroup>
                                </AvGroup>
                                <div className="text-center">
                                    <Button disabled={this.state.createAccount} className="my-4" color="primary">
                                        {this.state.otpLoading ? "Submitting ..." : "Submit Your Otp"}
                                    </Button>
                                </div>

                            </AvForm>
                        </div>
                    </Modal>

                }
            </>
        );
    }
}

export default withSnackbar(ForgotPassword);
