import MediaDevice from './MediaDevice';
import Emitter from './Emitter';

const PC_CONFIG = { iceServers: [{ urls: ['stun:stun.l.google.com:19302'] }] };

export class PeerConnection extends Emitter {

  constructor(socket) {
    super();
    this.userId = JSON.parse(localStorage.getItem("user"))?.id 
    this.full_name = JSON.parse(localStorage.getItem("user"))?.full_name
    this.socket = socket
    this.pc = new RTCPeerConnection(PC_CONFIG);

    this.pc.onicecandidate = (event) => 
        this.socket.send(
        JSON.stringify({
          action: "call",
          action_type: "call",
          calling_to: "admin",
          candidate: event.candidate,
          user_id:this.userId,
          me_id:this.userId,
          full_name:this.full_name
      }))

      this.pc.ontrack = (event) => this.emit('peerStream', event.streams[0]);

      this.mediaDevice = new MediaDevice();
  }

  /**
   * Starting the call
   * @param {Boolean} isCaller
   */
  start(isCaller) {
    this.mediaDevice
      .on('changeStream', (stream) => {
        this.emit('localStream', stream)
      })
      .on('stream', (stream) => {
        stream.getTracks().forEach((track) => {
          this.pc.addTrack(track, stream);
        });
        this.emit('localStream', stream);
        if (isCaller) {
          this.socket.send(
            JSON.stringify({ 
              action: "call",
              action_type: "request",
              from:this.full_name,
              calling_to: "admin",
              user_id: this.userId,
              me_id: this.userId,
              full_name: this.full_name,
          }));
        } 
        else {
          this.createOffer();
        }
      })
      .start();

    return this;
  }

  /**
   * Stop the call
   * @param {Boolean} isStarter
   */
  stop(isStarter) {
    if (isStarter) {
      //socket.emit('end', { to: this.friendID });
      this.socket.send(
      JSON.stringify({ 
        action: "call",
        action_type: "end",
        isStarter:isStarter,
        calling_to: "admin",
        user_id: this.userId,
        me_id: this.userId,
        full_name: this.full_name,
      })
    )
    }
    this.mediaDevice.stop();
    this.pc.close();
    this.pc = null;
    this.off();
    return this;
  }

  createOffer() {
    this.pc.createOffer()
      .then(this.getDescription.bind(this))
      .catch((err) => console.log(err));
    return this;
  }

  createAnswer() {
    this.pc.createAnswer()
      .then(this.getDescription.bind(this))
      .catch((err) => console.log(err));
    return this;
  }

  /**
   * @param {RTCLocalSessionDescriptionInit} desc - Session description
   */
  getDescription(desc) {
    this.pc.setLocalDescription(desc);
    //socket.emit('call', { to: this.friendID, sdp: desc });
    this.socket.send(
      JSON.stringify({
        action: "call",
        action_type: "call",
        calling_to: "admin",
        sdp: desc,
        user_id:this.userId,
        me_id:this.userId,
        full_name:this.full_name
      })
    )

    return this;
  }

  /**
   * @param {RTCSessionDescriptionInit} sdp - Session description
   */
  setRemoteDescription(sdp) {
    const rtcSdp = new RTCSessionDescription(sdp);
    this.pc.setRemoteDescription(rtcSdp);
    return this;
  }

  /**
   * @param {RTCIceCandidateInit} candidate - ICE Candidate
   */
  addIceCandidate(candidate) {
    if (candidate) {
      const iceCandidate = new RTCIceCandidate(candidate);
      this.pc.addIceCandidate(iceCandidate);
    }
    return this;
  }
}
