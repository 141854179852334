/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {Button, Card, CardBody, CardHeader, Col, Row} from "reactstrap";
// core components
import './styles.scss'
import Services from '../../RemoteServices/RemoteServices'
import avatar from '../../assets/img/theme/avatarnew.png'
import AnnouncementField from "../../components/Community/AnnouncementField";
import CommunityPostList from "../../components/Community/CommunityPostList";
import FollowingModal from "../../components/Modals/FollowingModal";
import RewardModal from "../../components/Modals/RewardModal";
import FollowersModal from "../../components/Modals/FollowersModal";
import PeopleYouMayKnow from "../../components/Community/PeopleYouMayKnow";
import moment from "moment";

class OtherProfile extends React.Component {
    state = {
        userdetails: '',
        communityList: '',
        loading: true,
        showFollowing: false,
        showFollowers: false,
        communityLoading: true,
        followingList: '',
        peopleList: '',
    };

    getPeopleYouMayKnow = async () => {
        Services.referredUser().then((response) => {
            this.setState((prevState) => ({
                ...prevState,
                peopleList: response,
            }));
        }).catch(e => {
            throw e
        })
    }

    getFollowingList = async () => {
        Services.getFollowingList('follower', this.state.userdetails.user.id).then((response) => {
            this.setState((prevState) => ({
                ...prevState,
                followingList: response,
            }));
        }).catch(e => {
            throw e
        })
    }

    getFollowerList = async () => {
        Services.getFollowingList('following', this.state.userdetails.user.id).then((response) => {
            this.setState((prevState) => ({
                ...prevState,
                followingList: response,
            }));
        }).catch(e => {
            throw e
        })
    }

    toggleModal = (state) => {
        this.setState({
            [state]: !this.state[state],
        });
    };

    getCommunity = async (url) => {
        if (url) {
            Services.getCommunity(url).then((response) => {
                this.setState((prevState) => ({
                    ...prevState,
                    communityList: {
                        results: [...prevState.communityList.results, ...response.results]
                    }
                }));
            }).catch(e => {
                throw e
            })
        } else {
            const response = await Services.getCommunity(`community/?user=${this.props.match.params.id}`);
            this.setState({
                communityList: response,
                loading: false,
            });
        }
    }


    async componentDidMount() {
        await this.getUserDetails(this.props.match.params.id);
        await this.getCommunity();
        await this.getPeopleYouMayKnow();
    }


    getUserDetails = async (id) => {
        const response = await Services.getUserDetails(id);
        this.setState({
            userdetails: response.results[0]
        })
    }

    render() {
        let userDetails = this.state.userdetails
        const padWithZero = (number, length) => {

            let my_string = '' + number;
            while (my_string.length < length) {
                my_string = '0' + my_string;
            }

            return my_string;

        }
        return (
            <>
                <Row className={'m-0 p-0'}>
                    {/* Page content */}
                    <Col className={'m-0 p-0'} xl={9}>
                        <div className={'m-0 p-0'}>
                            <Row className={'m-0 p-0'}>
                                <Card className={'m-0 pb-2'} style={{
                                    justifyContent: 'flex-end',
                                    minHeight: '150px',
                                    // height: '20%',
                                    width: '100%',
                                    borderRadius: '0px', backgroundColor: '#6C32A0',
                                }}>
                                    <div className={'followers'} style={{
                                        display: "flex",
                                        flexDirection: 'row',
                                        height: '30px',
                                        // marginTop: '60px',
                                        // marginLeft: '20%',
                                        justifyContent: 'flex-start',
                                        // alignItems: 'center'
                                    }}>
                                        <div className={'profileImage'}>
                                            <div className={'imageAndDetail'}>
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    // marginTop: '-160px',
                                                    marginLeft: "14px"
                                                }}>
                                                    <img
                                                        height={180}
                                                        width={180}
                                                        alt="..."
                                                        className="rounded-circle"
                                                        src={userDetails?.user?.profile_picture || avatar}
                                                    />
                                                    <div style={{
                                                        marginLeft: '0px',
                                                        marginTop: '120px'
                                                    }}>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <Button className={'button-pad'} style={{
                                                backgroundColor: 'darkblue',
                                                color: 'white',
                                                borderRadius: '30px'
                                            }} size={'md'}>
                                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                                <span
                                                    onClick={() => {
                                                        this.toggleModal('showFollowers')
                                                        this.getFollowerList()
                                                    }}>Followers:{userDetails?.user?.follower_count}</span>
                                                </div>
                                            </Button>
                                            <Button className={'button-pad'} style={{
                                                backgroundColor: 'darkblue',
                                                color: 'white',
                                                borderRadius: '30px'
                                            }} size={'md'}>
                                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                                <span
                                                    onClick={() => {
                                                        this.toggleModal('showFollowing')
                                                        this.getFollowingList()
                                                    }
                                                    }>Followings:{userDetails?.user?.following_count}</span>
                                                </div>
                                            </Button>
                                            {/*<Button style={{*/}
                                            {/*    width: '25%',*/}
                                            {/*    backgroundColor: 'darkblue',*/}
                                            {/*    color: 'white',*/}
                                            {/*    padding: '5px',*/}
                                            {/*    borderRadius: '30px'*/}
                                            {/*}} size={'md'}>*/}
                                            {/*    <div style={{display: 'flex', flexDirection: 'column'}}>*/}
                                            {/*        <span>Posts:{userDetails?.user?.post_count}</span>*/}
                                            {/*    </div>*/}
                                            {/*</Button>*/}
                                            {/*<Button style={{*/}
                                            {/*    width: '25%',*/}
                                            {/*    backgroundColor: 'darkblue',*/}
                                            {/*    color: 'white',*/}
                                            {/*    padding: '5px',*/}
                                            {/*    borderRadius: '30px'*/}
                                            {/*}} size={'md'}>*/}
                                            {/*    <div style={{display: 'flex', flexDirection: 'column'}}>*/}
                                            {/*        <span>Likes:5000</span>*/}
                                            {/*    </div>*/}
                                            {/*</Button>*/}
                                        </div>
                                    </div>
                                </Card>
                            </Row>
                            <Row className={'change-pass'}>
                                <Col sm={12} md={12} xl={12}>
                                    <div className={'follow'} style={{
                                        display: "flex",
                                        flexDirection: 'row',
                                        marginTop: '5px',
                                        marginLeft: '18%',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center'
                                    }}>
                                        <Button size={'md'}>
                                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                                <span>Follow</span>
                                            </div>
                                        </Button>
                                        {/*<Button size={'md'}>*/}
                                        {/*    <div style={{display: 'flex', flexDirection: 'column'}}>*/}
                                        {/*        <span>Message</span>*/}
                                        {/*    </div>*/}
                                        {/*</Button>*/}
                                    </div>

                                </Col>
                            </Row>
                            <Row>
                                <div style={{
                                    width: '100%',
                                    marginTop: '10px',
                                    marginLeft: '20px',
                                    marginRight: '5px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'flex-start',
                                    padding: '1.5rem'
                                }}>
                                    <div className={'profile-id'}>
                                <span style={{
                                    fontSize: 22,
                                    fontWeight: 'bold'
                                }}>{userDetails.full_name}
                                    <span style={{
                                        color: 'purple'
                                    }}>{userDetails.is_paid ? ' : Premium User' : ''}
                                    </span>
                                </span>
                                        <span style={{
                                            color: 'black',
                                            fontWeight: 'bold'
                                        }}>USER ID: {userDetails.new_id}</span>
                                    </div>

                                    {/*{userDetails?.bio &&*/}
                                    {/*    <span style={{*/}
                                    {/*        fontSize: 16,*/}
                                    {/*        fontWeight: 'bold',*/}
                                    {/*        marginTop: '5px'*/}
                                    {/*    }}>{userDetails?.user?.bio}</span>*/}

                                    {/*}*/}

                                </div>
                            </Row>
                            <Row style={{marginLeft: '20px', marginRight: '20px'}}>
                                <Card className={'profile-card ml-3 mr-3'}
                                >
                                    <CardBody>
                                        <div className={'profile-card-detail'}>
                                            <div className={'profile-detail'}>
                                                <span style={{
                                                    marginTop: 10,
                                                    fontSize: 16
                                                }}><i
                                                    className={'fa fa-phone'}></i>Contact: &nbsp;&nbsp;{'XXXXXXXXXX'}</span>
                                                <span style={{
                                                    marginTop: 10,
                                                    fontSize: 16
                                                }}><i
                                                    className={'fa fa-birthday-cake'}></i>&nbsp;&nbsp;DOB: &nbsp;&nbsp;{userDetails?.user?.dob || 'N/A'}</span>
                                                <span style={{
                                                    marginTop: 10,
                                                    fontSize: 16
                                                }}><i
                                                    className={`fa ${userDetails?.user?.gender === 'Male' ? 'fa-male' : 'fa-female'}`}></i>&nbsp;&nbsp;&nbps;Gender: &nbsp;&nbsp;{userDetails?.user?.gender || 'N/A'}</span>
                                                <span style={{
                                                    marginTop: 10,
                                                    fontSize: 16
                                                }}><i
                                                    className={'fa fa-minus-circle'}></i>&nbsp;&nbsp;Email: &nbsp;&nbsp;{'XXXXXXXXXXXXXXXXX'}</span>
                                            </div>

                                            <div className={'profile-detail'}>
                                                <span style={{
                                                    marginTop: 10,
                                                    fontSize: 16
                                                }}><i
                                                    className={'fa fa-people-carry'}></i>&nbsp;&nbsp;Total Referrals: &nbsp;&nbsp;{userDetails?.user?.referred_count || 'N/A'}</span>
                                                <span style={{
                                                    marginTop: 10,
                                                    fontSize: 16
                                                }}><i
                                                    className={'fa fa-money-check'}></i>&nbsp;&nbsp;Referral Code: &nbsp;&nbsp;{userDetails?.user?.referral_code || 'N/A'}</span>
                                                <span style={{
                                                    marginTop: 10,
                                                    fontSize: 16
                                                }}><i
                                                    className={'fa fa-calendar-times'}></i>&nbsp;&nbsp;Joined: &nbsp;&nbsp;{moment(userDetails?.user?.created_at).format('YYYY-MM-DD') || 'N/A'}</span>
                                                <span style={{
                                                    marginTop: 10,
                                                    fontSize: 16
                                                }}><i
                                                    className={'fa fa-address-card'}></i>&nbsp;&nbsp;From: &nbsp;&nbsp;{userDetails?.user?.address || 'N/A'}</span>
                                            </div>

                                        </div>
                                    </CardBody>
                                </Card>
                            </Row>
                            <Row className={'m-0 p-0'}>
                                <Card className='ml-3 mr-3'
                                      style={{
                                          width: '100%',
                                          borderRadius: '20px',
                                          marginTop: '5px',
                                          alignItems: 'center',
                                          border: 'none',
                                          backgroundColor: 'transparent'
                                      }}
                                >
                                    <CardBody style={{width: '100%'}}>
                                        <CommunityPostList
                                            getCommunity={this.getCommunity}
                                            communityList={this.state.communityList}
                                            communityLoading={this.state.loading}
                                        />
                                    </CardBody>
                                </Card>
                            </Row>
                        </div>
                    </Col>
                    <Col xl={3} className={'people-know'}>
                        <PeopleYouMayKnow
                            getPeopleYouMayKnow={this.getPeopleYouMayKnow}
                            peopleList={this.state.peopleList}/>
                    </Col>
                </Row>
                {this.state.showFollowing && (
                    <FollowingModal
                        referral={this.state.userdetails.user.referrals}
                        referredCount={this.state.userdetails.user.referred_count}
                        showReferral={this.state.showFollowing}
                        toggleModal={this.toggleModal}/>
                )}

                {this.state.showFollowing && (
                    <FollowingModal
                        referral={this.state.userdetails.user.referrals}
                        referredCount={this.state.userdetails.user.referred_count}
                        showReferral={this.state.showFollowing}
                        followingList={this.state.followingList}
                        toggleModal={this.toggleModal}/>
                )}

                {this.state.showFollowers && (
                    <FollowersModal
                        referral={this.state.userdetails.user.referrals}
                        referredCount={this.state.userdetails.user.referred_count}
                        showReferral={this.state.showFollowers}
                        followingList={this.state.followingList}
                        toggleModal={this.toggleModal}/>
                )}

            </>
        );
    }
}

export default OtherProfile;
