/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { withSnackbar } from "notistack";

// reactstrap components
import { Button, Card, CardBody, Col, Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import { AvFeedback, AvForm, AvGroup, AvInput } from "availity-reactstrap-validation";
import GoogleLogin from "react-google-login";
import GoogleBadge from "../../assets/img/icons/common/google.svg";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import FacebookBadge from "../../assets/img/f_logo_RGB-Blue_58.png";
import Services from "../../RemoteServices/RemoteServices";
import { Link } from "react-router-dom";
import moment from "moment";

class Register extends React.Component {
    state = {
        createAccount: false,
        referral_code: '',
        disabled: false,
        showPassword: false,
        showPasswordAgain: false,
        email: '',
        full_name: '',
        gender: '',
        password1: '',
        password2: '',
        phone_number: '',
        referral_code: '',
        dob:'',
        emailError:false,
        phoneNumberError:false,
        passworError:false,
        fullNameError:false,
        password2Error:false,
        passwordNotMatched:false,
    }


    validateEmail = (email) => {
        // Regular expression for basic email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // Test the email against the regular expression
        return emailRegex.test(email);
    }

    handleInput = (e) => {
        const { name, value } = e.target
        console.log('asdfasdf',name,value)
        if(name==='email'||name==='password1'||name==='password2'){
            this.setState({ [name]: value.trim('') });
        }else{
        this.setState({ [name]: value });
        }
    };

    hasReferralCode = () => {
        const params = new URLSearchParams(this.props.location.search);
        const referralCode = params.get('referralCode')
        if (referralCode) {
            this.setState((prevState) => ({
                ...prevState,
                referral_code: referralCode,
                disabled: true
            }))
        }

    }

    componentDidMount() {
        this.hasReferralCode();
    }

    responseGoogle = async (response) => {
        if (response) {
            let { accessToken } = response
            let tokenData = {
                access_token: accessToken
            }
            await Services.createGoogleExchangeToken(tokenData).then((response) => {
                localStorage.setItem('token', response.access);
                localStorage.setItem("refresh", response.refresh);
                localStorage.setItem('user', JSON.stringify(response.user));
                this.props.history.push("/admin/index");
            })
                .catch((error) => {
                    this.setState({ snackBar: true, loggingIn: false });
                    this.props.enqueueSnackbar('Username or Password is incorrect', {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right',
                        }
                    })
                })
        }

    }

    responseFacebook = async (response) => {
        if (response) {
            let { accessToken } = response
            let tokenData = {
                access_token: accessToken
            }
            await Services.createExchangeToken(tokenData).then((response) => {
                if (response.access) {
                    localStorage.setItem('token', response.access);
                    localStorage.setItem("refresh", response.refresh);
                    localStorage.setItem('user', JSON.stringify(response.user));
                    this.props.history.push('/admin/index')
                } else {
                    this.setState({ snackBar: true, loggingIn: false });
                    this.props.enqueueSnackbar(response.detail, {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                    });
                }
            })
                .catch((error) => {
                    this.setState({ snackBar: true, loggingIn: false });
                    this.props.enqueueSnackbar(error.message, {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                    });
                });
        }

    }

    handleInvalidSubmit = (event, errors) => {
        this.props.enqueueSnackbar(`Please enter ${errors.join(" and ")}`, {
            variant: "error",
            anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
            },
        });
    };

    handleLogin = async (data) => {
        // requestForToken()
        await Services.sendLogin(data)
            .then((response) => {
                if (response.access) {
                    localStorage.setItem("token", response.access);
                    localStorage.setItem("refresh", response.refresh);
                    localStorage.setItem("user", JSON.stringify(response.user));
                    this.props.history.push("/admin/dashboard");
                } else {
                    this.setState({ snackBar: true, loggingIn: false });
                    this.props.enqueueSnackbar(response.detail, {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                    });
                }
            })
            .catch((error) => {
                this.setState({ snackBar: true, loggingIn: false });
                this.props.enqueueSnackbar("Username or Password is incorrect", {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                });
            });
    }

    handleSubmit = async (event, values) => {
        console.log('asdfasdf',this.state.dob)
        event.preventDefault();
        this.setState({
            emailError:false,
            passwordError:false,
            phoneNumberError:false,
            fullNameError:false,
            password2Error:false,
            passwordNotMatched:false,
        })
        let reqDto = {
            email: this.state.email,
            full_name: this.state.full_name,
            gender: this.state.gender,
            password1: this.state.password1,
            password2: this.state.password2,
            phone_number: this.state.phone_number,
            referral_code: this.state.referral_code,
            dob:this.state.dob?moment(this.state.dob).format('YYYY-MM-DD'):null,
        }
         if(this.state.full_name===''){
            this.setState({fullNameError:true})
        }
        else if (this.validateEmail(this.state.email)===false) {
            this.setState({ emailError: true })
        } else if(this.state.password1===''|| this.state.password1.length<5){
            this.setState({ passwordError: true })   
        } else if(this.state.password2===''||this.state.password2.length<5){
            this.setState({ password2Error: true })
            
        }
        else if (this.state.password1 !== this.state.password2) {
            this.setState({ passwordNotMatched:true });
        }
        else if(this.state.phone_number===''){
            this.setState({ phoneNumberError: true })
        }
        else {
            await this.setState({ createAccount: true });
            await Services.addUser(reqDto)
                .then((response) => {
                    if (response) {
                        // localStorage.setItem("token", response.access);
                        // localStorage.setItem("user", JSON.stringify(response.user));
                        // this.props.history.push("/auth/login");
                        this.handleLogin({ email: this.state.email, password: this.state.password1 })

                    } else {
                        this.setState({ snackBar: true, loggingIn: false, createAccount: false });
                        this.props.enqueueSnackbar(response.detail, {
                            variant: "error",
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "right",
                            },
                        });
                    }
                })
                .catch((error) => {
                    this.setState({ snackBar: true, loggingIn: false, createAccount: false });
                    if (error?.email?.[0]) {
                        this.props.enqueueSnackbar(error.email[0], {
                            variant: "error",
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "right",
                            },
                        })
                    }
                    if (error?.phone_number?.[0]) {
                        this.props.enqueueSnackbar(error.phone_number[0], {
                            variant: "error",
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "right",
                            },
                        })
                    }
                    if (error?.dob?.[0]) {
                        this.props.enqueueSnackbar(error.dob[0], {
                            variant: "error",
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "right",
                            },
                        })
                    }
                });
        }
    }

    togglePassword = (state) => {
        this.setState({
            [state]: !this.state[state],
        });
    }


    render() {
        return (
            <>
                <Col lg="6" md="7">
                    <Card className="bg-secondary shadow border-0">
                        <CardBody className="px-lg-5 py-lg-5 text-center">
                            <img alt="..." src={require("assets/img/brand/argon-logo.png")} />
                            <div className="text-center text-muted mb-4 mt-4">
                                <small>
                                    Enter Your Details
                                </small>
                            </div>
                            <AvForm
                                onValidSubmit={this.handleSubmit}
                                onInvalidSubmit={this.handleInvalidSubmit}
                            >
                                 <AvGroup className="mb-3">
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-email-83" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Full Name"
                                            type="text"
                                            name="full_name"
                                            value={this.state.full_name}
                                            onChange={this.handleInput}
                                            required
                                        />    
                                    </InputGroup>
                                    {this.state.fullNameError && <span style={{color:'red'}}>Please Enter Full Name</span>}

                                </AvGroup>

                                <AvGroup className="mb-3">
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-email-83" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Email"
                                            type="text"
                                            name="email"
                                            value={this.state.email}
                                            onChange={this.handleInput}
                                            required
                                            errorMessage="Invalid email address"
                                        />    
                                    </InputGroup>
                                    {this.state.emailError && <span style={{color:'red'}}>Invalid email address</span>}
                                </AvGroup>

                                <AvGroup>
                                    <InputGroup className='input-group-alternative mb-3'
                                        style={{ background: '#ffffff' }}>
                                        <InputGroupAddon addonType='prepend'>
                                            <InputGroupText>
                                                <i className='fa fa-mars' />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <div><AvInput type='select' name='gender' value={this.state.gender} onChange={this.handleInput}>
                                            <option value='' hidden>Select Gender</option>
                                            <option value={'Male'}>Male</option>
                                            <option value={'Female'}>Female</option>
                                            <option value={'Other'}>Other</option>
                                        </AvInput>
                                        </div>
                                        <InputGroupAddon addonType='prepend' className={'ml-4'}>
                                            <InputGroupText>
                                                <i className='fa fa-birthday-cake' />&nbsp;&nbsp;
                                                DOB
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <AvInput name={'dob'} type={'date'} min={'1940-01-01'} max={'2022-01-01'} onChange={this.handleInput} />
                                    </InputGroup>
                                </AvGroup>


                                <AvGroup>
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-lock-circle-open" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Password"
                                            type={this.state.showPassword ? 'text' : 'password'}
                                            name="password1"
                                            value={this.state.password1}
                                            onChange={this.handleInput}
                                            required
                                            errorMessage="Please enter password"
                                        />
                                       
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className={this.state.showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'} color="secondary"
                                                    onClick={() => this.togglePassword('showPassword')}>
                                                </i>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                    </InputGroup>
                                    {this.state.passwordError && <span style={{color:'red'}}>Please Enter Password greater than or eqaul to 5 character.</span>}
                                </AvGroup>

                                <AvGroup>
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-lock-circle-open" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Re-Enter Password"
                                            type={this.state.showPasswordAgain ? 'text' : 'password'}
                                            name="password2"
                                            value={this.state.password2}
                                            onChange={this.handleInput}
                                            required
                                            errorMessage="Please Re-enter password"
                                        />
                                       
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className={this.state.showPasswordAgain ? 'fa fa-eye' : 'fa fa-eye-slash'} color="secondary"
                                                    onClick={() => this.togglePassword('showPasswordAgain')}>
                                                </i>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                    </InputGroup>
                                    {this.state.password2Error && <span style={{color:'red'}}>Please Re-Enter Password.</span>}
                                    {this.state.passwordNotMatched && <span style={{color:'red'}}>Password doesnot match.</span>}

                                </AvGroup>
                                <AvGroup>
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fa fa-phone" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Phone Number"
                                            type="number"
                                            name="phone_number"
                                            value={this.state.phone_number}
                                            pattern='[0-9]'
                                            onChange={this.handleInput}
                                        
                                        />
                                    </InputGroup>
                                    {this.state.phoneNumberError && <span style={{color:'red'}}>Please Enter Phone Number</span>}

                                </AvGroup>
                                <AvGroup>
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fa fa-user" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <AvInput
                                            placeholder="Referral Code"
                                            type="referralCode"
                                            name="referral_code"
                                            onChange={this.handleInput}
                                            value={this.state.referral_code}
                                            disabled={this.state.disabled}
                                        />
                                    </InputGroup>
                                </AvGroup>

                                <div className="text-center">
                                    <Button className="my-4" color="primary">
                                        {this.state.createAccount ? "Create an Account ..." : "Create an Account"}
                                    </Button>
                                </div>
                                <div style={{ marginTop: '-12px' }}>
                                    <span className={'font-weight-bold'}>OR</span></div>

                            </AvForm>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                padding: '10px',
                                justifyContent: 'space-around'
                            }}>
                                <GoogleLogin
                                    clientId="724611569483-scj2k9ja9va5skk4aa5quhctjn2udr0o.apps.googleusercontent.com"
                                    render={renderProps => (
                                        <button style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            height: '40px',
                                            alignItems: 'center',
                                            marginBottom: '10px'
                                        }} className={'googleLoginButton'} onClick={renderProps.onClick}
                                            disabled={renderProps.disabled}><img height={20} width={20}
                                                alt=""
                                                src={GoogleBadge} />&nbsp;&nbsp;Sign
                                            Up With Google</button>
                                    )}
                                    autoLoad={false}
                                    onSuccess={this.responseGoogle}
                                    cookiePolicy={'single_host_origin'}
                                />
                                {/* <FacebookLogin
                                    appId="154096314201916"
                                    fields="name,email,picture"
                                    callback={this.responseFacebook}
                                    autoLoad={false}
                                    render={renderProps => (
                                        <button style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '40px'
                                        }} className={'facebookLoginButton'} onClick={renderProps.onClick}
                                            disabled={renderProps.disabled}><img height={20} width={20}
                                                alt=""
                                                src={FacebookBadge} />&nbsp;&nbsp;Sign
                                            Up With Facebook</button>
                                    )}
                                /> */}
                            </div>
                            <div className="createAccountDiv mt-3"><p>Already Have An Account? <Link
                                to="/auth/login">Login</Link></p></div>

                        </CardBody>
                    </Card>
                </Col>

            </>
        );
    }
}

export default withSnackbar(Register);
