import React, {useEffect, useState} from 'react';
import {Button, FormGroup, Input, Label, Modal} from "reactstrap";
import MaterialTable from 'material-table';
import Services from "../../../RemoteServices/RemoteServices";
import {withSnackbar} from 'notistack';
import avatar from '../../../assets/img/theme/avatarnew.png'
import {useHistory} from "react-router-dom";
import moment from "moment";


const WalletModal = ({
                         showModal,
                         toggleModal,
                         tableData,
                         walletDetail,
                         enqueueSnackbar

                     }) => {
    console.log('walletDetail', walletDetail)
    const [walletList, setWalletList] = React.useState([]);
    const [showWallet, setShowWallet] = React.useState(false);
    const [addWallet, setAddWallet] = React.useState(false);
    const [wallet, setWallet] = React.useState({
        type: '',
        bank_name: '',
        full_name: '',
        number: '',
        wallet_name: '',
        branch: ''
    });
    const [showBank, setShowBank] = React.useState(false);
    const [showWallets, setShowWallets] = React.useState(false);
    const [walletDetailIndex, setWalletDetailIndex] = React.useState({
        walletIndex: '',
        selectedWalletId: ''
    })
    const [withdrawAmount, setWithdrawAmount] = React.useState('');
    const history = useHistory();
    const [withdrawResponse, setWithdrawResponse] = useState('');
    let totalRemaining = Number(walletDetail?.total_earning) - Number(walletDetail?.total_withdrawal) || 0;

    const selectWalletForPaymentRequest = (item, index) => {
        setWalletDetailIndex({
            walletIndex: index,
            selectedWalletId: item.id
        })

    }
    const getWallet = async () => {
        await Services.getWalletDetails().then((response) => {
            setWalletList(response)
        });
        if (walletList.length <= 3) {
            setAddWallet(false)
        } else {
            setAddWallet(true)
        }
    }

    const getWalletInformations = async () => {
        await Services.getWalletInformation().then((response) => {
            setWithdrawResponse(response)
        });
    }

    const handleUpdate = async () => {
        let walletData = {
            type: wallet.wallet_name === 'Esewa' || wallet.wallet_name === 'Khalti' || wallet.wallet_name === 'ImePay' ? wallet.wallet_name : "Bank",
            bank_name: wallet.bank_name || wallet.wallet_name,
            full_name: wallet.full_name,
            number: wallet.number,
            branch:wallet.branch||''
        };
        Services.setWalletDetails(walletData).then((response) => {
            getWallet();
            // setShowWallet(false);
        });
    }

    const deletePaymentDetail = async (item) => {
        await Services.deleteWalletDetail(item.id).then((response) => {
            getWallet();
            enqueueSnackbar('Payment Detail Deleted', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                }
            })
        }).catch((error) => {
            enqueueSnackbar('Error Occurred ', {
                variant: 'danger',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                }
            })
        });
    }

    const requestPayment = async () => {
        let reqDto = {
            wallet: walletDetailIndex.selectedWalletId,
            amount: withdrawAmount
        }
        await Services.withdrawRequest(reqDto)
            .then((response) => {
                toggleModal();
                enqueueSnackbar('Payment Request Successfully Submitted', {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    }
                })

            })
            .catch((error) => {
                enqueueSnackbar('Payment Request Submission Failed', {
                    variant: 'danger',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    }
                })

            });
    }


    const showUserProfile = (id) => {
        this.props.history.push(`/profile/otherProfile/${id}`);
    }

    useEffect(() => {
        getWallet();
        getWalletInformations();
    }, []);
    let base = window.config.appUrl;

    return (
        <>
            <Modal
                keyboard={false}
                backdrop={'static'}
                className="modal-dialog-centered modal-lg"
                isOpen={showModal}
                toggle={() => toggleModal()}
            >
                <div className="modal-header">
                    <h2 style={{justifyContent: 'center'}} className="modal-title" id="modal-title-default">
                        Wallet
                    </h2>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => toggleModal()}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className={'modal-body m-0 p-0 '}>

                    <div className={'m-4'} style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        <>
                            <h1 style={{fontFamily: 'serif', color: 'indigo'}}>Congratulations!! </h1>
                            <h3>You have earned</h3>
                        </>

                        <h1>Rs. {walletDetail.total_earning - walletDetail.total_withdrawal}</h1>
                        {totalRemaining >= 5000 ? <h3>is ready to withdrawal</h3> :
                            <h3 style={{color: 'red'}}>You must have more than or equal to Rs. 5000 to withdraw.</h3>
                        }
                    </div>
                    <>
                        {withdrawResponse?.previous_request ?
                            <div style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center'
                            }}>
                                <span
                                    style={{
                                        color: 'indigo',
                                        justifyContent: 'center',
                                        width: '90%',
                                        display: 'flex',
                                        fontWeight: 'bold'
                                    }}>
                                   Your withdrawal request of amount Rs. {withdrawResponse?.amount} with wallet information {withdrawResponse?.wallet} has been received and is now pending review.
                                </span>
                            </div>
                            :
                            <>
                                {totalRemaining >= 5000 ?
                                    <div className={'m-4'} style={{
                                        display: "flex",
                                        flexDirection: 'column',
                                        alignItems: "center"
                                    }}>
                                        <Label>Enter Amount</Label>
                                        <Input
                                            type={'number'}
                                            name={'withdrawAmount'}
                                            onChange={(event) => setWithdrawAmount(event.target.value)}
                                            placeholder="Enter preferred Amount" className={'input-field'}></Input>
                                        {withdrawAmount > walletDetail.total_earning - walletDetail.total_withdrawal || withdrawAmount < 5000 || withdrawAmount > 50000
                                            ? <span style={{marginTop: '10px', color: 'red'}}>Please Enter Valid Amount not greater than remaining balance and  minimum amount 5,000 or and maximum amount 50,000.</span>
                                            :
                                            <Button onClick={() => {
                                                if (withdrawAmount >= 5000 && withdrawAmount <= 50000) {
                                                    setShowWallet(true)
                                                } else {
                                                    enqueueSnackbar('Please Enter Amount Greater than 5000 and Less than 50000', {
                                                        variant: 'error',
                                                        anchorOrigin: {
                                                            vertical: 'bottom',
                                                            horizontal: 'right',
                                                        }
                                                    })

                                                }
                                            }

                                            } className={'mt-4 all-btn'}
                                                    style={{
                                                        width: '60%'
                                                    }}>Withdraw</Button>
                                        }
                                    </div>
                                    :
                                    <>
                                        {walletDetail?.total_earnings - walletDetail?.total_withdrawal > 0 ?
                                            <div className={'m-4'} style={{
                                                display: "flex",
                                                flexDirection: 'column',
                                                alignItems: "center"
                                            }}>
                                                <Button onClick={() => setShowWallet(true)} className={'mt-4 all-btn'}
                                                        style={{
                                                            width: '60%'
                                                        }}>Setup Wallet</Button>
                                            </div>
                                            :
                                            ''
                                        }
                                    </>
                                }
                            </>
                        }
                    </>


                    <div className={'m-4'}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                        }}>
                            <h3>Transaction History</h3>
                        </div>
                        <MaterialTable
                            options={{
                                search: false,
                                showTitle: false,
                                toolbar: false,
                                actionsColumnIndex: -1,
                                padding: "dense"
                            }}
                            columns={[
                                {
                                    title: 'Avatar',
                                    field: 'imageUrl',
                                    render: rowData => <img
                                        src={rowData?.profile_picture ? base.concat('/media/').concat(rowData.profile_picture) : avatar}
                                        style={{width: 40,height:40, borderRadius: '50%'}}/>
                                },
                                {
                                    title: 'Full Name',
                                    field: 'full_name'
                                },
                                {
                                    title: 'Transaction Date',
                                    render: rowData => <>
                                                <span>
                                                        {moment(rowData?.created_at).format('YYYY-MM-DD')}
                                                </span>
                                    </>
                                },
                                {
                                    title: ' Amount', field: 'amount', width: "20%"
                                },
                                {
                                    title: 'Type', field: 'type', render: rowData => <>
                                        <span
                                            className={`${rowData?.type === 'credited' ? "badge badge-success" : "badge badge-danger"}`}>{rowData.type.toUpperCase()}</span>
                                    </>
                                },
                            ]}
                            data={walletDetail?.ledgers}
                        />
                    </div>
                </div>
            </Modal>

            <Modal
                keyboard={false}
                backdrop={'static'}
                className="modal-dialog-centered modal-md"
                isOpen={showWallet}
                toggle={() => setShowWallet(!showWallet)}
            >
                <div className="modal-header">
                    <h2 className="modal-title" id="modal-title-default">
                        Add Wallet
                    </h2>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setShowWallet(!showWallet)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className={'modal-body'}>
                    {walletList.length <= 0 ?
                        <div style={{
                            display: 'flex',
                            width: '100%',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <h4 className={'mt--4'}>Add Bank Or Wallet</h4>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-evenly'
                            }}>
                                <Button onClick={() => {
                                    setShowBank(true)
                                    setShowWallets(false)
                                }} style={{
                                    paddingLeft: '20px',
                                    paddingRight: '20px',
                                    backgroundColor: '#6C32A0',
                                    color: 'white'
                                }} className={'btn'}>Bank</Button>
                                <Button onClick={() => {
                                    setShowWallets(true)
                                    setShowBank(false)
                                }} style={{
                                    paddingLeft: '20px',
                                    paddingRight: '20px',
                                    backgroundColor: '#6C32A0',
                                    color: 'white'
                                }} className={'btn'}>Wallet</Button>
                            </div>
                            {showBank && (
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                    justifyContent: 'center'
                                }}>
                                    <div style={{width: '100%', flexDirection: 'column', justifyContent: 'center'}}>
                                        <FormGroup className={'mt-4'}>
                                            <Input type="select" name="bank_name" id="exampleSelect"
                                                   onChange={(event) => setWallet({
                                                       ...wallet,
                                                       bank_name: event.target.value
                                                   })}>
                                                <option>Select Bank</option>
                                                <option value={'NABIL'}>Nabil Bank Limited</option>
                                                <option value={'MIBL'}>Mega Investment Bank Limited</option>
                                                <option value={'GLOBAL'}>Global IME Bank Limited</option>
                                                <option value={'ADBL'}>Agriculture Development Bank Limited</option>
                                                <option value={'NIBL'}>Nepal Investment Bank Limited</option>
                                                <option value={'ADBL'}>Standard Chartered Bank Limited</option>
                                                <option value={'HBL'}>Himalayan Bank Limited</option>
                                                <option value={'SBI'}>Nepal SBI Bank Limited</option>
                                                <option value={'NBL'}>Nepal Bank Limited</option>
                                                <option value={'LAXMISUNRISE'}>Laxmi Sunrise Bank Limited</option>
                                                <option value={'PCBL'}>Prime Commercial Bank Ltd</option>
                                                <option value={'PRVU'}>Prabhu Bank Ltd</option>
                                            </Input>
                                            <Input className={'mt-2'} placeholder={'Account Name'}
                                                   type={'text'} name={'bank_name'}
                                                   value={wallet.bank_name}
                                                   onChange={(event) => setWallet({
                                                       ...wallet,
                                                       bank_name: event.target.value
                                                   })}></Input>
                                            <Input className={'mt-2'} placeholder={'Account Number'}
                                                   type={'text'}
                                                   name={'number'} value={wallet.number}
                                                   onChange={(event) => setWallet({
                                                       ...wallet,
                                                       number: event.target.value
                                                   })}
                                            ></Input>
                                            <Input onChange={(event) => setWallet({
                                                ...wallet,
                                                branch: event.target.value
                                            })} className={'mt-2'} placeholder={'Branch'} type={'text'}></Input>
                                        </FormGroup>
                                        <div style={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'center'
                                        }}>
                                            <Button onClick={async () => {
                                                handleUpdate();
                                            }} style={{
                                                paddingLeft: '20px',
                                                paddingRight: '20px',
                                                backgroundColor: '#6C32A0',
                                                color: 'white'
                                            }} className={'btn'}>Update</Button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {showWallets && (
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                    justifyContent: 'center'
                                }}>
                                    <div style={{width: '80%', flexDirection: 'column', justifyContent: 'center'}}>
                                        <FormGroup className={'mt-4'}>
                                            <Input type="select" name="wallet_name" value={wallet.wallet_name}
                                                   onChange={(event) => setWallet({
                                                       ...wallet,
                                                       wallet_name: event.target.value
                                                   })} id="exampleSelect">
                                                <option>Select Wallet</option>
                                                <option value={'Esewa'}>Esewa</option>
                                                <option value={'Khalti'}>Khalti</option>
                                                <option value={'ImePay'}>ImePay</option>
                                            </Input>
                                            <Input name={'full_name'} value={wallet.full_name}
                                                   onChange={(event) => setWallet({
                                                       ...wallet,
                                                       full_name: event.target.value
                                                   })} className={'mt-2'}
                                                   placeholder={'Name'} type={'text'}></Input>
                                            <Input name={'number'} value={wallet.number}
                                                   onChange={(event) => setWallet({
                                                       ...wallet,
                                                       number: event.target.value
                                                   })} className={'mt-2'}
                                                   placeholder={'ID'} type={'text'}></Input>
                                        </FormGroup>
                                        <div style={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'center'
                                        }}>
                                            <Button onClick={async () => {
                                                handleUpdate();
                                                setShowWallet(true);
                                            }}
                                                    style={{
                                                        paddingLeft: '20px',
                                                        paddingRight: '20px',
                                                        backgroundColor: '#6C32A0',
                                                        color: 'white'
                                                    }} className={'btn'}>Update</Button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div> :
                        <div className={'mt--3'}>
                            <>
                                {!addWallet && walletList.length < 3 &&
                                    <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                                        <Button onClick={() => {
                                            setAddWallet(true)
                                            setShowWallets(false)
                                        }} style={{
                                            paddingLeft: '20px',
                                            paddingRight: '20px',
                                            backgroundColor: '#6C32A0',
                                            color: 'white',
                                            width: '100%'
                                        }} className={'btn'}>Add Another Wallet</Button>
                                    </div>
                                }

                                {addWallet &&
                                    <div style={{
                                        display: 'flex',
                                        width: '100%',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-evenly'
                                        }}>
                                            <Button onClick={() => {
                                                setShowBank(true)
                                                setShowWallets(false)
                                            }} style={{
                                                paddingLeft: '20px',
                                                paddingRight: '20px',
                                                backgroundColor: '#6C32A0',
                                                color: 'white'
                                            }} className={'btn'}>Bank</Button>
                                            <Button onClick={() => {
                                                setShowWallets(true)
                                                setShowBank(false)
                                            }} style={{
                                                paddingLeft: '20px',
                                                paddingRight: '20px',
                                                backgroundColor: '#6C32A0',
                                                color: 'white'
                                            }} className={'btn'}>Wallet</Button>
                                        </div>
                                        {showBank && (
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                width: '100%',
                                                justifyContent: 'center'
                                            }}>
                                                <div style={{
                                                    width: '100%',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center'
                                                }}>
                                                    <FormGroup className={'mt-4'}>
                                                        <Input type="select" name="bank_name" id="exampleSelect"
                                                               onChange={(event) => setWallet({
                                                                   ...wallet,
                                                                   bank_name: event.target.value
                                                               })}>
                                                            <option>Select Bank</option>
                                                            <option value={'NABIL'}>Nabil Bank Limited</option>
                                                            <option value={'MIBL'}>Mega Investment Bank Limited</option>
                                                            <option value={'GLOBAL'}>Global IME Bank Limited</option>
                                                            <option value={'ADBL'}>Agriculture Development Bank
                                                                Limited
                                                            </option>
                                                            <option value={'NIBL'}>Nepal Investment Bank Limited
                                                            </option>
                                                            <option value={'ADBL'}>Standard Chartered Bank Limited
                                                            </option>
                                                            <option value={'HBL'}>Himalayan Bank Limited</option>
                                                            <option value={'SBI'}>Nepal SBI Bank Limited</option>
                                                            <option value={'NBL'}>Nepal Bank Limited</option>
                                                            <option value={'LAXMISUNRISE'}>Laxmi Sunrise Bank Limited
                                                            </option>
                                                            <option value={'PCBL'}>Prime Commercial Bank Ltd</option>
                                                            <option value={'PRVU'}>Prabhu Bank Ltd</option>
                                                        </Input>
                                                        <Input className={'mt-2'} placeholder={'Account Name'}
                                                               type={'text'} name={'full_name'}
                                                               value={wallet.full_name}
                                                               onChange={(event) => setWallet({
                                                                   ...wallet,
                                                                   full_name: event.target.value
                                                               })}></Input>
                                                        <Input className={'mt-2'} placeholder={'Account Number'}
                                                               type={'text'}
                                                               name={'number'}
                                                               value={wallet.number}
                                                               onChange={(event) => setWallet({
                                                                   ...wallet,
                                                                   number: event.target.value
                                                               })}
                                                        ></Input>
                                                        <Input onChange={(event) => setWallet({
                                                            ...wallet,
                                                            branch: event.target.value
                                                        })} className={'mt-2'} placeholder={'Branch'}
                                                               type={'text'}></Input>
                                                    </FormGroup>
                                                    <div style={{
                                                        width: '100%',
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'center'
                                                    }}>
                                                        <Button onClick={async () => {
                                                            handleUpdate();
                                                            setShowWallets(!showWallets)
                                                        }} style={{
                                                            paddingLeft: '20px',
                                                            paddingRight: '20px',
                                                            backgroundColor: '#6C32A0',
                                                            color: 'white'
                                                        }} className={'btn'}>Add</Button>
                                                        <Button onClick={async () => {
                                                            setAddWallet(!addWallet)
                                                        }} style={{
                                                            paddingLeft: '20px',
                                                            paddingRight: '20px',
                                                            backgroundColor: 'red',
                                                            color: 'white'
                                                        }} className={'btn'}>Close</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {showWallets && (
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                width: '100%',
                                                justifyContent: 'center'
                                            }}>
                                                <div style={{
                                                    width: '80%',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center'
                                                }}>
                                                    <FormGroup className={'mt-4'}>
                                                        <Input type="select" name="walletName"
                                                               value={wallet.wallet_name}
                                                               onChange={(event) => setWallet({
                                                                   ...wallet,
                                                                   wallet_name: event.target.value
                                                               })} id="exampleSelect">
                                                            <option>Select Wallet</option>
                                                            <option value={'Esewa'}>Esewa</option>
                                                            <option value={'Khalti'}>Khalti</option>
                                                            <option value={'ImePay'}>ImePay</option>
                                                        </Input>
                                                        <Input name={'full_name'}
                                                               value={wallet.full_name}
                                                               onChange={(event) => setWallet({
                                                                   ...wallet,
                                                                   full_name: event.target.value
                                                               })} className={'mt-2'}
                                                               placeholder={'Name'} type={'text'}></Input>
                                                        <Input name={'number'} value={wallet.number}
                                                               onChange={(event) => setWallet({
                                                                   ...wallet,
                                                                   number: event.target.value
                                                               })} className={'mt-2'}
                                                               placeholder={'ID'} type={'text'}></Input>
                                                    </FormGroup>
                                                    <div style={{
                                                        width: '100%',
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'center'
                                                    }}>
                                                        <Button onClick={async () => {
                                                            handleUpdate();
                                                        }}
                                                                style={{
                                                                    paddingLeft: '20px',
                                                                    paddingRight: '20px',
                                                                    backgroundColor: '#6C32A0',
                                                                    color: 'white'
                                                                }} className={'btn'}>Add</Button>

                                                        <Button onClick={async () => {
                                                            setAddWallet(!addWallet)
                                                        }} style={{
                                                            paddingLeft: '20px',
                                                            paddingRight: '20px',
                                                            backgroundColor: 'red',
                                                            color: 'white'
                                                        }} className={'btn'}>Close</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                }


                                {walletList && walletList.map((item, index) => {
                                    return (
                                        <FormGroup className={'m-0 p-0'}>
                                            <Button
                                                style={{
                                                    display: 'flex', flexDirection: 'row', width: '100%',
                                                    paddingLeft: '20px',
                                                    paddingRight: '20px',
                                                    backgroundColor: '#6C32A0',
                                                    color: 'white',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                    marginTop: '10px'
                                                }}
                                                onClick={() => selectWalletForPaymentRequest(item, index)}
                                            >
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    marginLeft: '10px',
                                                    alignItems: 'center',
                                                }}>
                                                    <Input
                                                        style={{width: '20px', height: '20px'}}
                                                        name="wallet"
                                                        type="radio"
                                                        checked={walletDetailIndex.walletIndex === index}
                                                    />
                                                    <div style={{
                                                        marginLeft: '20px',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'flex-start'
                                                    }}>
                                                        <span>{item.bank_name}</span>
                                                        <span>{item.full_name} </span>
                                                        <span>{item.number}</span>
                                                    </div>
                                                </div>
                                                <div className={'float-right'}>
                                                    <i style={{fontSize: 22}} className={'fa fa-trash'}
                                                       onClick={() => deletePaymentDetail(item)}/>
                                                </div>
                                            </Button>
                                        </FormGroup>
                                    )
                                })}
                                <div className={'mt-3'}
                                     style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                                    <Button onClick={() => requestPayment()}
                                            style={{backgroundColor: '#6C32A0', color: 'white'}}
                                            className={'btn btn-info'}>Request</Button>
                                </div>
                            </>
                        </div>
                    }
                </div>
            </Modal>
        </>

    )

}

export default withSnackbar(WalletModal)