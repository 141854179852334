import React from "react";

import {
    Button,
    Card,
    CardBody,
 
    CardTitle,
    Col,
    Container,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,

    Row,
} from "reactstrap";
import { withSnackbar } from "notistack";
import YouTube from "react-youtube";

import Quiz from "./Quiz";

import QuizResultModal from "../../components/Modals/QuizResultModal";
import { MobXContext } from "MobXProvider";
import { observer } from "mobx-react";
import CommentForm from "../../components/CommentForm";
import img from '../../assets/img/subscribe-our-youtube-channel.png';
import InfiniteScroll from "react-infinite-scroll-component";
import { Oval } from "react-loader-spinner";
import Loading from "../../components/Loading/Loading";
import '../../views/examples/styles.scss'
import CommonDescription from "../../components/Modals/CommonDescription";
import ReactPlayer from "react-player";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDownAZ, faBookOpen, faComment, faGraduationCap, faPaperPlane, faSquarePollVertical } from "@fortawesome/free-solid-svg-icons";
import FreeComment from "components/Comment/FreeComment";


const Services = require("../../RemoteServices/RemoteServices.js");

const LessonHistory = observer(
    class LessonHistory extends React.Component {
        static contextType = MobXContext;

        constructor(props) {
            super(props);
            this.state = {
                commentTag: 'id',
                lessonHistory: [],
                playVideo: false,
                offset: 0,
                images: [],
                videoLink: "",
                comment: "",
                commentError: false,
                filterOffset: 0,
                showMore: false,
                options: [
                    { id: 1, label: 'All', value: 'all' },
                    { id: 2, label: 'New', value: 'created_at' },
                    { id: 3, label: 'Trending', value: 'like_count' },
                ],
                freeList: [],
                showAdd: true,
                isLoading: false,
                quizData: '',
                dropdownOpen: false,
                showQuiz: false,
                tag: 'new',
                like: false,
                dislike_count: null,
                current_user_like_status: '',
                like_count: null,
                dislike: '',
                current_user_dislike_status: "",
                active: 0,
                showQuizResult: false,
                searchContent: false,
                watchVideo: false,
                searchItem: '',
                lessonData: '',
                commentMessage: false,
                tab: 'all',
                courseList: [],
                loading: false,
                objects: [],

                recentmessage: false,
                currentpostcommentTime: '',
            };
        }

        filterComment = async (tag) => {
            try {
                if (this.state.commentTag === tag) {
                    await this.context.getReviewsList(this.state.lessonData.id, this.state.filterOffset, tag)
                } else {
                    await this.context.getReviewsList(this.state.lessonData.id, this.state.filterOffset, tag)
                }
            } catch (e) {

            }
        }

        selectedVideo = async (value) => {
            await this.setState({
                isLoading: true,
                showAdd: true,
                offset: 0,
            })
            const lessonData = await Services.getLessonDetail(value.id);
            await this.setState({
                lessonData: lessonData,
                lessonId: value.id,
                status: value.status,
                videoLink: value.youtube_link,
                isLoading: false,
                like: value.current_user_like_status,
                dislike_count: value.dislike_count,
                like_count: value.like_count,
                current_user_like_status: value.current_user_like_status,
                dislike: value.current_user_dislike_status,
                playVideo: true,

            });
            await this.fetchQuiz()
            await this.getReviewsList();
        }
        fetchQuiz = async () => {
            await Services.getQuizList({
                lesson_id: this.state.lessonData.id,
                course_id: this.state.lessonData.course,
            })
                .then((response) => {
                    this.setState({
                        quizData: response,
                    })
                })
                .catch((err) => {
                    this.setState({
                        quizData: '',
                    })
                });
        }
        handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                this.handleComment()
            }
        }

        handleSearchKeyword = async () => {
            await Services.getLessonsHistoryByKeyword(this.state.searchItem).then((response) => {
                this.setState({ lessonHistory: response });
            }).catch((err) => {
                this.setState({
                    lessonHistory: [],
                })
            });
        }

        handleSearchKeyDown = (e) => {
            if (e.key === 'Enter') {
                this.handleSearchKeyword()
            }
        }
        clearComment = () => {
            this.setState({ comment: '' })
        }
        // handleComment = async () => {
        //     if (this.state.comment) {
        //         this.setState({ commentError: false });
        //         await Services.review({
        //             user: JSON.parse(localStorage.getItem('user'))?.id,
        //             lesson: this.state.lessonData.id,
        //             message: this.state.comment,
        //         });
        //         this.setState({ comment: "", commentMessage: true });
        //         setTimeout(() => {
        //             this.setState({ commentMessage: false })
        //         }, 2000)
        //     } else {
        //         this.setState({ commentError: true });
        //     }
        // };
        handleComment = async () => {
            if (this.state.comment) {
                this.setState({ commentError: false });
                await Services.review({
                    user: JSON.parse(localStorage.getItem('user'))?.id,
                    lesson: this.state.lessonData.id,
                    message: this.state.comment,
                }).then((response) => {
                    this.setState({ comment: "", commentMessage: true, currentpostcommentTime: new Date(response.created_at).getTime() });
                    if (this.context?.reviewsList?.results) {
                        this.context.reviewsList.results.unshift(response);
                    }
                    this.HighlishtComment()
                    setTimeout(() => {
                        this.setState({ commentMessage: false })
                    }, 2000)
                })
                    .catch((error) => {
                        this.props.enqueueSnackbar(error.message, {
                            variant: "error",
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "right",
                            },
                        });
                    });
            } else {
                this.setState({ commentError: true });
            }
        };

        getReviewsList = () => {
            this.context.getReviewsList(this.state.lessonData.id, this.state.offset, this.state.commentTag);
            this.setState((state) => ({
                offset: (state.offset += 10),
            }));
        };

        toggleState = (state) => {
            this.setState({
                [state]: !this.state[state],
            });
        };

        getRecentLessonHistory = async () => {
            await Services.getRecentLessonsHistory().then((response) => {
                this.setState({ lessonHistory: response });
            });
        }

        getLessonCourseId = async () => {
            await Services.getLessonCourse().then((response) => {
                this.setState({ courseList: response });
            });
        }

        searchWithCourseId = async (id) => {
            await Services.searchWithCourseId(id).then((response) => {
                this.setState({ lessonHistory: response });
            });
        }


        async componentDidMount() {
            const params = new URLSearchParams(this.props.location.search);
            this.setState({ loading: true })
            await Services.getLessonsHistory('no').then((response) => {
                this.setState({ lessonHistory: response, loading: false });
            }).catch((e) => {
                this.setState({ loading: false })
            });
            await this.getLessonCourseId();
            if (params.get('lesson')) {
                const filterData = this.state.lessonHistory.filter((data) => data.id == params.get('lesson'));
                this.selectedVideo(filterData[0])
            }
        }

        handleCommentChange = (e) => {
            this.setState({ comment: e.target.value });

        };

        handleSearchItem = (e) => {
            this.setState({ searchItem: e.target.value })
        }

        _onStateChange(event) {
            // console.log(event);
            if (event.data === "1") {
                if (
                    document.getElementsByClassName(
                        "ytp-chrome-top ytp-show-cards-title"
                    )[0]
                ) {
                    document.getElementsByClassName(
                        "ytp-chrome-top ytp-show-cards-title"
                    )[0].style.visibility = "hidden";
                }
            }
            // document.getElementsByClassName('ytp-chrome-top ytp-show-cards-title')[0].style.visibility = 'hidden'
        }

        toggle = () => this.setState({
            dropdownOpen: !this.state.dropdownOpen,
        });

        setTabState = async (data) => {
            if (data === 'all') {
                await Services.getLessonsHistory('no').then((response) => {
                    this.setState({ lessonHistory: response });
                }).catch((err) => {
                    this.setState({
                        lessonHistory: [],
                    })
                });
                this.setState({ tab: data })
            } else if (data === 'recent') {
                await Services.getLessonsHistory('yes').then((response) => {
                    this.setState({ lessonHistory: response });
                }).catch((err) => {
                    this.setState({
                        lessonHistory: [],
                    })
                });
                this.setState({ tab: data })

            }
        }
        HighlishtComment = () => {
            let userTime = Date.now()
            let timediff = Math.abs(this.state.currentpostcommentTime - userTime)

            if (timediff <= 3000) {
                this.setState({ recentmessage: true })
                setTimeout(() => {
                    this.setState({ recentmessage: false })
                }, 60000);
            } else {
                this.setState({ recentmessage: false })
            }
        }

        render() {
            const optsDesktop = {
                width: "100%",
                height: "100%",
                playerVars: {
                    // https://developers.google.com/youtube/player_parameters
                    autoplay: 1,
                    frameborder: 0,
                    modestbranding: 1,
                    rel: 0,
                    showinfo: 0,
                },
            };
            const optsMobile = {
                width: "100%",
                height: "250",
                playerVars: {
                    // https://developers.google.com/youtube/player_parameters
                    autoplay: 0,
                },
            };
            const videoLink = this.state?.lessonData?.youtube_link;
            const youtubeVideoId = videoLink?.split("/").pop();
            const params = new URLSearchParams(this.props.location.search);

            return (
                <>

                    {/* Page content */}
                    {!this.state.loading ?
                        <Container className={'container-dashboard'} fluid
                            style={{ minHeight: "calc(100vh - 76px - 118px)" }}>
                            {!this.state.playVideo &&
                                <>
                                    <div className={'lesson-history'}>
                                        <div
                                            className={'trending-div btn-mobile'}>
                                            <Button
                                                className={'trending-button btn-sm mr-2'}
                                                onClick={() => this.setTabState('all')}
                                                style={{

                                                    backgroundColor: this.state.tab === 'all' ? 'rgba(33, 8, 193, 0.4431372549)' : ''
                                                }}
                                            >
                                                <span>
                                                    All
                                                </span>
                                            </Button>
                                            <Button
                                                className={'trending-button btn-sm mr-2'}
                                                onClick={() => this.setTabState('recent')}
                                                style={{

                                                    backgroundColor: this.state.tab === 'recent' ? 'rgba(33, 8, 193, 0.4431372549)' : ''
                                                }}
                                            >
                                                <span>
                                                    Recently
                                                    Watched
                                                </span>
                                            </Button>
                                        </div>
                                        <div className={'lesson-tab-search'}>
                                            <Card className={'history-search'}>
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    gap: '0px',
                                                    alignItems: 'center'
                                                }}>
                                                    <div style={{ position: 'relative' }}>
                                                        <input
                                                            className="history-search-input"
                                                            type="search"
                                                            placeholder="Search Term"
                                                            value={this.state.searchItem}
                                                            onKeyDown={this.handleSearchKeyDown}
                                                            onChange={this.handleSearchItem}
                                                        />
                                                        <i
                                                            className="fa fa-search"
                                                            style={{
                                                                position: 'absolute',
                                                                top: '50%',
                                                                transform: 'translateY(-50%)',
                                                                left: '10px',
                                                                color: '#a1a1a1',
                                                                pointerEvents: 'none' // Prevents the icon from being clickable
                                                            }}
                                                        ></i>
                                                    </div>
                                                </div>
                                            </Card>
                                            <div className={'ml-3 mr-3 filter'} style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                width: '100%',
                                                justifyContent: 'flex-end',
                                                alignItems: 'center'
                                            }}>
                                                <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                                                    <DropdownToggle style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center'
                                                    }}><FontAwesomeIcon icon={faArrowDownAZ} /> &nbsp;&nbsp; Filter</DropdownToggle>
                                                    <DropdownMenu>
                                                        {this.state?.courseList?.map(item => (
                                                            <DropdownItem
                                                                onClick={() => this.searchWithCourseId(item.id)}>{item.title}</DropdownItem>
                                                        ))}
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <hr style={{
                                        height: 'auto',
                                        color: 'black',
                                        margin: '6px 0 0 0',

                                    }} />
                                    <div className="youtube-list">
                                        {this.state.lessonHistory.map((value, index) => {
                                            const videoLink = value.youtube_link;
                                            const youtubeVideoId = videoLink.split("/").pop();
                                            return (
                                                <>
                                                    <div className={'youtube-list-item'}>
                                                        <Card className={'history-card'}>
                                                            <div onClick={() => this.selectedVideo(value)}
                                                                className={'youtube-content'}>
                                                                <img style={{
                                                                    height: '100%',
                                                                    width: '100%',
                                                                    contain: 'cover'
                                                                }}
                                                                    src={value.thumbnail ? window.config.imgUrl + value.thumbnail : img}
                                                                    alt={'playbutton'} />
                                                                <div style={{
                                                                    position: "absolute",
                                                                    top: 75,
                                                                    border: '4px solid indigo',
                                                                    borderRadius: '50%',
                                                                    width: '50px',
                                                                    height: '50px',
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    color: 'indigo'
                                                                }}>
                                                                    <i style={{ fontSize: 20 }}
                                                                        className="fa fa-play"></i>
                                                                </div>
                                                            </div>
                                                            <div className={'youtube-title'}>
                                                                <span
                                                                    className={'mt-2 font-weight-bold'}>{value.title}</span>
                                                                {/*<span*/}
                                                                {/*    className={'mt-2'}>{value.description.substring(0, 80)}</span>*/}
                                                                <span className={'mt-1 font-weight-bold'}>Completed On:
                                                                    {new Date(value.approved_info.modified_at).toDateString()}
                                                                </span>


                                                            </div>
                                                        </Card>
                                                    </div>
                                                </>
                                            );
                                        })}
                                    </div>
                                </>
                            }
                            {this.state.lessonHistory.length > 0 ?
                                <>
                                    {this.state.playVideo &&
                                        <>
                                            <Row className={'mt-0 paindlesson-div-padding-mobile'}>
                                                <Col xs={12} md={12} xl={9} className={' paindlesson-div-padding-mobile'}>
                                                    <Row>

                                                        <div
                                                            className={'trending-div mb-1 ml-x-15'} xs={12} xl={12}>
                                                            <Button
                                                                className={'trending-button btn-sm '}
                                                                style={{
                                                                    backgroundColor: ' #4f2bab',
                                                                    color: 'white',
                                                                 
                                                                }}
                                                                onClick={() => {
                                                                    this.toggleState('playVideo')
                                                                }}>
                                                                <span>

                                                                    <i className='fa fa-arrow-circle-left' />
                                                                    &nbsp;BACk
                                                                </span>
                                                            </Button>
                                                        </div>
                                                    </Row>
                                                    <Row className={' paindlesson-div-padding-mobile'}>
                                                        <Col xs={12} className="padding-x-none">
                                                            {params.get('lesson') && this.state?.lessonData?.thumbnail ?
                                                                <div className={'player-change paindlesson-div-padding-mobile'}>
                                                                    <ReactPlayer
                                                                        url={this.state?.lessonData?.youtube_link} playIcon={<div></div>}
                                                                        light={
                                                                            <div className="padding-x-none" style={{
                                                                                position: 'relative',
                                                                                width: '100%',
                                                                                height: '100%',
                                                                            }}>
                                                                                <img
                                                                                    alt={'thumbnail'}
                                                                                    style={{
                                                                                        height: '100%',
                                                                                        width: '100%',
                                                                                        objectFit: 'stretch'
                                                                                    }}
                                                                                    src={this.state.lessonData.thumbnail[0] === '/' ? window.config.imgUrl.concat(this.state?.lessonData?.thumbnail) : this.state?.lessonData?.thumbnail} />
                                                                                <div style={{
                                                                                    position: 'absolute',
                                                                                    top: '50%',
                                                                                    left: '50%',
                                                                                    transform: 'translate(-50%, -50%)'
                                                                                }}>
                                                                                    <div style={{
                                                                                        border: '4px solid purple',
                                                                                        borderRadius: '50%',
                                                                                        width: '60px',
                                                                                        height: '60px',
                                                                                        display: 'flex',
                                                                                        justifyContent: 'center',
                                                                                        alignItems: 'center',
                                                                                        color: 'purple'
                                                                                    }}>
                                                                                        <i style={{ fontSize: 20 }}
                                                                                            className="fa fa-play"></i>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        className="react-player"
                                                                        width={'100%'}
                                                                        height={'100%'}
                                                                        playing={true}
                                                                        controls={true}
                                                                    />
                                                                </div>
                                                                :
                                                                <div className="padding-x-none"
                                                                    style={{
                                                                        width: "100%",
                                                                        position: "relative",
                                                                        paddingBottom: "56.25%",
                                                                    }}
                                                                >
                                                                    <YouTube
                                                                        containerClassName="YouTubeContainer"
                                                                        videoId={youtubeVideoId}
                                                                        opts={optsDesktop}
                                                                        onReady={this._onReady}
                                                                        onStateChange={this._onStateChange}
                                                                    />
                                                                </div>
                                                            }


                                                        </Col>
                                                        <Col xs={12} className={'mb-4 paindlesson-div-padding-mobile'}>
                                                            <Card className="shadow paindlesson-div-padding-mobile">
                                                                <CardBody>
                                                                    <CardTitle className={'m-0 p-0 mb-2'}>
                                                                        <b>{this.state.lessonData.title}</b>
                                                                    </CardTitle>
                                                                    <div tabs className='mb-2' >
                                                                        <div className=""
                                                                            style={{
                                                                                display: 'flex',
                                                                                justifyContent: 'space-between'
                                                                            }}
                                                                        >
                                                                            <div style={{
                                                                                display: 'flex',
                                                                                justifyContent: 'space-between',
                                                                                flexDirection: 'row'
                                                                            }}>
                                                                                {this.state?.lessonData?.objects?.length > 0 && (
                                                                                    <Button
                                                                                        className={`btn  btn-list-freelesson ${this.state.newDescription ? ' CurrentTab' : 'N-CurrentTab'}`}

                                                                                        onClick={() => {
                                                                                            this.toggleState('newDescription')
                                                                                            this.setState({ showQuiz: false })
                                                                                        }}
                                                                                    >
                                                                                        See Description&nbsp; <FontAwesomeIcon icon={faBookOpen} />
                                                                                    </Button>
                                                                                )}

                                                                                {this.state.quizData?.data?.quizData?.length > 0 && (
                                                                                    <Button
                                                                                        className={`btn btn-list-freelesson ${this.state.showQuiz ? ' CurrentTab' : 'N-CurrentTab'}`}
                                                                                        onClick={() => {
                                                                                            this.toggleState('showQuiz')
                                                                                            this.setState({ newDescription: false })
                                                                                        }}

                                                                                    >
                                                                                        Show Quiz&nbsp; <FontAwesomeIcon icon={faGraduationCap} />
                                                                                    </Button>
                                                                                )}

                                                                                {this.state.quizData?.is_completed && this.state?.quizData !== '' && (
                                                                                    <Button
                                                                                        className={`btn btn-list-freelesson ${this.state.showQuizResult ? ' CurrentTab' : 'N-CurrentTab'}`}
                                                                                        onClick={() => this.toggleState('showQuizResult')}

                                                                                    >
                                                                                        View Result &nbsp;<FontAwesomeIcon icon={faSquarePollVertical} />
                                                                                    </Button>
                                                                                )}





                                                                            </div>


                                                                        </div>

                                                                        {this.state.newDescription && (
                                                                            <div
                                                                                className={` ${this.state.newDescription ? ' CurrentTab' : 'N-CurrentTab'}`}
                                                                            >
                                                                                <CommonDescription arrayObject={this.state.lessonData.objects} />

                                                                            </div>
                                                                        )}
                                                                        {this.state.showQuiz && (
                                                                            <div
                                                                                className={` ${this.state.showQuiz ? ' CurrentTab' : 'N-CurrentTab'}`}
                                                                            >
                                                                                <Quiz
                                                                                    toggleState={this.toggleState}
                                                                                    showQuiz={this.state.showQuiz}
                                                                                    lessonData={this.state.lessonData}
                                                                                    quizData={this.state.quizData}
                                                                                    fetchQuiz={() => this.fetchQuiz()}
                                                                                />
                                                                            </div>

                                                                        )}
                                                                        {this.state.showQuizResult &&
                                                                            <div
                                                                                className={` ${this.state.showQuizResult ? ' CurrentTab' : 'N-CurrentTab'}`}
                                                                            >
                                                                                <QuizResultModal
                                                                                    showQuizResult={this.state.showQuizResult}
                                                                                    toggleModal={this.toggleState}
                                                                                    quizData={this.state.quizData}
                                                                                />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col xs={12} md={12} xl={3} className='paindlesson-div-padding-mobile'>
                                                    <Col xs={12} className="m-0 p-0 mb-4">
                                                        <Col xs={12} className="mb-4 p-0 paindlesson-div-padding-mobile">

                                                            <Col xs={12} className=""
                                                                style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    borderBottom: '2px solid  #4f2bab',
                                                                }}
                                                            >
                                                                <Input
                                                                    className={'search-input'}

                                                                    invalid={this.state.commentError}
                                                                    type="text"
                                                                    name="comment"
                                                                    placeholder={'Add a public comment'}
                                                                    value={this.state.comment}
                                                                    onKeyDown={this.handleKeyDown}
                                                                    onChange={this.handleCommentChange}
                                                                />
                                                                <div style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'row',

                                                                    justifyContent: 'flex-end',


                                                                }}

                                                                >
                                                                    <Button
                                                                        className={'btn '}
                                                                        style={{
                                                                            backgroundColor: '#f5f5f5',
                                                                            border: 'none',
                                                                            boxShadow: 'none',
                                                                            color: '#4f2bab',
                                                                            Opacity: '1',
                                                                            padding: '0',
                                                                            paddingTop: '0'



                                                                        }}
                                                                        data-dismiss="modal"
                                                                        type="button"
                                                                        onClick={() => this.handleComment()}>

                                                                        <FontAwesomeIcon icon={faPaperPlane} size='xl' />
                                                                    </Button>

                                                                </div>
                                                            </Col>
                                                            <div style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',

                                                                justifyContent: 'space-between',
                                                                alignItems: 'center',
                                                                color: '#4f2bab',
                                                                gap: '8px',
                                                                padding: '0 15px'
                                                            }}>
                                                                <div>
                                                                    {this.context?.reviewsList?.results && (
                                                                        <>
                                                                            <span>{this.context.reviewsList.count}</span>&nbsp;
                                                                            <FontAwesomeIcon icon={faComment} size='xl' />
                                                                        </>
                                                                    )}
                                                                </div>
                                                                <div className={'mt-2  filter'} style={{


                                                                }}>
                                                                    <Dropdown
                                                                        style={{
                                                                            // marginRight: '11px'
                                                                        }}
                                                                        isOpen={this.state.dropdownOpen}
                                                                        toggle={this.toggle}>
                                                                        <DropdownToggle style={{
                                                                            display: 'flex',
                                                                            flexDirection: 'row',
                                                                            justifyContent: 'space-between',
                                                                            alignItems: 'center',
                                                                            color: '#4f2bab',
                                                                        }}>
                                                                            <FontAwesomeIcon icon={faArrowDownAZ} size='xl' /> </DropdownToggle>
                                                                        <DropdownMenu>
                                                                            <DropdownItem onClick={() => {
                                                                                this.setState({ tag: 'like_count' });
                                                                                this.filterComment('like_count')
                                                                            }
                                                                            }>Top Comments</DropdownItem>
                                                                            <DropdownItem onClick={() => {
                                                                                this.setState({ tag: 'created_at' });
                                                                                this.filterComment('created_at')
                                                                            }
                                                                            } text>Newest First</DropdownItem>
                                                                        </DropdownMenu>
                                                                    </Dropdown>
                                                                </div>
                                                            </div>
                                                            {this.state.commentMessage && <span
                                                                style={{
                                                                    textAlign: 'center',
                                                                    color: 'green',
                                                                    fontSize: '14px'
                                                                }}>Successfully commited Comment</span>}
                                                            <div className="mt-3 mb-2 scroll-container"
                                                                id={'scroll-container'}>
                                                                {this.context?.reviewsList?.results &&
                                                                    this.context?.reviewsList?.results.map((result, index) => (
                                                                        <div key={index} className={`${(this.state.recentmessage &&
                                                                            this.state.currentpostcommentTime === new Date(result.created_at).getTime()) ? 'HiglightCommentDiv' : ''}`} >

                                                                            <FreeComment
                                                                                key={result.id}
                                                                                comment={result}
                                                                                lesson={this.state.lessonData.id}
                                                                            />

                                                                        </div>
                                                                    ))}
                                                                <Row style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    width: '100%',
                                                                    justifyContent: 'center'
                                                                }}>

                                                                    {this.context?.reviewsList?.results &&
                                                                        <>
                                                                            <InfiniteScroll
                                                                                dataLength={this.context?.reviewsList?.results?.length}
                                                                                hasMore={this.context?.reviewsList?.count > this.context?.reviewsList?.results?.length}
                                                                                next={() => this.getReviewsList()}
                                                                                scrollableTarget="scroll-container"
                                                                                loader={<div>
                                                                                    <Oval
                                                                                        height="30"
                                                                                        width="30"
                                                                                        radius="12"
                                                                                        color="grey"
                                                                                        ariaLabel="loading"
                                                                                        wrapperStyle
                                                                                        wrapperClass
                                                                                    />
                                                                                </div>}
                                                                                endMessage={
                                                                                    <p style={{ textAlign: 'center' }}>
                                                                                        <b>Yay! You have seen it all</b>
                                                                                    </p>

                                                                                }
                                                                            >
                                                                            </InfiniteScroll>
                                                                        </>
                                                                    }
                                                                </Row>
                                                            </div>
                                                        </Col>
                                                    </Col>
                                                </Col>
                                            </Row>
                                        </>

                                    }

                                </> :
                                <div style={{ display: 'flex', height: "calc(100vh - 76px - 130px)", justifyContent: 'center', alignItems: 'center' }}>
                                    <span className={'font-weight-bold'} style={{ color: 'purple' }}>No History Found</span>
                                </div>
                            }
                        </Container>
                        :
                        <>
                            <Loading />
                        </>
                    }
                    {this.context.commentFormOpen && <CommentForm></CommentForm>}
                </>
            );
        }
    }
)

export default withSnackbar(LessonHistory);
