var utils ={
    hasAccess(layoutUser, userType) {
        let id = 1
        if(userType === 'student'){
            id = 2
        }
        if(userType === 'youtuber'){
            id = 2
        }
        if(userType === 'Superuser'){
            id = 3
        }
    
        if(layoutUser <= id) return true
        return false
    }
}


module.exports = utils

