import React from "react";
import { Button, Container } from "reactstrap";

import "./styles.scss";
import Loading from "../../components/Loading/Loading";
import ReactPlayer from "react-player/lazy";
import moment from "moment";
import momentTimezone from "moment-timezone";
import { withSnackbar } from "notistack";
import { withRouter } from "react-router-dom";
import MeetingCards from "./MeetingCard";

const Services = require("../../RemoteServices/RemoteServices.js");

class TeacherSupport extends React.PureComponent {
  state = {
    loading: false,
    supportData: "",
    now: momentTimezone().tz("Asia/Kathmandu").format("h:mm A"),
  };

  getSupportData = async () => {
    this.setState({ loading: true });
    try {
      const response = await Services.getTeacherSupport();
      this.setState({
        supportData: response[0],
        loading: false,
      });
    } catch (e) {
      this.setState({
        loading: false,
      });
    }
  };

  async componentDidMount() {
    await this.getSupportData();
  }

  render() {
    const { supportData, now } = this.state;
    let paid = JSON.parse(localStorage.getItem("paid"));
    const morningStartTime = moment(supportData?.start_time_morning, "h:mm A");
    const morningEndTime = moment(supportData?.end_time_morning, "h:mm A");
    const eveningStartTime = moment(supportData?.start_time_evening, "h:mm A");
    const eveningEndTime = moment(supportData?.end_time_evening, "h:mm A");

    // Check if the current time is within the morning or evening range
    const isMorning = moment(now, "h:mm A").isBetween(
      morningStartTime,
      morningEndTime,
      null,
      "[]"
    );

    const isEvening = moment(now, "h:mm A").isBetween(
      eveningStartTime,
      eveningEndTime,
      null,
      "[]"
    );

    const redirectButton = () => {
      // this.props.enqueueSnackbar(
      //   "Sorry this features is only available for paid users",
      //   {
      //     variant: "error",
      //     anchorOrigin: {
      //       vertical: "bottom",
      //       horizontal: "right",
      //     },
      //   }
      // );
      this.props.history.push("/admin/meet");
    };

    return (
      <React.Fragment>
        <>
          {!this.state.loading ? (
            <Container className={"container"} style={{ minHeight: "600px" }}>
              <MeetingCards />

              {supportData?.videolink !== "" && (
                <div className={"player-change"} style={{ marginTop: 5 }}>
                  <ReactPlayer
                    url={supportData?.videolink}
                    playIcon={<div></div>}
                    light={
                      <div
                        style={{
                          position: "relative",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <img
                          alt={"thumbnail"}
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "stretch",
                          }}
                          src={supportData?.thumbnail}
                        />
                        <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                          }}
                        >
                          <div
                            style={{
                              border: "4px solid purple",
                              borderRadius: "50%",
                              width: "60px",
                              height: "60px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              color: "purple",
                            }}
                          >
                            <i
                              style={{ fontSize: 20 }}
                              className="fa fa-play"
                            ></i>
                          </div>
                        </div>
                      </div>
                    }
                    className="react-player"
                    width={"100%"}
                    height={"100%"}
                    playing={true}
                    controls={true}
                  />
                </div>
              )}

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "25px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "10px",
                    backgroundColor: "white",
                    width: "100%",
                    borderRadius: "5px",
                    border: "1px solid purple",
                    color: "black",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h2 className={"mt-2 ml-3"}>{supportData?.title}</h2>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "25px",
                }}
              >
                <div
                  style={{
                    padding: "10px",
                    backgroundColor: "white",
                    width: "100%",
                    borderRadius: "5px",
                    border: "1px solid purple",
                    color: "black",
                  }}
                  dangerouslySetInnerHTML={{ __html: supportData?.description }}
                ></div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                  gap: "15px",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                {isMorning || isEvening ? (
                  <>
                    {paid === "Paid" ? (
                      <Button
                        className={"all-btn"}
                        href={supportData?.meet_link}
                        target={"_blank"}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ width: "40%" }}>
                          <i
                            className={"fa fa-comments"}
                            style={{ fontSize: 24, color: "green" }}
                          />
                        </div>
                        &nbsp;
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <span style={{ fontSize: "20px" }}>
                            Join the Meet
                          </span>
                        </div>
                      </Button>
                    ) : (
                      <Button
                        className={"all-btn"}
                        onClick={() => redirectButton()}
                        target={"_blank"}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ width: "40%" }}>
                          <i
                            className={"fa fa-comments"}
                            style={{ fontSize: 24, color: "green" }}
                          />
                        </div>
                        &nbsp;
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <span style={{ fontSize: "20px" }}>
                            Join the Meet
                          </span>
                        </div>
                      </Button>
                    )}
                  </>
                ) : (
                  <span>
                    Available Time is {supportData?.start_time_morning}-
                    {supportData?.end_time_morning} and{" "}
                    {supportData?.start_time_evening}-
                    {supportData?.end_time_evening} Timezone: UTC+5:45.
                  </span>
                )}
              </div>
            </Container>
          ) : (
            <>
              <Loading />
            </>
          )}
        </>
      </React.Fragment>
    );
  }
}

export default withSnackbar(withRouter(TeacherSupport));
