/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Col,
    Input,
} from "reactstrap";
import { AvForm, AvGroup, AvInput, AvFeedback } from "availity-reactstrap-validation";
import { withSnackbar } from "notistack";
import GoogleLogin from "react-google-login";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import GoogleBadge from '../../assets/img/icons/common/google.svg'
import FacebookBadge from '../../assets/img/f_logo_RGB-Blue_58.png'
import { Link } from "react-router-dom";
import { requestForToken } from "../../firebase";
import { faL } from "@fortawesome/free-solid-svg-icons";


const Services = require("../../RemoteServices/RemoteServices.js");

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            snackBar: false,
            loggingIn: false,
            showPassword: false,
            email: "",
            password: "",
            emailError: false,
            passwordError: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    togglePassword = (state) => {
        this.setState({
            [state]: !this.state[state],
        });
    }
    componentDidMount() {
        if (localStorage.getItem("user")) {
            this.props.history.push("/admin/dashboard");
        }
    }

    async handleSubmit(event, values) {
        this.setState({
            emailError:false,
            passwordError:false,
        })
        event.preventDefault();
        let reqDto = {
            email: this.state.email,
            password: this.state.password
        }
        if (this.validateEmail(this.state.email)===false) {
            this.setState({ emailError: true })
        } else if (!this.state.password) {
            this.setState({ passwordError: true })
        } else {
            this.setState({ loggingIn: true });
            // requestForToken()
            await Services.sendLogin(reqDto)
                .then((response) => {
                    if (response.access) {
                        localStorage.setItem("token", response.access);
                        localStorage.setItem("refresh", response.refresh);
                        localStorage.setItem("user", JSON.stringify(response.user));
                        this.props.history.push("/admin/dashboard");
                    } else {
                        this.setState({ snackBar: true, loggingIn: false });
                        this.props.enqueueSnackbar(response.detail, {
                            variant: "error",
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "right",
                            },
                        });
                    }
                })
                .catch((error) => {
                    this.setState({ snackBar: true, loggingIn: false });
                    this.props.enqueueSnackbar("Username or Password is incorrect", {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                    });
                });
        }
    }

    responseGoogle = async (response) => {
        if (response) {
            console.log('google', response)
            let { accessToken } = response
            let tokenData = {
                access_token: accessToken
            }
            await Services.createGoogleExchangeToken(tokenData).then((response) => {
                localStorage.setItem('token', response.access);
                localStorage.setItem("refresh", response.refresh);
                localStorage.setItem('user', JSON.stringify(response.user));
                this.props.history.push("/admin/dashboard");
            })
                .catch((error) => {
                    this.setState({ snackBar: true, loggingIn: false });
                    this.props.enqueueSnackbar('Sorry Internal Server Error!!', {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right',
                        }
                    })
                })
        }

    }

    responseFacebook = async (response) => {
        if (response.status !== 'unknown') {
            console.log('facebook', response)
            let { accessToken } = response
            let tokenData = {
                access_token: accessToken
            }
            await Services.createExchangeToken(tokenData).then((response) => {
                if (response.access) {
                    localStorage.setItem('token', response.access);
                    localStorage.setItem("refresh", response.refresh);
                    localStorage.setItem('user', JSON.stringify(response.user));
                    this.props.history.push('/admin/dashboard')
                } else {
                    this.setState({ snackBar: true, loggingIn: false });
                    this.props.enqueueSnackbar(response.detail, {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                    });
                }
            })
                .catch((error) => {
                    this.setState({ snackBar: true, loggingIn: false });
                    this.props.enqueueSnackbar(error.message, {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                    });
                });
        }

    }

    handleInvalidSubmit = (event, errors) => {
        this.props.enqueueSnackbar(`Please enter ${errors.join(" and ")}`, {
            variant: "error",
            anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
            },
        });
    };

    validateEmail = (email) => {
        // Regular expression for basic email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // Test the email against the regular expression
        console.log('asdfasdf', emailRegex.test(email))
        return emailRegex.test(email);
    }

    handleInput = (e) => {
        const { name, value } = e.target
        this.setState({ [name]: value.trim('') });
    };

    render() {
        const validateEmail = (email) => {
            // Regular expression for basic email validation
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            // Test the email against the regular expression
            return emailRegex.test(email);
        }

        return (
            <>
                <Col lg="5" md="7">
                    <Card className="bg-secondary shadow border-0">
                        <CardBody className="px-lg-5 py-lg-5 text-center">
                            <img alt="..." src={require("assets/img/brand/argon-logo.png")} />
                            <div className="text-center text-muted mb-4 mt-4">
                                <small>
                                    Sign in to English Hub Online English Learning Platform
                                </small>
                                <br />
                                <small>Please download our app to register.</small>
                            </div>
                            <AvForm
                                onValidSubmit={this.handleSubmit}
                                onInvalidSubmit={this.handleInvalidSubmit}
                            >
                                <AvGroup className="mb-3">
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-email-83" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Email"
                                            type="text"
                                            name="email"
                                            value={this.state.email}
                                            onChange={this.handleInput}
                                            required
                                            errorMessage="Invalid email address"
                                        />    
                                    </InputGroup>
                                    {this.state.emailError && <span style={{color:'red'}}>Invalid email address</span>}
                                </AvGroup>
                                <AvGroup>
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-lock-circle-open" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Password"
                                            type={this.state.showPassword ? 'text' : 'password'}
                                            name="password"
                                            value={this.state.password}
                                            onChange={this.handleInput}
                                            required
                                            errorMessage="Please enter password"
                                        />
                                       
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className={this.state.showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'} color="secondary"
                                                    onClick={() => this.togglePassword('showPassword')}>
                                                </i>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                    </InputGroup>
                                    {this.state.passwordError && <span style={{color:'red'}}>Please Enter Password</span>}
                                </AvGroup>

                                <div className="createAccountDiv mt-3"><Link
                                    to="/auth/forgotPassword">Forgot Password?</Link></div>

                                <div className="text-center">
                                    <Button className="my-4" color="primary">
                                        {this.state.loggingIn ? "Signing In ..." : "Sign In"}
                                    </Button>
                                </div>
                                <div style={{ marginTop: '-12px' }}>
                                    <span className={'font-weight-bold'}>OR</span>
                                </div>
                            </AvForm>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                padding: '10px',
                                justifyContent: 'space-around'
                            }}>
                                <GoogleLogin
                                    clientId="724611569483-scj2k9ja9va5skk4aa5quhctjn2udr0o.apps.googleusercontent.com"
                                    render={renderProps => (
                                        <button style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            height: '40px',
                                            alignItems: 'center',
                                            marginBottom: '10px'
                                        }} className={'googleLoginButton'} onClick={renderProps.onClick}
                                            disabled={renderProps.disabled}><img height={20} width={20}
                                                alt=""
                                                src={GoogleBadge} />&nbsp;&nbsp;Sign
                                            In With Google</button>
                                    )}
                                    autoLoad={false}
                                    onSuccess={this.responseGoogle}
                                    cookiePolicy={'single_host_origin'}
                                />
                                {/* <FacebookLogin
                                    appId="815479103543290"
                                    // appId="154096314201916"
                                    fields="name,email,picture"
                                    callback={this.responseFacebook}
                                    autoLoad={false}
                                    render={renderProps => (
                                        <button style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '40px'
                                        }} className={'facebookLoginButton'} onClick={renderProps.onClick}
                                            disabled={renderProps.disabled}><img height={20} width={20}
                                                alt=""
                                                src={FacebookBadge} />&nbsp;&nbsp;Login
                                            With Facebook</button>
                                    )}
                                /> */}
                            </div>
                            <div className="createAccountDiv mt-3"><p>Don't have an account? <Link
                                to="/auth/register">Register</Link></p></div>
                        </CardBody>
                    </Card>
                </Col>
            </>
        );
    }
}

export default withSnackbar(Login);
