/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {Card, CardBody, Container, Row, Col} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
class About extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userdetails: localStorage.getItem("user"),
        };
    }

    render() {
        // let userDetails = JSON.parse(localStorage.getItem("user"));
        return (
            <>
                <UserHeader/>
                {/* Page content */}
                <div className="mt--7" style={{
                   padding:'0 15px 0 15px' }} fluid>
                    <Row>
                        <Col className="order-xl-2 mb-1 mb-xl-0" xl="12">
                            <Card className="card-profile shadow">
                                <Row className="justify-content-center">
                                    <Col className="order-lg-2" lg="3">
                                        <div className="text-center mt-3 mb-3">
                                            <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                                <img
                                                    alt="..."
                                                    className=""
                                                    src={require("assets/img/brand/argon-logo.png")}
                                                />
                                            </a>
                                        </div>
                                    </Col>
                                </Row>

                                <CardBody className="pt-2 pt-md-4 px-3">
                                    <b> Welcome to English Hub - Your Gateway to Learning English! 🌟</b>
                                    <br/>
                                    Are you ready to learn English in a fun and easy way? English Hub is
                                    here to help you. Our app is perfect for beginners, students, or
                                    anyone who wants to improve their English. With English Hub, you have
                                    everything you need to start speaking, writing, and understanding
                                    English.
                                    <br/>
                                    <br/>
                                    <b> Live Classes: Engage with English! 🎓 </b>
                                       <br/>
                                    Join our live classes to learn with other students. A friendly teacher
                                    will guide you through third-party platforms. With only 5 students in
                                    each class, you get lots of attention. You can pick your class time,
                                    available from 6 in the morning to 10 at night, Nepali time. Take the
                                    first step towards fluency in English today!
                                    <br/>
                                    <br/>
                                    <b>Video Lessons: Learn Anytime, Anywhere! 📺</b>
                                    <br/>
                                    Watch fun videos to learn English. You can watch anytime, day or
                                    night. After each video, you will get small homework. Do your homework
                                    and send it to us. Then you'll get a new video. Learn new words and
                                    rules easily with our videos.
                                    <br/>
                                    <br/>
                                    <b> How to Join: Easy as 1, 2, 3! 🎉{'\n'}</b>
                                    <br/>
                                    Joining English Hub is super easy.Simply download the app, follow the
                                    instructions, and start learning right away. If you have any
                                    questions, our support team is here to help you. Your path to learning
                                    English awaits at English Hub!
                                    <br/>
                                    <br/>
                                    <b>Why Learn English: Open New Doors! 🌍</b>
                                    <br/>
                                    English is spoken by many people all around the world. If you know
                                    English, you can make new friends, find better jobs, and travel
                                    easily. Learning English is fun and can make life better.
                                    <br/>
                                    <br/>
                                    <b>Why Choose English Hub: Because We Care! ❤️{'\n'}</b>
                                    <br/>
                                    Why pick English Hub? We make learning fun and easy. Our teachers are
                                    friendly, and our videos are fun to watch. Many people have learned
                                    English with us, and you can too!
                                    <br/>
                                    <br/>
                                    <b>Features: 🎁</b>
                                    <br/>
                                    {'\n'}• Friendly Live Classes {'\n'}
                                    <br/>
                                    • Fun Video Lessons {'\n'}
                                    <br/>
                                    • Homework and Progress Tracking {'\n'}
                                    <br/>
                                    • Easy-to-Use and Beautiful Design {'\n'}
                                    <br/>
                                    • Helpful Customer Support{'\n'}
                                    {'\n'}

                                    <br/>
                                    <br/>

                                    <b>Start Your English Learning Journey Today! 🚀</b>
                                    <br/>
                                    Start learning today and take the first step to learn English. We are
                                    here to help you every step of the way. We believe in you, and we know
                                    you can learn English. Join us now, and let's learn English together!

                                    <br/>
                                    <br/>
                                    <b>Contact details</b>
                                    <br/>
                                    014482878 , 9860279942
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default About;
