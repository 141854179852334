import React, { useEffect, useState } from 'react'

import './styles.scss';
import DownlineModal from "../../components/Modals/ReferAndEarn/DownlineModal";
import ReferralModal from "../../components/Modals/ReferAndEarn/ReferralModal";
import WalletModal from "../../components/Modals/ReferAndEarn/WalletModal";
import TotalModal from "../../components/Modals/ReferAndEarn/TotalModal";
import MaterialTable from "material-table";
import Services, { getUserDetails } from "../../RemoteServices/RemoteServices";
import { useHistory } from "react-router-dom";
import avatar from "../../assets/img/theme/avatarnew.png";
import ShareModal from "../../components/Modals/ReferAndEarn/ShareModal";
import moment from "moment";
import { Button, InputGroup, InputGroupAddon, InputGroupText, Modal } from "reactstrap";
import { AvForm, AvGroup, AvInput } from "availity-reactstrap-validation";

export const ReferAndEarn = () => {
    let base = window.config.imgUrl;
    let base1 = window.config.imgUrl;
    const data = [
        { id: 1, name: 'John', age: 25, action: 'call', web: 'share link' },
        { id: 2, name: 'Jane', age: 28, action: 'call', web: 'share link' },
        { id: 3, name: 'Bob', age: 32, action: 'call', web: 'share link' },
    ];
    const [showModal, setShowModal] = useState(false);
    const [showReferralModal, setShowReferralModal] = useState(false)
    const [showWalletModal, setShowWalletModal] = useState(false)
    const [showTotal, setShowTotal] = useState(false);
    const [heading, setHeading] = useState("");
    const [tableData, settableData] = useState([]);
    const [noData, setNoData] = useState(false);
    const [latestReferral, setlatestReferral] = useState([]);
    const [totalReferral, setTotalReferral] = useState([]);
    const [platform, setPlatform] = useState();
    const [showShareModal, setShowShareModal] = useState(false);
    const [heirarchyDetail, setHeirarchyDetail] = useState([]);
    const [walletDetail, setWalletDetail] = useState();
    const [recentUsers, setRecentUsers] = useState();
    const history = useHistory();
    const [verifyEmail, setVerifyEmail] = useState(false)
    const [otpLoading, setOtpLoading] = useState(false);
    const [logginIn, setLogginIn] = useState(false);
    const [otpError, setOtpError] = useState(false);
    const [otpErrorMessage, setOtpErrorMessage] = useState('');
    const [otp, setOtp] = useState('');
    const [isEmail, setIsEmail] = useState(false);

    const checkPlatform = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        const isAndroid = /android/.test(userAgent);
        const isIOS = /iphone|ipad|ipod/.test(userAgent);
        if (isIOS) {
            setPlatform('M')
        } else if (isAndroid) {
            setPlatform('M')
        } else {
            setPlatform('W')
        }
    }

    useEffect(() => {
        if (localStorage.getItem('user')) {
            let user = JSON.parse(localStorage.getItem('user'))
            if (!user.email_verified) {
                setIsEmail(true)
            }
        }
        checkPlatform();
        recentJoinedUsers();
        getHierarchyDetail();
        getLatestReferralList();
        getWalletDetail();

    }, []);

    const showUserProfile = (id) => {
        history.push(`/profile/otherProfile/${id}`);
    }

    const recentJoinedUsers = () => {
        const userId = JSON.parse(localStorage.getItem('user'))?.id
        let reqDto = {
            user_limit: 200
        }
        Services.recentlyJoinedUser(userId, reqDto).then((response) => {
            setRecentUsers(response)
        }).catch((error) => {
            console.log(error)
        })
    }

    const getHierarchyDetail = (heading, count) => {
        const userId = JSON.parse(localStorage.getItem('user'))?.id
        let requestData = {
            downline_count: count
        }
        Services.postHierarchy(userId, count && requestData).then((response) => {
            setHeirarchyDetail(response.data)
        }).catch((error) => {
            console.log(error)
        })
    }

    const getWalletDetail = (heading, count) => {
        const userId = JSON.parse(localStorage.getItem('user'))?.id
        let requestData = {
            downline_count: count
        }
        Services.getNewWalletDetails(userId).then((response) => {
            setWalletDetail(response)
        }).catch((error) => {
            console.log(error)
        })
    }

    const getHierarchy = (heading, count) => {
        const userId = JSON.parse(localStorage.getItem('user'))?.id
        let requestData = {
            downline_count: count
        }
        settableData([])
        setShowModal(true);
        setHeading(heading);
        Services.postHierarchy(userId, count && requestData).then((response) => {
            settableData(response.data)
        }).catch((error) => {
            console.log(error)
        })
    }

    const addSubtractDate = (startingDate, number) => {
        return new Date(new Date().setDate(startingDate.getDate() - number)).toLocaleDateString()
    }

    const formatDate = (date) => {
        const dateParts = date.split('/')
        return dateParts[2] + '-' + ('0' + dateParts[0]).slice(-2) + '-' + ('0' + dateParts[1]).slice(-2)
    }
    const getLatestReferralList = () => {
        const userId = JSON.parse(localStorage.getItem('user'))?.id
        const fromDate = formatDate(addSubtractDate(new Date(), 365))
        const toDate = formatDate(new Date().toLocaleDateString());
        Services.getLatestReferral(toDate, fromDate).then((response) => {
            setlatestReferral(response)
        }).catch((error) => {
            console.log(error)
        })
    }
    const walletData = (downline, heading) => {
        setShowWalletModal(true);
        settableData(
            [
                { amount: '200.00', name: 'Shirish Parajuli', referralId: 'EH-000152', imageUrl: '' }
            ]
        )
    }
    const totalData = (downline, heading) => {
        setShowTotal(true);
        settableData(
            [
                { amount: '200.00', name: 'Shirish Parajuli', referralId: 'EH-000152', imageUrl: '' }
            ]
        )
    }

    const referralData = (referral, heading) => {
        setHeading(heading);
        setShowReferralModal(true);     
        if (heading === 'Paid Referral') {
            const filteredData = latestReferral.filter(item => item.payment_status === 'Paid');
            setTotalReferral(filteredData);
        } else if (heading === 'Unpaid Referral') {
            const filteredData = latestReferral.filter(item => item.payment_status === 'Unpaid');
            setTotalReferral(filteredData);
        } else {
            setTotalReferral(latestReferral)
        }
    }
    const toggleModal = () => {
        setShowModal(!showModal);
    }
    const toggleReferralModal = () => {
        setShowReferralModal(!showReferralModal);
    }
    const toggleWalletModal = () => {
        setShowWalletModal(!showWalletModal);
    }
    const toggleTotalModal = () => {
        setShowTotal(!showTotal);
    }

    const toggleShareModal = () => {
        setShowShareModal(!showShareModal);
    }

    const referralCode = JSON.parse(localStorage.getItem('user'))?.referral_code

    const getUserDetails = async (id) => {
        const response = await Services.getUserDetails(id);
        localStorage.setItem('paid', JSON.stringify(response.results[0].payment_status))
        const localStorageData = localStorage.getItem('user');

        // Check if the key exists in localStorage
        if (localStorageData) {
            // Step 2: Parse the JSON data
            const parsedData = JSON.parse(localStorageData);

            // Step 3: Modify the data (change the value of the referred_count key)
            parsedData.referred_count = response.results[0]?.user.referred_count;
            parsedData.email_verified = response.results[0]?.user.email_verified;
            // Replace 15 with the new value you want

            // Step 4: Set the updated data back to localStorage
            localStorage.setItem('user', JSON.stringify(parsedData));
            console.log('asdfasdf', 'i am updated')
        } else {
            console.error('The key does not exist in localStorage.');
        }
    }

    const handleOtpSubmit = async (event, values) => {
        let user = JSON.parse(localStorage.getItem('user'))
        event.preventDefault();
        setOtpLoading(true);
        setLogginIn(true);
        await Services.verifyOtp({
            otp: values.otp,
            email: user.email
        })
            .then((response) => {
                getUserDetails(user.id)
                setIsEmail(false);
                setVerifyEmail(false);
                setOtpLoading(false);
                setLogginIn(false)
            })
            .catch((error) => {
                setOtpError(true);
                setOtpErrorMessage(error.message);
                setLogginIn(false)
                setOtpLoading(false)
                // this.props.history.push("/auth/changePassword", { email: this.state.email });
                setTimeout(() => {
                    setOtpError(false)
                }, 3000)
            });
    }

    const verifyEmailValidation = async () => {
        let user = JSON.parse(localStorage.getItem('user'))
        setLogginIn(true);
        await Services.verifyEmail({ email: user.email })
            .then((response) => {
                setVerifyEmail(true);
                setLogginIn(false);
            })
            .catch((error) => {
                setOtpError(true)
                setOtpErrorMessage(error.message);
                setLogginIn(false)
                setTimeout(() => {
                    setOtpError(false)
                }, 3000)
            });
    }


    return (

        <>
       
            <div className={'container-dashboard-refer'} style={{ minHeight: '600px' }}>
                {/*Mobile View*/}
                <div className={'refer-and-earn'}>
                    <div className={'image-profile'}>
                        <img height={100} width={100} style={{ marginTop: '-50px' }} className={'rounded-circle'}
                            alt={'profile'}
                            // src={require('../../assets/img/theme/team-2-800x800.jpg')}/>
                            src={
                                JSON.parse(localStorage.getItem('user'))?.profile_picture !== null && JSON.parse(localStorage.getItem('user'))?.profile_picture?.[0] === '/'
                                    ? base.concat(JSON.parse(localStorage.getItem('user'))?.profile_picture) :
                                    JSON.parse(localStorage.getItem('user'))?.profile_picture !== null && JSON.parse(localStorage.getItem('user'))?.profile_picture?.[0] === 'h'
                                        ? JSON.parse(localStorage.getItem('user'))?.profile_picture :
                                        JSON.parse(localStorage.getItem('user'))?.profile_picture !== null ?
                                            base1.concat(JSON.parse(localStorage.getItem('user'))?.profile_picture)
                                            : require("assets/img/theme/avatarnew.png")
                            } />
                    </div>
                    <div className={'button-section mt-4 mb-3'}>
                        <div className={'all-btn'}>
                            <div className={'icon-background'}>
                                <i className={'fa fa-money-bill'} />
                            </div>
                            <div className={'total-section'} onClick={() => totalData()}>
                                <span className={'title'}> Total</span>
                                <span className={'amount'}> Rs. {walletDetail?.total_earning || 0}/-</span>
                            </div>
                        </div>
                        <div className={'all-btn'}>
                            <div className={'icon-background'}>
                                <i className={'fa fa-wallet'} />
                            </div>
                            <div className={'total-section'} onClick={() => walletData()}>
                                <span className={'title'}> Wallet</span>
                                <span
                                    className={'amount'}> Rs. {walletDetail?.total_earning - walletDetail?.total_withdrawal || 0}/-</span>
                            </div>
                        </div>
                        <div className={'line-title'}>
                            <div className="divider">
                                <hr className="divider-line" />
                                <h2 className="divider-title">Referral Bonus</h2>
                                <hr className="divider-line" />
                            </div>
                        </div>
                        <div className={'all-btn'}>
                            <div className={'icon-background'}>
                                <i className={'fa fa-certificate'} />
                            </div>
                            <div className={'total-section'} onClick={() => getHierarchy('Direct', 1)}>
                                <span className={'title'}> Direct</span>
                                <span className={'amount'}>Rs. {heirarchyDetail?.downline_1_earning || 0}</span>
                            </div>
                        </div>
                        <div className={'all-btn'}>
                            <div className={'icon-background'}>
                                <i className={'fa fa-certificate'} />
                            </div>
                            <div className={'total-section'} onClick={() => getHierarchy('Indirect', 2)}>
                                <span className={'title'}>Indirect</span>
                                <span className={'amount'}> Rs. {heirarchyDetail?.downline_2_earning || 0}</span>
                            </div>
                        </div>
                        <div className={'all-btn'}>
                            <div className={'icon-background'}>
                                <i className={'fa fa-certificate'} />
                            </div>
                            <div className={'total-section'} onClick={() => getHierarchy('Others', 3)}>
                                <span className={'title'}>Others</span>
                                <span className={'amount'}> Rs. {heirarchyDetail?.downline_3_earning || 0}</span>
                            </div>
                        </div>
                        <div className={'line-title'}>
                            <div className="divider">
                                <hr className="divider-line" />
                                <h2 className="divider-title">Referrals</h2>
                                <hr className="divider-line" />
                            </div>
                        </div>
                        <div className={'all-btn'}>
                            <div className={'icon-background'}>
                                <i className={'fa fa-users'} />
                            </div>
                            <div className={'total-section'} onClick={() => referralData('total', 'Total Referral')}>
                                <span className={'title'}>Total</span>
                                <span className={'amount'}> {latestReferral?.length}</span>
                            </div>
                        </div>
                        <div className={'all-btn'}>
                            <div className={'icon-background'}>
                                <i className={'fa fa-dollar-sign'} />
                            </div>
                            <div className={'total-section'} onClick={() => referralData('paid', 'Paid Referral')}>
                                <span className={'title'}>Paid</span>
                                <span
                                    className={'amount'}> {latestReferral?.filter(item => item.payment_status === 'Paid')?.length}</span>
                            </div>
                        </div>
                        <div className={'all-btn'}>
                            <div className={'icon-background'}>
                                <i className={'fa fa-dolly-flatbed'} />
                            </div>
                            <div className={'total-section'} onClick={() => referralData('unpaid', 'Unpaid Referral')}>
                                <span className={'title'}>Unpaid</span>
                                <span
                                    className={'amount'}> {latestReferral?.filter(item => item.payment_status === 'Unpaid')?.length}</span>
                            </div>
                        </div>
                    </div>
                </div>
                {/*Mobile View*/}

                {/*Web View*/}
                <div className={'refer-and-earn-web'}>
                    <div className={'image-box'}></div>
                    <div className={'img-section'}>
                        <img style={{ objectFit: 'contain' }} className={'profile-image'} height={200} width={200}
                            src={JSON.parse(localStorage.getItem('user'))?.profile_picture !== null && JSON.parse(localStorage.getItem('user'))?.profile_picture?.[0] === '/'
                                ? base.concat(JSON.parse(localStorage.getItem('user'))?.profile_picture) :
                                JSON.parse(localStorage.getItem('user'))?.profile_picture !== null && JSON.parse(localStorage.getItem('user'))?.profile_picture?.[0] === 'h'
                                    ? JSON.parse(localStorage.getItem('user'))?.profile_picture :
                                    JSON.parse(localStorage.getItem('user'))?.profile_picture !== null ?
                                        base1.concat(JSON.parse(localStorage.getItem('user'))?.profile_picture)
                                        : require("assets/img/theme/avatarnew.png")}
                        />
                    </div>

                    <div className={'stat-box mt-2'}>
                        <div className={'stat-box-button'}>

                            <div className={'box-text'}>
                                <i className={'mb-3 fa fa-money-bill-wave'} />
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    alignItems: 'baseline',
                                }}>
                                    <div className={'heading-title'}><span>Total Reward</span></div>
                                </div>
                            </div>
                            {noData ?
                                <span style={{ color: 'indigo' }}>No Data Found</span>
                                :
                                <>
                                    <h3 className={'mt-1'}>Rs. {walletDetail?.total_earning || 0}</h3>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        width: '100%',
                                        justifyContent: 'flex-end',
                                        alignItems: "center"
                                    }}>
                                        <div className={'span-icon'} onClick={() => totalData()}>
                                            <span style={{
                                                fontSize: '12px',
                                            }}>See Details</span>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                        <div className={'stat-box-button'}>
                            <div className={'box-text'}>
                                <i className={'mb-3 fa fa-wallet'} />
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    alignItems: 'baseline',
                                    gap: '20px'
                                }}>
                                    <div className={'heading-title'}><span>Wallet</span></div>
                                </div>
                            </div>
                            <h3 className={'mt-1'}>Rs. {walletDetail?.total_earning - walletDetail?.total_withdrawal || 0}</h3>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                justifyContent: 'flex-end',
                                alignItems: "center"
                            }}>
                                <div className={'span-icon'} onClick={() => walletData()}>
                                    <span style={{
                                        fontSize: '12px',
                                    }}>See Details</span>
                                </div>
                            </div>
                        </div>

                    </div>

                    <h2 style={{
                        paddingLeft: '20px',
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                        color: '#6C32A0',
                        textAlign: 'center'
                    }}>Referral Bonus</h2>
                    <div className={'stat-box'}>
                        <div className={'stat-box-button'}>
                            <div className={'box-text'}>
                                <i className={'mb-3 fa fa-award'} />
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    alignItems: 'baseline',
                                    gap: '20px'
                                }}>
                                    <div className={'heading-title'}><span>Direct</span></div>
                                </div>
                            </div>
                            <h3 className={'mt-1'}>Rs. {heirarchyDetail?.downline_1_earning || 0}</h3>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                justifyContent: 'flex-end',
                                alignItems: "center"
                            }}>
                                <div className={'span-icon'} onClick={() => getHierarchy('Direct', 1)}>
                                    <span style={{
                                        fontSize: '12px',
                                    }}>See Details</span>
                                </div>
                            </div>
                        </div>
                        <div className={'stat-box-button'}>
                            <div className={'box-text'}>
                                <i className={'mb-3 fa fa-award'} />
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    alignItems: 'baseline',
                                    gap: '20px'
                                }}>
                                    <div className={'heading-title'}><span>Indirect</span></div>
                                </div>
                            </div>
                            <h3 className={'mt-1'}>Rs. {heirarchyDetail?.downline_2_earning || 0}</h3>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                justifyContent: 'flex-end',
                                alignItems: "center"
                            }}>
                                <div className={'span-icon'} onClick={() => getHierarchy('Indirect', 2)}>
                                    <span style={{
                                        fontSize: '12px',
                                    }}>See Details</span>
                                </div>
                            </div>

                        </div>
                        <div className={'stat-box-button'}>
                            <div className={'box-text'}>
                                <i className={'mb-3 fa fa-award'} />
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    alignItems: 'baseline',
                                    gap: '20px'
                                }}>
                                    <div className={'heading-title'}><span>Others</span></div>
                                </div>
                            </div>
                            <h3 className={'mt-1'}>Rs. {heirarchyDetail?.downline_3_earning || 0}</h3>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                justifyContent: 'flex-end',
                                alignItems: "center"
                            }}>
                                <div className={'span-icon'} onClick={() => getHierarchy('Others', 3)}>
                                    <span style={{
                                        fontSize: '12px',
                                    }}>See Details</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h2 style={{
                        paddingLeft: '20px',
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                        color: '#6C32A0',
                        textAlign: 'center'
                    }}>Referral Stats</h2>

                    <div className={'stat-box'}>
                        <div className={'stat-box-button'}>
                            <div className={'box-text'}>
                                <i className={'mb-3 fa fa-users'} />
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    alignItems: 'baseline',
                                    gap: '20px'
                                }}>
                                    <div className={'heading-title'}><span>Total</span></div>
                                </div>
                            </div>
                            <h3 className={'mt-1'}>{latestReferral?.length || 0}</h3>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                justifyContent: 'flex-end',
                                alignItems: "center"
                            }}>
                                <div className={'span-icon'} onClick={() => referralData('total', 'Total Referral')}>
                                    <span style={{
                                        fontSize: '12px',
                                    }}>See Details</span>
                                </div>
                            </div>
                        </div>
                        <div className={'stat-box-button'}>
                            <div className={'box-text'}>
                                <i className={'mb-3 fa fa-dollar-sign'} />
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    alignItems: 'baseline',
                                    gap: '20px'
                                }}>
                                    <div className={'heading-title'}><span> Paid</span></div>
                                </div>
                            </div>
                            <h3 className={'mt-1'}>{latestReferral?.filter(item => item.payment_status === 'Paid')?.length || 0}</h3>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                justifyContent: 'flex-end',
                                alignItems: "center"
                            }}>
                                <div className={'span-icon'} onClick={() => referralData('paid', 'Paid Referral')}>
                                    <span style={{
                                        fontSize: '12px',
                                    }}>See Details</span>
                                </div>
                            </div>
                        </div>
                        <div className={'stat-box-button'}>
                            <div className={'box-text'}>
                                <i className={'mb-3 fa fa-search-dollar'} />
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    alignItems: 'baseline',
                                    gap: '20px'
                                }}>
                                    <div className={'heading-title'}><span>Upaid</span></div>
                                </div>
                            </div>
                            <h3 className={'mt-1'}>{latestReferral?.filter(item => item.payment_status === 'Unpaid')?.length || 0}</h3>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                justifyContent: 'flex-end',
                                alignItems: "center"
                            }}>
                                <div className={'span-icon'} onClick={() => referralData('unpaid', 'Unpaid Referral')}>
                                    <span style={{
                                        fontSize: '12px',
                                    }}>See Details</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h2 style={{
                        paddingLeft: '20px',
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                        color: '#6C32A0',
                        textAlign: 'center'
                    }}>Recently Joined Users</h2>
                </div>
                {/*Web View*/}
                <div className={'recent-table mt-3 mb-3'}>
                    <div className={'table-heading'}>
                        <h4>Recent Users</h4>
                    </div>
                    <MaterialTable
                        title={''}
                        style={{
                            borderTopLeftRadius: '20px',
                            borderTopRightRadius: '20px',
                        }}
                        columns={[
                            {
                                title: 'Photo',
                                field: 'imageUrl',
                                width: '8%',
                                render: rowData => <img
                                    src={rowData.profile_picture ? base.concat('/media/').concat(rowData.profile_picture) : avatar}
                                    style={{ width: 40, height: 40, borderRadius: '50%' }} />
                            },
                            {
                                title: 'Name', field: 'full_name', width: "20%", render: rowData => <>
                                    <span style={{ cursor: 'pointer' }}
                                        onClick={() => showUserProfile(rowData.id)}>{rowData.full_name}</span>
                                </>
                            },

                            {
                                title: 'Number', field: 'full_name', width: "20%", render: rowData => <>
                                    <span style={{ cursor: 'pointer' }}>{rowData.phone_number}</span>
                                </>
                            },
                            {
                                title: 'Payment Status', field: 'payment_status', width: "30%", render: rowData => <>
                                    <div
                                        className={rowData.payment_status === 'Paid' ? 'badge-success' : 'badge-danger'}
                                        style={{
                                            display: "flex",
                                            justifyContent: 'center',
                                            width: '60px', padding: '2px', borderRadius: '10px', cursor: 'pointer'
                                        }}>
                                        <span style={{ fontSize: '12px' }}>{rowData?.payment_status}</span>
                                    </div>
                                </>
                            },
                            {
                                title: 'Joined',
                                field: 'payment_status',
                                render: rowData => <>
                                    <span>
                                        { moment(rowData?.created_at).format('YYYY-MM-DD') 
                                        }
                                    </span>
                                </>
                            },
                            {
                                title: 'Action', field: 'refer', render: rowData => <>
                                    <button style={{ cursor: 'pointer' }} onClick={() => {
                                        if (platform === 'W') {
                                            // toggleShareModal();
                                        } else {
                                            window.location.href = `tel:${rowData.phone_number}`; // Replace with the phone number you want to call
                                        }
                                    }} className={'all-btn'}>{platform === 'W' ? 'Poke' : 'Call'}</button>
                                </>
                            },
                        ]}
                        data={recentUsers}
                        options={{
                            //   exportButton: true,
                            // exportAllData: true,
                            sorting: true,
                            actionsColumnIndex: -1,
                            pageSize: 10,
                            pageSizeOptions: [10, 20, 100],
                            headerStyle: {
                                backgroundColor: '#6772E5',
                                color: '#FFF',
                                searchFieldAlignment: 'left'
                            }

                        }}
                    />
                </div>
            </div>

            {
                showModal && (
                    <DownlineModal
                        platform={platform}
                        toggleShareModal={toggleShareModal}
                        showModal={showModal}
                        toggleModal={toggleModal}
                        tableData={tableData}
                        heading={heading} />
                )
            }

            {
                showReferralModal && (
                    <ReferralModal
                        platform={platform}
                        toggleShareModal={toggleShareModal}
                        showModal={showReferralModal}
                        toggleModal={toggleReferralModal}
                        tableData={totalReferral}
                        heading={heading} />
                )
            }

            {
                showWalletModal && (
                    <WalletModal
                        walletDetail={walletDetail}
                        showModal={showWalletModal}
                        toggleModal={toggleWalletModal}
                        tableData={tableData} />
                )
            }

            {
                showTotal && (
                    <TotalModal
                        heirarchyDetail={heirarchyDetail}
                        showModal={showTotal}
                        toggleModal={toggleTotalModal}
                        tableData={tableData} />
                )
            }


            {
                showShareModal && (
                    <ShareModal
                        showModal={showShareModal}
                        toggleModal={toggleShareModal}
                        linkValue={window.config.appUrl.concat('auth/register').concat(`?referralCode=${referralCode}`)}
                    />
                )
            }

            {isEmail && (
                <Modal
                    keyboard={false}
                    backdrop={'static'}
                    className="modal-dialog-centered modal-md"
                    isOpen={isEmail}
                >
                    <div className="modal-header">
                        <h2 className="modal-title" id="modal-title-default">
                            {verifyEmail ? 'Verify OTP' : 'Verify Email'}
                        </h2>
                        {/*<button*/}
                        {/*    aria-label="Close"*/}
                        {/*    className="close"*/}
                        {/*    data-dismiss="modal"*/}
                        {/*    type="button"*/}
                        {/*    onClick={() => setVerifyEmail(false)}*/}
                        {/*>*/}
                        {/*    <span aria-hidden={true}>×</span>*/}
                        {/*</button>*/}
                    </div>
                    <div className={'modal-body'}>

                        <AvForm
                            onValidSubmit={handleOtpSubmit}
                        >
                            {verifyEmail ?
                                <>
                                    <AvGroup className="mb-3">
                                        <InputGroup className="input-group-alternative">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="fa fa-user" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <AvInput
                                                placeholder="Otp"
                                                type="text"
                                                name="otp"
                                                value={otp}
                                                required
                                                autoFocus
                                            />
                                        </InputGroup>
                                    </AvGroup>

                                    <div className="text-center">
                                        <Button disabled={otpLoading} className="my-4" color="primary">
                                            {logginIn ? "Submitting ..." : "Submit Your OTP"}
                                        </Button>
                                    </div>
                                </> :
                                <div className={'d-flex justify-content-center'}>
                                    <Button disabled={otpLoading} onClick={() => verifyEmailValidation()}
                                        className="my-4" color="primary">
                                        Verify Email
                                    </Button>
                                </div>

                            }
                            {otpError && otpErrorMessage &&
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                    justifyContent: 'center'
                                }}>
                                    <span style={{ color: 'red', justifyContent: 'center' }}>{otpErrorMessage}</span>
                                </div>
                            }

                        </AvForm>
                    </div>
                </Modal>
            )}

        </>
    )
}
