/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/

import React from "react";
import './styleoffooter.css';

const date = new Date().getFullYear();

class Footer extends React.Component {
  constructor() {
    super()
    this.state = {
      SocialMedia: [
        { name: 'facebook', icon: 'facebook-f', link: 'https://www.facebook.com/68english.hub' },
        { name: 'Instagram', icon: 'instagram', link: 'https://www.instagram.com/68english.hub' },
        { name: 'linkedin', icon: 'linkedin', link: 'https://www.linkedin.com/company/english-hub68' },
        { name: 'youtube', icon: 'youtube', link: 'https://www.youtube.com/@EnglishHub68' },
        { name: 'viber', icon: 'viber', link: 'https://invite.viber.com/?g2=AQB2n%2F0RwJ3760uRnSdS6xouHMJPZNw8eO7eONGtk5fDGMM8CPmsOYJ73fXQc2Bt' },
        { name: 'whatsapp', icon: 'whatsapp', link: 'https://wa.me/message/NXIDEWNWSC5CH1' },
      ]
    }
  }
  render() {
    return (
      <footer className="footer">
        <div className="footer-container">
          <div className="footer-logo-container">
            <img src="https://englishhub.app/englishhublogo.png" alt="English Hub Logo" className="footer-logo" />
          </div>
          <div className="footer-social-media">
            {this.state.SocialMedia.map((item, index) => (
              <a key={index} href={item.link} target="_blank" rel="noopener noreferrer" className="footer-icon">
                <i className={`fab fa-${item.icon} `}  ></i>
              </a>
            ))}
          </div>
          <div className="">
            <p className="footer-rights mb-0" >&copy; {date} English Hub. All Rights Reserved.</p>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
