import React from 'react';
import {withSnackbar} from "notistack";
import {Badge, Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal} from "reactstrap";
import Services from "../../RemoteServices/RemoteServices";
import MaterialTable from 'material-table';
import Sort from "../../assets/img/sort.png";
import avatar from '../../assets/img/theme/avatarnew.png'
import {withRouter} from "react-router-dom";
import img from "../../assets/img/subscribe-our-youtube-channel.png";

class ReferralModal extends React.Component {
    state = {
        createAccount: false,
        userDetails: '',
        showFilter: false,
        dropdownOpen:false,
        filterTag:'',
    }

     showUserProfile=(id)=>{
       this.props.history.push(`/profile/otherProfile/${id}`);
    }

    toggle = () => this.setState({
        dropdownOpen: !this.state.dropdownOpen,
    });

    handleSubmit = async (event, values) => {
        event.preventDefault();
        await this.setState({createAccount: true});
        await Services.updateStaff(this.state.userDetails.id, values)
            .then((response) => {
                localStorage.setItem("user", JSON.stringify(response))
                this.props.toggleModal("updateProfile")
            })
            .catch((error) => {
                this.setState({loggingIn: false});
            });
    }
    handleInvalidSubmit = (event, errors) => {
        this.props.enqueueSnackbar(`Please enter ${errors.join(" and ")}`, {
            variant: "error",
            anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
            },
        });
    };

    async componentDidMount() {
        await this.setState(prevState => ({
            ...prevState,
            updateProfile: this.props.updateProfile
        }))
        const userDetail = this.props.referral;
        this.setState({
            userDetails: userDetail
        })

    }



    toggleModal = (state) => {
        this.setState({
            [state]: !this.state[state],
        });
    };

    render() {
        const {userDetails} = this.state;
        return (
            <Modal
                keyboard={false}
                backdrop={'static'}
                className="modal-dialog-centered modal-lg"
                isOpen={this.props.showReferral}
                toggle={() => this.props.toggleModal("showReferral")}
            >
                <div className="modal-header">
                    <h2 className="modal-title" id="modal-title-default">
                        Referrals
                    </h2>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.props.toggleModal('showReferral')}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className={'modal-body m-0 p-0 '}>
                    <div className={'filter-refer ml-4 mr-4'} style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        marginBottom: '8px',
                    }}>
                        <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                            <DropdownToggle style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}><img height={20} alt={'sort'}
                                    src={Sort}/> &nbsp;&nbsp; Filter</DropdownToggle>
                            <DropdownMenu className={'refer-dropdown'}>
                                <DropdownItem
                                    onClick={() => {
                                        this.setState({filterTag: 'Paid'});
                                        this.props.fetchRefer('Paid')
                                    }
                                    }
                                >Paid</DropdownItem>
                                <DropdownItem
                                    onClick={() => {
                                        this.setState({filterTag: 'Unpaid'});
                                        this.props.fetchRefer('Unpaid')
                                    }
                                    }
                                >Unpaid</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>&nbsp;&nbsp;
                        <Badge color={'success'}>{this.state.filterTag}</Badge>
                    </div>

                    <div className={'m-4'}>
                        <MaterialTable
                            options={{
                                search: false,
                                showTitle: false,
                                toolbar: false,
                                actionsColumnIndex: -1,
                                padding: "dense"
                            }}


                            columns={[
                                {
                                    title: 'Photo',
                                    field: 'imageUrl',
                                    width: '8%',
                                    render: rowData => <img src={rowData.imageUrl || avatar}
                                                            style={{width: 40, borderRadius: '50%'}}/>
                                },
                                {title: 'Name',  field: 'full_name', width: "20%",render:rowData => <>
                                        <span style={{cursor:'pointer'}} onClick={()=>this.showUserProfile(rowData.user_id)}>{rowData.full_name}</span>
                                    </>},
                                {
                                    title: 'Refer', field: 'refer', render: rowData => <>
                                        {rowData.refer ?
                                            <span>{rowData.refer}</span> :
                                            <span>0</span>
                                        }
                                    </>
                                },
                                {
                                    title: 'Payment', field: 'payment_status', render: rowData => <>
                                        {rowData.payment_status ?
                                            <>
                                                {rowData.payment_status === 'Paid' ?
                                                    <span className="badge badge-success">
                                                         {rowData.payment_status}
                                                    </span> :
                                                    <span className="badge badge-warning">
                                                        {rowData.payment_status}
                                                     </span>
                                                }
                                            </> :
                                            <span className="badge badge-danger">Pending</span>

                                        }


                                    </>
                                },
                                {
                                    title: 'Action', render: rowData => <>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            alignItems: 'center'
                                        }}>
                                            <Button className="btn btn-sm btn-outline-danger">
                                                <span>Follow</span>
                                            </Button>
                                            <Button className="btn btn-sm btn-outline-darker">
                                                Message
                                            </Button>
                                        </div>
                                    </>
                                }
                            ]}
                            data={this.props.filteredData?this.props.filteredData:this.props.referral}
                        />
                    </div>
                </div>
            </Modal>
        )

    }
}

export default withRouter( withSnackbar(ReferralModal))
