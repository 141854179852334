import React, { useRef, useState } from 'react'
import { Button, Card, CardBody, Col, Modal, Row } from "reactstrap";
import ReactPlayer from "react-player/lazy";
import Payment from "../Payment";
import { useHistory } from "react-router-dom";
import Services from "../../RemoteServices/RemoteServices";
import KhaltiCheckout from "khalti-checkout-web";
import img from "../../assets/img/subscribe-our-youtube-channel.png";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";

const CommonDescription = (props) => {
    const history = useHistory();
    const eSewaRef = useRef(null);
    const [showPayment, setShowPayment] = useState(false);
    const [amount, setAmount] = useState(0);
    const [paymentDetail, setPaymentDetail] = useState({});
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [paymentError, setPaymentError] = useState(false);
    const [khaltiResponse, setKhaltiResponse] = useState('');
    const [paymentResponse, setPaymentResponse] = useState();
    const [buttonObject, setButtonObject] = useState();

    const khaltiConfig = {
        publicKey: "live_public_key_adebfca5a4f74cb8b4a8c1d762f27415",
        productIdentity: "ee2c3ca1-696b-4cc5-a6be-2c40d929d453",
        productName: "englishhub68",
        productUrl: "https://englishhub68.com/",
        eventHandler: {
            onSuccess(payload) {
                setKhaltiResponse(payload);
                (async () => {
                    try {
                        const data = await Services.setLinkWalletDetails({
                            payment_method: "KHALTI",
                            amount_paid: Number(payload.amount),
                            transaction_id: payload.idx,
                            reference_no: payload.idx,
                            category_object_id: buttonObject.parent,
                            category: buttonObject.category,
                            payment_button_id: buttonObject.id
                        });
                        setPaymentResponse(data);
                        setPaymentSuccess(true);
                    } catch (error) {
                        setPaymentError(true)
                    }
                })();
            },
            onError(error) {
                setPaymentError(true)
            },
        },
        paymentPreference: [
            "KHALTI",
            "EBANKING",
            "MOBILE_BANKING",
            // "CONNECT_IPS",
            "SCT",
        ],
    };
    const khaltiCheckout = new KhaltiCheckout(khaltiConfig);

    const handleKhaltiPay = async (parent, category, id) => {
        let reqDto = {
            return_url: "https://uat-user.englishhub.app/admin/khalti",
            website_url: "https://uat-user.englishhub.app/",
            amount: amount * 100,
            purchase_order_id: uuidv4(),
            purchase_order_name: 'English Hub',
        }
        await Services.saveKhaltiPayment(reqDto).then((response) => {
            return window.location.href = response.Response.payment_url
        });
    }


    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center',paddingLeft:'10px',paddingRight:'5px' }}>
                {props.arrayObject && props.arrayObject.sort((a, b) => a.order - b.order).map((item, index) => {
                    return (
                        <div key={index}>
                            {item.title && !item.key && (
                                <>
                                    <h3 style={{
                                        textAlign: 'center',
                                        fontWeight: 'bold',
                                        textTransform:'capitalize'
                                    }}> {item.title}</h3>
                                </>
                            )}
                            {item.text && (
                                <div className={'mb-3'} style={{
                                    textAlign: item.orientation,
                                }}
                                    dangerouslySetInnerHTML={{
                                        __html: item.text,
                                    }}
                                >
                                </div>
                            )}

                            {item.remark_text && (
                                <div className={'mb-3'} style={{
                                    textAlign: item.orientation,
                                }}
                                    dangerouslySetInnerHTML={{
                                        __html: item.remark_text,
                                    }}
                                >
                                </div>
                            )}

                            {item.button_link &&
                                <>
                                    <div className={'button-link mb-3 mt-3'} style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: item.orientation
                                    }}>
                                        <Button className={'all-btn'} href={item.button_link}
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            {item.button_name}
                                        </Button>
                                    </div>
                                </>
                            }

                            {item.youtube_link &&
                                <>
                                    {item.thumbnail !== null || item ?
                                        <>
                                            <div className="mt-3 mb-3">

                                                <ReactPlayer
                                                    url={item.youtube_link}
                                                    playIcon={<div></div>}
                                                    light={
                                                        <div style={{
                                                            position: 'relative',
                                                            width: '100%',
                                                            height: '100%',
                                                            border: '5px solid #00b050'

                                                        }}>
                                                            <img
                                                                alt={'thumbnail'}
                                                                style={{
                                                                    width: '100%',
                                                                    height: '100%',
                                                                    objectFit: 'stretch'
                                                                }}
                                                                src={item.thumbnail}
                                                            />
                                                            <div style={{
                                                                position: 'absolute',
                                                                top: '50%',
                                                                left: '50%',
                                                                transform: 'translate(-50%, -50%)'
                                                            }}>
                                                                <div style={{
                                                                    border: '4px solid indigo',
                                                                    borderRadius: '50%',
                                                                    width: '50px',
                                                                    height: '50px',
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    color: 'indigo'
                                                                }}>
                                                                    <i style={{ fontSize: 20 }}
                                                                        className="fa fa-play"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    className="react-player"
                                                    width={'100%'}
                                                    height={'498px'}
                                                    playing={true}
                                                    controls={true}
                                                />
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="mt-3 mb-3">
                                                <ReactPlayer
                                                    url={item.youtube_link || item.video_link}
                                                    className="react-player"
                                                    height='478px'
                                                    width='100%'
                                                    // playing={!shouldPause}
                                                    controls={true}
                                                />
                                            </div>
                                        </>
                                    }
                                </>
                            }

                            {item.video_link &&
                                <>
                                    {item.thumbnail_image !== null || item ?
                                        <>
                                            <div className="mt-3 mb-3">

                                                <ReactPlayer
                                                    url={item.video_link}
                                                    playIcon={<div></div>}
                                                    light={
                                                        <div style={{
                                                            position: 'relative',
                                                            width: '100%',
                                                            height: '100%'
                                                        }}>
                                                            <img
                                                                alt={'thumbnail'}
                                                                style={{
                                                                    width: '100%',
                                                                    height: '100%',
                                                                    objectFit: 'stretch'
                                                                }}
                                                                src={item.thumbnail_image}
                                                            />
                                                            <div style={{
                                                                position: 'absolute',
                                                                top: '50%',
                                                                left: '50%',
                                                                transform: 'translate(-50%, -50%)'
                                                            }}>
                                                                <div style={{
                                                                    border: '4px solid purple',
                                                                    borderRadius: '50%',
                                                                    width: '60px',
                                                                    height: '60px',
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    color: 'purple'
                                                                }}>
                                                                    <i style={{ fontSize: 20 }}
                                                                        className="fa fa-play"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    className="react-player"
                                                    width={'100%'}
                                                    height={'498px'}
                                                    playing={true}
                                                    controls={true}
                                                />
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="mt-3 mb-3">
                                                <ReactPlayer
                                                    url={item.video_link}
                                                    className="react-player"
                                                    height='478px'
                                                    width='100%'
                                                    // playing={!shouldPause}
                                                    controls={true}
                                                />
                                            </div>
                                        </>
                                    }
                                </>
                            }
                            {item.table_data && (
                                <>
                                    <div className={'mt-2 mb-2'}
                                        style={{ width: '100%', overflowX: "auto" }}>
                                        <table style={{ width: '100%' }}>
                                            {item.table_data.map((row, i) => (
                                                <React.Fragment key={i}>
                                                    {i === 0 ? (
                                                        <thead>
                                                            <tr>
                                                                {row.map((col, j) => (
                                                                    <th style={{
                                                                        border: '2px solid #4633af',
                                                                        borderRadius: '10px'
                                                                    }} key={j}>
                                                                        {col ? col : ''}
                                                                    </th>
                                                                ))}
                                                            </tr>
                                                        </thead>
                                                    ) : (
                                                        <tbody>
                                                            <tr>
                                                                {row.map((col, j) => (
                                                                    <td style={{
                                                                        border: '2px solid #4633af',
                                                                        borderRadius: '10px',
                                                                        textAlign: 'left', // Align content to the left

                                                                    }} key={j}>{col ? col : ''}</td>
                                                                ))}
                                                            </tr>
                                                        </tbody>
                                                    )}
                                                </React.Fragment>
                                            ))}
                                        </table>
                                    </div>
                                </>
                            )}

                            {item.data && (
                                <>
                                    <div className={'mt-2 mb-2'}
                                        style={{ width: '100%', overflowX: "auto" }}>
                                        <table style={{ width: '100%' }}>
                                            {item.data.map((row, i) => (
                                                <React.Fragment key={i}>
                                                    {i === 0 ? (
                                                        <thead>
                                                            <tr>
                                                                {row.map((col, j) => (
                                                                    <th style={{
                                                                        border: '2px solid #4633af',
                                                                        borderRadius: '10px'
                                                                    }} key={j}>
                                                                        {col ? col : ''}
                                                                    </th>
                                                                ))}
                                                            </tr>
                                                        </thead>
                                                    ) : (
                                                        <tbody>
                                                            <tr>
                                                                {row.map((col, j) => (
                                                                    <td style={{
                                                                        border: '2px solid #4633af',
                                                                        borderRadius: '10px',
                                                                        textAlign: 'left', // Align content to the left

                                                                    }} key={j}>{col ? col : ''}</td>
                                                                ))}
                                                            </tr>
                                                        </tbody>
                                                    )}
                                                </React.Fragment>
                                            ))}
                                        </table>
                                    </div>
                                </>
                            )}
                            {item.image &&
                                <>
                                    <div className={'mt-3 mb-3'} style={{
                                        border: '5px solid #00b050'
                                    }}>
                                        <img className={'m-0 p-0'}
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'contain'
                                            }}
                                            src={item.image}
                                            alt={''} />
                                    </div>
                                </>
                            }

                            {item.amount && !item.payment_status &&
                                <div className={'button-link mb-3 mt-3'} style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: item.orientation
                                }}>
                                    <Button className={'all-btn'} onClick={async () => {
                                        setButtonObject(item)
                                        setShowPayment(!showPayment);
                                        setAmount(item.amount)
                                    }}>
                                        {item.button_name}
                                    </Button>
                                </div>
                            }
                            {
                                
                            }
                        </div>
                    )
                })}
            </div>
            {showPayment &&
                <Modal
                    keyboard={false}
                    backdrop={'static'}
                    className="modal-dialog-centered modal-md"
                    isOpen={showPayment}
                    toggle={() => setShowPayment(!showPayment)}
                >
                    <div className="modal-header">
                        <h2 className="modal-title" id="modal-title-default">
                            Payment
                        </h2>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => setShowPayment(!showPayment)}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className={'modal-body'}>
                        <Row>
                            <Col xs={12} className="mb-3">
                                <div
                                    style={{
                                        padding: "1.25rem",
                                        border: "1px solid #e6ecf1",
                                        borderRadius: ".25rem",
                                        background: "#f5f7f9",
                                    }}
                                >
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td style={{ width: 100 }}>Amount</td>
                                                <td>Rs.{(amount / 113 * 100).toFixed(2)}</td>
                                            </tr>
                                            <tr>
                                                <td>VAT</td>
                                                <td>Rs.{(amount - amount / 113 * 100).toFixed(2)}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <h2 className="mb-0">Total</h2>
                                                </td>
                                                <td>
                                                    <h2 className="mb-0">
                                                        Rs.{amount}
                                                    </h2>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </Col>
                            <Col xs={6} className="mb-3">
                                <form
                                    action="https://esewa.com.np/epay/main"
                                    method="POST"
                                    ref={eSewaRef}
                                >
                                    <input
                                        value={Number(amount)}
                                        // value={113}
                                        name="tAmt"
                                        type="hidden"
                                    />
                                    <input
                                        // value={100}
                                        value={Number(amount / 113 * 100).toFixed(2)}
                                        name="amt" type="hidden" />
                                    <input
                                        //value={13}
                                        value={(amount - amount / 113 * 100).toFixed(2)}
                                        name="txAmt"
                                        type="hidden"
                                    />
                                    <input value="0" name="psc" type="hidden" />
                                    <input value="0" name="pdc" type="hidden" />
                                    <input value="NP-ES-EEAPHPL" name="scd" type="hidden" />
                                    <input value="ee2c3ca1-696b-4cc5-a6be-2c40d929d453" name="pid" type="hidden" />
                                    <input
                                        // value={`http://localhost:3000/admin/esewa?q=su&parent=${buttonObject.parent}&category=${buttonObject.category}&id=${buttonObject.id}`}
                                        value={`https://user.englishhub.app/admin/esewa?q=su&parent=${buttonObject.parent}&category=${buttonObject.category}&id=${buttonObject.id}`}
                                        type="hidden"
                                        name="su"
                                    />
                                    <input
                                        // value="http://localhost:3000/admin/esewa?q=fu"
                                        value="https://user.englishhub.app/admin/esewa?q=fu"
                                        type="hidden"
                                        name="fu"
                                    />
                                </form>
                                <Card
                                    className="card-stats"
                                    style={{ height: "100%", cursor: "pointer" }}
                                    onClick={() => eSewaRef.current.submit()}
                                >
                                    <CardBody style={{ display: "flex", alignItems: "center" }}>
                                        <img
                                            alt="esewa"
                                            src={require("assets/img/esewa.png")}
                                            style={{ width: "100%" }}
                                        ></img>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xs={6} className="mb-3">
                                <Card
                                    className="card-stats"
                                    style={{ height: "100%", cursor: "pointer" }}
                                    // onClick={() => khaltiCheckout.show({
                                    //         amount: amount * 100,
                                    //         // amount: 10 * 100,
                                    //     })
                                    // }
                                    onClick={() => {
                                        handleKhaltiPay(buttonObject.parent, buttonObject.category, buttonObject.id)
                                    }}
                                >
                                    <CardBody style={{ display: "flex", alignItems: "center" }}>
                                        <img
                                            alt="khalti"
                                            src={require("assets/img/khalti.svg")}
                                            style={{ width: "100%" }}
                                        ></img>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Modal>
            }

        </>

    )
}
export default CommonDescription;