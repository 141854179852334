import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Typography, Card, CardContent } from "@material-ui/core";
import { CalendarToday as CalendarIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto",
    padding: "1rem",
  },
  card: {
    width: "100%",
    maxWidth: "32rem",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "1.5rem",
  },
  iconWrapper: {
    marginBottom: "0.75rem",
    padding: "0.5rem",
    borderRadius: "50%",
    backgroundColor: "#F7F7F7",
  },
  icon: {
    width: 48,
    height: 48,
    color: "#333",
  },
  title: {
    marginBottom: 4,
    fontWeight: 600,
    color: "#000",
  },
  description: {
    marginBottom: "1.5rem",
    textAlign: "center",
    color: "#666",
  },
  infoBox: {
    width: "100%",
    maxWidth: 320,
    backgroundColor: "#f5f5f5",
    borderRadius: 8,
    padding: "16px",
  },
  infoText: {
    textAlign: "center",
    color: "#666",
    fontSize: "0.875rem",
  },
}));

const EmptyMeetingScreen = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <div className={classes.iconWrapper}>
            <CalendarIcon className={classes.icon} />
          </div>

          <Typography variant="h5" className={classes.title}>
            No Meetings Available
          </Typography>

          <Typography className={classes.description}>
            There are currently no scheduled meetings. Please check back later
            or contact your administrator for more information.
          </Typography>

          <div className={classes.infoBox}>
            <Typography className={classes.infoText}>
              This is a read-only view. Meeting schedules will appear here when
              they become available.
            </Typography>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default EmptyMeetingScreen;
