import { faCircleXmark, faForward, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Opacity } from '@material-ui/icons';
import React, { useState, } from 'react';
import { Button, Modal } from "reactstrap";

const Services = require("../../RemoteServices/RemoteServices.js");


const FreeQuiz = (props) => {
    const [selectedOption, setSelectedOption] = useState([]);
    const [selectedData, setselectedData] = useState([]);
    const [showResult, setShowResult] = useState(false);
    const [result, setResult] = useState('')
    const [percentage, setPercentage] = useState(25);
    const [quiz, setQuiz] = useState({
        is_completed: props.quizData.is_completed || false,
        data: { quizData: props.quizData.data.quizData || [] },
        key: props.quizData.data.key,
        set_title: props.quizData.data.title

    });

    const [answers, setAnswers] = useState([]);

    const fetchQuiz = async () => {
        await Services.getFreeQuizList({
            lesson_id: props.lessonData.id,
            course_id: props.lessonData.course,
        })
            .then((response) => {
                if (response.is_completed) {
                    props.toggleState('showQuiz')
                    props.fetchQuiz()
                } else {
                    setQuiz({
                        is_completed: response.is_completed,
                        data: { quizData: response.data.quizData || [] },
                        key: response.data.key,
                        set_title: response.data.title

                    })
                }
            })
            .catch((err) => {

            });
    }

    const fetchNextQuiz = async () => {
        try {
            await fetchQuiz();
            setSelectedOption([])
            setAnswers([])
            setselectedData([])
            setShowResult(false)
        } catch (e) {

        }

    }

    const handleAnswerChange = (questionId, optionValue) => {
        const newAnswers = [...answers];
        const answerIndex = newAnswers.findIndex(answer => answer.questionId === questionId);
        if (answerIndex === -1) {
            newAnswers.push({ questionId, answer: optionValue });
        } else {
            newAnswers[answerIndex].answer = optionValue;
        }
        setAnswers(newAnswers);
    };

    const handelQuestionSelection = (quiz, questionId, question) => {
        // for submission of quiz
        const newData = [...selectedData];
        const newQuestion = [...question.answerOptions];
        const questionIndex = newData.findIndex((i) => i.id == questionId);
        if (questionIndex > -1) {
            newData[questionIndex].answerOptions.map((q, index) =>
                q.id == quiz.id
                    ? (newData[questionIndex].answerOptions[index].isSelected = true)
                    : (newData[questionIndex].answerOptions[index].isSelected = false)
            );
        } else {
            newQuestion.map((q, index) => (
                q.id == quiz.id
                    ? (newQuestion[index].isSelected = true)
                    : (newQuestion[index].isSelected = false)
            ));
            newData.push({
                ...question,
                answerOptions: newQuestion,
            });
        }
        setselectedData(newData);
    };

    const checkAnswer = async () => {
        const newData = [...answers]
        let score = 0;
        newData.map((s) =>
            s.answer.isCorrect ? (score = score + 1) : (score = score)
        );
        const percentage = (score * 100) / newData.length;
        setPercentage(Math.round(percentage));
        setResult(Math.round(percentage))
        await Services.submitFreeQuiz({
            lesson: props.lessonData.id,
            course_id: props.lessonData.course,
            key: quiz.key,
            score: percentage,
            solution: JSON.stringify(selectedData),
            set_title: quiz.set_title,
        })
            .then((response) => {
                console.log("quizSubmitResponse", response);
            })
            .catch((err) => {
                console.log("Quizerror", err);
            });
        setShowResult(true)
        setSelectedOption([]);
    }


    const handleQuizSubmit = async () => {
        const newData = [...selectedOption];
        let score = 0;
        await newData.map((s) =>
            s.isCorrect ? (score = score + 1) : (score = score)
        );

        const percentage = (score * 100) / newData.length;
        console.warn("percentage", percentage);
        setTimeout(async () => {
            await Services.submitQuiz({
                lesson: props.lessonData.id,
                course_id: props.lessonData.course,
                key: quiz.data.key,
                score: percentage,
                solution: JSON.stringify(answers),
                set_title: quiz.data.title,
            })
                .then((response) => {
                    console.log("quizSubmitResponse", response);
                })
                .catch((err) => {
                    console.log("Quizerror", err);
                });
        }, 1000);

        setSelectedOption([]);
    };
    return (
        <div>
            <div
                className={` ${props.showQuiz ? '' : 'd-none'}`}

                width="100%"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    // borderTop:'1px solid #554a70',
                    // marginTop:'5px'
                }}
            >
                <div className="modal-header">
                    <h2 className="modal-title " id="modal-title-default">
                        Quizes
                    </h2>
                    <Button
                        className={'btn '}
                        data-dismiss="modal"
                        type="button"
                        style={{
                            backgroundColour: 'white',
                            border: 'none',
                            boxShadow: 'none',
                            color: '#e22c3b'
                        }}
                        onClick={() => props.toggleState("showQuiz")}
                    >
                        <FontAwesomeIcon icon={faCircleXmark} size='l' />
                    </Button>
                </div>
                <div className={'modal-body'}>
                    {!showResult ?
                        <>
                            {quiz.data.quizData.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <h3>{item.questionText}</h3>
                                        <br />
                                        <ul className="list-group">
                                            {
                                                item.answerOptions.map((choice, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <button style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent: 'flex-start',
                                                                alignItems: 'center',
                                                                width: '100%'
                                                            }} onClick={() => {
                                                                handleAnswerChange(item.id, choice, quiz)
                                                                handelQuestionSelection(choice, item.id, item)
                                                            }
                                                            }
                                                                key={item.id}
                                                                className="list-group-item">
                                                                <input
                                                                    type="radio"
                                                                    id={`${item.id}-${choice}`}
                                                                    name={`item-${item.id}`}
                                                                    value={choice}
                                                                    checked={answers.some(answer => answer.questionId === item.id && answer.answer === choice)}
                                                                    onChange={() => {
                                                                        handleAnswerChange(item.id, choice, quiz, choice)
                                                                        handelQuestionSelection(choice, item.id, item)
                                                                    }
                                                                    }
                                                                />&nbsp;{choice.answerText}
                                                            </button>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </ul>
                                        <br />
                                    </div>
                                )
                            })}
                        </>
                        :
                        <>
                            <div style={{ marginTop: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <i className={'fa fa-trophy fa-10x'} style={{ color: result > 60 ? 'green' : 'red' }} />
                                {result > 60 ?
                                    <span className={'mt-4'}
                                        style={{ color: 'green' }}>Congratulation You got {result}% </span>
                                    :
                                    <span className={'mt-4'}
                                        style={{ color: 'red' }}>Practice Hard You got {result}% </span>
                                }

                            </div>
                        </>
                    }
                </div>
                <div className="modal-footer">
                    {showResult &&
                        <>

                            <Button
                                className={'btn '}
                                style={{
                                    backgroundColour: 'white',
                                    border: 'none',
                                    boxShadow: 'none',
                                    color: '#4f2bab',
                                    Opacity: '1'
                                }}
                                data-dismiss="modal"
                                type="button"
                                onClick={() => fetchNextQuiz()}
                              
                            >
                                Next Quiz &nbsp; <FontAwesomeIcon icon={faForward} size='l' />
                            </Button>
                            <Button
                                className={'btn '}
                                data-dismiss="modal"
                                type="button"
                                style={{
                                    backgroundColour: 'white',
                                    border: 'none',
                                    boxShadow: 'none',
                                    color: '#e22c3b'
                                }}
                                onClick={() => props.toggleState("showQuiz")}
                            >
                                Close &nbsp; <FontAwesomeIcon icon={faCircleXmark} size='l' />
                            </Button>
                        </>

                    }
                    {!showResult &&
                        <>
                            <Button
                                className={'btn '}
                                style={{
                                    backgroundColour: 'white',
                                    border: 'none',
                                    boxShadow: 'none',
                                    color: '#4f2bab',
                                    Opacity: '1'
                                }}
                                data-dismiss="modal"
                                type="button"
                                onClick={() => checkAnswer()}
                                disabled={quiz.data.quizData.length !== answers.length}
                            >
                                Send &nbsp; <FontAwesomeIcon icon={faPaperPlane} size='l' />
                            </Button>
                            <Button
                                className={'btn '}
                                data-dismiss="modal"
                                type="button"
                                style={{
                                    backgroundColour: 'white',
                                    border: 'none',
                                    boxShadow: 'none',
                                    color: '#e22c3b'
                                }}
                                onClick={() => props.toggleState("showQuiz")}
                            >
                                Close &nbsp; <FontAwesomeIcon icon={faCircleXmark} size='l' />
                            </Button>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}
export default FreeQuiz
