import React, { useEffect, useState, useContext, useRef } from "react";
import PropTypes from "prop-types";
import {
  faMicrophone,
  faPhone,
  faPhoneSlash,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import ActionButton from "./ActionButton";
import { SocketContext } from "layouts/SocketContext";
import { socketListen } from "../../utils/video-call/SocketListener";
import MediaDevice from "../../utils/video-call/MediaDevice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "reactstrap";
import PeerAvatar from "./PeerAvatar";
let clientId = 102901;

function MainWindow({ startCall }) {
  const testvid = useRef(null);
  const mediaStreamRef = useRef(null);

  // useEffect(() => {
  //    new MediaDevice().on('stream', (stream) => {
  //     testvid.current.srcObject = stream
  //     mediaStreamRef.current = stream
  //    }).start()
  //    return () => {
  //     if (mediaStreamRef.current) {
  //       mediaStreamRef.current.getTracks().forEach(track => {
  //         track.stop();
  //       });
  //     }
  //   };
  // }, [])

  /**
   * Start the call with or without video
   * @param {Boolean} video
   */
  const callWithVideo = (video) => {
    const config = { audio: true, video };
    return () => startCall(true, null, config);
  };

  return (
    <React.Fragment>
      <div className="main-window">
        <div className="logo-container"></div>
        <div className="main-call-container">
          <div className="call-container-mode">
            {/* video frame */}

            {/* <video  className="local-video-frame-init" autoPlay playsInline /> 
 
 toggle local-video-frame-init or PeerAvatar MessageDis={'Your Mic & Video are Off'}
 
 */}
            <PeerAvatar MessageDis={"Your Mic & Video are Off"} />

            <div className="call-control-div">
              {/* for audio togle */}
              <ActionButton icon={faMicrophone} className="btn-action" />
              {/* for video togle */}
              <ActionButton icon={faVideo} className="btn-action" />
            </div>
          </div>
          <div className="call-container-join-cnt">
            <h1 className="call-theme-poem">
              {" "}
              "In the heart of every great teacher is the desire to see their
              students not just learn, but thrive."
            </h1>

            <div className="call-join-box">
              <div className="call-actions">
                {/* call or join */}
                <Button className="btn-join" onClick={callWithVideo(true)}>
                  Join &nbsp;
                  <FontAwesomeIcon icon={faPhone} />
                </Button>
                {/* close window */}
                <Button className="btn-cancel">
                  Cancel &nbsp;
                  <FontAwesomeIcon icon={faPhoneSlash} />
                </Button>
              </div>
              <div className="call-message">
                <h1>Would you like to join ?</h1>
                {/* <div className='someone-has-join'>   to show someone has join | h2
                      <PeerAvatar MessageDis={'Join :'} />
                    </div> */}
                <h2>No one has join</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

MainWindow.propTypes = {
  startCall: PropTypes.func.isRequired,
};

export default MainWindow;
