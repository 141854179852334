import React from 'react';
import { Button, Modal } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faGraduationCap } from '@fortawesome/free-solid-svg-icons';


class QuizResultModal extends React.Component {
    state = {
        quizData: this.props.quizData,
        activeTab: null,
        tabId: 0,
        quizResult: '',
        showResult: false,
        result: ''

    }

    toggle(tab, item) {
        this.setState({
            activeTab: tab,
            quizResult: item,
            showResult: false,
        });
    }
    viewResult = async (id) => {
        await this.setState({
            showResult: true,
            result: this.state.quizData.data[id].score
        })
    }

    render() {
        const { quizData, quizResult } = this.state
        return (
            <div
                style={{
                    borderBottom: '1px solid rgba(79, 43, 171, 0.35)'
                }}
            >
                <div className="modal-header">
                    <h2 className="modal-title" id="modal-title-default">
                        Quiz Result
                    </h2>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.props.toggleModal('showQuizResult')}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className={'modal-body'}>
                    <div>
                        {quizData && quizData?.data?.map((item, index) => (
                            <>
                                <Button active={this.state.activeTab === index}
                                    onClick={() => this.toggle(index, JSON.parse(item.solution))}
                                    className={'btn btn-sm btn-outline-success'}>{item.set_title}</Button>
                            </>
                        ))}
                        <div>
                            {!this.state.showResult && quizResult && quizResult.map((item, index) => (
                                <div className={'mt-4'} key={index}>
                                    <h3>{item.questionText}</h3>
                                    <br />
                                    <ul className="list-group" style={{
                                        backgroundColor: " rgb(245, 245, 245)"
                                    }}>
                                        {
                                            item.answerOptions.map((choice, index) => {
                                                return (
                                                    <div key={index}>
                                                        <button style={{
                                                            color: (choice.isSelected && choice.isCorrect ? 'lightblue' : !choice.isCorrect && choice.isSelected ? 'red' : choice.isCorrect ? 'green' : 'black'),
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            justifyContent: 'flex-start',
                                                            alignItems: 'center',
                                                            width: '100%'
                                                        }}
                                                            key={item.id}
                                                            className="list-group-item">
                                                            <input
                                                                type="radio"
                                                                id={`${item.id}-${choice}`}
                                                                name={`item-${item.id}`}
                                                                value={choice}
                                                                checked={choice.isSelected}
                                                            />&nbsp;{choice.answerText}
                                                        </button>
                                                    </div>
                                                )
                                            })
                                        }
                                    </ul>
                                    <br />
                                </div>
                            ))}

                            {this.state.showResult &&
                                <div style={{ marginTop: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <i className={'fa fa-trophy fa-10x'} style={{ color: this.state.result > 60 ? 'green' : 'red' }} />
                                    {this.state.result > 60 ?
                                        <span className={'mt-4'}
                                            style={{ color: 'green' }}>Congratulation You got {this.state.result}% </span>
                                        :
                                        <span className={'mt-4'}
                                            style={{ color: 'red' }}>Practice Hard You got {this.state.result}% </span>
                                    }

                                </div>
                            }

                        </div>
                    </div>
                </div>
                <div className={'modal-footer '}
                    style={{
                        backgroundColor: " rgb(245, 245, 245)"
                    }}
                >

                    {this.state.activeTab !== null && !this.state.showResult &&

                        <Button style={{
                            // backgroundColour: 'white',
                            border: 'none',
                            boxShadow: 'none',
                            color: '#4f2bab',
                            Opacity: '1'
                        }} className={'btn'}
                            onClick={() => this.viewResult(this.state.activeTab)}>Show Result  &nbsp; <FontAwesomeIcon icon={faGraduationCap} size='l' />  </Button>
                    }
                    <Button style={{
                        // backgroundColour: 'white',
                        border: 'none',
                        boxShadow: 'none',
                        color: '#e22c3b'
                    }} onClick={() => this.props.toggleModal('showQuizResult')}
                        className={' btn '}>Close &nbsp; <FontAwesomeIcon icon={faCircleXmark} size='l' /></Button>

                </div>
            </div>
        )

    }
}

export default QuizResultModal