import React from "react";
import { observer } from "mobx-react";

import {
    Button,
    Card,
    CardBody,

    Col,
    Container,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,

    Input,
    Row
} from "reactstrap";
import { withSnackbar } from "notistack";
import { MobXContext } from "MobXProvider";
import { Oval } from 'react-loader-spinner';
import ReactPlayer from 'react-player/lazy';



import "./styles.css";
import './styles.scss'
import FreeComment from "../../components/Comment/FreeComment";
import FreeCommentForm from "../../components/CommentForm/FreeComment";
import QuizResultModal from "../../components/Modals/QuizResultModal";
import FreeQuiz from "./FreeQuiz";
import InfiniteScroll from "react-infinite-scroll-component";
import img from "../../assets/img/subscribe-our-youtube-channel.png";
import Loading from "../../components/Loading/Loading";
import { withTranslation } from 'react-i18next';
import CommonDescription from "../../components/Modals/CommonDescription";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDownAZ, faPaperPlane, faThumbsDown as fathumbsDownSolid, faThumbsUp as fathumbsUpSolid } from "@fortawesome/free-solid-svg-icons";
import { faThumbsDown as fathumbsDownRegular, faThumbsUp as fathumbsUpRegular } from '@fortawesome/free-regular-svg-icons'



const Services = require("../../RemoteServices/RemoteServices.js");

const Lessons = observer(
    class Lessons extends React.Component {
        static contextType = MobXContext;

        constructor(props) {
            super(props);

            this.state = {
                lessonData: [],
                images: [],
                videoLink: "",
                comment: "",
                commentError: false,
                offset: 0,
                filterOffset: 0,
                showMore: false,
                options: [
                    { id: 1, label: 'All', value: 'all' },
                    { id: 2, label: 'New', value: 'created_at' },
                    { id: 3, label: 'Trending', value: 'like_count' },
                ],
                freeList: [],
                showAdd: true,
                isLoading: false,
                quizData: '',
                dropdownOpen: false,
                showQuiz: false,
                tag: 'new',
                like: false,
                dislike_count: null,
                current_user_like_status: '',
                like_count: null,
                dislike: '',
                current_user_dislike_status: "",
                active: 0,
                showQuizResult: false,
                searchContent: false,
                watchVideo: false,
                searchItem: '',
                commentMessage: false,
                loading: false,
                loadingError: false,
                newDescription: false,
                // PRADIP
                currentpostcommentTime: '',
                recentmessage: false,

            };

        }

        user = JSON.parse(localStorage.getItem("user"));

        async componentDidMount() {
            const params = new URLSearchParams(this.props.location.search);
            this.setState({ loading: true })
            await this.setState({
                offset: 0,
                active: 0,
            })
            // await this.getLessonData();
            await this.getFreeLessonList();
            await this.fetchQuiz();
            if (params.get('lesson')) {
                this.selectedVideo(params.get('lesson'))
            }

        }


        toggle = () => this.setState({
            dropdownOpen: !this.state.dropdownOpen,
        });
        toggleState = (state) => {
            this.setState({
                [state]: !this.state[state],
            });
        };

        fetchQuiz = async () => {
            await Services.getFreeQuizList({
                lesson_id: this.state.lessonData.id,
            })
                .then((response) => {
                    this.setState({
                        quizData: response,
                    })
                })
                .catch((err) => {
                    this.setState({
                        quizData: ''
                    })
                });
        }

        selectedVideo = async (id) => {
            await this.setState({
                isLoading: true,
                showAdd: true,
                offset: 0,
                watchVideo: true,
            })
            const lessonData = await Services.getFreeLessons(id);
            await this.setState({
                lessonData: lessonData,
                lessonId: lessonData.id,
                status: lessonData.status,
                videoLink: lessonData.youtube_link,
                isLoading: false,
                like: lessonData.current_user_like_status,
                dislike_count: lessonData.dislike_count,
                like_count: lessonData.like_count,
                current_user_like_status: lessonData.current_user_like_status,
                dislike: lessonData.current_user_dislike_status,

            });
            await this.getReviewsList();
            await this.fetchQuiz()
        }
        getFreeLessonList = async () => {
            const response = await Services.getFreeLessonsList();
            this.setState({
                freeList: response,
                loading: false,
            })
        }
        getFreeSearchLessonList = async (data) => {
            const response = await Services.getFreeSearchLessonsList(data);
            this.setState({
                freeList: response
            })
        }

        async getLessonData() {
            await this.setState({
                isLoading: true,
            })
            const lessonData = await Services.getFreeLessons(10);
            await this.setState({
                lessonData: lessonData,
                lessonId: lessonData.id,
                status: lessonData.status,
                videoLink: lessonData.youtube_link,
                isLoading: false,
                like: lessonData.current_user_like_status,
                dislike_count: lessonData.dislike_count,
                like_count: lessonData.like_count,
                current_user_like_status: lessonData.current_user_like_status,
                dislike: lessonData.current_user_dislike_status,
            });
        }

        getReviewsList = async () => {

            await this.context.getFreeReviewsList(this.state.lessonData.id, this.state.offset);
            this.setState((state) => ({
                offset: (state.offset += 10),
            }));

        };

        // change filteroffset to offset
        filterComment = async (tag) => {
            try {
                if (this.state.tag === tag) {
                    await this.context.getFilterFreeReviewList(this.state.lessonData.id, this.state.offset, tag)
                    this.setState((state) => ({
                        offset: (state.offset += 10),
                    }));
                } else {
                    await this.setState({ filterOffset: 0 });
                    await this.context.getFilterFreeReviewList(this.state.lessonData.id, this.state.filterOffset, tag)
                    this.setState((state) => ({
                        offset: (state.offset = 10),
                    }));
                    this.setState({ tag: tag })
                }
            } catch (e) {

            }
        }

        onChange = (imageList, addUpdateIndex) => {
            // data for submit
            // console.log(imageList, addUpdateIndex);
            this.setState({ images: imageList });
        };

        submitAssignment() {
            // console.log(this.state.images);
            this.setState({ loading: true });
            let assignments = [];
            let images = this.state.images;
            images.forEach((image) => {
                let base64 = image.data_url;
                assignments.push(base64);
            });
            let data = {
                images: assignments,
                lesson: this.state.lessonId,
            };
            Services.sendAssignment(data)
                .then((response) => {
                    this.setState({
                        images: [],
                        assignmentSubmitted: true,
                        loading: false,
                    });
                    this.getLessonData();
                    // console.log(response)
                })
                .catch((error) => {
                    // console.log(error)
                });
        }

        _onReady(event) {
            // access to player in all event handlers via event.target
            // event.target.pauseVideo();
            if (
                document.getElementsByClassName(
                    "ytp-chrome-top ytp-show-cards-title"
                )[0]
            ) {
                document.getElementsByClassName(
                    "ytp-chrome-top ytp-show-cards-title"
                )[0].style.visibility = "hidden";
            }

        }

        likeVideo = async (flag) => {
            try {
                if (flag) {
                    let reqDto = {
                        have_i_Like: flag,
                        id: this.state.lessonData.id,
                        user_id: JSON.parse(localStorage.getItem('user'))?.id
                    }
                    const response = await Services.likeFreeLesson(reqDto)
                    await this.setState({
                        current_user_like_status: flag,
                    })
                    await this.setState((prevState) => ({
                        ...prevState,
                        lessonData: {
                            ...prevState.lessonData,
                        },
                        like_count: this.state.like_count += 1,
                        dislike_count: this.state.current_user_like_status && this.state.dislike_count !== 0 ? this.state.dislike_count - 1 : this.state.dislike_count,
                        dislike: !flag,
                        like: !this.state.like,
                    }))
                } else {
                    let reqDto = {
                        have_i_Like: flag,
                        id: this.state.lessonData.id,
                        user_id: JSON.parse(localStorage.getItem('user'))?.id
                    }
                    const response = await Services.likeFreeLesson(reqDto)
                    await this.setState({
                        current_user_like_status: flag,
                    })
                    await this.setState((prevState) => ({
                        ...prevState,
                        lessonData: {
                            ...prevState.lessonData,
                        },
                        dislike_count: this.state.dislike_count += 1,
                        like_count: !this.state.current_user_like_status && this.state.like_count !== 0 ? this.state.like_count - 1 : this.state.like_count,
                        like: flag,
                        dislike: !this.state.dislike
                    }))
                }
            } catch (e) {

            }

        }

        clearComment = () => {
            this.setState({ comment: '' })
        }

        _onStateChange(event) {
            // console.log(event);
            if (event.data === "1") {
                if (
                    document.getElementsByClassName(
                        "ytp-chrome-top ytp-show-cards-title"
                    )[0]
                ) {
                    document.getElementsByClassName(
                        "ytp-chrome-top ytp-show-cards-title"
                    )[0].style.visibility = "hidden";
                }
            }
            // document.getElementsByClassName('ytp-chrome-top ytp-show-cards-title')[0].style.visibility = 'hidden'
        }

        handleCommentChange = (e) => {
            this.setState({ comment: e.target.value });
        };
        playVideo = async () => {
            await this.setState({
                showAdd: false,
            })
            const lessonData = await Services.getFreeLessons(10);
            await this.setState({
                lessonData: lessonData,
                lessonId: lessonData.id,
                status: lessonData.status,
                videoLink: lessonData.youtube_link,
                isLoading: false,
            });
        }

        handleComment = async () => {
            if (this.state.comment.length >= 25) {
                this.setState({ commentError: false });
                await await Services.freeReview({
                    user: this.user.id,
                    lesson: this.state.lessonData.id,
                    message: this.state.comment,
                }).then((response) => {
                    this.setState({ comment: "", commentMessage: true, currentpostcommentTime: new Date(response.created_at).getTime() });


                    if (this.context?.reviewsList?.results) {
                        this.context.reviewsList.results.unshift(response);
                    }
                    this.HighlishtComment()
                    setTimeout(() => {
                        this.setState({ commentMessage: false })
                    }, 2000)

                })
                    .catch((error) => {
                        this.props.enqueueSnackbar(error.message, {
                            variant: "error",
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "right",
                            },
                        });
                    });


            } else {
                this.setState({ commentError: true });
            }

        };

        handleLike = async (payload) => {
            await Services.likeReview(payload);
        };
        changeVideo = async (link) => {
            this.setState((prevState) => ({
                ...prevState,
                videoLink: link

            }))

        }

        _handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                if (this.state.searchItem !== '') {
                    this.searchContent(this.state.searchItem)
                }
            }
        }

        handleOnChange = async (e) => {
            e.preventDefault();
            await this.setState({
                searchItem: e.target.value
            })
            if (this.state.searchItem === '') {
                await this.toggleState('searchContent')
                await this.getFreeLessonList();
            }
        }

        handleSearchKeyDown = (e) => {
            if (e.key === 'Enter') {
                this.searchContent()
            }
        }

        handleSearchItem = (e) => {
            this.setState({ searchItem: e.target.value })
        }

        searchContent = async (searchItem) => {
            // await this.toggleState('searchContent')
            await this.setState({
                watchVideo: false,
            })
            await this.getFreeSearchLessonList(this.state.searchItem);
        }

        searchFreeContent = async (index, data) => {
            await this.setState({
                active: index,
            })
            const response = await Services.getFreeLessonsList(data, this.state.searchItem);
            this.setState({
                freeList: response
            })
        }

        handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                this.handleComment()
            }
        }
        // pradip

        HighlishtComment = () => {
            let userTime = Date.now()
            let timediff = Math.abs(this.state.currentpostcommentTime - userTime)

            if (timediff <= 3000) {
                this.setState({ recentmessage: true })
                setTimeout(() => {
                    this.setState({ recentmessage: false })
                }, 60000);
            } else {
                this.setState({ recentmessage: false })
            }
        }
        onVideoEnd() {
            if (this.state.showAdd) {
                this.setState({ showAdd: false });
            }
        }



        render() {

            const user = JSON.parse(localStorage.getItem('user'))


            return (
                <>


                    {!this.state.loading && !this.state.isLoading ?
                        <Container className={'container-dashboard'}
                            fluid
                            style={{ minHeight: "calc(100vh - 76px - 118px)" }}
                        >
                            {this.state.watchVideo && (
                                <Row xs={12} md={12} xl={12} className="m-0 p-0  " >
                                    <Col xs={4} md={8} xl={8} className="m-0 p-0  "

                                    >
                                        <div
                                            className={'trending-div'}>
                                            <Button
                                                className={'trending-button btn-sm mr-2'}
                                                style={{
                                                    backgroundColor: ' #4f2bab',
                                                    color: 'white'
                                                }}
                                                onClick={() => {
                                                    this.toggleState('watchVideo')
                                                }}>
                                                <span>

                                                    <i className='fa fa-arrow-circle-left' />
                                                    &nbsp;BACk
                                                </span>
                                            </Button>
                                        </div>

                                    </Col>
                                    <Col xs={8} md={4} xl={4} className={'m-0 p-0'}>
                                        <div style={{
                                            cursor: 'pointer',
                                            display: 'flex',
                                            marginBottom: '20px',
                                            // justifyContent: 'space-around',
                                            flexWrap: 'nowrap',
                                        }} className="coursesCardSlider  btn-adjust-tranding">

                                            {this.state?.freeList?.length > 0 &&
                                                <>

                                                    {this.state?.options.map((data, index) => {
                                                        return (
                                                            <div key={index}
                                                                className={'trending-div '}>
                                                                <Button
                                                                    className={'trending-button btn-sm mr-2'}
                                                                    active={this.state.active === index}
                                                                    onClick={() => this.searchFreeContent(index, data.value)}>
                                                                    <span>
                                                                        {data.label}
                                                                    </span>
                                                                </Button>
                                                            </div>

                                                        )
                                                    })}
                                                </>
                                            }
                                        </div>
                                    </Col>
                                </Row>

                            )}
                            {this.state.freeList.length > 0 ?
                                <>
                                    {this.state.searchContent && !this.state.watchVideo ?
                                        <>
                                            <Row className={'mb-5 '}>
                                                <Col xs={12} md={12} xl={12}>
                                                    {this.state?.freeList && this.state?.freeList.map(item => {
                                                        return (
                                                            <div className={'free-lesson-list'}
                                                                key={item.id}
                                                                onClick={() => this.selectedVideo(item.id)}>
                                                                <div key={item.id}
                                                                    style={{
                                                                        width: '25%',
                                                                        maxHeight: '25%',
                                                                        marginBottom: '15px',
                                                                    }}>
                                                                    <img style={{
                                                                        borderRadius: '15px',
                                                                        cursor: 'pointer',
                                                                        height: '100%',
                                                                        width: '100%',
                                                                        objectFit: 'cover',
                                                                        position: 'static',
                                                                        overflow: 'hidden',
                                                                        background: 'rgba(0, 0, 0, 0.05)'

                                                                    }}
                                                                        alt=""
                                                                        src={item?.thumbnail} />
                                                                </div>
                                                                <div style={{
                                                                    marginLeft: '30px',
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    justifyContent: 'flex-start'
                                                                }}>
                                                                    <span style={{ cursor: 'pointer' }}
                                                                        className={'mb-1 font-weight-bold'}>{item.title}</span>
                                                                    <div className={'m-0 p-0'}
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: item.details?.substring(0, 40),
                                                                        }}
                                                                    ></div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                    {!this.state?.freeList?.length > 0 &&
                                                        <div style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            width: '100%',
                                                            justifyContent: 'center'
                                                        }}>
                                                            <span>No Content Found</span>
                                                        </div>
                                                    }
                                                </Col>
                                            </Row>
                                        </> :
                                        <>
                                            {!this.state.watchVideo &&
                                                <>
                                                    <div className={'lesson-history'


                                                    }>
                                                        <div className={'trending-div btn-mobile'}
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                // alignItems: 'end'
                                                            }}
                                                        >
                                                            {this.state?.options.map((data, index) => (
                                                                <Button
                                                                    className={'trending-button btn-sm mr-2'}
                                                                    active={this.state.active === index}
                                                                    onClick={() => this.searchFreeContent(index, data.value)}>
                                                                    <span>
                                                                        {data.label}
                                                                    </span>
                                                                </Button>
                                                            ))}
                                                        </div>

                                                        <div className={'lesson-tab-search'}>
                                                            <Card className={'history-search'}>
                                                                <div style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    gap: '0px',
                                                                    alignItems: 'center'
                                                                }}>
                                                                    <div style={{ position: 'relative' }}>
                                                                        <input
                                                                            className="history-search-input"
                                                                            type="search"
                                                                            placeholder="Search Term"
                                                                            value={this.state.searchItem}
                                                                            onKeyDown={this.handleSearchKeyDown}
                                                                            onChange={this.handleSearchItem}
                                                                        />
                                                                        <i
                                                                            className="fa fa-search"
                                                                            style={{
                                                                                position: 'absolute',
                                                                                top: '50%',
                                                                                transform: 'translateY(-50%)',
                                                                                left: '10px',
                                                                                color: '#a1a1a1',
                                                                                pointerEvents: 'none' // Prevents the icon from being clickable
                                                                            }}
                                                                        ></i>
                                                                    </div>
                                                                </div>
                                                            </Card>
                                                            <div className={'ml-3 mr-3 filter'} style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                width: '100%',
                                                                justifyContent: 'flex-end',
                                                                alignItems: 'center'
                                                            }}>
                                                                <Dropdown isOpen={this.state.dropdownOpen}
                                                                    toggle={this.toggle}>
                                                                    <DropdownToggle style={{
                                                                        display: 'flex',
                                                                        flexDirection: 'row',
                                                                        justifyContent: 'space-between',
                                                                        alignItems: 'center'
                                                                    }}><FontAwesomeIcon icon={faArrowDownAZ} size="lg" /> &nbsp;&nbsp; Filter</DropdownToggle>
                                                                    <DropdownMenu>
                                                                        {this.state?.options?.map((item, index) => (
                                                                            <DropdownItem key={index}
                                                                                onClick={() => this.searchFreeContent(index, item.value)}>{item.label}</DropdownItem>
                                                                        ))}
                                                                    </DropdownMenu>
                                                                </Dropdown>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <hr style={{
                                                        height: 'auto',
                                                        color: 'black',
                                                        margin: '6px 0 0 0',

                                                    }} />

                                                    <div className="youtube-list"  >
                                                        {this.state?.freeList && this.state?.freeList.map(item => {
                                                            return (
                                                                <div className={'youtube-list-item'}>
                                                                    <Card className={'history-card'}>
                                                                        <div
                                                                            onClick={() => this.selectedVideo(item.id)}
                                                                            key={item.id}
                                                                            className={'youtube-content'}>
                                                                            <img style={{
                                                                                cursor: 'pointer',
                                                                                height: '100%',
                                                                                width: '100%',
                                                                                contain: 'cover',
                                                                                background: 'rgba(0, 0, 0, 0.05)',
                                                                            }}
                                                                                alt=""
                                                                                src={item?.thumbnail} />

                                                                            <div style={{
                                                                                position: "absolute",
                                                                                top: 75,
                                                                                border: '4px solid indigo',
                                                                                borderRadius: '50%',
                                                                                width: '50px',
                                                                                height: '50px',
                                                                                display: 'flex',
                                                                                justifyContent: 'center',
                                                                                alignItems: 'center',
                                                                                color: 'indigo'
                                                                            }}>
                                                                                <i style={{ fontSize: 20 }}
                                                                                    className="fa fa-play"></i>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className={'mt-2 youtube-title'}>
                                                                            <span style={{ cursor: 'pointer', fontSize: 18 }}
                                                                                className={'font-weight-bold'}>{item.title}</span>
                                                                            <div style={{
                                                                                textAlign: 'justify',
                                                                                fontSize: '14px',
                                                                                marginTop: '3px'
                                                                            }}
                                                                                dangerouslySetInnerHTML={{ __html: item.description?.substring(0, 100) }}
                                                                            />

                                                                        </div>
                                                                    </Card>
                                                                </div>

                                                            )
                                                        })}
                                                    </div>
                                                </>
                                            }
                                        </>

                                    }
                                    {this.state.watchVideo &&

                                        <Row

                                        >
                                            <Col xs={12} md={8} xl={8} className='videowatchingdiv srollbarwidth '
                                                style={{
                                                    maxHeight: '1400px',
                                                    overflowX: 'hidden',
                                                    overflowY: 'auto'
                                                }}
                                            >
                                                <Row  >
                                                    <Col xs={12} className='videowatchingdiv mb-4' >
                                                        {this.state.lessonData.thumbnail && this.state.showAdd ? (
                                                            <div
                                                            className="video-container" 
                                                            style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden', maxWidth: '100%', background: '#000' }}
                                                            >
                                                                <>
                                                                    <ReactPlayer
                                                                        url="https://www.youtube.com/watch?v=UeCPxjazkq4"
                                                                        className="react-player"
                                                                        playing={true}
                                                                        width="100%"
                                                                        height="100%"
                                                                        style={{ position: 'absolute', top: 0, left: 0 }}
                                                                        controls={true}
                                                                        onEnded={() => this.setState({
                                                                            showAdd: false,
                                                                        })}
                                                                    />

                                                                </>
                                                            </div>
                                                       
                                                        ) :
                                                        <div
                                                        className="video-container" 
                                                        style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden', maxWidth: '100%', background: '#000' }}
                                                        >
                                                            <>
                                                                <ReactPlayer
                                                                    url={this.state.videoLink}
                                                                    className="react-player"
                                                                    playing={true}
                                                                    width="100%"
                                                                    height="100%"
                                                                    style={{ position: 'absolute', top: 0, left: 0 }}
                                                                    controls={true}
                                                                    // onEnded={() => this.setState({
                                                                    //     showAdd: false,
                                                                    // })}
                                                                />

                                                            </>
                                                        </div>
                                                 
                                                        }
                                                        <Card>
                                                            <CardBody

                                                            >
                                                                <div style={{
                                                                    display: 'flex',
                                                                    flexDirection: "row",
                                                                    width: '100%',
                                                                    padding: '0'
                                                                }}>
                                                                    <div className="font-sm-mobile-par" style={{
                                                                        fontWeight: 'bold',
                                                                        display: 'flex',
                                                                        flexDirection: 'row',
                                                                        justifyContent: 'flex-start',
                                                                        width: '80%'
                                                                    }}>
                                                                        <span>{this.state.lessonData.views} Views</span>
                                                                        <span
                                                                            className={'ml-2'}>{this.state.lessonData.created_ago} Ago</span>
                                                                        <span
                                                                            className={'ml-2'}>#{this.state.lessonData.trending_number} On Trending</span>
                                                                    </div>
                                                                    <div style={{
                                                                        width: '20%',
                                                                        display: 'flex',
                                                                        justifyContent: 'flex-end',
                                                                        textAlign: 'center',
                                                                        alignContent: 'center'
                                                                    }}>
                                                                        <button disabled={this.state.like}
                                                                            onClick={() => this.likeVideo(true)}
                                                                            className={' btn-sm font-sm-mobile-par'}
                                                                            style={{
                                                                                border: 'none ',
                                                                                color: this.state.like ? '#4f2bab' : 'black',
                                                                                fontSize: '0.9rem',
                                                                                background: 'none',
                                                                                display: 'flex',
                                                                                flexDirection: 'row',
                                                                                textAlign: 'center',
                                                                                padding: '0',
                                                                                alignItems: 'center',

                                                                            }}
                                                                        >
                                                                            <FontAwesomeIcon size="lg" icon={this.state.like ? fathumbsUpSolid : fathumbsUpRegular} />

                                                                            &nbsp;&nbsp;<span>{this.state.like_count}</span>
                                                                        </button>

                                                                        <button className='font-sm-mobile-par'
                                                                            style={{
                                                                                border: 'none ',
                                                                                color: this.state.dislike ? 'red' : 'black',
                                                                                fontSize: '0.9rem',
                                                                                background: 'none',
                                                                                padding: '0'
                                                                            }}
                                                                            disabled={this.state.dislike}
                                                                            onClick={() => this.likeVideo(false)}
                                                                            >
                                                                            &nbsp;&nbsp;
                                                                            <FontAwesomeIcon size="lg" icon={this.state.dislike ? fathumbsDownSolid : fathumbsDownRegular} />
                                                                            &nbsp;&nbsp;
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div className={'mt-1 font-weight-bold'}>
                                                                    <span className="font-sm-mobile-head" style={{ fontWeight: 'bold', fontSize: 18, color: 'black' }}>{this.state.lessonData.title}</span>
                                                                </div>
                                                                <Row className=''
                                                                    style={{
                                                                        paddingLeft: '15px',
                                                                        gap: '3px'
                                                                    }}
                                                                >

                                                                    {this.state?.lessonData?.objects?.length > 0 &&
                                                                        <Button  className={`btn btn-list-freelesson ${this.state.newDescription ? ' CurrentTab' : 'N-CurrentTab'}`}
                                                                            onClick={() => {
                                                                                this.toggleState('newDescription')
                                                                                this.setState({ showQuiz: false ,showQuizResult:false})

                                                                            }}>See Description</Button>}
                                                                  
                                                                    {this.state?.quizData?.data?.quizData?.length > 0 &&
                                                                        <Button 
                                                                        className={`btn  btn-list-freelesson ${this.state.showQuiz ? ' CurrentTab' : 'N-CurrentTab'}`}


                                                                            onClick={() => {
                                                                                this.toggleState('showQuiz')
                                                                                this.setState({ newDescription: false })
                                                                            }}
                                                                        > {this.state.showQuiz ? 'Hide Quiz' : 'Show Quiz'}</Button>
                                                                    }
                                                                    {this.state?.quizData !== '' && this.state?.quizData?.is_completed &&
                                                                        <Button  className={`btn btn-list-freelesson  ${this.state.showQuizResult ? ' CurrentTab' : 'N-CurrentTab'}`}
                                                                        // style={{
                                                                        //     padding: ' 0.4rem 0.5rem '
                                                                        // }}

                                                                            onClick={() => 
                                                                            {this.toggleState('showQuizResult')
                                                                            this.setState({ newDescription:false})}
                                                                        }
                                                                            
                                                                            >View
                                                                            Result</Button>
                                                                    }
                                                                </Row>
                                                               
                                                                <div>
                                                                    <span>{!this.state.newDescription ? this.state?.lessonData?.short_description : ''}</span>
                                                                    {this.state.newDescription && 
                                                                 <div   className={` ${this.state.newDescription ? ' CurrentTab' : 'N-CurrentTab'}`}>
                                                                       <CommonDescription
                                                                        arrayObject={this.state?.lessonData?.objects} />
                                                                 </div>   
                                                                 }
                                                                </div>
                                                                
                                                                {this.state.showQuiz && (
                                                                   <div  className={` ${this.state.showQuiz ? ' CurrentTab' : 'N-CurrentTab'}`}>
                                                                     <FreeQuiz
                                                                        toggleState={this.toggleState}
                                                                        showQuiz={this.state.showQuiz}
                                                                        lessonData={this.state.lessonData}
                                                                        quizData={this.state.quizData}
                                                                        fetchQuiz={() => this.fetchQuiz()}
                                                                    />
                                                                   </div>

                                                                )}
                                                                {this.state.showQuizResult &&
                                                                   <div
                                                                   className={` ${this.state.showQuizResult ? ' CurrentTab' : 'N-CurrentTab'}`}
                                                                   >
                                                                     <QuizResultModal
                                                                        showQuizResult={this.state.showQuizResult}
                                                                        toggleModal={this.toggleState}
                                                                        quizData={this.state.quizData}
                                                                    />
                                                                   </div>
                                                                }
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                    <div   style={{
                                                        width: '100%',
                                                      

                                                    }} className="m-0 comment-div-freelesson">
                                                        <div className="mb-2"
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                padding: '0px 10px',
                                                                borderBottom: '2px solid  #4f2bab',
                                                                alignContent: 'center',
                                                                textAlign: 'center',

                                                            }}
                                                        >
                                                            <Input
                                                                className={'search-input'}
                                                                style={{
                                                                    borderRight: " 2px solid #4f2bab"
                                                                }}
                                                                invalid={this.state.commentError}
                                                                type="text"
                                                                name="comment"
                                                                placeHolder={'Add a public comment'}
                                                                value={this.state.comment}
                                                                onKeyDown={this.handleKeyDown}
                                                                onChange={this.handleCommentChange}
                                                            />
                                                            <Button
                                                                className={'btn '}
                                                                style={{
                                                                    backgroundColor: '#f5f5f5',
                                                                    border: 'none',
                                                                    boxShadow: 'none',
                                                                    color: '#4f2bab',
                                                                    Opacity: '1',
                                                                    paddingBottom: '0',
                                                                    paddingTop: '0'



                                                                }}
                                                                data-dismiss="modal"
                                                                type="button"
                                                                onClick={() => this.handleComment()}>
                                                                {/* Comment &nbsp; */}
                                                                <FontAwesomeIcon icon={faPaperPlane} size='xl' />
                                                            </Button>
                                                            {/* <Button
                                                                    className={'btn float-right'}
                                                                    data-dismiss="modal"
                                                                    type="button"
                                                                    style={{
                                                                        backgroundColor: '#f5f5f5',
                                                                        border: 'none',
                                                                        boxShadow: 'none',
                                                                        color: '#e22c3b',

                                                                    }}
                                                                    onClick={() => this.clearComment()}>
                                                                    Close &nbsp; <FontAwesomeIcon icon={faCircleXmark} size='l' />
                                                                </Button> */}
                                                            <div>
                                                                <div className={'mt-2  filter'} style={{
                                                                    display: 'flex',
                                                                    // flexDirection: 'row',
                                                                    // width: '100%',
                                                                    // justifyContent: 'flex-end',
                                                                    // alignItems: 'center',

                                                                }}>
                                                                    <Dropdown
                                                                        style={{
                                                                            // marginRight: '11px'
                                                                        }}
                                                                        isOpen={this.state.dropdownOpen}
                                                                        toggle={this.toggle}>
                                                                        <DropdownToggle style={{
                                                                            display: 'flex',
                                                                            flexDirection: 'row',
                                                                            justifyContent: 'space-between',
                                                                            alignItems: 'center',
                                                                            color: '#4f2bab',
                                                                        }}>
                                                                            <FontAwesomeIcon icon={faArrowDownAZ} size='xl' /> </DropdownToggle>
                                                                        <DropdownMenu>
                                                                            <DropdownItem onClick={() => {
                                                                                this.setState({ tag: 'top' });
                                                                                this.filterComment('top')
                                                                            }
                                                                            }>Top Comments</DropdownItem>
                                                                            <DropdownItem onClick={() => {
                                                                                this.setState({ tag: 'created_at' });
                                                                                this.filterComment('created_at')
                                                                            }
                                                                            } text>Newest First</DropdownItem>
                                                                        </DropdownMenu>
                                                                    </Dropdown>
                                                                </div>
                                                            </div>



                                                        </div>
                                                        <div>
                                                            {this.state.commentMessage && <span
                                                                style={{
                                                                    textAlign: 'center',
                                                                    color: 'green',
                                                                    fontSize: '14px'
                                                                }}>Successfully commited Comment </span>}
                                                            {this.state.commentError &&
                                                                <span
                                                                    style={{ textAlign: 'center', color: 'red', fontSize: '14px' }}>
                                                                    Your comment must be at least 25 characters.</span>}
                                                        </div>





                                                        <div className=" mb-1 scroll-container"
                                                            style={{ height: '' }}
                                                            id={'scroll-container'}>
                                                            {this.context?.reviewsList?.results &&
                                                                this.context?.reviewsList?.results.map((result, index) => (
                                                                    <div key={index} className={`${(this.state.recentmessage &&
                                                                        this.state.currentpostcommentTime === new Date(result.created_at).getTime()) ? 'HiglightCommentDiv' : ''}`} >

                                                                        <FreeComment
                                                                            key={result.id}
                                                                            comment={result}
                                                                            lesson={this.state.lessonData.id}
                                                                        />
                                                                    </div>
                                                                ))}
                                                            <Row style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                width: '100%',
                                                                justifyContent: 'center'
                                                            }}>

                                                                {this.context?.reviewsList?.results &&
                                                                    <>
                                                                        <InfiniteScroll
                                                                            dataLength={this.context?.reviewsList?.results?.length}
                                                                            hasMore={this.context?.reviewsList?.count > this.context?.reviewsList?.results?.length}
                                                                            next={() => this.getReviewsList()}
                                                                            scrollableTarget="scroll-container"
                                                                            loader={<div>
                                                                                <Oval
                                                                                    height="30"
                                                                                    width="30"
                                                                                    radius="12"
                                                                                    color="grey"
                                                                                    ariaLabel="loading"
                                                                                    wrapperStyle
                                                                                    wrapperClass
                                                                                />
                                                                            </div>}
                                                                            endMessage={
                                                                                <p style={{ textAlign: 'center' }}>
                                                                                    <b>Yay! You have seen it all</b>
                                                                                </p>

                                                                            }
                                                                        >
                                                                        </InfiniteScroll>
                                                                    </>
                                                                }
                                                            </Row>
                                                        </div>

                                                    </div>
                                                </Row>
                                            </Col>

                                            <Col className={'m-0 p-0 srollbarwidth  videolist-mobile'} xs={12} md={4} xl={4}
                                                style={{
                                                    maxHeight: '1400px',
                                                    overflowX: 'hidden',
                                                    overflowY: 'auto',

                                                }}
                                            >


                                                <Row>
                                                    <Col xs={12} md={12} xl={12}>
                                                        {this.state?.freeList && this.state?.freeList.map(item => {
                                                            return (
                                                                <div key={item.id}
                                                                    onClick={() => this.selectedVideo(item.id)}
                                                                    style={{
                                                                        display: 'flex',
                                                                        flexDirection: 'row',
                                                                        justifyContent: 'flex-start',
                                                                    }}>
                                                                    <div key={item.id}

                                                                        style={{
                                                                            minWidth: '45%',
                                                                            width: '45%',
                                                                            height: '120px',
                                                                            marginBottom: '15px',
                                                                        }}>
                                                                        <img style={{
                                                                            borderRadius: '15px',
                                                                            cursor: 'pointer',
                                                                            height: '100%',
                                                                            width: '100%',
                                                                            objectFit: 'fill',
                                                                            position: 'static',
                                                                            overflow: 'hidden',
                                                                            background: 'rgba(0, 0, 0, 0.05)'

                                                                        }}
                                                                            alt=""
                                                                            src={item?.thumbnail} />
                                                                    </div>
                                                                    <div style={{
                                                                        width: '55%',
                                                                        marginLeft: '15px',
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        justifyContent: 'flex-start'
                                                                    }}>
                                                                        <span style={{ cursor: 'pointer' }}
                                                                            className={'mb-1 font-weight-bold'}>{item.title}</span>
                                                                        <div className={'m-0 p-0'}
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: item.details?.substring(0, 40),
                                                                            }}
                                                                        ></div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </Col>
                                                </Row>

                                            </Col>
                                        </Row>
                                    }
                                </>
                                :
                                <>
                                    <div style={{
                                        display: 'flex',
                                        height: "calc(100vh - 76px - 130px)",
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                        <span className={'font-weight-bold'} style={{ color: 'purple' }}>No Free Lesson Found</span>
                                    </div>
                                </>
                            }
                        </Container> :
                        <>
                            <Loading />
                        </>
                    }
                    {this.context.commentFormOpen && <FreeCommentForm></FreeCommentForm>
                    }

                </>
            );
        }
    }
)
    ;

export default withTranslation()(withSnackbar(Lessons));
