/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import {
  faDisplay,
  faDownLeftAndUpRightToCenter,
  faHandshakeSlash,
  faMicrophone,
  faMicrophoneSlash,
  faPhone,
  faPhoneSlash,
  faShare,
  faUser,
  faVideo,
  faVideoSlash,
} from "@fortawesome/free-solid-svg-icons";
import ActionButton from "./ActionButton";
import { Badge } from "reactstrap";
import PeerAvatar from "./PeerAvatar";

function CallWindow({
  peerSrc,
  localSrc,
  config,
  pc,
  mediaDevice,
  status,
  endCall,
  handleMinMaxScreen,
}) {
  const peerVideo = useRef(null);
  const localVideo = useRef(null);
  const [video, setVideo] = useState(config.video);
  const [audio, setAudio] = useState(config.audio);
  const [isShareScreen, setIsShareScreen] = useState(false);
  const streamContainerRef = useRef(null);
  const [stream_child_Num, setstream_child_Num] = useState(0);
  useEffect(() => {
    if (peerVideo.current && peerSrc) peerVideo.current.srcObject = peerSrc;
    if (localVideo.current && localSrc) localVideo.current.srcObject = localSrc;
  });

  useEffect(() => {
    if (mediaDevice) {
      mediaDevice.toggle("Video", video);
      mediaDevice.toggle("Audio", audio);
    }
  }, [mediaDevice]);

  /**
   * Turn on/off a media device
   * @param {'Audio' | 'Video'} deviceType - Type of the device eg: Video, Audio
   */
  const toggleMediaDevice = (deviceType) => {
    if (deviceType === "Video") {
      setVideo((vid) => !vid);
      mediaDevice.toggle(deviceType, !video);
    }
    if (deviceType === "Audio") {
      setAudio((audio) => !audio);
      mediaDevice.toggle(deviceType, !audio);
    }
  };

  const toggleShare = () => {
    mediaDevice.toggleScreenShare(!isShareScreen, pc);
    setIsShareScreen((prev) => !prev);
  };

  // if you want to remove then provide 
  //  stream_child_Num vale of total no of div in streamContainer
  useEffect(() => {
    if (streamContainerRef.current) {
      const childElements = streamContainerRef.current.children;
      setstream_child_Num(childElements.length); // find total no of div in streamContainer
    }
  }, []);

  //1. component for screen share
// {/* <div className="screen-share-div  D-St">
// <video id="share-stream" autoPlay muted />
// </div> */}

// 2. component for peervideo | remote user
// {/* <div className="peer-video-div">
// <video ref={peerVideo} className="peer-stream" autoPlay />
// <PeerAvatar className="peer-avatar" />
// </div> */}

// apend as a children in streamContainer
    // comp 1 only when share screen is true
    // comp 2 only when remote user is present 
            // display peer-stream | peer-avatar
  return (
    <div className={`video-div `}>
      <div
        ref={streamContainerRef}
        className={`stream-container ch-${stream_child_Num}`}
      >
  
        <div className="local-video-div D-St">
          {video ? (
            <video ref={localVideo} id="local-stream" autoPlay muted />
          ) : (
            <PeerAvatar id="local-avatar" />
          )}
  
        </div>


{/* add dynamic remote user :, 
screen share div
remote user div
*/}


       
      </div>
      <div className="video-control">
        <div className="local-control">
          <ActionButton
            className="btn-action"
            key="btnVideo"
            icon={video ? faVideo : faVideoSlash}
            disabled={!video}
            onClick={() => toggleMediaDevice("Video")}
          />
          <ActionButton
            className="btn-action"
            key="btnAudio"
            icon={audio ? faMicrophone : faMicrophoneSlash}
            disabled={!audio}
            onClick={() => toggleMediaDevice("Audio")}
          />

          <ActionButton
            className="screenshare btn-action"
            iconClass="blue-back"
            key="btnShare"
            icon={!isShareScreen ? faDisplay : faHandshakeSlash}
            onClick={() => toggleShare()}
          />

          <ActionButton
            className="hangup btn-action"
            icon={faPhone}
            onClick={() => endCall(true)}
          />
          <ActionButton
            className="minimize-max btn-action"
            icon={faDownLeftAndUpRightToCenter}
            onClick={() => handleMinMaxScreen()}
          />
        </div>
        <div className="user-list">
          <Badge badgeContent={4} color="primary">
            <ActionButton className="user btn-action" icon={faUser} />
          </Badge>
        </div>
      </div>
    </div>
  );
}

CallWindow.propTypes = {
  status: PropTypes.string.isRequired,
  localSrc: PropTypes.object,
  peerSrc: PropTypes.object,
  config: PropTypes.shape({
    audio: PropTypes.bool.isRequired,
    video: PropTypes.bool.isRequired,
  }).isRequired,
  mediaDevice: PropTypes.object,
  endCall: PropTypes.func.isRequired,
};

export default CallWindow;


