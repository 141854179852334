// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getMessaging, getToken, onMessage} from "firebase/messaging";
import {useHistory} from "react-router-dom";

const Services = require("./RemoteServices/RemoteServices.js");

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCvQq9CZuveHvmjKU7ffSpMmGuHgd0sDj8",
    authDomain: "englishhuben.firebaseapp.com",
    projectId: "englishhuben",
    storageBucket: "englishhuben.appspot.com",
    messagingSenderId: "724611569483",
    appId: "1:724611569483:web:1c6b204d63d00c0498bace",
    measurementId: "G-RQL9GMJYGV"
};

export const requestForToken = () => {
    return getToken(messaging, {vapidKey: "BFGEYv0tmSodpkE0BmqykTv6FlIKqpxcN39Zi3OeCZ3THaXsPcnFt7s_0xueWHvmDyMFtyYAYFpvs2B_RjOBHwg"})
        .then(async (currentToken) => {
            if (currentToken) {
                console.log('current token for client: ', currentToken);
                if (!localStorage.getItem('fcmToken')) {
                    localStorage.setItem('fcmToken', currentToken);
                    const response = await Services.saveFcmToken({
                        registration_id: currentToken
                    })
                }

                // Perform any other neccessary action with the token
            } else {
                // Show permission request UI
                console.log('No registration token available. Request permission to generate one.');
            }
        })
        .catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
        });
}

// export const setupPushListener = () => {
//     onMessage(messaging, (payload) => {
//         console.log('Received push notification:', payload);
//         const history = useHistory();
//         history.push("/admin/Notifications"); // Replace with your actual route
//     });
// }


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging();

