export function socketListen(socket) {
  const listeners = {};

  socket.onmessage = (event) => {
    const data = JSON.parse(event.data);
    const { action, data: { action_type, calling_to } = {} } = data.message;

    const key = `${action}:${action_type}:${calling_to}`;
    if (listeners[key]) {
      listeners[key].forEach((callback) => callback(data.message.data));
    }
  };

  return {
    on: (action, actionType, calling_to, callback) => {
      const key = `${action}:${actionType}:${calling_to}`;
      if (!listeners[key]) {
        listeners[key] = [];
      }
      listeners[key].push(callback);
    },
  };
}