import React from 'react';
import routes from "routes.js";
import {Route, Switch} from "react-router-dom";

class PublicLayout extends React.Component {
    getRoutes = routes => {
        return routes.map((prop, key) => {
            if (prop.layout === "/public") {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                    />
                );
            } else {
                return null;
            }
        });
    };

    render() {
        return (
            <div style={{backgroundColor:'white',color:'black',margin:'20px',fontWeight:'bold',padding:'20px'}}>
            <Switch>
                {this.getRoutes(routes)}
            </Switch>
            </div>
        )
    }
}

export default PublicLayout;