import React from 'react';
import {withSnackbar} from "notistack";
import {AvForm, AvGroup, AvInput} from "availity-reactstrap-validation";
import {Button, InputGroup, InputGroupAddon, InputGroupText, Modal} from "reactstrap";
import Services from "../../RemoteServices/RemoteServices";


class UserUpdateModal extends React.Component {
    state = {
        createAccount: false,
        oldPassword: '',
        newPassword: '',
        passwordAgain: '',
        loggingIn: true
    }
    handleUpdate = async (event, values) => {
        event.preventDefault();
        await this.setState({createAccount: true});
        Services.changePassword(this.state.userDetails.id, values)
            .then((response) => {
                    this.props.toggleModal("changePassword")
            })
            .catch((error) => {
                this.setState({createAccount: false})
                this.props.enqueueSnackbar(`Please Check Your Old Password`, {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                });
            });
    }

    handleInvalidSubmit = (event, errors) => {
        this.props.enqueueSnackbar(`Please enter ${errors.join(" and ")}`, {
            variant: "error",
            anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
            },
        });
    };

    async componentDidMount() {
        await this.setState(prevState => ({
            ...prevState,
            updateProfile: this.props.updateProfile
        }))
        const userDetail = JSON.parse(localStorage.getItem('user'));
        this.setState({
            userDetails: userDetail
        })

    }

    render() {
        return (
            <Modal
                keyboard={false}
                backdrop={'static'}
                className="modal-dialog-centered modal-md"
                isOpen={this.props.changePassword}
                toggle={() => this.props.toggleModal("changePassword")}
            >
                <div className="modal-header">
                    <h2 className="modal-title" id="modal-title-default">
                        Change Your Password
                    </h2>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.props.toggleModal('changePassword')}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className={'modal-body'}>
                    <AvForm
                        onValidSubmit={this.handleUpdate}
                        onInvalidSubmit={this.handleInvalidSubmit}
                    >
                        <AvGroup className="mb-3">
                            <InputGroup className="input-group-alternative">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="ni ni-lock-circle-open"/>
                                    </InputGroupText>
                                </InputGroupAddon>
                                <AvInput
                                    placeholder="Old Password"
                                    type="password"
                                    name="old_password"
                                    required
                                    autoFocus
                                />
                            </InputGroup>
                        </AvGroup>

                        <AvGroup className="mb-3">
                            <InputGroup className="input-group-alternative">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="ni ni-lock-circle-open"/>
                                    </InputGroupText>
                                </InputGroupAddon>
                                <AvInput
                                    placeholder="New Password"
                                    type="password"
                                    name="password1"
                                    required
                                    autoFocus
                                />
                            </InputGroup>
                        </AvGroup>
                        <AvGroup className="mb-3">
                            <InputGroup className="input-group-alternative">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="ni ni-lock-circle-open"/>
                                    </InputGroupText>
                                </InputGroupAddon>
                                <AvInput
                                    placeholder="Re Enter New Password"
                                    type="password"
                                    name="password2"
                                    required
                                    autoFocus
                                />
                            </InputGroup>
                        </AvGroup>

                        <div className="text-center">
                            <Button className="my-4" color="primary">
                                {this.state.createAccount ? "Submitting ..." : "Change Your Password"}
                            </Button>
                        </div>

                    </AvForm>
                </div>
            </Modal>
        )

    }
}

export default withSnackbar(UserUpdateModal)