import React from "react";
import { observer } from "mobx-react";

import Review from '../examples/Review'
import {
    Button,
    Card,
    CardBody,

    CardTitle,
    Col,
    Container,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    Modal,

    Row,

} from "reactstrap";
import { withSnackbar } from "notistack";
import ImageUploading from "react-images-uploading";
import YouTube from "react-youtube";

import { MobXContext } from "MobXProvider";
import CommentForm from "components/CommentForm";

import "./styles.css";
import Payment from "../../components/Payment";
import Quiz from "./Quiz";

import QuizResultModal from "../../components/Modals/QuizResultModal";
import { Oval } from "react-loader-spinner";
import InfiniteScroll from "react-infinite-scroll-component";
import img from "../../assets/img/subscribe-our-youtube-channel.png";
import './styles.scss'
import googleChat from '../../assets/img/Google_Chat_icon_(2020).svg'
import ReactPlayer from "react-player/lazy";
import Loading from "../../components/Loading/Loading";
import CommonDescription from "../../components/Modals/CommonDescription";

import FreeComment from "components/Comment/FreeComment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDownAZ, faBookOpen, faCartShopping, faGraduationCap, faPaperPlane,  faPlaneDeparture,  faSquarePollVertical, faTrash, faUserPen } from "@fortawesome/free-solid-svg-icons";
import { faComment,  faImage } from "@fortawesome/free-regular-svg-icons";




const Services = require("../../RemoteServices/RemoteServices.js");


const Lessons = observer(
    class Lessons extends React.Component {
        static contextType = MobXContext;

        constructor(props) {
            super(props);
            this.state = {
                commentTag: 'id',
                lessonData: '',
                images: [],
                videoLink: "",
                thumbnail: '',
                comment: "",
                commentError: false,
                offset: 0,
                filterOffset: 0,
                ePaymentOpen: false,
                showDetails: false,
                defaultModal: false,
                showQuiz: false,
                quizData: '',
                showMore: false,
                dropdownOpen: false,
                showQuizResult: false,
                commentMessage: false,
                loading: false,
                snackBar: true,
                newDescription: false,

                showAdd: false,
                isplaying: false,
                recentmessage: false,
                currentpostcommentTime: '',
                countvisiblecomment: 0

            };
            this.onChange = this.onChange.bind(this);
            this.onImageUpload = this.onImageUpload.bind(this);
            this.submitAssignment = this.submitAssignment.bind(this);
        }
        imageUploaderRef = null;

        filterComment = async (tag) => {
            try {
                if (this.state.commentTag === tag) {
                    await this.context.getReviewsList(this.state.lessonData.id, this.state.offset, tag)
                    this.setState((state) => ({
                        offset: (state.offset += 10),
                    }));
                } else {

                    await this.context.getReviewsList(this.state.lessonData.id, this.state.filterOffset, tag)
                    this.setState((state) => ({
                        offset: (state.offset = 10),
                    }));
                    this.setState({ commentTag: tag })
                }
            } catch (e) {

            }
        }

        user = JSON.parse(localStorage.getItem("user"));

        toggle = () => this.setState({
            dropdownOpen: !this.state.dropdownOpen,
        });

        toggleModal = (state) => {
            this.setState({
                ePaymentOpen: !this.state.ePaymentOpen,
            });
        };
        toggleState = (state) => {
            this.setState({
                [state]: !this.state[state],
            });
        };
        fetchQuiz = async () => {
            await Services.getQuizList({
                lesson_id: this.state.lessonData.id,
                course_id: this.state.lessonData.course,
            })
                .then((response) => {
                    this.setState({
                        quizData: response,
                    })
                })
                .catch((err) => {
                    this.setState({
                        quizData: '',
                    })
                });
        }

        async componentDidMount() {
            this.setState({ loading: true })
            await this.getLessonData();
            // await this.fetchQuiz();
            this.getReviewsList();
            await this.fetchQuiz()
           

        }
       

        async getLessonData() {
            const lessonData = await Services.getLatestLesson();
            this.setState({
                lessonData: lessonData,
                lessonId: lessonData.id,
                status: lessonData.status,
                videoLink: lessonData.youtube_link,
                thumbnail: lessonData.thumbnail,
                loading: false
            });
        }

        getReviewsList = () => {
            this.context.getReviewsList(this.state.lessonData.id, this.state.offset, this.state.commentTag);
            this.setState((state) => ({
                offset: (state.offset += 10),
            }));
        };

        handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                this.handleComment()
            }
        }

        onChange = (imageList, addUpdateIndex) => {

            this.setState({ images: imageList });
        };


        submitAssignment() {
            this.setState({ loading: true });
            const images = this.state.images;
            const MAX_SIZE = 1024 * 1024; // 1 MB in bytes
            const resizeImage = (imageBase64) => {
                return new Promise((resolve, reject) => {
                    const img = new Image();
                    img.onload = () => {
                        const canvas = document.createElement('canvas');
                        const ctx = canvas.getContext('2d');

                        // Calculate the aspect ratio to maintain the image proportions
                        const aspectRatio = img.width / img.height;
                        const targetWidth = Math.sqrt(MAX_SIZE * aspectRatio);
                        const targetHeight = targetWidth / aspectRatio;

                        canvas.width = targetWidth;
                        canvas.height = targetHeight;

                        // Draw the image on the canvas with the new dimensions
                        ctx.drawImage(img, 0, 0, targetWidth, targetHeight);

                        // Convert the canvas data to Base64 with JPEG format and desired quality
                        const resizedBase64 = canvas.toDataURL('image/jpeg', 0.4); // Adjust JPEG quality as needed (0.1 to 1)
                        resolve(resizedBase64);
                    };

                    img.onerror = (error) => {
                        reject(error);
                    };

                    img.src = imageBase64;
                });
            };

            const resizePromises = images.map((image) => {
                return resizeImage(image.data_url); // Assuming 'data_url' property contains the Base64 representation of each image
            });

            Promise.all(resizePromises)
                .then((resizedImages) => {
                    console.log('Resized images:', resizedImages);

                    // Calculate the total size of all images after resizing
                    let totalSizeAfterResizing = 0;
                    resizedImages.forEach((imageBase64) => {
                        totalSizeAfterResizing += Math.round((imageBase64.length - 'data:image/jpeg;base64,'.length) * 3 / 4);
                    });

                    console.log('Total size of selected images after resizing:', totalSizeAfterResizing, 'bytes');

                    let data = {
                        images: resizedImages,
                        lesson: this.state.lessonId,
                    };
                    return Services.sendAssignment(data)
                })
                .then((response) => {
                    this.setState({
                        images: [],
                        assignmentSubmitted: true,
                        loading: false,
                    });
                    this.getLessonData();
                    // console.log(response)
                })
                .catch((error) => {
                    this.setState({
                        images: [],
                        assignmentSubmitted: false,
                        loading: false,
                    });
                    this.getLessonData();
                    console.log('i am here')
                    this.setState({ snackBar: true, loading: false });
                    this.props.enqueueSnackbar("File Size is too large", {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                    });

                    // console.log(error)
                });
        }
        _onReady(event) {

            if (
                document.getElementsByClassName(
                    "ytp-chrome-top ytp-show-cards-title"
                )[0]
            ) {
                document.getElementsByClassName(
                    "ytp-chrome-top ytp-show-cards-title"
                )[0].style.visibility = "hidden";
            }

        }

        _onStateChange(event) {
            // console.log(event);
            if (event.data === "1") {
                if (
                    document.getElementsByClassName(
                        "ytp-chrome-top ytp-show-cards-title"
                    )[0]
                ) {
                    document.getElementsByClassName(
                        "ytp-chrome-top ytp-show-cards-title"
                    )[0].style.visibility = "hidden";
                }
            }
            // document.getElementsByClassName('ytp-chrome-top ytp-show-cards-title')[0].style.visibility = 'hidden'
        }

        handleCommentChange = (e) => {
            this.setState({ comment: e.target.value });
        };
        clearComment = () => {
            this.setState({ comment: '' })
        }

        handleComment = async () => {
            if (this.state.comment.length > 25) {
                this.setState({ commentError: false });
                await Services.review({
                    user: this.user.id,
                    lesson: this.state.lessonData.id,
                    message: this.state.comment,
                }).then((response) => {
                    this.setState({ comment: "", commentMessage: true, currentpostcommentTime: new Date(response.created_at).getTime() });
                    if (this.context?.reviewsList?.results) {
                        this.context.reviewsList.results.unshift(response);
                    }
                    this.HighlishtComment()
                    setTimeout(() => {
                        this.setState({ commentMessage: false })
                    }, 2000)
                })
                    .catch((error) => {
                        this.props.enqueueSnackbar(error.message, {
                            variant: "error",
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "right",
                            },
                        });
                    });
            } else {
                this.setState({ commentError: true });
            }
        };

        handleLike = async (payload) => {
            await Services.likeReview(payload);
        };
        HighlishtComment = () => {
            let userTime = Date.now()
            let timediff = Math.abs(this.state.currentpostcommentTime - userTime)

            if (timediff <= 3000) {
                this.setState({ recentmessage: true })
                setTimeout(() => {
                    this.setState({ recentmessage: false })
                }, 60000);
            } else {
                this.setState({ recentmessage: false })
            }
        }
        onImageUpload() {
            if (this.imageUploaderRef) {
                this.imageUploaderRef.onImageUpload();
            }
        }
       
        render() {
            const optsDesktop = {
                width: "100%",
                height: "100%",
                playerVars: {
                    autoplay: 0,
                    frameborder: 0,
                    modestbranding: 1,
                    rel: 0,
                    showinfo: 0,
                },
            };
            const maxNumber = 100;
           
            const images = this.state.images;
            const video = this.state.videoLink;

            const youtubeVideoId = video.split("/").pop();
            const user = JSON.parse(localStorage.getItem('user'))
           
            return (
                <>

                    {/* Page content */}
                    {!this.state.loading ?
                        <Container className={'container-dashboard'}


                            fluid
                        // style={{minHeight: "calc(100vh - 76px - 118px)"}}
                        >
                            <Row className='paindlesson-div-padding-mobile'>
                                <Col className='paindlesson-div-padding-mobile' xs={12} md={12} xl={9}>
                                    <Row className='paindlesson-div-padding-mobile'>
                                        <Col xs={12} className="mb-1 paindlesson-div-padding-mobile" >
                                            {this.state.thumbnail ?
                                                <>
                                                    {this.state.isplaying ?
                                                        <div
                                                        className="video-container" 
                                                        style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden', maxWidth: '100%', background: '#000' }}
                                                        >
                                                            <>
                                                                <ReactPlayer
                                                                    url={this.state.videoLink}
                                                                    className="react-player"
                                                                    playing={true}
                                                                    width="100%"
                                                                    height="100%"
                                                                    style={{ position: 'absolute', top: 0, left: 0 }}
                                                                    controls={true}
                                                                    // onEnded={() => this.setState({
                                                                    //     showAdd: false,
                                                                    // })}
                                                                />

                                                            </>
                                                        </div>:
                                                        <div onClick={() => this.setState({ isplaying: true })} style={{ position: 'relative', width: '100%', height: '100%', cursor: 'pointer' }}>
                                                            <img
                                                                alt={'thumbnail'}
                                                                style={{
                                                                    height: '100%',
                                                                    width: '100%',
                                                                    maxHeight: '550px',
                                                                    objectFit: 'stretch',
                                                                }}
                                                                src={this.state.thumbnail[0] === '/' ? window.config.imgUrl.concat(this.state.thumbnail) : this.state.thumbnail}
                                                            />
                                                            <div
                                                                style={{
                                                                    position: 'absolute',
                                                                    top: '50%',
                                                                    left: '50%',
                                                                    transform: 'translate(-50%, -50%)',
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        border: '4px solid purple',
                                                                        borderRadius: '50%',
                                                                        width: '60px',
                                                                        height: '60px',
                                                                        display: 'flex',
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center',
                                                                        color: 'purple',
                                                                    }}
                                                                >
                                                                    <i style={{ fontSize: 20 }} className="fa fa-play"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </>
                                                :
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        position: "relative",
                                                        paddingBottom: "56.25%",
                                                    }}
                                                >
                                                    <YouTube
                                                        containerClassName="YouTubeContainer"
                                                        videoId={youtubeVideoId}
                                                        opts={optsDesktop}
                                                        onReady={this._onReady}
                                                        onStateChange={this._onStateChange}
                                                    />
                                                </div>
                                            }
                                        </Col>

                                        <Col xs={12} className="mb-2 paindlesson-div-padding-mobile">
                                            <Card className="shadow" 
                                            style={{
                                                backgroundColor:'#f5f5f5'
                                            }}
                                            >
                                                <CardBody>
                                                    <CardTitle className={'m-0 p-0 mb-2'}>
                                                        <b>{this.state.lessonData.title}</b>
                                                    </CardTitle>
                                                    {this.state.status === "Pending" &&
                                                           <CardTitle>
                                                           <b style={{ color: '#4f2bab' }}>
                                                               Your assignment is successfully submitted and is
                                                               under review. Please check back later.
                                                           </b>
                                                       </CardTitle>
                                                    }
                                                    <div tabs className='mb-2' >
                                                        <div className=""
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between'
                                                            }}
                                                        >
                                                            <div  style={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                flexDirection:'row'
                                                            }}>
                                                                {this.state?.lessonData?.objects?.length > 0 && (
                                                                    <Button
                                                                        className={`btn  btn-list-freelesson ${this.state.newDescription ? ' CurrentTab' : 'N-CurrentTab'}`}

                                                                        onClick={() => {
                                                                            this.toggleState('newDescription')
                                                                            this.setState({ showQuiz: false })
                                                                        }}
                                                                    >
                                                                        See Description&nbsp; <FontAwesomeIcon icon={faBookOpen} />
                                                                    </Button>
                                                                )}

                                                                {this.state.quizData?.data?.quizData?.length > 0 && (
                                                                    <Button
                                                                        className={`btn btn-list-freelesson ${this.state.showQuiz ? ' CurrentTab' : 'N-CurrentTab'}`}
                                                                        onClick={() => {
                                                                            this.toggleState('showQuiz')
                                                                            this.setState({ newDescription: false })
                                                                        }}

                                                                    >
                                                                        Show Quiz&nbsp; <FontAwesomeIcon icon={faGraduationCap} />
                                                                    </Button>
                                                                )}

                                                                {this.state.quizData?.is_completed && (
                                                                    <Button
                                                                        className={`btn btn-list-freelesson ${this.state.showQuizResult ? ' CurrentTab' : 'N-CurrentTab'}`}
                                                                        onClick={() => this.toggleState('showQuizResult')}

                                                                    >
                                                                        View Result &nbsp;<FontAwesomeIcon icon={faSquarePollVertical} />
                                                                    </Button>
                                                                )}


                                                                {
                                                                    this.state.status !== "Pending" && this.state?.lessonData?.lesson_type === 'default' && this.state?.lessonData?.tag !== 'Completed' &&
                                                                    <Button
                                                                        className="btn btn-list-freelesson N-CurrentTab"
                                                                        onClick={() => this.toggleModal()}

                                                                    >
                                                                        Make Payment&nbsp; <FontAwesomeIcon icon={faCartShopping} />
                                                                    </Button>
                                                                }


                                                            </div>

                                                            <div>
                                                                {this.state.images.length < 1 && this.state?.lessonData?.lesson_type !== 'default' &&this.state.status !== "Pending"  && (
                                                                    <Button

                                                                        className={`all-btn btn-list-freelesson ${this.state.status === "Pending" ? 'pointer-event-none-Custom' : ''}`}
                                                                        onClick={this.onImageUpload}

                                                                    >
                                                                        Upload Assignment&nbsp; <FontAwesomeIcon icon={ faUserPen} />
                                                                    </Button>
                                                                )}
                                                            </div>
                                                        </div>

                                                        {this.state.newDescription && (
                                                            <div
                                                                className={` ${this.state.newDescription ? ' CurrentTab' : 'N-CurrentTab'}`}
                                                            >
                                                                <CommonDescription arrayObject={this.state.lessonData.objects} />

                                                            </div>
                                                        )}
                                                        {this.state.showQuiz && (
                                                            <div
                                                                className={` ${this.state.showQuiz ? ' CurrentTab' : 'N-CurrentTab'}`}
                                                            >
                                                                <Quiz
                                                                    toggleState={this.toggleState}
                                                                    showQuiz={this.state.showQuiz}
                                                                    lessonData={this.state.lessonData}
                                                                    quizData={this.state.quizData}
                                                                    fetchQuiz={() => this.fetchQuiz()}
                                                                />
                                                            </div>

                                                        )}
                                                    </div>


                                                    {this.state.showDetails &&
                                                        <Modal
                                                            className="modal-dialog-centered modal-lg"
                                                            isOpen={this.state.showDetails}
                                                            toggle={() => this.toggleState("showDetails")}
                                                            width="100%"
                                                        >
                                                            <div className="modal-header">
                                                                <h2 className="modal-title" id="modal-title-default">
                                                                    {this.state.lessonData.title}
                                                                </h2>
                                                                <button
                                                                    aria-label="Close"
                                                                    className="close"
                                                                    data-dismiss="modal"
                                                                    type="button"
                                                                    onClick={() => this.toggleState("showDetails")}
                                                                >
                                                                    <span aria-hidden={true}>×</span>
                                                                </button>
                                                            </div>
                                                            <div
                                                                className="modal-body"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: this.state.lessonData.details,
                                                                }}
                                                            ></div>
                                                            <div className="modal-footer">
                                                                <Button
                                                                    className="ml-auto"
                                                                    color="link"
                                                                    data-dismiss="modal"
                                                                    type="button"
                                                                    onClick={() => this.toggleState("showDetails")}
                                                                >
                                                                    Close
                                                                </Button>
                                                            </div>
                                                        </Modal>
                                                    }

                                                    {this.state.status === "Pending" ? (
                                                        <>
                                                            {/* <CardTitle>
                                                                <b style={{ color: '#4f2bab' }}>
                                                                    Your assignment is successfully submitted and is
                                                                    under review. Please check back later.
                                                                </b>
                                                            </CardTitle> */}
                                                        </>
                                                    ) : (
                                                        <>

                                                            {this.state?.lessonData?.lesson_type !== 'default' && this.state?.lessonData?.tag !== 'Completed' &&
                                                                <ImageUploading
                                                                    multiple
                                                                    value={images}
                                                                    onChange={this.onChange}
                                                                    maxNumber={maxNumber}
                                                                    dataURLKey="data_url"
                                                                >
                                                                    {({
                                                                        imageList,
                                                                        onImageUpload,
                                                                        onImageRemoveAll,
                                                                        onImageUpdate,
                                                                        onImageRemove,
                                                                        isDragging,
                                                                        dragProps,
                                                                    }) => {
                                                                        // Reference to access internal methods
                                                                        this.imageUploaderRef = {
                                                                            onImageUpload,
                                                                            onImageRemoveAll,
                                                                            imageList,
                                                                            onImageUpdate,
                                                                            onImageRemove,
                                                                            isDragging,
                                                                            dragProps,
                                                                        }


                                                                        return (
                                                                            // write your building UI
                                                                            <div className="upload-image-wrapper">

                                                                                {imageList.length > 0 && (
                                                                                    <>
                                                                                        <hr />
                                                                                        <div className={'upload-image-div'}>
                                                                                            <div className={'upload-image'}>
                                                                                                {imageList.map((image, index) => (

                                                                                                    <div
                                                                                                        className={'upload-list'}>
                                                                                                        <div key={index}
                                                                                                            className="image-item m-0 p-0">
                                                                                                            <div
                                                                                                                className={'youtube-content'}>
                                                                                                                <img
                                                                                                                    src={image.data_url}
                                                                                                                    alt=""
                                                                                                                    width='100%'
                                                                                                                    height='100%' /* Set a fixed height for all images */
                                                                                                                /* Maintain aspect ratio while covering the container */
                                                                                                                />
                                                                                                            </div>
                                                                                                            <h5 style={{ textAlign: 'center' }}
                                                                                                                className={'font-weight-bold m-1 p-0'}>{index + 1}</h5>
                                                                                                            <i style={{
                                                                                                                position: 'absolute',
                                                                                                                top: '2px',
                                                                                                                right: '2px',
                                                                                                                color: 'red',
                                                                                                                fontSize: 20,
                                                                                                                cursor: 'pointer'
                                                                                                            }}
                                                                                                                onClick={() => onImageRemove(index)}
                                                                                                                className={'fa fa-times'}></i>
                                                                                                            <Button
                                                                                                                onClick={() => onImageUpdate(index)}
                                                                                                                className={'edit-assignment'}>
                                                                                                                <i
                                                                                                                    style={{
                                                                                                                        color: 'purple',
                                                                                                                        cursor: 'pointer',
                                                                                                                        fontSize: 20,
                                                                                                                    }}
                                                                                                                    className={'icon-edit fa fa-edit'}></i>
                                                                                                            </Button>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                ))}
                                                                                            </div>
                                                                                        </div>
                                                                                        <hr />
                                                                                    </>
                                                                                )}

                                                                                <div className={'btn-assignment'}>
                                                                                    <div className={'row-btn'}>
                                                                                        {this.state.images.length > 0 && (
                                                                                            <Button
                                                                                                className={'m-0'}
                                                                                                // color="primary"
                                                                                                onClick={onImageUpload}
                                                                                                {...dragProps}
                                                                                            >
                                                                                                Add &nbsp; <FontAwesomeIcon icon={faImage} />
                                                                                            </Button>
                                                                                        )}
                                                                                        {this.state.images.length > 0 && (
                                                                                            <Button
                                                                                                className={'m-0'}
                                                                                                // color="danger"
                                                                                                onClick={() => onImageRemoveAll()}
                                                                                            >
                                                                                                Remove &nbsp;<FontAwesomeIcon icon={faTrash} />
                                                                                            </Button>
                                                                                        )}
                                                                                    </div>
                                                                                    {this.state.images.length > 0 && (
                                                                                        <>
                                                                                            {this.state.loading ? (
                                                                                                <>
                                                                                                    Submitting Assignment.
                                                                                                    Please
                                                                                                    wait...
                                                                                                </>
                                                                                            ) : (
                                                                                                <>
                                                                                                    <Button
                                                                                                        className={'all-btn'}
                                                                                                        onClick={this.submitAssignment.bind(
                                                                                                            this
                                                                                                        )}
                                                                                                        {...dragProps}
                                                                                                    >
                                                                                                        Submit&nbsp; <FontAwesomeIcon icon={faPlaneDeparture} />
                                                                                                    </Button>
                                                                                                </>
                                                                                            )}
                                                                                        </>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                    }
                                                                </ImageUploading>
                                                            }
                                                        </>
                                                    )}

                                                </CardBody>
                                            </Card>
                                        </Col>

                                        <Col xs={12}>
                                            {this.state.lessonData.lesson_type === 'default' && this.state.lessonData.tag === 'Completed' && (
                                                <div style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    flexDirection: 'row',
                                                    gap: '10px'
                                                }}>
                                                    <Button className={'all-btn'}
                                                        href={'https://play.google.com/store/apps/details?id=com.google.android.apps.dynamite&hl=en&gl=US'}
                                                        target={'_blank'}
                                                        style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <div style={{ width: '40%' }}>
                                                            <img height={40} width={40} src={googleChat} />
                                                        </div>
                                                        <div style={{
                                                            width: '60%',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            alignItems: 'flex-start'
                                                        }}>
                                                            <span>Download</span>
                                                            <span>Google Chat</span>
                                                        </div>
                                                    </Button>
                                                    <Button href={"https://appurl.io/JmqHdQu6g8"} target={'_blank'}
                                                        style={{ display: 'flex', alignItems: 'center' }}
                                                        className={'all-btn'}
                                                        onClick={() => this.props.history.push('/admin/review')}>
                                                        <i className={'fa fa-star'}
                                                            style={{ fontSize: '28px', color: 'yellow' }}></i>&nbsp;  Rate
                                                        Us
                                                    </Button>

                                                </div>
                                            )}
                                        </Col>
                                        {this.state.lessonData.lesson_type === 'default' && this.state.lessonData.tag === 'Completed' && (
                                            <Col className={'mt-2'} xs={12}>
                                                <Card className={'shadow'}>
                                                    <CardBody>
                                                        <CardTitle className={'m-0 p-0 mb-2'}>
                                                            <b>Find Us On (Social Media Links)</b>
                                                        </CardTitle>
                                                        <CardBody className={'m-0 p-0'}>
                                                            <div className="link-bottom">
                                                                <div className="social-menu">
                                                                    <ul className={'m-0 p-0'}>
                                                                        <li><a
                                                                            href="https://www.facebook.com/68english.hub"
                                                                            target="_blank"><i style={{ color: "white" }}
                                                                                className="fab fa-facebook-f"></i></a>
                                                                        </li>
                                                                        <li><a
                                                                            href="https://www.instagram.com/68english.hub"
                                                                            target="_blank"><i style={{ color: 'white' }}
                                                                                className="fab fa-instagram"></i></a>
                                                                        </li>
                                                                        <li><a href="#"
                                                                            onClick={() => window.open("https://www.youtube.com/@EnglishHub68")}><i
                                                                                style={{ color: 'white' }}
                                                                                className="fab fa-youtube"
                                                                                target="_blank"></i></a>
                                                                        </li>
                                                                        <li><a href="#"
                                                                            onClick={() => window.open("https://www.linkedin.com/company/english-hub68")}><i
                                                                                style={{ color: 'white' }}
                                                                                className="fab fa-linkedin"
                                                                                target="_blank"></i></a></li>
                                                                        <li><a href="#"
                                                                            onClick={() => window.open("https://invite.viber.com/?g2=AQB2n%2F0RwJ3760uRnSdS6xouHMJPZNw8eO7eONGtk5fDGMM8CPmsOYJ73fXQc2Bt")}>
                                                                            <svg className='fontawesomesvg'
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                viewBox="0 0 512 512">
                                                                                <path
                                                                                    fill={'white'}
                                                                                    d="M444 49.9C431.3 38.2 379.9.9 265.3.4c0 0-135.1-8.1-200.9 52.3C27.8 89.3 14.9 143 13.5 209.5c-1.4 66.5-3.1 191.1 117 224.9h.1l-.1 51.6s-.8 20.9 13 25.1c16.6 5.2 26.4-10.7 42.3-27.8 8.7-9.4 20.7-23.2 29.8-33.7 82.2 6.9 145.3-8.9 152.5-11.2 16.6-5.4 110.5-17.4 125.7-142 15.8-128.6-7.6-209.8-49.8-246.5zM457.9 287c-12.9 104-89 110.6-103 115.1-6 1.9-61.5 15.7-131.2 11.2 0 0-52 62.7-68.2 79-5.3 5.3-11.1 4.8-11-5.7 0-6.9.4-85.7.4-85.7-.1 0-.1 0 0 0-101.8-28.2-95.8-134.3-94.7-189.8 1.1-55.5 11.6-101 42.6-131.6 55.7-50.5 170.4-43 170.4-43 96.9.4 143.3 29.6 154.1 39.4 35.7 30.6 53.9 103.8 40.6 211.1zm-139-80.8c.4 8.6-12.5 9.2-12.9.6-1.1-22-11.4-32.7-32.6-33.9-8.6-.5-7.8-13.4.7-12.9 27.9 1.5 43.4 17.5 44.8 46.2zm20.3 11.3c1-42.4-25.5-75.6-75.8-79.3-8.5-.6-7.6-13.5.9-12.9 58 4.2 88.9 44.1 87.8 92.5-.1 8.6-13.1 8.2-12.9-.3zm47 13.4c.1 8.6-12.9 8.7-12.9.1-.6-81.5-54.9-125.9-120.8-126.4-8.5-.1-8.5-12.9 0-12.9 73.7.5 133 51.4 133.7 139.2zM374.9 329v.2c-10.8 19-31 40-51.8 33.3l-.2-.3c-21.1-5.9-70.8-31.5-102.2-56.5-16.2-12.8-31-27.9-42.4-42.4-10.3-12.9-20.7-28.2-30.8-46.6-21.3-38.5-26-55.7-26-55.7-6.7-20.8 14.2-41 33.3-51.8h.2c9.2-4.8 18-3.2 23.9 3.9 0 0 12.4 14.8 17.7 22.1 5 6.8 11.7 17.7 15.2 23.8 6.1 10.9 2.3 22-3.7 26.6l-12 9.6c-6.1 4.9-5.3 14-5.3 14s17.8 67.3 84.3 84.3c0 0 9.1.8 14-5.3l9.6-12c4.6-6 15.7-9.8 26.6-3.7 14.7 8.3 33.4 21.2 45.8 32.9 7 5.7 8.6 14.4 3.8 23.6z" />
                                                                            </svg>
                                                                        </a></li>
                                                                        <li><a href="#"
                                                                            onClick={() => window.open("https://wa.me/message/NXIDEWNWSC5CH1")}><i
                                                                                style={{ color: 'white' }}
                                                                                className="fab fa-whatsapp"></i></a></li>
                                                                        <li><a href="#"
                                                                            onClick={() => window.open("https://www.tiktok.com/@englishhub68")}>
                                                                            <svg className={'tiktok'}
                                                                                viewBox="0 0 512 512"
                                                                                id="icons"
                                                                                xmlns="http://www.w3.org/2000/svg">
                                                                                <path
                                                                                    fill={'white'}
                                                                                    d="M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z" />
                                                                            </svg>
                                                                        </a></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        )}
                                    </Row>
                                </Col>
                                {this.state.lessonId !== 4 ?
                                    <Col className=''
                                        style={{
                                            padding: '0 5px'
                                        }}
                                    >
                                        <Col className="m-0 p-0 mb-4">
                                            <Col className="mb-4 p-0">
                                                {/* <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',

                                                    justifyContent: 'flex-start',
                                                    alignItems: 'flex-start',
                                                    color: 'black',
                                                    gap: '5px',
                                                    padding: '0 15px'
                                                }}>
                                                    {this.context?.reviewsList?.results && (
                                                        <>
                                                            <span>{this.context.reviewsList.count}</span>
                                                            <span>Comments</span>
                                                        </>
                                                    )}
                                                </div> */}
                                                <Col xs={12} className=""
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        borderBottom: '2px solid  #4f2bab',
                                                    }}
                                                >
                                                    <Input
                                                        className={'search-input'}

                                                        invalid={this.state.commentError}
                                                        type="text"
                                                        name="comment"
                                                        placeholder={'Add a public comment'}
                                                        value={this.state.comment}
                                                        onKeyDown={this.handleKeyDown}
                                                        onChange={this.handleCommentChange}
                                                    />
                                                    <div style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',

                                                        justifyContent: 'flex-end',


                                                    }}

                                                    >
                                                        <Button
                                                            className={'btn '}
                                                            style={{
                                                                backgroundColor: '#f5f5f5',
                                                                border: 'none',
                                                                boxShadow: 'none',
                                                                color: '#4f2bab',
                                                                Opacity: '1',
                                                                padding: '0',
                                                                paddingTop: '0'



                                                            }}
                                                            data-dismiss="modal"
                                                            type="button"
                                                            onClick={() => this.handleComment()}>

                                                            <FontAwesomeIcon icon={faPaperPlane} size='xl' />
                                                        </Button>

                                                    </div>
                                                </Col>
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',

                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    color: '#4f2bab',
                                                    gap: '8px',
                                                    padding: '0 15px'
                                                }}>
                                                    <div>
                                                        {this.context?.reviewsList?.results && (
                                                            <>
                                                                <span>{this.context.reviewsList.count}</span>&nbsp;
                                                                <FontAwesomeIcon icon={faComment} size='xl' />
                                                            </>
                                                        )}
                                                    </div>
                                                    <div className={'mt-2  filter'} style={{


                                                    }}>
                                                        <Dropdown
                                                            style={{
                                                                // marginRight: '11px'
                                                            }}
                                                            isOpen={this.state.dropdownOpen}
                                                            toggle={this.toggle}>
                                                            <DropdownToggle style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent: 'space-between',
                                                                alignItems: 'center',
                                                                color: '#4f2bab',
                                                            }}>
                                                                <FontAwesomeIcon icon={faArrowDownAZ} size='xl' /> </DropdownToggle>
                                                            <DropdownMenu>
                                                                <DropdownItem onClick={() => {
                                                                    this.setState({ tag: 'top' });
                                                                    this.filterComment('top')
                                                                }
                                                                }>Top Comments</DropdownItem>
                                                                <DropdownItem onClick={() => {
                                                                    this.setState({ tag: 'created_at' });
                                                                    this.filterComment('created_at')
                                                                }
                                                                } text>Newest First</DropdownItem>
                                                            </DropdownMenu>
                                                        </Dropdown>
                                                    </div>
                                                </div>


                                                <div>
                                                    {this.state.commentMessage && <span
                                                        style={{
                                                            textAlign: 'center',
                                                            color: 'green',
                                                            fontSize: '14px'
                                                        }}>Successfully commited Comment </span>}
                                                    {this.state.commentError &&
                                                        <span
                                                            style={{ textAlign: 'center', color: 'red', fontSize: '14px' }}>
                                                            Your comment must be at least 25 characters.</span>}
                                                </div>

                                                <div className="mt-3 mb-2 scroll-container" id={'scroll-container'}>
                                                    {this.context?.reviewsList?.results &&
                                                        this.context?.reviewsList?.results.map((result, index) => (
                                                            <div key={index} className={`${(this.state.recentmessage &&
                                                                this.state.currentpostcommentTime === new Date(result.created_at).getTime()) ? 'HiglightCommentDiv' : ''}`} >

                                                                <FreeComment
                                                                    key={result.id}
                                                                    comment={result}
                                                                    lesson={this.state.lessonData.id}
                                                                />
                                                       
                                                            </div>
                                                        ))}
                                                    <Row style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        width: '100%',
                                                        justifyContent: 'center'
                                                    }}>

                                                        {this.context?.reviewsList?.results &&
                                                            <>
                                                                <InfiniteScroll
                                                                    dataLength={this.context?.reviewsList?.results?.length}
                                                                    hasMore={this.context?.reviewsList?.count > this.context?.reviewsList?.results?.length}
                                                                    next={() => this.getReviewsList()}
                                                                    scrollableTarget="scroll-container"
                                                                    loader={<div>
                                                                        <Oval
                                                                            height="30"
                                                                            width="30"
                                                                            radius="12"
                                                                            color="grey"
                                                                            ariaLabel="loading"
                                                                            wrapperStyle
                                                                            wrapperClass
                                                                        />
                                                                    </div>}
                                                                    endMessage={
                                                                        <p style={{ textAlign: 'center' }}>
                                                                            <b>Yay! You have seen it all</b>
                                                                        </p>

                                                                    }
                                                                >
                                                                </InfiniteScroll>
                                                            </>
                                                        }
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Col>
                                    </Col>
                                    :
                                    <Col xs={12} md={12} xl={3}>
                                        <Col xs={12} className="m-0 p-0 mb-4">
                                            <Col xs={12} className="m-0 p-0 mb-4">
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    width: '100%',
                                                    marginBottom: '10px'
                                                }}>
                                                </div>
                                                <div className="m-0 p-0">
                                                    <Review lesson={'lesson'} />
                                                </div>
                                            </Col>
                                        </Col>
                                    </Col>

                                }
                            </Row>
                        </Container> :
                        <>
                            <Loading />
                        </>
                    } {
                        this
                            .context
                            .commentFormOpen
                        && <CommentForm getReviewList={this.getReviewsList}></CommentForm>
                    }

                    {
                        this.state.ePaymentOpen && (
                            <Payment
                                isOpen={this.state.ePaymentOpen}
                                toggle={this.toggleModal}
                            ></Payment>
                        )
                    }

                    {
                        this.state.showQuizResult &&
                        <QuizResultModal
                            showQuizResult={this.state.showQuizResult}
                            toggleModal={this.toggleState}
                            quizData={this.state.quizData}
                        />
                    }
                </>
            )
                ;
        }
    }
)
    ;

export default withSnackbar(Lessons);
