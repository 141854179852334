import React from 'react';
import {Bars, ThreeDots} from "react-loader-spinner";

const Loading=()=>{
    return(
        <div style={{display:'flex',width:'100%',flexDirection:'row',justifyContent:'center',alignItems:'center',minHeight: "calc(100vh - 76px - 118px)"}}>
            <ThreeDots
                height="50"
                width="50"
                color="#4f2bab"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            />
        </div>
    )
}
export default Loading
