import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AdminNavbar from "components/Navbars/AdminNavbar";
import AdminFooter from "components/Footers/AdminFooter";
import Sidebar from "components/Sidebar/Sidebar";
import Esewa from "components/Payment/esewa";
import routes from "routes.js";
import { requestForToken } from "../firebase";
import messageSound from "../assets/sound/sound.mp3";
import "../views/examples/styles.css";
import Services from "../RemoteServices/RemoteServices";
import { SocketContext } from "./SocketContext";

class Admin extends React.Component {
  static contextType = SocketContext;
  state = {
    socket: null,
    message: [],
    messages: [],
    adminTyping: false,
    fullName: "",
    nextUrl: "",
    loading: false,
    allMessageLoaded: false,
    sidebartoggle: true,

    opencall: false,
    incomingAudio: true,
    incomingvideo: true,
    call: {
      isBusyMode: false,
      receivesentState: false,
    },
  };

  togglesidebar = () => {
    this.setState((prev) => ({ sidebartoggle: !prev.sidebartoggle }));
  };

  getChatMessage = async () => {
    await Services.getChatMessage(JSON.parse(localStorage.getItem("user")).id)
      .then((response) => {
        this.setState({
          messages: response.results.reverse(),
          nextUrl: response.next,
        });
      })
      .catch((error) => {
        throw error;
      });
  };

  getNext = async () => {
    try {
      if (this.state.nextUrl) {
        this.setState({ loading: true });
        const response = await Services.getLinkNext(this.state.nextUrl);

        this.setState((prevState) => ({
          messages: [...response.results.reverse(), ...prevState.messages],
          loading: false,
          nextUrl: response.next,
          allMessagesLoaded: !response.next,
        }));
      } else {
        this.setState({ loading: false });
      }
    } catch (error) {
      this.setState({ loading: false });
      console.error(error);
    }
  };
  resetMessage = () => {
    this.setState({
      message: [],
    });
  };

  updateDeliveryStatus = (incomingMessage) => {
    this.setState((prevState) => {
      // Create a new array with updated delivery_type
      const updatedMessages = prevState.messages.map((msg) =>
        msg.id === incomingMessage.id
          ? { ...msg, delivery_type: incomingMessage.delivery_type }
          : msg
      );
      return { messages: updatedMessages };
    });
  };

  listenSocket = () => {
    if (this.context.socket) {
      this.setState({ socket: this.context.socket });
      this.context.socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        let delivery;
        if (data.message.action === "message") {
          this.setState((prevMessage) => ({
            ...prevMessage,
            messages: [...prevMessage.messages, data.message.data],
            message: [...prevMessage.message, data.message.data],
          }));
          if (
            data.message.data.to_user_id ===
            JSON.parse(localStorage.getItem("user")).id
          ) {
            if (this.props.history.location.pathname === "/admin/MessageUs") {
              const sound = new Audio(messageSound);
              sound
                .play()
                .catch((error) => console.error("Error playing sound:", error));
              delivery = {
                action: "delivery_status",
                id: data.message.data.id,
                delivery_type: "seen",
                me_id: JSON.parse(localStorage.getItem("user"))?.id,
                user_id: data.message.data.user,
              };
            } else {
              console.log("deeee");
              delivery = {
                action: "delivery_status",
                id: data.message.data.id,
                delivery_type: "delivered",
                me_id: JSON.parse(localStorage.getItem("user"))?.id,
                user_id: data.message.data.user,
              };
            }
            this.context.socket.send(JSON.stringify(delivery));
          }
        } else if (data.message.action == "bulk_message") {
          this.setState((prevMessage) => ({
            ...prevMessage,
            messages: [...prevMessage.messages, data.message.data],
            message: [...prevMessage.message, data.message.data],
          }));
          const sound = new Audio(messageSound);
          sound
            .play()
            .catch((error) => console.error("Error playing sound:", error));
          if (this.props.history.location.pathname === "/admin/MessageUs") {
            if (
              data.message.data.to_user_id ===
                JSON.parse(localStorage.getItem("user")).id ||
              data.message.data.to_user_id === "user"
            ) {
              delivery = {
                action: "delivery_status",
                id: data.message.data.id,
                delivery_type: "seen",
                me_id: JSON.parse(localStorage.getItem("user"))?.id,
                user_id: data.message.data.user,
              };
            } else {
              delivery = {
                action: "delivery_status",
                id: data.message.data.id,
                delivery_type: "delivered",
                me_id: JSON.parse(localStorage.getItem("user"))?.id,
                user_id: data.message.data.user,
              };
            }
            this.context.socket.send(JSON.stringify(delivery));
          } else {
            delivery = {
              action: "delivery_status",
              id: data.message.data.id,
              delivery_type: "delivered",
              me_id: JSON.parse(localStorage.getItem("user"))?.id,
              user_id: data.message.data.user,
            };
            this.context.socket.send(JSON.stringify(delivery));
          }
        } else if (data.message.action == "online_users") {
          console.log("online", data);
        } else if (data.message.action == "typing") {
          if (
            data.message.data.user_id ===
            JSON.parse(localStorage.getItem("user")).id
          ) {
            this.setState({
              adminTyping: data.message.data.typing,
              fullName: data.message.data.full_name,
            });
          }
        } else if (data.message.action === "delivery_status") {
          this.updateDeliveryStatus(data.message.data);
        } else if (data.message.action === "call") {
          this.handleCallReceivingAction(data);
        }
      };
    }
  };

  handleCallReceivingAction(data) {
    const { action_type, calling_to } = data.message.data;
    if (calling_to == "user") {
      switch (action_type) {
        case "ringing":
          this.setState({
            opencall: true,
          });
          break;
      }
    }
  }

  componentDidMount() {
    this.listenSocket();
    requestForToken();
    this.getChatMessage();
    document.addEventListener("click", this.handleUserInteraction);
  }

  componentWillUnmount() {
    if (this.state.socket) {
      this.state.socket.close();
    }
    document.removeEventListener("click", this.handleUserInteraction);
  }

  handleUserInteraction = () => {
    this.setState({ userInteracted: true });
    document.removeEventListener("click", this.handleUserInteraction);
  };

  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }
  getRoutes = (routes) => {
    // eslint-disable-next-line array-callback-return
    return routes.map((prop, key) => {
      if (localStorage.getItem("user")) {
        // let userType = JSON.parse(localStorage.getItem("user")).user_type;
        if (prop.layout === "/admin" || prop.layout === "/profile") {
          // if (hasAccess(prop.user, userType) && userType){
          return (
            <Route
              path={prop.layout + prop.path}
              key={key}
              render={(routeProps) => (
                <prop.component
                  {...routeProps}
                  socket={this.state.socket}
                  messages={this.state.messages}
                  adminTyping={this.state.adminTyping}
                  fullName={this.state.fullName}
                  showMessage={this.state.showMessage}
                  currentUserId={this.state.currentUserId}
                  message={this.state.message}
                  resetMessage={this.resetMessage}
                  nextUrl={this.state.nextUrl}
                  getNext={this.getNext}
                  loading={this.state.loading}
                />
              )}
            />
          );
          // } else {
          //   this.props.history.push('/auth/login')
          // }
        } else {
          return null;
        }
      } else {
        this.props.history.push("/auth/login");
      }
    });
  };
  getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return null;
  };
  onAccept = () => {
    const url = `/udplayot/callModel?audio=${this.state.incomingAudio}&video=${this.state.incomingvideo}&mode=incomming`;
    window.open(url, "_blank");
    this.setState({ opencall: false });
  };
  onReject = () => {
    this.state.socket.send(
      JSON.stringify({
        action: "call",
        action_type: "reject_call",
        calling_to: "admin",
        user_id: JSON.parse(localStorage.getItem("user"))?.id,
        me_id: JSON.parse(localStorage.getItem("user"))?.id,
        full_name: JSON.parse(localStorage.getItem("user"))?.full_name,
      })
    );
    this.setState({ opencall: false });
  };

  render() {
    return (
      <div
        className="d-flex flex-column"
        style={{
          width: "100%",
          height: "100vh",
          overflow: "hidden",
        }}
      >
        <div style={{ width: "100vw", margin: "0" }}>
          <AdminNavbar
            message={this.state.message}
            socket={this.state.socket}
            call={this.state.call}
            togglesideFuns={this.togglesidebar}
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
            onReject={this.onReject}
            opencall={this.state.opencall}
            onAccept={this.onAccept}
            callerName="English hub"
            callerImg={require("../assets/img/withoutNameEng.png")}
          />
        </div>
        <div
          className="d-flex flex-grow-1 admin_content_sider_bar"
          ref="mainContent"
          style={{
            overflow: "auto",
          }}
        >
          <div
            className="admin_siderbar srollbarwidth"
            style={{
              // borderRight:'1px solid black'
              boxShadow: "1px 0px 4px #c6c6e9",
            }}
          >
            <Sidebar
              {...this.props}
              routes={routes}
              sidebartoggle={this.state.sidebartoggle}
              logo={{
                innerLink: "/admin/dashboard",
                imgSrc: require("assets/img/brand/argon-logo.png"),
                imgAlt: "...",
              }}
            />
          </div>
          <div
            className={`main-content admin_content  ${
              this.state.sidebartoggle ? "" : ""
            }`}
            style={{
              paddingLeft: "5px ",
              paddingRight: "5px ",
              overflowX: "hidden",
              overflowY: "auto",
            }}
          >
            <Switch>
              {this.getRoutes(routes)}
              <Redirect from="*" to="/admin/dashboard" />
              <Route path="/admin/esewa" component={Esewa} />
            </Switch>
          </div>
        </div>
        <div style={{ width: "100vw" }}>
          <AdminFooter />
        </div>
      </div>
    );
  }
}

export default Admin;
