import React, { createContext, useState, useEffect } from 'react';

export const SocketContext = createContext();

export  const SocketProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);
    const [loading, setLoading] = useState(false)
    
    const initializeSocket = (userId) => {
        setLoading(true)
        return new Promise((resolve, reject) => {
            const socketi = new WebSocket(`${process.env.REACT_APP_SOCKET_URL}users/${userId}/chat/`);
            socketi.onopen = () => {
                resolve(socketi)
                setLoading(false)
            };
            socketi.onerror = (error) => {
                reject('err', error)
                setLoading(false)
            };
            socketi.onclose =  () => {
                reject('close socket connection')
                setLoading(false)
            }
        })
    }

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.id && !socket) {
          initializeSocket(user.id)
            .then(socketi => {
              setSocket(socketi);
            })
            .catch(() => {
              setTimeout(async () => {
                try {
                  const socketi = await initializeSocket(user.id);
                  setSocket(socketi);
                } catch (err) {
                  console.error('Socket initialization failed:', err);
                }
              }, 1000);
            });
        }
        return () => {
          if (socket) {
            socket.close();
          }
        };
      }, [socket]);
      
    return (
        <SocketContext.Provider value={{ socket, setSocket, loading }}>
            {socket ? children : null}
        </SocketContext.Provider>
      );
}



