import React from "react";
import "../../assets/css/FullScreenDialog.css";
import {
  Avatar,
  Box,
  Button,
  Container,
  makeStyles,
  Paper,
  styled,
  Typography,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { useDateTime } from "hooks/useDateTime";
import {
  Mic,
  Videocam,
  EmojiEmotions,
  ScreenShare,
  EmojiPeople,
  PhoneDisabled,
  People,
  Message,
  InfoRounded,
  MicOff,
  VideocamOff,
} from "@material-ui/icons";
import PreMeetingScreen from "./PreMeetingScreen";
import { useLocation, useHistory } from "react-router-dom";
import { useWebrtc } from "hooks/useWebrtc";
import { useGridLayout } from "hooks/useGridLayout";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(1),
    gap: theme.spacing(1),
    position: "fixed",
    width: "100%",
    inset: "0px",
    backgroundColor: "#333",
    color: "#fff",
  },
  boxContainer: {
    width: "100%",
    maxWidth: "95%",
  },
  box: {
    display: "grid",
    gap: theme.spacing(3),
    aspectRatio: "16 / 9",
    marginLeft: "auto",
    marginRight: "auto",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },
  userContainer: {
    cursor: "pointer",
  },
  videoContainer: {
    backgroundColor: theme.palette.grey[800],
    borderRadius: theme.shape.borderRadius,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    overflow: "hidden",
  },
  video: {
    position: "absolute",
    width: "100%",
    height: "100%",
    objectFit: "cover",
    transform: "scaleX(-1)",
  },
  videoMessage: {
    color: theme.palette.grey[400],
    fontSize: "1.2rem",
  },
  videoAvatar: {
    width: "8rem",
    height: "8rem",
    fontSize: "2rem",
    backgroundColor: "#8b4c70",
    textTransform: "capitalize",
  },
  controlContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100vw",
    padding: "0.5rem 2rem",
  },
  endCall: {
    backgroundColor: "red",
    color: "#fff",
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1.5, 3),
    "&:hover": {
      backgroundColor: "#d32f2f",
    },
  },
}));

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
  backgroundColor: "#444",
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
  boxShadow: theme.shadows[3],
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  border: "none",
  borderRadius: theme.shape.borderRadius,
  color: "#fff",
  padding: theme.spacing(1),
  "&.Mui-selected": {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  "&:hover": {
    backgroundColor: theme.palette.primary.light,
  },
}));

const TeacherMeet = () => {
  const classes = useStyles();
  const {
    localVideoRef,
    isAudioMuted,
    isVideoMuted,
    toggleAudio,
    toggleVideo,
    peersRef,
  } = useWebrtc();

  const [controls, setControls] = React.useState(() => ["mic", "cam"]);
  const { time } = useDateTime();
  const location = useLocation();
  const history = useHistory();
  const gridRef = React.useRef(null);
  const TOTAL_PEERS = peersRef.current?.size + 1;

  const { layout } = useGridLayout(gridRef, TOTAL_PEERS);

  const queryParams = new URLSearchParams(location.search);
  const isReady = queryParams.get("ready");
  const isJoin = queryParams.get("join");
  const userDetail = JSON.parse(localStorage.getItem("user"));
  const userId = userDetail?.id;
  const VISIBLE_PEERS = Math.min(TOTAL_PEERS, layout.maxTiles);

  console.log("peersRef", peersRef.current);

  const handleChange = (event, newControls) => {
    setControls(newControls);
  };

  const handleEndCall = () => {
    history.push("/admin/TeacherSupport");
  };

  if (isReady) {
    return (
      <PreMeetingScreen
        ref={localVideoRef}
        {...{ isAudioMuted, toggleAudio, toggleVideo, isVideoMuted }}
      />
    );
  } else if (!isJoin) {
    return null;
  }

  return (
    <Container maxWidth={"xl"} className={classes.container}>
      <Box className={classes.boxContainer}>
        <div
          className={classes.box}
          ref={gridRef}
          style={{
            gridTemplateColumns: `repeat(var(--col-count), 1fr)`,
            gridTemplateRows: `repeat(calc(${Math.ceil(
              VISIBLE_PEERS / "var(--col-count)"
            )}), 1fr)`,
          }}
        >
          {/* local video */}

          <Box className={classes.userContainer}>
            <Paper elevation={2} className={classes.videoContainer}>
              {isVideoMuted ? (
                <Typography className={classes.videoMessage}>
                  <Avatar color="primary" className={classes.videoAvatar}>
                    {userDetail.full_name
                      .split(" ")
                      .map((name) => name[0])
                      .join("")}
                  </Avatar>
                </Typography>
              ) : (
                <video
                  muted
                  ref={localVideoRef}
                  className={classes.video}
                  autoPlay
                />
              )}
            </Paper>
          </Box>

          {/* peer videos */}
          {Array.from(peersRef.current?.values()).map(({ id, stream }) => {
            if (String(id) === String(userId)) {
              return null;
            }
            return (
              <Box key={id} className={classes.userContainer}>
                <Paper
                  key={id}
                  elevation={2}
                  className={classes.videoContainer}
                >
                  <video
                    autoPlay
                    playsInline
                    ref={(videoElement) => {
                      if (videoElement && stream) {
                        videoElement.srcObject = stream;
                      }
                    }}
                    className={classes.video}
                  />
                </Paper>
              </Box>
            );
          })}
        </div>
      </Box>

      {/* call controls */}
      <Box className={classes.controlContainer}>
        <div>
          <span>{time}</span>
          <span> | teacher-support</span>
        </div>
        <Box style={{ display: "flex", alignItems: "center", gap: "6px" }}>
          <StyledToggleButtonGroup
            color="primary"
            value={controls}
            onChange={handleChange}
          >
            <StyledToggleButton onClick={toggleAudio} value="mic">
              {isAudioMuted ? <MicOff /> : <Mic />}
            </StyledToggleButton>
            <StyledToggleButton onClick={toggleVideo} value="cam">
              {isVideoMuted ? <VideocamOff /> : <Videocam />}
            </StyledToggleButton>
            <StyledToggleButton value="emoji">
              <EmojiEmotions />
            </StyledToggleButton>
            <StyledToggleButton value="share-screen">
              <ScreenShare />
            </StyledToggleButton>
            <StyledToggleButton value="raise-hand">
              <EmojiPeople />
            </StyledToggleButton>
          </StyledToggleButtonGroup>
          <Button className={classes.endCall} onClick={handleEndCall}>
            <PhoneDisabled />
          </Button>
        </Box>

        <Box style={{ display: "flex", gap: 4, alignItems: "center" }}>
          <StyledToggleButton value="info">
            <InfoRounded />
          </StyledToggleButton>

          <StyledToggleButton value="people">
            <People />
          </StyledToggleButton>

          <StyledToggleButton value="message">
            <Message />
          </StyledToggleButton>
        </Box>
      </Box>
    </Container>
  );
};

export default TeacherMeet;
