import React, {useState} from 'react';
import {Button, Input, Modal} from "reactstrap";
import MaterialTable from 'material-table';
import avatar from '../../../assets/img/theme/avatarnew.png'
import Loading from "../../Loading/Loading";
import {useHistory} from "react-router-dom";

const ShareModal = ({
                        showModal,
                        toggleModal,
                        linkValue
                    }) => {

    const [valueCopied, setValueCopied] = useState(false)
    const handleCopy = (e) => {
        e.preventDefault();
        const valueToCopy = linkValue;

        // Create a temporary input element
        const input = document.createElement('input');
        input.value = valueToCopy;
        document.body.appendChild(input);

        // Select the input content
        input.select();
        input.setSelectionRange(0, 99999); // For mobile devices

        // Copy the content
        document.execCommand('copy');

        // Remove the temporary input element
        document.body.removeChild(input);

        setValueCopied(true);
        setTimeout(() => {
            setValueCopied(false)
        }, 2000)
    }

    return (
        <Modal
            keyboard={false}
            backdrop={'static'}
            className="modal-dialog-centered modal-lg"
            isOpen={showModal}
            toggle={() => toggleModal()}
        >
            <div className="modal-header">
                <h2 style={{justifyContent: 'center'}} className="modal-title" id="modal-title-default">
                    Share Your Link
                </h2>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => toggleModal()}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className={'modal-body m-0 p-0 '}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    padding: 20,
                    alignItems: 'center',
                    gap: '20px'
                }}>
                    <span style={{border: '1px solid lightgray', padding: '10px', borderRadius: 15}}>{linkValue}</span>
                    <span style={{cursor: "pointer"}} onClick={handleCopy}><i
                        style={{color: 'indigo', fontSize: 20, cursor: 'pointer'}} className={'fa fa-copy'}></i>{' '}Copy</span>
                </div>
                {valueCopied &&
                    <div className={'alert alert-success m-3 p-2'} role="alert">Link Copied</div>
                }

            </div>
            <div className={'modal-footer'}>
                <Button onClick={() => toggleModal()}>Close</Button>
            </div>
        </Modal>
    )

}

export default ShareModal