
import parse from 'html-react-parser';
import { withSnackbar } from "notistack";
import React from "react";
import ReactToPrint from "react-to-print";
import { Button, Card, CardBody, CardText, Col, Container, Modal, Row } from "reactstrap";
import messageImage from '../../assets/img/messageImage.png';
import Loading from "../../components/Loading/Loading";
import CommonDescription from "../../components/Modals/CommonDescription";
import DateTimeAgo from "../../utils/DateTimeAgo";
import { convertDateTime } from "../../utils/convertDateTime";
import './styles.scss';


const Services = require("../../RemoteServices/RemoteServices.js");

class Notifications extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notifications: { results: [] },
            filter: 'lesson',
            loading: true,
            showArticle: false,
            assignment: false,
            showMessage: false,
            showRemarks: false,
            remarksData: '',
            articleData: '',
            showPayment: false,
            paymentData: '',
            title: ''
        };
    }


    showMessage = (data, title) => {
        this.setState({
            showMessage: true,
            messageData: data,
            title: title ? title : ''
        })

    }

    showRemarks = (data) => {
        this.setState({
            showRemarks: true,
            remarksData: data,
        })

    }

    showArticle = async (type, data) => {
        if (type === 'article') {
            const response = await Services.getArticlesById(data.split(',')[0])
            this.setState({
                assignment: false,
                showArticle: true,
                articleData: response,
            })
        } else {
            const response = await Services.getAssignmentDetail(data.split(',')[0]).then((response) => {
                this.setState({
                    assignment: true,
                    showArticle: true,
                    articleData: response,
                })
            }).catch((error) => {
                this.props.enqueueSnackbar("Error Occured", {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                });
            });

        }
    }

    showPaymentDetail = async (data) => {
        const response = await Services.postPaymentById({ id: data.split(',')[0] })
        this.setState({
            showPayment: true,
            paymentData: response,
        })
    }

    componentDidMount() {
        const userId = JSON.parse(localStorage.getItem('user'))?.id
        Services.getNotifications(userId).then((response) => {
            this.setState({
                loading: false,
                notifications: {
                    results: response
                }
            });
        });
        if ('Notification' in window) {
            Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                    // You can now send push
                    console.log('granted')
                }
                console.log('notgranted')
            });
        }
    }

    getUserDetail = async () => {
        const userId = JSON.parse(localStorage.getItem('user'))?.id
        const response = await Services.getUserDetailsById(userId);
        await localStorage.setItem("user", JSON.stringify(response));
        window.location.href = '/admin/referAndEarn'
    }

    render() {
        // const options = this.state.dropdownOptions;
        return (
            <>
   
                {!this.state.loading ?
                    <Container className={'container-dashboard mb-4'} fluid
                        style={{ minHeight: "calc(100vh - 76px - 118px)" }}>
                        <div className={'notification'}>
                            <button onClick={() => this.setState({ filter: 'lesson' })} style={{
                                backgroundColor: this.state.filter === 'lesson' ? '#4f2bab' : '',
                                color: this.state.filter === 'lesson' ? 'white' : '',
                                paddingLeft: '30px',
                                paddingRight: '30px'
                            }} className={'btn btn-sm'}>Lessons
                            </button>
                            <button onClick={() => this.setState({ filter: 'others' })} style={{
                                backgroundColor: this.state.filter === 'others' ? '#4f2bab' : '',
                                color: this.state.filter === 'others' ? 'white' : '',
                                paddingLeft: '30px',
                                paddingRight: '30px'
                            }} className={'btn btn-sm'}>Others
                            </button>
                        </div>
                        {this.state.notifications.results.length > 0 ?
                            <Row>
                                <Col className="mb-5 mb-xl-0 text-center" xl="8" md="8">
                                    {this.state.notifications.results && this.state.notifications.results.length > 0 && this.state.notifications.results.map((value, index) => {
                                        return (
                                            <div key={index}>
                                                <Card className="notification-main mt-3">
                                                    <CardBody className={'m-1 p-1'}>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: 'row',
                                                            justifyContent: "flex-start",
                                                            gap: '10px',
                                                        }}>
                                                            <div className={'notification-icon-width'}>
                                                                <div className="col-auto">
                                                                    {value.notification_type.toUpperCase() === "LE_LIKE" && (
                                                                        <div
                                                                            className="notification-icon icon icon-shape text-white rounded-circle shadow">
                                                                            <i style={{ color: '#4f2bab' }}
                                                                                className="fa fa-thumbs-up" />
                                                                        </div>
                                                                    )}
                                                                    {value.notification_type.toUpperCase() === "LE_COMMENT" && (
                                                                        <div
                                                                            className="notification-icon icon icon-shape text-white rounded-circle shadow">
                                                                            <i style={{ color: '#4f2bab' }}
                                                                                className="fa fa-comment" />
                                                                        </div>
                                                                    )}
                                                                    {value.notification_type.toUpperCase() === "FR_LIKE" && (
                                                                        <div
                                                                            className="notification-icon icon icon-shape text-white rounded-circle shadow">
                                                                            <i style={{ color: '#4f2bab' }}
                                                                                className="fa fa-thumbs-up" />
                                                                        </div>
                                                                    )}
                                                                    {value.notification_type.toUpperCase() === "FR_COMMENT" && (
                                                                        <div
                                                                            className="notification-icon icon icon-shape text-white rounded-circle shadow">
                                                                            <i style={{ color: '#4f2bab' }}
                                                                                className="fa fa-comment" />
                                                                        </div>
                                                                    )}
                                                                    {value.notification_type.toUpperCase() === "RE_LIKE" && (
                                                                        <div
                                                                            className="notification-icon icon icon-shape text-white rounded-circle shadow">
                                                                            <i style={{ color: '#4f2bab' }}
                                                                                className="fa fa-thumbs-up" />
                                                                        </div>
                                                                    )}
                                                                    {value.notification_type.toUpperCase() === "RE_COMMENT" && (
                                                                        <div
                                                                            className="notification-icon icon icon-shape text-white rounded-circle shadow">
                                                                            <i style={{ color: '#4f2bab' }}
                                                                                className="fa fa-comment" />
                                                                        </div>
                                                                    )}
                                                                    {value.notification_type.toUpperCase() === "PUSH" && (
                                                                        <div
                                                                            className="notification-icon icon icon-shape text-white rounded-circle shadow">
                                                                            <i style={{ color: '#4f2bab' }}
                                                                                className="fa fa-comment" />
                                                                        </div>
                                                                    )}
                                                                    {value.notification_type === "Info" && (
                                                                        <div
                                                                            className="notification-icon icon icon-shape text-white rounded-circle shadow">
                                                                            <i style={{ color: '#4f2bab' }}
                                                                                className="ni ni-bell-55" />
                                                                        </div>
                                                                    )}
                                                                    {value.notification_type.toUpperCase() === "WITHDRAW_SUCCES" && (
                                                                        <div
                                                                            className="notification-icon icon icon-shape text-white rounded-circle shadow">
                                                                            <i style={{ color: '#4f2bab' }}
                                                                                className="fa fa-check-square" />
                                                                        </div>
                                                                    )}
                                                                    {value.notification_type.toUpperCase() === "REWARD_GAIN" && (
                                                                        <div
                                                                            className="notification-icon icon icon-shape text-white rounded-circle shadow">
                                                                            <i style={{ color: '#4f2bab' }}
                                                                                className="fa fa-money-bill" />
                                                                        </div>
                                                                    )}

                                                                    {value.notification_type.toUpperCase() === "WITHDRAW_DENIED" && (
                                                                        <div
                                                                            className="notification-icon icon icon-shape text-white rounded-circle shadow">
                                                                            <i style={{ color: '#4f2bab' }}
                                                                                className="ni ni-fat-remove" />
                                                                        </div>
                                                                    )}
                                                                    {value.notification_type.toUpperCase() === "WITHDRAW_PEND" && (
                                                                        <div
                                                                            className="notification-icon icon icon-shape text-white rounded-circle shadow">
                                                                            <i style={{ color: '#4f2bab' }}
                                                                                className="fa fa-clock" />
                                                                        </div>
                                                                    )}
                                                                    {value.notification_type.toUpperCase() === "NETWORK_GAIN" && (
                                                                        <div
                                                                            className="notification-icon icon icon-shape text-white rounded-circle shadow">
                                                                            <i style={{ color: '#4f2bab' }}
                                                                                className="fa fa-users" />
                                                                        </div>
                                                                    )}
                                                                    {value.notification_type.toUpperCase() === "ASSG_PENDING" && (
                                                                        <div
                                                                            className="notification-icon icon icon-shape text-white rounded-circle ">
                                                                            <i style={{ color: '#4f2bab' }}
                                                                                className="ni ni-check-bold" />
                                                                        </div>
                                                                    )}
                                                                    {value.notification_type === "Positive" && (
                                                                        <div
                                                                            className="notification-icon icon icon-shape text-white rounded-circle ">
                                                                            <i style={{ color: '#4f2bab' }}
                                                                                className="ni ni-check-bold" />
                                                                        </div>
                                                                    )}
                                                                    {value.notification_type === "Negative" && (
                                                                        <div
                                                                            className="notification-icon icon icon-shape text-white rounded-circle">
                                                                            <i style={{
                                                                                color: '#4f2bab',
                                                                                fontSize: '30px'
                                                                            }} className="ni ni-fat-remove" />
                                                                        </div>
                                                                    )}
                                                                    {value.notification_type === "Admin message" || value.notification_type === 'Admin_Message' || value.notification_type.toUpperCase() === 'UNPAID_MESSAGE' ? (
                                                                        <div
                                                                            className="notification-icon icon icon-shape text-white rounded-circle">
                                                                            <img height={40} width={40}
                                                                            alt=''
                                                                                src={messageImage} />
                                                                        </div>
                                                                    ) : <></>
                                                                    }
                                                                    {/*{value.notification_type === "ARTICLE_UPDATE" || value.notification_type==='Article_Update' && (*/}
                                                                    {value.notification_type.toUpperCase() === "ARTICLE_UPDATE" && (
                                                                        <div
                                                                            className="notification-icon icon icon-shape text-white rounded-circle">
                                                                            <i style={{
                                                                                color: '#4f2bab',
                                                                                fontSize: '25px'
                                                                            }} className="ni ni-notification-70" />
                                                                        </div>
                                                                    )}
                                                                    {value.notification_type === "Course_Assign" && (
                                                                        <div
                                                                            className="notification-icon icon icon-shape text-white rounded-circle shadow">
                                                                            <i style={{ color: '#4f2bab' }}
                                                                                className="ni ni-bell-55" />
                                                                        </div>
                                                                    )}
                                                                    {value.notification_type === "Course_Update" && (
                                                                        <div
                                                                            className="notification-icon icon icon-shape text-white rounded-circle shadow">
                                                                            <i style={{ color: '#4f2bab' }}
                                                                                className="ni ni-bell-55" />
                                                                        </div>
                                                                    )}

                                                                    {value.notification_type === "Course_Complete" && (
                                                                        <div
                                                                            className="notification-icon icon icon-shape text-white rounded-circle shadow">
                                                                            <i style={{ color: '#4f2bab' }}
                                                                                className="ni ni-bell-55" />
                                                                        </div>
                                                                    )}

                                                                    {value.notification_type === "Payment_Success" && (
                                                                        <div
                                                                            className="notification-icon icon icon-shape text-white rounded-circle shadow">
                                                                            <i style={{ color: '#4f2bab' }}
                                                                                className="ni ni-bell-55" />
                                                                        </div>
                                                                    )}

                                                                </div>
                                                            </div>
                                                            <div style={{ width: '100%' }}>
                                                                <div style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    justifyContent: 'flex-start',
                                                                    alignItems: 'flex-start'
                                                                }}>
                                                                    {/*{value.notification_type === "ARTICLE_UPDATE" || value.notification_type==="Article_Update" ?*/}
                                                                    {value.notification_type.toUpperCase() === "ARTICLE_UPDATE" ||
                                                                        value.notification_type.toUpperCase() === "PAYMENT_SUCCESS"
                                                                        || value.notification_type.toUpperCase() === "POSITIVE" ||
                                                                        value.notification_type.toUpperCase() === "NEGATIVE"
                                                                        ?
                                                                        <CardText style={{ textAlign: 'left' }}
                                                                            className={'font-weight-bold m-0 p-0'}>{value.title.split(',')[1]?value.title.split(',')[1]:value.title}
                                                                        </CardText> :
                                                                        <CardText style={{ textAlign: 'left' }}
                                                                            className={'font-weight-bold m-0 p-0'}>{value.title}
                                                                        </CardText>
                                                                    }

                                                                    <div style={{
                                                                        display: 'flex',
                                                                        flexDirection: 'row',
                                                                        justifyContent: 'flex-start',
                                                                        alignItems: 'baseline',
                                                                    }}>

                                                                        {value.content && value.notification_type !== 'Negative' &&
                                                                            value.notification_type !== 'Positive'
                                                                            && value.notification_type.toUpperCase() !== "ADMIN_MESSAGE"
                                                                            && value.notification_type.toUpperCase() !== "WITHDRAW_SUCCES"
                                                                            && value.notification_type.toUpperCase() !== "WITHDRAW_DENIED"
                                                                            && value.notification_type.toUpperCase() !== "WITHDRAW_PEND"
                                                                            && value.notification_type.toUpperCase() !== "REWARD_GAIN"
                                                                            && value.notification_type.toUpperCase() !== "NETWORK_GAIN"
                                                                            && value.notification_type.toUpperCase() !== "ASSG_PENDING"
                                                                            && value.notification_type.toUpperCase() !== "LE_LIKE"
                                                                            && value.notification_type.toUpperCase() !== "LE_COMMENT"
                                                                            && value.notification_type.toUpperCase() !== "FR_LIKE"
                                                                            && value.notification_type.toUpperCase() !== "FR_COMMENT"
                                                                            && value.notification_type.toUpperCase() !== "RE_LIKE"
                                                                            && value.notification_type.toUpperCase() !== "UNPAID_MESSAGE"
                                                                            && value.notification_type.toUpperCase() !== "RE_COMMENT" &&

                                                                            <span className={'m-0 p-0'}
                                                                                style={{ textAlign: 'left' }}>
                                                                                {parse(value.content.substring(0, 200))}
                                                                            </span>
                                                                        }
                                                                        {value.notification_type.toUpperCase() === "ADMIN_MESSAGE" &&
                                                                            <span
                                                                                className={'m-0 p-0'}
                                                                                style={{ textAlign: 'left' }}>
                                                                                {value.short_description || 'Backend Left'}
                                                                            </span>
                                                                        }
                                                                        {value.notification_type.toUpperCase() === "UNPAID_MESSAGE" &&
                                                                            <span
                                                                                className={'m-0 p-0'}
                                                                                style={{ textAlign: 'left' }}>
                                                                                {value.short_description || 'Backend Left'}
                                                                            </span>
                                                                        }
                                                                        {value.notification_type.toUpperCase() === "WITHDRAW_SUCCES" &&
                                                                            <span
                                                                                className={'m-0 p-0'}
                                                                                style={{ textAlign: 'left' }}>
                                                                                {value.content || 'Backend Left'}
                                                                            </span>
                                                                        }
                                                                        {value.notification_type.toUpperCase() === "WITHDRAW_DENIED" &&
                                                                            <span
                                                                                className={'m-0 p-0'}
                                                                                style={{ textAlign: 'left' }}>
                                                                                {value.content || 'Backend Left'}
                                                                            </span>
                                                                        }
                                                                        {value.notification_type.toUpperCase() === "ASSG_PENDING" &&
                                                                            <span
                                                                                className={'m-0 p-0'}
                                                                                style={{ textAlign: 'left' }}>
                                                                                {value.content || 'Backend Left'}
                                                                            </span>
                                                                        }
                                                                        {value.notification_type.toUpperCase() === "WITHDRAW_PEND" &&
                                                                            <span
                                                                                className={'m-0 p-0'}
                                                                                style={{ textAlign: 'left' }}>
                                                                                {value.content || 'Backend Left'}
                                                                            </span>
                                                                        }
                                                                        {value.notification_type.toUpperCase() === "REWARD_GAIN" &&
                                                                            <span
                                                                                className={'m-0 p-0'}
                                                                                style={{ textAlign: 'left' }}>
                                                                                {value.content || 'Backend Left'}
                                                                            </span>
                                                                        }
                                                                        {value.notification_type.toUpperCase() === "NETWORK_GAIN" &&
                                                                            <span
                                                                                className={'m-0 p-0'}
                                                                                style={{ textAlign: 'left' }}>
                                                                                {value.content || 'Backend Left'}
                                                                            </span>
                                                                        }

                                                                        {value.notification_type.toUpperCase() === "LE_LIKE" &&
                                                                            <span
                                                                                className={'m-0 p-0'}
                                                                                style={{ textAlign: 'left' }}>
                                                                                {value.content || 'Backend Left'}
                                                                            </span>
                                                                        }
                                                                        {value.notification_type.toUpperCase() === "LE_COMMENT" &&
                                                                            <span
                                                                                className={'m-0 p-0'}
                                                                                style={{ textAlign: 'left' }}>
                                                                                {value.content || 'Backend Left'}
                                                                            </span>
                                                                        }
                                                                        {value.notification_type.toUpperCase() === "FR_LIKE" &&
                                                                            <span
                                                                                className={'m-0 p-0'}
                                                                                style={{ textAlign: 'left' }}>
                                                                                {value.content || 'Backend Left'}
                                                                            </span>
                                                                        }
                                                                        {value.notification_type.toUpperCase() === "FR_COMMENT" &&
                                                                            <span
                                                                                className={'m-0 p-0'}
                                                                                style={{ textAlign: 'left' }}>
                                                                                {value.content || 'Backend Left'}
                                                                            </span>
                                                                        }
                                                                        {value.notification_type.toUpperCase() === "RE_LIKE" &&
                                                                            <span
                                                                                className={'m-0 p-0'}
                                                                                style={{ textAlign: 'left' }}>
                                                                                {value.content || 'Backend Left'}
                                                                            </span>
                                                                        }
                                                                        {value.notification_type.toUpperCase() === "RE_COMMENT" &&
                                                                            <span
                                                                                className={'m-0 p-0'}
                                                                                style={{ textAlign: 'left' }}>
                                                                                {value.content || 'Backend Left'}
                                                                            </span>
                                                                        }


                                                                    </div>
                                                                    <span style={{ fontSize: 11 }}> <DateTimeAgo
                                                                        dateTime={value.created_at} /></span>
                                                                </div>

                                                                {value.notification_type === 'Info' &&
                                                                    <div className={'mobile-button'}>
                                                                        <span style={{ textAlign: 'left' }}>To join speaking class please follow the guide video</span>
                                                                        <button
                                                                            className={'notification-button btn btn-sm'}>Watch
                                                                            Video
                                                                        </button>
                                                                    </div>
                                                                }

                                                                {value.notification_type.toUpperCase() === 'NETWORK_GAIN' &&
                                                                    <div className={'mobile-button'} style={{
                                                                        width: '100%',
                                                                        justifyContent: 'flex-end'
                                                                    }}>
                                                                        <button
                                                                            onClick={() => {
                                                                                this.getUserDetail();
                                                                            }}
                                                                            className={'notification-button btn btn-sm'}>Detail
                                                                        </button>
                                                                    </div>
                                                                }
                                                                {value.notification_type.toUpperCase() === 'REWARD_GAIN' &&
                                                                    <div className={'mobile-button'} style={{
                                                                        width: '100%',
                                                                        justifyContent: 'flex-end'
                                                                    }}>
                                                                        <button
                                                                            onClick={() => this.props.history.push('/admin/referAndEarn')}
                                                                            className={'notification-button btn btn-sm'}>Detail
                                                                        </button>
                                                                    </div>
                                                                }
                                                                {value.notification_type === 'Positive' &&
                                                                    <>
                                                                        {value.content.length > 0 ?
                                                                            <div className={'mobile-button'}>
                                                                                <span
                                                                                    style={{ textAlign: 'left' }}></span>
                                                                                <button
                                                                                    onClick={() => this.showRemarks(value.content)}
                                                                                    //for link implementation
                                                                                    // onClick={() => this.showArticle('assignment', value.title)}
                                                                                    className={'notification-button btn btn-sm'}
                                                                                >Read Remarks
                                                                                </button>
                                                                            </div>
                                                                            :
                                                                            ''
                                                                        }
                                                                    </>
                                                                }

                                                                {value.notification_type === 'Negative' &&
                                                                    <>
                                                                        {value.content.length > 0 ?
                                                                            <div className={'mobile-button'}>
                                                                                <span
                                                                                    style={{ textAlign: 'left' }}></span>
                                                                                <button
                                                                                    onClick={() => this.showRemarks(value.content)}
                                                                                    //for link implementation
                                                                                    // onClick={() => this.showArticle('assignment', value.title)}
                                                                                    className={'notification-button btn btn-sm'}
                                                                                >Read Remarks
                                                                                </button>
                                                                            </div>
                                                                            :
                                                                            ''
                                                                        }
                                                                    </>
                                                                }

                                                                {value.notification_type === 'Admin message' || value.notification_type === 'Admin_Message' ?
                                                                    <button
                                                                        onClick={() => this.showMessage(value.content, value.title)}
                                                                        className={'notification-button float-right btn btn-sm'}>Read
                                                                        Message
                                                                    </button> :
                                                                    <>
                                                                    </>
                                                                }

                                                                {value.notification_type.toUpperCase() === 'ARTICLE_UPDATE' &&
                                                                    <button
                                                                        onClick={() => this.showArticle('article', value.title)}
                                                                        className={'notification-button float-right btn btn-sm'}>Open
                                                                    </button>
                                                                }
                                                                {value.notification_type === 'Course_Assign' &&
                                                                    <div className={'mobile-button'}>
                                                                        <span style={{ textAlign: 'left' }}>Click the button to watch the guide video. </span>
                                                                        <button
                                                                            onClick={() => this.props.history.push('/admin/dashboard')}
                                                                            className={'notification-button btn btn-sm'}>Learn
                                                                            Now
                                                                        </button>
                                                                    </div>
                                                                }
                                                                {value.notification_type === 'Course_Complete' &&
                                                                    <div className={'mobile-button'}>
                                                                        <span style={{ textAlign: 'left' }}>To join speaking class please follow the guide video.</span>
                                                                        <button
                                                                            className={'notification-button btn btn-sm'}>Watch
                                                                            Video
                                                                        </button>
                                                                    </div>
                                                                }
                                                                {value.notification_type === 'Course_Update' &&
                                                                    <div className={'mobile-button'}>
                                                                        <span style={{ textAlign: 'left' }}>Click the button to watch the guide video.</span>
                                                                        <button
                                                                            className={'notification-button btn btn-sm'}>Watch
                                                                            Video
                                                                        </button>
                                                                    </div>
                                                                }

                                                                {value.notification_type === 'Payment_Success' &&
                                                                    <div className={'mobile-button'}>
                                                                        <span style={{ textAlign: 'left' }}>View the payment detail</span>
                                                                        <button
                                                                            onClick={() => this.showPaymentDetail(value.title)}
                                                                            className={'notification-button btn btn-sm'}>View
                                                                            Receipt
                                                                        </button>
                                                                    </div>
                                                                }

                                                                {value.notification_type.toUpperCase() === 'LE_LIKE' || value.notification_type.toUpperCase() === 'LE_COMMENT' ?
                                                                    <div className={'mobile-button'}>
                                                                        <span style={{ textAlign: 'left' }}></span>
                                                                        <button
                                                                            onClick={() => this.props.history.push(`/admin/history?lesson=${value.lesson_id}`)}
                                                                            className={'notification-button btn btn-sm'}>View
                                                                        </button>
                                                                    </div>
                                                                    : ''
                                                                }
                                                                {value.notification_type.toUpperCase() === 'FR_LIKE' || value.notification_type.toUpperCase() === 'FR_COMMENT' ?
                                                                    <div className={'mobile-button'}>
                                                                        <span style={{ textAlign: 'left' }}></span>
                                                                        <button
                                                                            onClick={() => this.props.history.push(`/admin/FreeLessons?lesson=${value.free_lesson_id}`)}
                                                                            className={'notification-button btn btn-sm'}>View
                                                                        </button>
                                                                    </div> :
                                                                    ''
                                                                }
                                                                {value.notification_type.toUpperCase() === 'RE_LIKE' || value.notification_type.toUpperCase() === 'RE_COMMENT' ?
                                                                    <div className={'mobile-button'}>
                                                                        <span style={{ textAlign: 'left' }}></span>
                                                                        <button
                                                                            onClick={() => this.props.history.push(`/admin/review`)}
                                                                            className={'notification-button btn btn-sm'}>View
                                                                        </button>
                                                                    </div>
                                                                    : ''
                                                                }


                                                            </div>
                                                        </div>

                                                    </CardBody>
                                                </Card>

                                            </div>
                                        );
                                    })}

                                </Col>

                                <Col className="mt-3 mb-5 mb-xl-0 text-center" xl="4" md="4">
                                    <Card className="notification-main"
                                        style={{ height: '400px', justifyContent: 'center' }}>
                                        <span>Coming Soon....</span>
                                    </Card>
                                </Col>

                            </Row> :
                            <div style={{
                                display: 'flex',
                                paddingTop: '250px',
                                width: '100%',
                                flexDirection: 'row',
                                justifyContent: 'center'
                            }}>
                                <strong style={{ color: 'black' }}>No Notifications Found...</strong>
                            </div>
                        }
                    </Container>
                    :
                    <>
                        <Loading />
                    </>
                }

                {this.state.showArticle &&
                    <Modal
                        keyboard={false}
                        backdrop={true}
                        className="modal-dialog-centered modal-lg"
                        isOpen={this.state.showArticle}
                        toggle={() => this.setState({ showArticle: false })}
                    >
                        <div className="modal-header"
                            style={{
                                borderBottom: '1px solid lightgrey',
                                display: 'flex',
                                justifyContent: 'center',
                                margin: '5px',
                                padding: '10px',
                            }}>
                            <h2 style={{ textAlign: 'center' }} className="modal-title" id="modal-title-default">
                                {this.state.assignment ? ` Your assignment of lesson ${this.state?.articleData?.lesson_name} has been ${this.state?.articleData?.status}` : this.state.articleData.title}
                            </h2>
                            <button
                                aria-label="Close"
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => this.setState({ showArticle: false })}
                            >
                                <span aria-hidden={true}>×</span>
                            </button>
                        </div>
                        {this.state.assignment &&
                            <div style={{ padding: 10 }} dangerouslySetInnerHTML={{ __html: this.state.articleData.remarks }}></div>
                        }
                        <div
                            className="modal-body m-0 p-2">
                            <CommonDescription
                                arrayObject={this.state?.articleData?.objects || this.state?.articleData?.assignment_remarks} />
                        </div>

                        <div className={'modal-footer'}>
                            <Button onClick={() => this.setState({ showArticle: false })}
                                className={'float-right btn'}>Close</Button>
                        </div>

                    </Modal>
                }
                {this.state.showMessage &&
                    <Modal
                        keyboard={false}
                        backdrop={true}
                        className="modal-dialog-centered modal-lg"
                        isOpen={this.state.showMessage}
                        toggle={() => this.setState({ showMessage: false })}
                    >
                        <div className="modal-header"
                            style={{
                                borderBottom: '1px solid lightgrey',
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                                margin: '5px',
                                padding: '10px',
                                alignItems: 'center'
                            }}>
                            <div style={{ width: '95%' }}>
                                <h2 style={{ textAlign: 'center' }} className="modal-title" id="modal-title-default">
                                    {this.state.title}
                                </h2>
                            </div>
                            <div style={{ width: '5%' }}>
                                <button
                                    aria-label="Close"
                                    className="close"
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={() => this.setState({ showMessage: false })}
                                >
                                    <span aria-hidden={true}>×</span>
                                </button>
                            </div>
                        </div>
                        <div className={'modal-body m-0 p-2'}>
                            <span className={'m-0 p-0'}>{parse(this.state.messageData)}</span>
                        </div>

                        <div className={'modal-footer'}>
                            <Button onClick={() => this.setState({ showMessage: false })}
                                className={'float-right btn'}>Close</Button>
                        </div>

                    </Modal>
                }

                {this.state.showRemarks &&
                    <Modal
                        keyboard={false}
                        backdrop={true}
                        className="modal-dialog-centered modal-lg"
                        isOpen={this.state.showRemarks}
                        toggle={() => this.setState({ showRemarks: false })}
                    >
                        <div className="modal-header"
                            style={{
                                borderBottom: '1px solid lightgrey',
                                display: 'flex',
                                justifyContent: 'center',
                                margin: '5px',
                                padding: '10px',
                            }}>
                            <h2 style={{ textAlign: 'center' }} className="modal-title" id="modal-title-default">
                                Remarks
                            </h2>
                            <button
                                aria-label="Close"
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => this.setState({ showRemarks: false })}
                            >
                                <span aria-hidden={true}>×</span>
                            </button>
                        </div>
                        <div className={'modal-body m-0 p-2'}>
                            <div dangerouslySetInnerHTML={{
                                __html: this.state.remarksData,
                            }} />
                        </div>

                        <div className={'modal-footer'}>
                            <Button onClick={() => this.setState({ showRemarks: false })}
                                className={'float-right btn'}>Close</Button>
                        </div>

                    </Modal>
                }


                {this.state.showPayment &&
                    <Modal
                        keyboard={false}
                        backdrop={true}
                        className="modal-dialog-centered modal-lg"
                        isOpen={this.state.showPayment}
                        toggle={() => this.setState({ showPayment: false })}
                    >
                        <div className="modal-header"
                            style={{
                                borderBottom: '1px solid lightgrey',
                                display: 'flex',
                                justifyContent: 'center',
                                margin: '5px',
                                padding: '10px',
                            }}>
                            <h2 style={{ textAlign: 'center' }} className="modal-title" id="modal-title-default">
                                Payment Alert
                            </h2>
                            <button
                                aria-label="Close"
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => this.setState({ showPayment: false })}
                            >
                                <span aria-hidden={true}>×</span>
                            </button>
                        </div>
                        <div className={'modal-body'}>
                            <Row>
                                <Col xs={12} className={'mb-3'}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: 'row',
                                        gap: '10px',
                                        alignItems: 'center'
                                    }}>
                                        <img src={require('../../assets/img/brand/argon-logo.png')} height={50}
                                            width={140} />
                                        <span style={{ fontSize: '20px' }} className={'font-weight-bold'}>English E and P Learning Hub Pvt. Ltd</span>
                                        <ReactToPrint
                                            trigger={() => {
                                                // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                                                // to the root node of the returned component as it will be overwritten.
                                                return <i className={'fa fa-print'}
                                                    style={{
                                                        fontSize: 20,
                                                        color: 'purple',
                                                        cursor: 'pointer'
                                                    }}></i>;
                                            }}
                                            content={() => this.componentRef}
                                        />
                                    </div>


                                </Col>
                                <div className={'payment-transaction'} style={{ width: '100%' }}
                                    ref={el => (this.componentRef = el)}>
                                    <Col xs={12} className="mb-3">
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            width: '100%',
                                            gap: '40px'
                                        }}>
                                            {/*<span style={{color: 'green'}}>Congratulation Your Payment was successfully done with payment method: ESEWA.</span>*/}
                                            <div style={{
                                                display: 'flex', flexDirection: 'row',
                                                borderBottom: '1px solid lightgrey',
                                                width: '100%'
                                            }}>
                                                <h2>Transaction Details</h2>
                                            </div>

                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'flex-start',
                                                width: '100%'
                                            }}>
                                                <div style={{ display: 'flex', flexDirection: 'column', width: '35%' }}>
                                                    <span className={'title'}>Transaction Code</span>
                                                    <span>{this.state.paymentData.transaction_id}</span>
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'column', width: '35%' }}>
                                                    <span className={'title'}>Date/Time</span>
                                                    <span>{convertDateTime(this.state.paymentData.paid_at)}</span>
                                                </div>

                                                <div style={{ display: 'flex', flexDirection: 'column', width: '30%' }}>
                                                    <span className={'title'}>Service Name</span>
                                                    <span>Basic-Advanced English</span>
                                                </div>

                                            </div>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'fiex-start'
                                            }}>
                                                <div style={{ display: 'flex', flexDirection: 'column', width: '35%' }}>
                                                    <span className={'title'}>Total Amount</span>
                                                    <span>Rs. {this.state.paymentData.actual_amount}</span>
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'column', width: '35%' }}>
                                                    <span className={'title'}>Vat Amount</span>
                                                    <span>Rs. {this.state.paymentData.vat_amount}</span>
                                                </div>

                                                <div style={{ display: 'flex', flexDirection: 'column', width: '30%' }}>
                                                    <span className={'title'}>Grand Total</span>
                                                    <span>Rs. {this.state.paymentData.amount_paid}</span>
                                                </div>

                                            </div>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'flex-start'
                                            }}>
                                                <div style={{ display: 'flex', flexDirection: 'column', width: '35%' }}>
                                                    <span className={'title'}>Product Service Charge</span>
                                                    <span>Rs. 0.00</span>
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'column', width: '35%' }}>
                                                    <span className={'title'}>Delivery Charge</span>
                                                    <span>Rs. 0.00</span>
                                                </div>

                                                <div style={{ display: 'flex', flexDirection: 'column', width: '30%' }}>
                                                    <span className={'title'}>Unique Id</span>
                                                    <span>{this.state.paymentData.transaction_id}</span>
                                                </div>

                                            </div>

                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'flex-start'
                                            }}>
                                                {/*<div style={{display: 'flex', flexDirection: 'column', width: '35%'}}>*/}
                                                {/*    <span>Transaction Amount</span>*/}
                                                {/*    <span>Rs. {this.state.paymentData.amount_paid}</span>*/}
                                                {/*</div>*/}
                                                <div style={{ display: 'flex', flexDirection: 'column', width: '35%' }}>
                                                    <span className={'title'}>Status</span>
                                                    <span><i className={'fa fa-check-circle'}
                                                        style={{ color: 'green' }} />&nbsp; Success</span>
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
                                                    <span className={'title'}>Payment Method</span>
                                                    <span>{this.state.paymentData.payment_method}</span>
                                                </div>
                                            </div>

                                        </div>
                                    </Col>
                                </div>
                            </Row>
                        </div>

                        <div className={'modal-footer'}>
                            <Button className={'float-right all-btn'}
                                onClick={() => this.props.history.push('/admin/Lessons')}>Go To Lesson</Button>
                            <Button onClick={() => this.setState({ showPayment: false })}
                                className={'float-right btn-danger'}>Close</Button>
                        </div>

                    </Modal>
                }
            </>
        );
    }
}

export default withSnackbar(Notifications);
