import React from 'react'
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Dropdown, DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormFeedback,
    Input,
    Row,
    Badge
} from "reactstrap";

import avatar from '../../assets/img/theme/avatarnew.png'
import InfiniteScroll from "react-infinite-scroll-component";
import {Oval} from 'react-loader-spinner';
import RatingReview from "../../components/Modals/RatingReview";
import _ from "lodash";
import ShowShareModal from "../../components/Modals/ShowShareModal";
import img from "../../assets/img/subscribe-our-youtube-channel.png";
import Sort from "../../assets/img/sort.png";
import like from "../../assets/img/theme/like.png";
import liked from "../../assets/img/theme/liked.png";
import './styles.scss'
import Loading from "../../components/Loading/Loading";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownAZ } from '@fortawesome/free-solid-svg-icons';


const Services = require("../../RemoteServices/RemoteServices.js");

class Review extends React.PureComponent {
    state = {
        reviewTag: '',
        rating: '',
        commentError: false,
        comment: '',
        showReply: false,
        reviewList: {
            count: '',
            next: '',
            results: []
        },
        rateReview: false,
        showShare: false,
        shareItem: '',
        loading:false,
    }

    handleCommentChange = (e) => {
        this.setState({comment: e.target.value});

    };

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            if (this.state.searchItem !== '') {
                this.searchContent(this.state.searchItem)
            }
        }
    }

    toggleReply(index) {
        this.setState((prevState) => ({
            showReply: prevState.showReply === index ? null : index,
        }));
    }

    toggleModal = (state) => {
        this.setState({
            [state]: !this.state[state],
        });
    };
    nextReview = async (nextUrl) => {
        try {
            const response = await Services.getNextReviewList(nextUrl);
            await this.setState((prevState) => ({
                reviewList: {
                    ...prevState.reviewList,
                    next: response.next,
                    results: [
                        ...prevState.reviewList.results,
                        ...response.results
                    ]
                }
            }))
        } catch (e) {

        }
    }

    fetchRating = async (tag) => {
        try {
            const response = await Services.getReviewList(tag);
            await this.setState({
                reviewList: response,
                loading:false,
            })
        } catch (e) {

        }

    }

    handleLikePress = (item) => {
        let newData = [...this.state.reviewList.results];
        const index = newData.findIndex((i) => i.id === item.id);
        if (JSON.parse(localStorage.getItem('user'))?.id === item.user.id) {
            if (item.have_i_Like) {
                newData[index].have_i_Like = false;
                newData[index].like_count = newData[index].like_count - 1;
                Services.addReviewLike({
                    have_i_Like: false,
                    id: newData[index].id,
                })
                    .then((res) => {
                        console.log("likeRes", res);
                    })
                    .catch((err) => {
                        console.log('asdfasdf', 'true')
                        console.log("err", err);
                    });
            } else {
                newData[index].have_i_Like = true;
                newData[index].like_count = newData[index].like_count + 1;
                Services.addReviewLike({
                    have_i_Like: true,
                    id: newData[index].id,
                })
                    .then((res) => {
                        console.log("likeRes", res);
                    })
                    .catch((err) => {
                        console.log('asdfasdf', 'false')
                        console.log("err", err);
                    });
            }
        } else {
            if (item.current_user_like_status) {
                newData[index].current_user_like_status = false;
                newData[index].like_count = newData[index].like_count - 1;
                Services.addReviewLike({
                    have_i_Like: false,
                    id: newData[index].id,
                })
                    .then((res) => {
                        console.log("likeRes", res);
                    })
                    .catch((err) => {
                        console.log('asdfasdfasdf', 'false')
                        console.log("err", err);
                    });
            } else {
                newData[index].current_user_like_status = true;
                newData[index].like_count = newData[index].like_count + 1;
                Services.addReviewLike({
                    have_i_Like: true,
                    id: newData[index].id,
                })
                    .then((res) => {
                        console.log("likeRes", res);
                    })
                    .catch((err) => {
                        console.log("err", err);
                    });
            }
        }

        this.setState({
            reviewList: {...this.state.reviewList, results: newData},
        });
    };
    handleReplyLikePress = (item) => {
        let newData = [...this.state.reviewList.results];
        console.log("newData", newData);
        const commentIndex = newData.findIndex((i) => i.id == item.review);
        if (commentIndex > -1) {
            const index = newData[commentIndex].app_reply.findIndex(
                (i) => i.id == item.id
            );

            if (JSON.parse(localStorage.getItem('user'))?.id === item.user.id) {
                if (item.have_i_Like) {
                    newData[commentIndex].app_reply[index].have_i_Like = false;
                    newData[commentIndex].app_reply[index].like_count =
                        newData[commentIndex].app_reply[index].like_count - 1;
                    Services.addReviewReplyLike({
                        have_i_Like: false,
                        reply_id: newData[commentIndex].app_reply[index].id,
                        auth_user: JSON.parse(localStorage.getItem('user'))?.id,
                    })
                        .then((res) => {
                            console.log("likeRes", res);
                        })
                        .catch((err) => {
                            console.log("err", err);
                        });
                } else {
                    newData[commentIndex].app_reply[index].have_i_Like = true;
                    newData[commentIndex].app_reply[index].like_count =
                        newData[commentIndex].app_reply[index].like_count + 1;
                    Services.addReviewReplyLike({
                        have_i_Like: true,
                        reply_id: newData[commentIndex].app_reply[index].id,
                        auth_user: JSON.parse(localStorage.getItem('user'))?.id,
                    })
                        .then((res) => {
                            console.log("likeRes", res);
                        })
                        .catch((err) => {
                            console.log("err", err);
                        });
                }
            } else {
                if (item.current_user_like_status) {
                    newData[commentIndex].app_reply[
                        index
                        ].current_user_like_status = false;
                    newData[commentIndex].app_reply[index].like_count =
                        newData[commentIndex].app_reply[index].like_count - 1;
                    Services.addReviewReplyLike({
                        have_i_Like: false,
                        reply_id: newData[commentIndex].app_reply[index].id,
                        auth_user: JSON.parse(localStorage.getItem('user'))?.id,
                    })
                        .then((res) => {
                            console.log("likeRes", res);
                        })
                        .catch((err) => {
                            console.log("err", err);
                        });
                } else {
                    newData[commentIndex].app_reply[
                        index
                        ].current_user_like_status = true;
                    newData[commentIndex].app_reply[index].like_count =
                        newData[commentIndex].app_reply[index].like_count + 1;
                    Services.addReviewReplyLike({
                        have_i_Like: true,
                        reply_id: newData[commentIndex].app_reply[index].id,
                        auth_user: JSON.parse(localStorage.getItem('user'))?.id,
                    })
                        .then((res) => {
                            console.log("likeRes", res);
                        })
                        .catch((err) => {
                            console.log("err", err);
                        });
                }
            }
        } else {
            console.warn("id not found");
        }

        this.setState({
            reviewList: {...this.state.reviewList, results: newData},
        });
    };

    handleShare = (item, isReply = false) => {
        this.setState({shareItem: item, showShare: true})
    };

    handleReviewReply = async (item) => {
        if (_.isEmpty(this.state.comment)) {
            console.log('Please Enter Comment')
        } else {
            this.setState({submitLoading: true});
            let split = "";
            if (!_.isEmpty(this.state.selectedReview)) {
                split = this.state.selectedReview.user.full_name.split(" ");
            }

            const data = {
                message: !_.isEmpty(this.state.selectedReview)
                    ? "@" + split[0] + " " + this.state.replyMessage
                    : this.state.replyMessage,
                have_i_Like: false,
                review: item.id,
                id: this.state.replyData.id,
            };
            if (this.state.replyData.isReply == "true") {
                await Services.updateReplyReview(data)
                    .then((response) => {
                        console.log("reviewReplyResponse", response);
                        this.setState({
                            submitLoading: false,
                            replyMessage: "",
                        });
                        const newData = [...this.state.reviewData.results];
                        const index = newData.findIndex((i) => i.id == item.id);
                        if (index > -1) {
                            const replyIndex = newData[index].app_reply.findIndex(
                                (i) => i.review == this.state.replyData.review
                            );
                            if (replyIndex > -1) {
                                newData[index].app_reply[replyIndex] = response;
                            }
                        }
                        this.setState({
                            reviewData: {...this.state.reviewData, results: newData},
                            selectedReview: {},
                            showModal: false,
                            showCommentModal: false,
                            submitLoading: false,
                            commentLoading: false,
                            replyData: {isReply: ""},
                            replyMessage: "",
                        });
                        // console.log(response)
                    })
                    .catch((error) => {
                        this.setState({submitLoading: false});
                        console.log("reviewError", error);
                    });
            } else {
                await Services.submitReviewReply(data)
                    .then((response) => {
                        this.setState({
                            submitLoading: false,
                            replyMessage: "",
                        });
                        const newData = [...this.state.reviewData.results];
                        const index = newData.findIndex((i) => i.id == item.id);
                        if (index > -1) {
                            newData[index].app_reply.unshift(response.data);
                        }
                        this.setState({
                            reviewData: {...this.state.reviewData, results: newData},
                            selectedReview: {},
                        });
                        // console.log(response)
                    })
                    .catch((error) => {
                        this.setState({submitLoading: false});
                        console.log("reviewError", error);
                    });
            }
        }
    };

    handleComment = async (item) => {
        if(this.state?.comment?.length>=25) {
            await Services.appReviewReply({
                user: JSON.parse(localStorage.getItem('user'))?.id,
                message: this.state.comment,
                review: item.id,

            }).then((response) => {
                this.setState({comment: '',commentError:false})
                this.fetchRating()

            }).catch(e => {

            });
        }else{
            this.setState({
                commentError:true
            })
        }
    }

    handleCommentReplyKeyDown = (e, item) => {
        if (e.key === 'Enter') {
            this.handleComment(item);
        }
    }

    toggle = () => this.setState({
        dropdownOpen: !this.state.dropdownOpen,
    });


    async componentDidMount() {
        if (this.props.lesson && this.props.lesson === 'lesson') {
            this.fetchRating('relevant')
        } else {
            this.setState({loading:true})
            this.fetchRating(this.state.reviewTag)
        }
    }

    render() {
        const {reviewList} = this.state;
        return (
            <React.Fragment>
                {!this.props.lesson && this.props.lesson !== 'lesson' ?
                    <>
                   
                        {!this.state.loading ?
                            <Container className={'container'} style={{minHeight: '600px'}}>
                                {reviewList?.count>0 ?
                                    <>
                                        <div className={'rating'}>
                                            <h1>Rating Overview</h1>
                                            <h2>
                                            <span>{reviewList?.avg_rating} <span
                                                style={{fontSize: '14px'}}>/5</span></span>
                                            </h2>
                                            <div className="star-rating mt--4">
                                                {[...Array(5)].map((star, index) => {
                                                    index += 1;
                                                    return (
                                                        <button
                                                            type="button"
                                                            key={index}
                                                            className={index <= this.state?.reviewList?.avg_rating ? "on" : "off"}
                                                        >
                                                            <span style={{fontSize: '40px'}}
                                                                  className="star">&#9733;</span>
                                                        </button>
                                                    );
                                                })}
                                            </div>
                                            <h2>{reviewList?.count} Ratings</h2>
                                        </div>
                                        <div className={'write-review'}>
                                            <Button style={{backgroundColor: '#4f2bab', color: 'white'}} className=''
                                                    type='button'
                                                    onClick={() => {
                                                        this.toggleModal('rateReview')
                                                    }}>
                                                        <span className='btn-inner--icon'>
                                                            <i className='fa fa-comment'/>
                                                        </span>
                                                <span className='btn-inner--text'>Write A Review</span>
                                            </Button>
                                        </div>

                                        <div className={'filter'} style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            width: '100%',
                                            justifyContent: 'flex-end',
                                            alignItems: 'center',
                                            marginTop: '12px',
                                            marginBottom: '8px',
                                        }}>
                                            {this.state.reviewTag!=='message'&&<Badge color={'success'}>{this.state.reviewTag}</Badge>}&nbsp;&nbsp;
                                            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                                                <DropdownToggle style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center'
                                                }}>
                                                    <FontAwesomeIcon icon={faArrowDownAZ} size="lg"/> &nbsp;&nbsp; Filter</DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem
                                                        onClick={() => {
                                                            this.setState({reviewTag: 'rating'});
                                                            this.fetchRating('rating')
                                                        }
                                                        }
                                                    >Rating</DropdownItem>
                                                    <DropdownItem
                                                        onClick={() => {
                                                            this.setState({reviewTag: 'recent'});
                                                            this.fetchRating('created_at')
                                                        }
                                                        }
                                                    >Recent</DropdownItem>

                                                    <DropdownItem
                                                        onClick={() => {
                                                            this.setState({reviewTag: 'relevant'});
                                                            this.fetchRating('relevant')
                                                        }
                                                        }
                                                    >Relevant</DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </div>

                                        <InfiniteScroll
                                            style={{overflow: 'none'}}
                                            dataLength={reviewList?.results?.length}
                                            hasMore={reviewList?.count > reviewList?.results?.length}
                                            next={() => this.nextReview(reviewList.next)}
                                            loader={<div
                                                className={'h-100 d-flex align-items-center justify-content-center'}>
                                                <Oval
                                                    height="30"
                                                    width="30"
                                                    radius="12"
                                                    color="grey"
                                                    ariaLabel="loading"
                                                    wrapperStyle
                                                    wrapperClass
                                                />
                                            </div>}
                                            endMessage={
                                                <p style={{textAlign: 'center'}}>
                                                    <b>Yay! You have seen it all</b>
                                                </p>
                                            }
                                            scrollableTarget="scrollableDiv"
                                        >
                                            <div className={'rating-list'}>
                                                {reviewList?.results && reviewList?.results.map((item, index) => (
                                                    <Card className={'mb-2'} key={index}>
                                                        <CardBody>
                                                            <div className={'m-0 p-0'}>
                                                                <div className={'card-image'}>
                                                                    <img
                                                                        onClick={() => this.props.history.push(`/profile/otherProfile/${item.user.id}`)}
                                                                        className={'circular_image'} alt={'review'}
                                                                        src={item.user.profile_picture !== null ? window.config.imgUrl + item.user.profile_picture : avatar}
                                                                        style={{
                                                                            borderRadius: '50%',
                                                                            contain: 'fill',
                                                                            height: '60px',
                                                                            width: '60px',
                                                                            cursor:'pointer'
                                                                        }}/>
                                                                    <div className={'card-name-star'}>
                                                                    <span style={{cursor: 'pointer'}}
                                                                          onClick={() => this.props.history.push(`/profile/otherProfile/${item.user.id}`)}>{item.user.full_name}&nbsp;
                                                                                                                  {item?.user?.email_verified && <i style={{ color: 'blue',fontSize:16 }} className={' fa fa-check-circle'}></i>}

                                                                          </span>
                                                                        <div className={'star'}>
                                                                            {[...Array(5)].map((star, index) => {
                                                                                index += 1;
                                                                                return (
                                                                                    <button
                                                                                        type="button"
                                                                                        key={index}
                                                                                        className={index <= item.rating ? "on" : "off"}
                                                                                        onClick={() => this.setState({rating: index})}
                                                                                    >
                                                                <span style={{fontSize: '25px'}}
                                                                      className="star">&#9733;</span>
                                                                                    </button>
                                                                                );
                                                                            })}
                                                                            <span style={{fontSize: '12px'}}
                                                                                  className={'ml-2'}>{new Date(item.created_at).toDateString()}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className={'ml-6 mb-2'}
                                                                     dangerouslySetInnerHTML={{
                                                                         __html: item.message,
                                                                     }}
                                                                ></div>
                                                                <div className={'ml-6 like-comment'}
                                                                     style={{display: 'flex', flexDirection: 'row'}}>
                                                                    {item.current_user_like_status ?
                                                                        <div style={{
                                                                            display: 'flex',
                                                                            flexDirection: 'column',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'center'
                                                                        }}>
                                                                            <div style={{
                                                                                display: 'flex',
                                                                                flexDirection: 'row',
                                                                                gap: '5px'
                                                                            }}>
                                                                                <img className={'like-hover'}
                                                                                     onClick={() => this.handleLikePress(item)}
                                                                                     src={liked}
                                                                                     height={20} width={20}/>
                                                                                <span style={{color: '#002060'}}
                                                                                      className={'font-weight-bold like-hover'}
                                                                                      onClick={() => this.handleLikePress(item)}>{item.like_count}</span>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div style={{
                                                                            display: 'flex',
                                                                            flexDirection: 'column'
                                                                        }}>
                                                                            <div style={{
                                                                                display: 'flex',
                                                                                flexDirection: 'row',
                                                                                gap: '5px'
                                                                            }}>
                                                                                <img className={'like-hover'}
                                                                                     onClick={() => this.handleLikePress(item)}
                                                                                     src={like}
                                                                                     height={20} width={20}/>
                                                                                <span style={{color: '#002060'}}
                                                                                      className={'like-hover'}
                                                                                      onClick={() => this.handleLikePress(item)}>{item.like_count}</span>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    <i onClick={() => this.toggleReply(index)}
                                                                       style={{
                                                                           marginLeft: '60px',
                                                                           fontSize: '20px',
                                                                           color: this.state.showReply === index ? 'purple' : 'grey',
                                                                           cursor: 'pointer',
                                                                       }}
                                                                       className={'fa fa-comments'}/> &nbsp;{item.app_reply.length}
                                                                    <i style={{
                                                                        marginLeft: '60px',
                                                                        fontSize: '20px',
                                                                        color: 'grey',
                                                                        cursor: 'pointer'
                                                                    }} onClick={() => this.handleShare(item)}
                                                                       className={'fa fa-share'}/>
                                                                </div>
                                                            </div>
                                                            {this.state.showReply === index && (
                                                                <div className={'ml-8 p-0'}>
                                                                    <Col xs={12} className="m-0 p-0 mt-4 mb-4">
                                                                        <Col xs={12} className="mb-2">
                                                                            <Input
                                                                                className={'search-input'}
                                                                                type="text"
                                                                                name="comment"
                                                                                placeHolder={'Reply a comment'}
                                                                                value={this.state.comment}
                                                                                onKeyDown={(e) => this.handleCommentReplyKeyDown(e, item)}
                                                                                onChange={this.handleCommentChange}
                                                                            />
                                                                            {this.state.commentError && <span
                                                                                style={{textAlign: 'center', color: 'red', fontSize: '14px'}}>Your comment must be at least 25 characters.</span>}
                                                                        </Col>
                                                                        {this.state.commentMessage && <span
                                                                            style={{
                                                                                textAlign: 'center',
                                                                                color: 'green',
                                                                                fontSize: '14px'
                                                                            }}>Success and is under review.</span>}
                                                                        <Col xs={12}>
                                                                            <Button style={{
                                                                                backgroundColor: '#6C32A0',
                                                                                color: 'white'
                                                                            }}
                                                                                    className={'btn btn-sm float-right'}
                                                                                    onClick={() => this.handleComment(item)}>Comment</Button>
                                                                            <Button style={{
                                                                                backgroundColor: '#ff1010',
                                                                                color: 'white'
                                                                            }}
                                                                                    className={'btn btn-sm float-right'}
                                                                                    onClick={() => this.setState({showReply:false})}>Cancel</Button>
                                                                        </Col>

                                                                        <Row style={{
                                                                            display: 'flex',
                                                                            flexDirection: 'row',
                                                                            width: '100%',
                                                                            justifyContent: 'center'
                                                                        }}>
                                                                            {this.context?.reviewsList?.results &&
                                                                                <InfiniteScroll
                                                                                    style={{overflow: 'none'}}
                                                                                    dataLength={this.context?.reviewsList?.results?.length}
                                                                                    hasMore={this.context?.reviewsList?.count > this.context?.reviewsList?.results?.length}
                                                                                    next={() => this.getReviewsList()}
                                                                                    loader={<div>
                                                                                        <Oval
                                                                                            height="30"
                                                                                            width="30"
                                                                                            radius="12"
                                                                                            color="grey"
                                                                                            ariaLabel="loading"
                                                                                            wrapperStyle
                                                                                            wrapperClass
                                                                                        />
                                                                                    </div>}
                                                                                    endMessage={
                                                                                        <p style={{textAlign: 'center'}}>
                                                                                            <b>Yay! You have seen it
                                                                                                all</b>
                                                                                        </p>

                                                                                    }
                                                                                    scrollableTarget="scrollableDiv"
                                                                                >
                                                                                </InfiniteScroll>
                                                                            }
                                                                        </Row>
                                                                    </Col>
                                                                    {item.app_reply.map((it) => (
                                                                        <>
                                                                            <div className={'card-image'}>
                                                                                <img alt={'review'}
                                                                                     src={it?.user.profile_picture !== null ? window.config.imgUrl.concat(it?.user.profile_picture) : avatar}
                                                                                     style={{
                                                                                         height: 50,
                                                                                         width:50,
                                                                                         borderRadius: '50%',
                                                                                         contain:'fill'
                                                                                     }}/>
                                                                                <div className={'card-name-star'}>
                                                                                    <span>{it?.user.full_name}</span>
                                                                                    <div className={'star'}>
                                                                                <span style={{fontSize: '12px'}}
                                                                                      className={'ml-2'}>{new Date(it.created_at).toDateString()}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className={'ml-6 mb-4'}
                                                                                 dangerouslySetInnerHTML={{
                                                                                     __html: it.message,
                                                                                 }}
                                                                            ></div>
                                                                            <div>
                                                                                {it.current_user_like_status ?
                                                                                    <div style={{
                                                                                        display: 'flex',
                                                                                        flexDirection: 'column',
                                                                                        alignItems: 'flex-start',
                                                                                        justifyContent: 'flex-start'
                                                                                    }}>
                                                                                        <div style={{
                                                                                            display: 'flex',
                                                                                            flexDirection: 'row',
                                                                                            gap: '5px',
                                                                                            justifyContent:'flex-start'
                                                                                        }}>
                                                                                            <img className={'like-hover'}
                                                                                                 onClick={() => this.handleReplyLikePress(it)}
                                                                                                 src={liked}
                                                                                                 height={20} width={20}/>
                                                                                            <span style={{color: '#002060'}}
                                                                                                  className={'font-weight-bold like-hover'}
                                                                                                  onClick={() => this.handleReplyLikePress(it)}>{it.like_count}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    :
                                                                                    <div style={{
                                                                                        display: 'flex',
                                                                                        flexDirection: 'column'
                                                                                    }}>
                                                                                        <div style={{
                                                                                            display: 'flex',
                                                                                            flexDirection: 'row',
                                                                                            gap: '5px'
                                                                                        }}>
                                                                                            <img className={'like-hover'}
                                                                                                 onClick={() => this.handleReplyLikePress(it)}
                                                                                                 src={like}
                                                                                                 height={20} width={20}/>
                                                                                            <span style={{color: '#002060'}}
                                                                                                  className={'like-hover'}
                                                                                                  onClick={() => this.handleReplyLikePress(it)}>{it.like_count}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                                <hr/>
                                                                            </div>
                                                                        </>
                                                                    ))}
                                                                </div>
                                                            )}
                                                        </CardBody>
                                                    </Card>
                                                ))}
                                            </div>
                                        </InfiniteScroll>
                                    </>:
                                    <div style={{width:'100%',display:'flex',justifyContent:'center',minHeight:'500px',alignItems:'center'}}>
                                        <span>No Review Found</span>
                                    </div>
                                }
                            </Container>
                            :
                            <>
                                <Loading/>
                            </>
                        }
                    </>
                    :
                    <>
                        {this.props.lesson === 'lesson' ?
                            <>

                                {reviewList?.count &&
                                    <>
                                        <div className={'rating'}>
                                            <h4>Rating Overview</h4>
                                            <h5>
                                            <span>{reviewList?.avg_rating} <span
                                                style={{fontSize: '14px'}}>/5</span></span>
                                            </h5>
                                            <div className="star-rating">
                                                {[...Array(5)].map((star, index) => {
                                                    index += 1;
                                                    return (
                                                        <button
                                                            type="button"
                                                            key={index}
                                                            className={index <= this.state?.reviewList?.avg_rating ? "on" : "off"}
                                                        >
                                                                <span style={{fontSize: '20px'}}
                                                                      className="star">&#9733;</span>
                                                        </button>
                                                    );
                                                })}
                                            </div>
                                            <h4>{reviewList?.count} Ratings</h4>
                                        </div>
                                        <hr style={{margin: '0 20% 5px 20%', borderBottom: '1px solid grey'}}></hr>
                                        <div className={'scroll-container'}>
                                            <div className={'scroll-content'}>
                                                <InfiniteScroll
                                                    style={{overflow: 'none'}}
                                                    dataLength={reviewList?.results?.length}
                                                    hasMore={reviewList?.count > reviewList?.results?.length}
                                                    next={() => this.nextReview(reviewList?.next)}
                                                    loader={<div
                                                        className={'h-100 d-flex align-items-center justify-content-center'}>
                                                        <Oval
                                                            height="30"
                                                            width="30"
                                                            radius="12"
                                                            color="grey"
                                                            ariaLabel="loading"
                                                            wrapperStyle
                                                            wrapperClass
                                                        />
                                                    </div>}
                                                    endMessage={
                                                        <p style={{textAlign: 'center'}}>
                                                            <b>Yay! You have seen it all</b>
                                                        </p>
                                                    }
                                                    // below props only if you need pull down functionality
                                                    // refreshFunction={this.refresh}
                                                    // pullDownToRefresh
                                                    // pullDownToRefreshThreshold={50}
                                                    // pullDownToRefreshContent={
                                                    //     <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
                                                    // }
                                                    // releaseToRefreshContent={
                                                    //     <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
                                                    // }
                                                >
                                                    <div className={'rating-list'}>
                                                        {reviewList?.results && reviewList?.results.map((item, index) => (
                                                            <Card style={{borderRadius: '2px'}} className={'mb-2'}
                                                                  key={index}>
                                                                <CardBody>
                                                                    <div className={'m-0 p-0'}>
                                                                        <div className={'card-image'}>
                                                                            <img className={'circular_image'}
                                                                                 alt={'review'}
                                                                                 src={item.user.profile_picture !== null ? window.config.imgUrl + item.user.profile_picture : avatar}
                                                                                 style={{
                                                                                     borderRadius: '50%',
                                                                                     contain: 'fill',
                                                                                     height: '60px',
                                                                                     width: '60px'
                                                                                 }}/>
                                                                            <div className={'card-name-star'}>
                                                                            <span
                                                                                style={{fontSize: '14px'}}>{item.user.full_name}</span>
                                                                                <div className={'star'}>
                                                                                    {[...Array(5)].map((star, index) => {
                                                                                        index += 1;
                                                                                        return (
                                                                                            <button
                                                                                                type="button"
                                                                                                key={index}
                                                                                                className={index <= item.rating ? "on" : "off"}
                                                                                                onClick={() => this.setState({rating: index})}
                                                                                            >
                                                                <span style={{fontSize: '14px'}}
                                                                      className="star">&#9733;</span>
                                                                                            </button>
                                                                                        );
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className={'mb-4'}
                                                                             style={{textAlign: 'justify'}}
                                                                             dangerouslySetInnerHTML={{
                                                                                 __html: item.message,
                                                                             }}
                                                                        ></div>
                                                                    </div>
                                                                </CardBody>
                                                            </Card>
                                                        ))}
                                                    </div>
                                                </InfiniteScroll>
                                            </div>
                                        </div>
                                    </>
                                }
                            </> :
                            ''}
                    </>
                }
                {this.state.rateReview &&
                    <RatingReview
                        rateReview={this.state.rateReview}
                        toggleModal={this.toggleModal}
                        fetchRating={this.fetchRating}
                    />
                }
                {this.state.showShare &&
                    <ShowShareModal
                        showShare={this.state.showShare}
                        toggleModal={this.toggleModal}
                        shareItem={this.state.shareItem}
                    />

                }
            </React.Fragment>

        )
    }
}

export default Review
