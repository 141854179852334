/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter, Route, Switch, Redirect} from "react-router-dom";
import {SnackbarProvider} from "notistack";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import "assets/scss/video-call/base-chat.scss"
import MobXProvider from "./MobXProvider";
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import PublicLayout from 'layouts/PublicLayout'
import Profile from "./layouts/Profile";
import Link from './layouts/Link';
import './views/styles.css';
import './assets/fonts/font.css';
import './translations/i18n';
import { I18nextProvider } from 'react-i18next';
import i18n from "./translations/i18n";
import { SocketProvider } from  './layouts/SocketContext'

ReactDOM.render(
    <BrowserRouter>
        <I18nextProvider i18n={i18n}>
        <SnackbarProvider maxSnack={3}>
            <MobXProvider>
                
                <Switch>
                    <Route path="/admin" render={props => 
                     <SocketProvider> 
                        <AdminLayout {...props} />
                     </SocketProvider>  
                    }/>
                    <Route path="/auth" render={(props) => <AuthLayout {...props} />}/>
                    <Route path="/public" render={(props) => <PublicLayout {...props} />}/>
                    <Route path="/profile" render={(props) => <AdminLayout {...props} />}/>
                    <Route path="/:id" render={(props) => <Link {...props} />}/>
                    <Redirect from="/" to="/auth"/>
                </Switch>
            </MobXProvider>
        </SnackbarProvider>
        </I18nextProvider>
    </BrowserRouter>,
    document.getElementById("root")
);
