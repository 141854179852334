import React from 'react'
import {
    Button,
   
    Container,
   
} from "reactstrap";

import _ from "lodash";

import './styles.scss'
import Loading from "../../components/Loading/Loading";


const Services = require("../../RemoteServices/RemoteServices.js");

class Reward extends React.PureComponent {
    state = {
        reviewTag: '',
        rating: '',
        commentError: '',
        comment: '',
        showReply: false,
        reviewList: {
            count: '',
            next: '',
            results: []
        },
        rateReview: false,
        showShare: false,
        shareItem: '',
        loading: false,
    }

    handleCommentChange = (e) => {
        this.setState({comment: e.target.value});

    };

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            if (this.state.searchItem !== '') {
                this.searchContent(this.state.searchItem)
            }
        }
    }

    toggleReply(index) {
        this.setState((prevState) => ({
            showReply: prevState.showReply === index ? null : index,
        }));
    }

    toggleModal = (state) => {
        this.setState({
            [state]: !this.state[state],
        });
    };
    nextReview = async (nextUrl) => {
        try {
            const response = await Services.getNextReviewList(nextUrl);
            await this.setState((prevState) => ({
                reviewList: {
                    ...prevState.reviewList,
                    next: response.next,
                    results: [
                        ...prevState.reviewList.results,
                        ...response.results
                    ]
                }
            }))
        } catch (e) {

        }
    }

    fetchRating = async (tag) => {
        try {
            const response = await Services.getReviewList(tag);
            await this.setState({
                reviewList: response,
                loading: false,
            })
        } catch (e) {

        }

    }

    handleLikePress = (item) => {
        let newData = [...this.state.reviewList.results];
        const index = newData.findIndex((i) => i.id === item.id);
        if (JSON.parse(localStorage.getItem('user'))?.id === item.user.id) {
            if (item.have_i_Like) {
                newData[index].have_i_Like = false;
                newData[index].like_count = newData[index].like_count - 1;
                Services.addReviewLike({
                    have_i_Like: false,
                    id: newData[index].id,
                })
                    .then((res) => {
                        console.log("likeRes", res);
                    })
                    .catch((err) => {
                        console.log('asdfasdf', 'true')
                        console.log("err", err);
                    });
            } else {
                newData[index].have_i_Like = true;
                newData[index].like_count = newData[index].like_count + 1;
                Services.addReviewLike({
                    have_i_Like: true,
                    id: newData[index].id,
                })
                    .then((res) => {
                        console.log("likeRes", res);
                    })
                    .catch((err) => {
                        console.log('asdfasdf', 'false')
                        console.log("err", err);
                    });
            }
        } else {
            if (item.current_user_like_status) {
                newData[index].current_user_like_status = false;
                newData[index].like_count = newData[index].like_count - 1;
                Services.addReviewLike({
                    have_i_Like: false,
                    id: newData[index].id,
                })
                    .then((res) => {
                        console.log("likeRes", res);
                    })
                    .catch((err) => {
                        console.log('asdfasdfasdf', 'false')
                        console.log("err", err);
                    });
            } else {
                newData[index].current_user_like_status = true;
                newData[index].like_count = newData[index].like_count + 1;
                Services.addReviewLike({
                    have_i_Like: true,
                    id: newData[index].id,
                })
                    .then((res) => {
                        console.log("likeRes", res);
                    })
                    .catch((err) => {
                        console.log("err", err);
                    });
            }
        }

        this.setState({
            reviewList: {...this.state.reviewList, results: newData},
        });
    };
    handleReplyLikePress = (item) => {
        let newData = [...this.state.reviewList.results];
        console.log("newData", newData);
        const commentIndex = newData.findIndex((i) => i.id == item.review);
        if (commentIndex > -1) {
            const index = newData[commentIndex].app_reply.findIndex(
                (i) => i.id == item.id
            );

            if (JSON.parse(localStorage.getItem('user'))?.id === item.user.id) {
                if (item.have_i_Like) {
                    newData[commentIndex].app_reply[index].have_i_Like = false;
                    newData[commentIndex].app_reply[index].like_count =
                        newData[commentIndex].app_reply[index].like_count - 1;
                    Services.addReviewReplyLike({
                        have_i_Like: false,
                        reply_id: newData[commentIndex].app_reply[index].id,
                        auth_user: JSON.parse(localStorage.getItem('user'))?.id,
                    })
                        .then((res) => {
                            console.log("likeRes", res);
                        })
                        .catch((err) => {
                            console.log("err", err);
                        });
                } else {
                    newData[commentIndex].app_reply[index].have_i_Like = true;
                    newData[commentIndex].app_reply[index].like_count =
                        newData[commentIndex].app_reply[index].like_count + 1;
                    Services.addReviewReplyLike({
                        have_i_Like: true,
                        reply_id: newData[commentIndex].app_reply[index].id,
                        auth_user: JSON.parse(localStorage.getItem('user'))?.id,
                    })
                        .then((res) => {
                            console.log("likeRes", res);
                        })
                        .catch((err) => {
                            console.log("err", err);
                        });
                }
            } else {
                if (item.current_user_like_status) {
                    newData[commentIndex].app_reply[
                        index
                        ].current_user_like_status = false;
                    newData[commentIndex].app_reply[index].like_count =
                        newData[commentIndex].app_reply[index].like_count - 1;
                    Services.addReviewReplyLike({
                        have_i_Like: false,
                        reply_id: newData[commentIndex].app_reply[index].id,
                        auth_user: JSON.parse(localStorage.getItem('user'))?.id,
                    })
                        .then((res) => {
                            console.log("likeRes", res);
                        })
                        .catch((err) => {
                            console.log("err", err);
                        });
                } else {
                    newData[commentIndex].app_reply[
                        index
                        ].current_user_like_status = true;
                    newData[commentIndex].app_reply[index].like_count =
                        newData[commentIndex].app_reply[index].like_count + 1;
                    Services.addReviewReplyLike({
                        have_i_Like: true,
                        reply_id: newData[commentIndex].app_reply[index].id,
                        auth_user: JSON.parse(localStorage.getItem('user'))?.id,
                    })
                        .then((res) => {
                            console.log("likeRes", res);
                        })
                        .catch((err) => {
                            console.log("err", err);
                        });
                }
            }
        } else {
            console.warn("id not found");
        }

        this.setState({
            reviewList: {...this.state.reviewList, results: newData},
        });
    };

    handleShare = (item, isReply = false) => {
        this.setState({shareItem: item, showShare: true})
    };

    handleReviewReply = async (item) => {
        if (_.isEmpty(this.state.comment)) {
            console.log('Please Enter Comment')
        } else {
            this.setState({submitLoading: true});
            let split = "";
            if (!_.isEmpty(this.state.selectedReview)) {
                split = this.state.selectedReview.user.full_name.split(" ");
            }

            const data = {
                message: !_.isEmpty(this.state.selectedReview)
                    ? "@" + split[0] + " " + this.state.replyMessage
                    : this.state.replyMessage,
                have_i_Like: false,
                review: item.id,
                id: this.state.replyData.id,
            };
            if (this.state.replyData.isReply == "true") {
                await Services.updateReplyReview(data)
                    .then((response) => {
                        console.log("reviewReplyResponse", response);
                        this.setState({
                            submitLoading: false,
                            replyMessage: "",
                        });
                        const newData = [...this.state.reviewData.results];
                        const index = newData.findIndex((i) => i.id == item.id);
                        if (index > -1) {
                            const replyIndex = newData[index].app_reply.findIndex(
                                (i) => i.review == this.state.replyData.review
                            );
                            if (replyIndex > -1) {
                                newData[index].app_reply[replyIndex] = response;
                            }
                        }
                        this.setState({
                            reviewData: {...this.state.reviewData, results: newData},
                            selectedReview: {},
                            showModal: false,
                            showCommentModal: false,
                            submitLoading: false,
                            commentLoading: false,
                            replyData: {isReply: ""},
                            replyMessage: "",
                        });
                        // console.log(response)
                    })
                    .catch((error) => {
                        this.setState({submitLoading: false});
                        console.log("reviewError", error);
                    });
            } else {
                await Services.submitReviewReply(data)
                    .then((response) => {
                        this.setState({
                            submitLoading: false,
                            replyMessage: "",
                        });
                        const newData = [...this.state.reviewData.results];
                        const index = newData.findIndex((i) => i.id == item.id);
                        if (index > -1) {
                            newData[index].app_reply.unshift(response.data);
                        }
                        this.setState({
                            reviewData: {...this.state.reviewData, results: newData},
                            selectedReview: {},
                        });
                        // console.log(response)
                    })
                    .catch((error) => {
                        this.setState({submitLoading: false});
                        console.log("reviewError", error);
                    });
            }
        }
    };

    handleComment = async (item) => {
        await Services.appReviewReply({
            user: JSON.parse(localStorage.getItem('user'))?.id,
            message: this.state.comment,
            review: item.id,

        }).then((response) => {
            this.setState({comment: ''})
            this.fetchRating()

        }).catch(e => {

        });
    }

    handleCommentReplyKeyDown = (e, item) => {
        if (e.key === 'Enter') {
            this.handleComment(item);
        }
    }

    toggle = () => this.setState({
        dropdownOpen: !this.state.dropdownOpen,
    });


    async componentDidMount() {
        if (this.props.lesson && this.props.lesson === 'lesson') {
            this.fetchRating('revlevant')
        } else {
            this.setState({loading: true})
            this.fetchRating(this.state.reviewTag)
        }
    }

    render() {
        const {reviewList} = this.state;
        return (
            <React.Fragment>
                {!this.props.lesson && this.props.lesson !== 'lesson' ?
                    <>
                    
                        {!this.state.loading ?
                            <Container className={'container'} style={{minHeight: '600px'}}>

                                <div className={'mt-2'}
                                     style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                                    <h1>Congratulation!! Have a look on Your Reward</h1>
                                </div>

                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    marginTop: '25px'
                                }}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        padding: '10px',
                                        backgroundColor: 'white',
                                        width: '32%',
                                        boxShadow: '2px 1px 1px 1px purple',
                                        borderRadius: '5px',
                                        border: '1px solid purple',
                                        color: 'white',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            width: '100%',
                                            justifyContent: 'center',
                                            color: 'black',
                                            alignItems: 'flex-start'
                                        }}>
                                            <div className={'ml-3'} style={{
                                                backgroundColor: '#511B80',
                                                borderRadius: '5px',
                                                border: '1px solid white',
                                                color: 'white',
                                                padding: 5,
                                                textAlign: 'center',
                                                justifyContent: 'flex-start'
                                            }}>
                                                <span style={{textAlign: 'center'}}>First Line</span>
                                            </div>
                                            <span className={'mt-2 ml-3'}>Total Referrel: 30</span>
                                            <span className={'mt-2 ml-3'}>Paid Referrel: 30</span>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                width: '100%'
                                            }}>
                                                <span className={'mt-2 ml-3'}>Reward: Rs. 10,000.00</span>
                                                <span className={'mt-2'} style={{cursor:'pointer',color: '#511B80'}}>See More...</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        padding: '10px',
                                        backgroundColor: 'white',
                                        width: '32%',
                                        boxShadow: '2px 1px 1px 1px purple',
                                        borderRadius: '5px',
                                        border: '1px solid purple',
                                        color: 'white',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            width: '100%',
                                            justifyContent: 'center',
                                            color: 'black',
                                            alignItems: 'flex-start'
                                        }}>
                                            <div className={'ml-3'} style={{
                                                backgroundColor: '#511B80',
                                                borderRadius: '5px',
                                                border: '1px solid white',
                                                color: 'white',
                                                padding: 5,
                                                textAlign: 'center',
                                                justifyContent: 'flex-start'
                                            }}>
                                                <span style={{textAlign: 'center'}}>Second Line</span>
                                            </div>
                                            <span className={'mt-2 ml-3'}>Total Referrel: 30</span>
                                            <span className={'mt-2 ml-3'}>Paid Referrel: 30</span>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                width: '100%'
                                            }}>
                                                <span className={'mt-2 ml-3'}>Reward: Rs. 10,000.00</span>
                                                <span className={'mt-2'} style={{cursor:'pointer',color: '#511B80'}}>See More...</span>

                                            </div>
                                        </div>
                                    </div>

                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        padding: '10px',
                                        backgroundColor: 'white',
                                        width: '32%',
                                        boxShadow: '2px 1px 1px 1px purple',
                                        borderRadius: '5px',
                                        border: '1px solid purple',
                                        color: 'white',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            width: '100%',
                                            justifyContent: 'center',
                                            color: 'black',
                                            alignItems: 'flex-start'
                                        }}>
                                            <div className={'ml-3'} style={{
                                                backgroundColor: '#511B80',
                                                borderRadius: '5px',
                                                border: '1px solid white',
                                                color: 'white',
                                                padding: 5,
                                                textAlign: 'center',
                                                justifyContent: 'flex-start'
                                            }}>
                                                <span style={{textAlign: 'center'}}>Third Line</span>
                                            </div>
                                            <span className={'mt-2 ml-3'}>Total Referrel: 30</span>
                                            <span className={'mt-2 ml-3'}>Paid Referrel: 30</span>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                width: '100%',
                                                alignItems:''
                                            }}>
                                                <span className={'mt-2 ml-3'}>Reward: Rs. 10,000.00</span>
                                                <span className={'mt-2'} style={{cursor:'pointer',color: '#511B80'}}>See More...</span>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={'mt-5'}
                                     style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                                    <h1>Total Earning: Rs 20,000.00</h1>
                                </div>

                                <div className={'mt-2'}
                                     style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                                    <h2>Note: You Will be able to withdraw earning when your earning reaches 10k.</h2>
                                </div>

                                <div className={'mt-2'}
                                     style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                                    <Button className={'all-btn'}><i className={'fa fa-money-bill'}/>&nbsp; Withdraw Money</Button>
                                </div>

                            </Container>
                            :
                            <>
                                <Loading/>
                            </>
                        }
                    </> :
                    ''
                }
            </React.Fragment>

        )
    }
}

export default Reward
