import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Chip,
  Container,
  Grid,
  CardActions,
  Box,
  CircularProgress,
} from "@material-ui/core";
import { Check, Link, People, VideoCall } from "@material-ui/icons";

import { makeStyles } from "@material-ui/core";
import RemoteServices from "RemoteServices/RemoteServices";
import EmptyMeetingScreen from "./EmptyMeetingScreen";

import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(4),
  },
  card: {
    margin: "auto",
    padding: "1rem",
    marginTop: theme.spacing(2),
    alignSelf: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  meetingId: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(0.5),
  },
  icon: {
    marginRight: theme.spacing(1),
    fontSize: "0.9rem",
    alignSelf: "start",
  },
  content: {
    paddingTop: 0,
  },
  infoRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  readyToJoin: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.main,
  },
  joinButton: {
    marginLeft: "auto",
    padding: "0.5rem auto",
  },
}));

const MeetingCards = () => {
  const classes = useStyles();
  const [meetings, setMeetings] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [refetch, setRefetch] = React.useState(false);

  React.useEffect(() => {
    async function fetchMeetings() {
      try {
        setIsLoading(true);
        const data = await RemoteServices.getMeetingLinks();
        if (data) {
          setMeetings(data);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
        setRefetch(false);
      }
    }
    fetchMeetings();
  }, [refetch]);

  return (
    <Container className={classes.root}>
      <Typography
        variant="h4"
        component="h1"
        align="center"
        className={classes.title}
      >
        Available Meeting Rooms
      </Typography>
      <Grid container spacing={3}>
        {isLoading ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress size={20} />
          </Box>
        ) : meetings & meetings?.length ? (
          <EmptyMeetingScreen />
        ) : (
          meetings?.map((meeting, index) => (
            <Grid item xs={6} key={index}>
              <MeetingCard meeting={meeting} />
            </Grid>
          ))
        )}
      </Grid>
    </Container>
  );
};

const MeetingCard = ({ meeting }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleMeetingRedirect = () => {
    history.push(`/admin/meet?ready=${meeting.meeting_id}`);
  };

  return (
    <Card className={classes.card} elevation={2}>
      <CardContent>
        <div className={classes.header}>
          <div>
            <Typography variant="h5" component="h2">
              Meeting Room {meeting.id}
            </Typography>
            <Typography variant="body2" className={classes.meetingId}>
              <Link className={classes.icon} />
              {meeting.meeting_id}
            </Typography>
          </div>
          <Chip label={"Available"} color={"primary"} size="small" />
        </div>
        <div className={classes.content}>
          <div className={classes.infoRow}>
            <Typography variant="body2" color="textSecondary">
              <People className={classes.icon} />0 participants
            </Typography>
            <Typography variant="body2" color="textSecondary">
              12:00 PM • 1 hour
            </Typography>
          </div>
        </div>
      </CardContent>
      <CardActions
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="body2" className={classes.readyToJoin}>
          <Check className={classes.icon} />
          Ready to join
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<VideoCall />}
          onClick={handleMeetingRedirect}
          className={classes.joinButton}
        >
          Join Meeting
        </Button>
      </CardActions>
    </Card>
  );
};

export default MeetingCards;
