import React from 'react';

function DateTimeAgo({ dateTime }) {
    const getTimeAgo = () => {
        const currentDate = new Date();
        const pastDate = new Date(dateTime);
        const timeDifference = currentDate.getTime() - pastDate.getTime();

        const minutes = Math.floor(timeDifference / (1000 * 60));
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        if (minutes < 60) {
            return `${minutes} minutes ago`;
        } else if (hours < 24) {
            return `${hours} hours ago`;
        } else {
            return `${days} days ago`;
        }
    };

    return <span className={'m-0 p-0'}>{getTimeAgo()}</span>;
}

export default DateTimeAgo;