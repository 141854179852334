/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {withSnackbar} from "notistack";

// reactstrap components
import {Button, Card, CardBody, Col, InputGroup, InputGroupAddon, InputGroupText} from "reactstrap";
import {AvForm, AvGroup, AvInput} from "availity-reactstrap-validation";

import Services from "../../RemoteServices/RemoteServices";


class ChangePassword extends React.Component {
    state = {
        createAccount: false,
        referral_code: '',
        disabled: false,
    }


    handleInvalidSubmit = (event, errors) => {
        this.props.enqueueSnackbar(`Password must be at least 5 characters long`, {
            variant: "error",
            anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
            },
        });
    };



    handleSubmit = async (event, values) => {
        event.preventDefault();
        this.setState({createAccount:true})
        let reqDto = {
            email: this.props.location.state.email,
            otp:this.props.location.state.otp,
            password1: values.password1,
            password2: values.password2,
        }
        if (values.password1 !== values.password2) {
            this.setState({snackBar: true, loggingIn: false});
            this.props.enqueueSnackbar("Password Doesnot Match", {
                variant: "error",
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                },
            });
        } else {
            await this.setState({createAccount: true});
            await Services.changeOtpPassword(reqDto)
                .then((response) => {
                    this.setState({createAccount:false})
                    this.props.history.push("/auth/login");
                   
                })
                .catch((error) => {
                    this.setState({loggingIn: false, createAccount: false});
                    this.props.enqueueSnackbar(error.message, {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                    });

                });
        }
    }


    render() {
        return (
            <>
                <Col lg="6" md="7" style={{ minHeight: "calc(100vh - 76px - 118px)" }}>
                    <Card className="bg-secondary shadow border-0">
                        <CardBody className="px-lg-5 py-lg-5 text-center">
                            <img alt="..." src={require("assets/img/brand/argon-logo.png")}/>
                            <div className="text-center text-muted mb-4 mt-4">
                                <small>
                                    Enter Your Password
                                </small>
                            </div>
                            <AvForm
                                onValidSubmit={this.handleSubmit}
                                onInvalidSubmit={this.handleInvalidSubmit}
                            >
                                <AvGroup>
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-lock-circle-open"/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <AvInput
                                            placeholder="Password"
                                            type="password"
                                            name="password1"
                                            validate={{ minLength: { value: 5, errorMessage: 'Password must be at least 5 characters long' } }}
                                            required
                                        />
                                    </InputGroup>
                                </AvGroup>
                                <AvGroup>
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-lock-circle-open"/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <AvInput
                                            placeholder="Re-enter Password"
                                            type="password"
                                            validate={{ minLength: { value: 5, errorMessage: 'Password must be at least 5 characters long' } }}
                                            name="password2"
                                        />
                                    </InputGroup>
                                </AvGroup>
                                <div className="text-center">
                                    <Button className="my-4" color="primary">
                                        {this.state.createAccount ? "Submitting ..." : "Change Password"}
                                    </Button>
                                </div>
                            </AvForm>
                        </CardBody>
                    </Card>
                </Col>

            </>
        );
    }
}

export default withSnackbar(ChangePassword);
