import {makeAutoObservable, flow} from "mobx";
import {map, filter} from "lodash";

const Services = require("RemoteServices/RemoteServices.js");

class MobXStore {
    reviewsList = {};
    commentFormOpen = false;
    commentContent = {};
    lessonId={};

    constructor() {
        makeAutoObservable(
            this,
            {
                getReviewsList: flow,
                getFreeReviewsList:flow,
                getFilterFreeReviewList:flow.bound,
                likeReview: flow.bound,
                deleteReview: flow.bound,
                deleteReply: flow.bound,
                editReview: flow.bound,
                editReply: flow.bound,
                replyReview: flow.bound,
                likeFreeReview: flow.bound,
                deleteFreeReview: flow.bound,
                deleteFreeReply: flow.bound,
                editFreeReview: flow.bound,
                editFreeReply: flow.bound,
                replyFreeReview: flow.bound,
            },
            {autoBind: true}
        );
    }

    openCommentForm(comment,lesson) {
        this.commentFormOpen = true;
        this.commentContent = comment;
        this.lessonId=lesson

    }

    closeCommentForm() {
        this.commentFormOpen = false;
        this.commentContent = {};
    }

    * replyReview(comment) {
        yield Services.replyReview({...this.commentContent, ...comment});
        try {
            const response = yield Services.reviewsList(comment.lesson, comment.offset,'-id');
            if (comment.offset === 0) {
                this.reviewsList = response;
            } else {
                this.reviewsList.results = [
                    ...this.reviewsList.results,
                    ...response.results,
                ];
            }
        } catch (error) {
            console.error(error);
        }

        this.closeCommentForm();
    }

    * editReview(comment) {
        const response = yield Services.editReview(comment);
        this.reviewsList.results = map(this.reviewsList.results, (result) =>
            result.id === comment.id ? response : result
        );
        this.closeCommentForm();
    }

    * editReply(comment) {
        yield Services.editReply(comment);
        this.closeCommentForm();
    }

    * getReviewsList(lesson, offset,tag) {
        try {
            const response = yield Services.reviewsList(lesson, offset,tag);
            if (offset === 0) {
                this.reviewsList = response;
            } else {
                this.reviewsList.results = [
                    ...this.reviewsList.results,
                    ...response.results,
                ];
            }
        } catch (error) {
            console.error(error);
        }
    }

    * likeReview({id, reply_id, have_i_Like, review, auth_user,current_user_like_status}) {
        console.log('aaaa',have_i_Like,current_user_like_status)
        try {
            yield Services.likeReview({
                id,
                reply_id,
                have_i_Like,
                auth_user,
                current_user_like_status,
            });
            this.reviewsList.results = map(this.reviewsList.results, (result) =>
                result.id === id || review
                    ? {
                        ...(review
                            ? {
                                ...result,
                                reply: map(result.reply, (reply) =>
                                    reply.id === reply_id
                                        ? {
                                            ...reply,
                                            current_user_like_status,
                                            like_count: current_user_like_status
                                                ? (reply.like_count += 1)
                                                : (reply.like_count -= 1),
                                        }
                                        : reply
                                ),
                            }
                            : {
                                ...result,
                                current_user_like_status,
                                like_count: current_user_like_status
                                    ? (result.like_count += 1)
                                    : (result.like_count -= 1),
                            }),
                    }
                    : result
            );
        } catch (error) {
            console.error(error);
        }
    }

    * deleteReview(id) {
        try {
            yield Services.deleteReview(id);
            this.reviewsList.results = filter(
                this.reviewsList.results,
                (result) => result.id !== id
            );
        } catch (error) {
            console.error(error);
        }
    }

    * deleteReply({id, review}) {
        try {
            yield Services.deleteReply(id);
            this.reviewsList.results = map(this.reviewsList.results, (result) =>
                result.id === review
                    ? {
                        ...result,
                        reply: filter(result.reply, (reply) => reply.id !== id),
                    }
                    : result
            );
        } catch (error) {
            console.error(error);
        }
    }

    //freee REviews

    * getFreeReviewsList(lesson, offset) {
        try {
            const response = yield Services.freeReviewsList(lesson, offset);
            if (offset === 0) {
                this.reviewsList = response;
            } else {
                this.reviewsList.results = [
                    ...this.reviewsList.results,
                    ...response.results,
                ];
            }
        } catch (error) {
            console.error(error);
        }
    }

    *getFilterFreeReviewList(lesson,offset,filter){
        try{
            const response=yield Services.freeFilterReviewsList(lesson,offset,filter);
            if (offset === 0) {
                this.reviewsList = response;
            } else {
                this.reviewsList.results = [
                    ...this.reviewsList.results,
                    ...response.results,
                ];
            }
        } catch (error) {
            console.error(error);
        }
    }


    * likeFreeReview({id, reply_id, have_i_Like, review, auth_user,status,current_user_like_status}) {
        try {
            yield Services.freeLikeReview({
                id,
                reply_id,
                have_i_Like,
                auth_user,
                status,
                current_user_like_status
            });
            this.reviewsList.results = map(this.reviewsList.results, (result) =>
                result.id === id || review
                    ? {
                        ...(review
                            ? {
                                ...result,
                                reply: map(result.reply, (reply) =>
                                    reply.id === reply_id
                                        ? {
                                            ...reply,
                                            current_user_like_status,
                                            like_count: current_user_like_status
                                                ? (reply.like_count += 1)
                                                : (reply.like_count -= 1),
                                        }
                                        : reply
                                ),
                            }
                            : {
                                ...result,
                                current_user_like_status,
                                like_count: current_user_like_status
                                    ? (result.like_count += 1)
                                    : (result.like_count -= 1),
                            }),
                    }
                    : result
            );
        } catch (error) {
            console.error(error);
        }
    }

    * deleteFreeReview(id) {
        try {
            yield Services.deleteFreeReview(id);
            this.reviewsList.results = filter(
                this.reviewsList.results,
                (result) => result.id !== id
            );
        } catch (error) {
            console.error(error);
        }
    }

    * deleteFreeReply({id, review}) {
        try {
            yield Services.deleteFreeReply(id);
            this.reviewsList.results = map(this.reviewsList.results, (result) =>
                result.id === review
                    ? {
                        ...result,
                        reply: filter(result.reply, (reply) => reply.id !== id),
                    }
                    : result
            );
        } catch (error) {
            console.error(error);
        }
    }

    * freeReplyReview(comment) {
        yield Services.freeReplyReview({...this.commentContent, ...comment});
        try {
            const response = yield Services.freeReviewsList(comment.lesson, comment.offset);
            if (comment.offset === 0) {
                this.reviewsList = response;
            } else {
                this.reviewsList.results = [
                    ...this.reviewsList.results,
                    ...response.results,
                ];
            }
        } catch (error) {
            console.error(error);
        }
        this.closeCommentForm();
    }

    * freeEditReview(comment) {
        console.log('asasa',comment)
        yield Services.editFreeReview(comment);
        try {
            const response = yield Services.freeReviewsList(comment.lesson, comment.offset);
            if (comment.offset === 0) {
                this.reviewsList = response;
            } else {
                this.reviewsList.results = [
                    ...this.reviewsList.results,
                    ...response.results,
                ];
            }
        } catch (error) {
            console.error(error);
        }
        this.closeCommentForm();
    }

    * freeEditReply(comment) {
        yield Services.editFreeReply(comment);
        try {
            const response = yield Services.freeReviewsList(this.lessonId, comment.offset);
            if (comment.offset === 0) {
                this.reviewsList = response;
            } else {
                this.reviewsList.results = [
                    ...this.reviewsList.results,
                    ...response.results,
                ];
            }
        } catch (error) {
            console.error(error);
        }
        this.closeCommentForm();
    }
}

export default MobXStore;
