import { Container, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
    gap: theme.spacing(3),
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: "#333",
    color: "#fff",
  },
}));

const CallUs = () => {
  const classes = useStyles();
  return (
    <Container maxWidth={"xl"} className={classes.container}>
      CallUs
    </Container>
  );
};

export default CallUs;
