/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
// import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import Esewa from "components/Payment/esewa";
import routes from "routes.js";
// import { hasAccess } from "variables/utils.js";

class Profile extends React.Component {
  // constructor(props) {
  //   super(props);

    // if (window.performance) {
    //   if (performance.navigation.type == 1) {
    //     this.props.history.push('/foo')

    //   } else {
    //     alert( "This page is not reloaded");
    //   }
    // }
  // }

  componentDidMount() {}

  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }
  getRoutes = (routes) => {
    // eslint-disable-next-line array-callback-return
    return routes.map((prop, key) => {
      if (localStorage.getItem("user")) {
        // let userType = JSON.parse(localStorage.getItem("user")).user_type;
        if (prop.layout === "/profile") {
          // if (hasAccess(prop.user, userType) && userType){
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
          // } else {
          //   this.props.history.push('/auth/login')
          // }
        } else {
          return null;
        }
      } else {
        this.props.history.push("/auth/login");
      }
    });
  };
  getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return null;
  };
  render() {
    return (
      <>
        <Sidebar
          {...this.props}
          routes={routes}
          logo={{
            innerLink: "/admin/dashboard",
            imgSrc: require("assets/img/brand/argon-logo.png"),
            imgAlt: "...",
          }}
        />
        <div className="main-content" ref="mainContent">
          <Switch>
            {this.getRoutes(routes)}
          </Switch>
          {/*<AdminFooter />*/}
        </div>
      </>
    );
  }
}

export default Profile;
