import React from 'react';
import {withSnackbar} from "notistack";
import {Button, Modal} from "reactstrap";
import Services from "../../RemoteServices/RemoteServices";
import MaterialTable from 'material-table';
import Sort from "../../assets/img/sort.png";
import avatar from '../../assets/img/theme/avatar.png'
import {withRouter} from "react-router-dom";

class FollowersModal extends React.Component {
    state = {
        createAccount: false,
        userDetails: '',
        showFilter: false,
    }
    showUserProfile = (id) => {
        this.props.history.push(`/profile/otherProfile/${id}`);
    }

    followUser = (id) => {
        const reqDto = {
            follower: JSON.parse(localStorage.getItem('user'))?.id,
            following: id,
            status: false
        }
        Services.followUsers(reqDto).then((response) => {
        }).catch(e => {
            throw e
        })
    }

    handleSubmit = async (event, values) => {
        event.preventDefault();
        await this.setState({createAccount: true});
        await Services.updateStaff(this.state.userDetails.id, values)
            .then((response) => {
                localStorage.setItem("user", JSON.stringify(response))
                this.props.toggleModal("updateProfile")
            })
            .catch((error) => {
                this.setState({loggingIn: false});
            });
    }
    handleInvalidSubmit = (event, errors) => {
        this.props.enqueueSnackbar(`Please enter ${errors.join(" and ")}`, {
            variant: "error",
            anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
            },
        });
    };

    async componentDidMount() {
    }

    toggleModal = (state) => {
        this.setState({
            [state]: !this.state[state],
        });
    };

    render() {
        return (
            <Modal
                keyboard={false}
                backdrop={'static'}
                className="modal-dialog-centered modal-md"
                isOpen={this.props.showReferral}
                toggle={() => this.props.toggleModal("showFollowers")}
            >
                <div className="modal-header">
                    <h2 className="modal-title" id="modal-title-default">
                        Followers List
                    </h2>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.props.toggleModal('showFollowers')}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className={'modal-body'}>
                    {this.props?.followingList?.length ?
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            marginBottom: '10px',
                            width: '100%'
                        }}>
                            {this.props.followingList && this.props.followingList?.map((item, index) => {
                                return (
                                    <>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            gap: '10px',
                                            justifyContent: 'space-between',
                                            marginBottom: '10px',
                                            alignItems: 'center',
                                        }} key={index}>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                gap: '10px',
                                                justifyContent: 'flex-start',
                                                alignItems: 'center'
                                            }}>
                                                <img
                                                    onClick={() => this.showUserProfile(item.follower.id)}
                                                    style={{cursor: 'pointer'}}
                                                    height={50}
                                                    width={50}
                                                    alt="..."
                                                    className="rounded-circle"
                                                    src={item.follower.profile_picture || avatar}
                                                />
                                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                            <span className={'link-span'}
                                                  onClick={() => this.showUserProfile(item.follower.id)}
                                                  style={{
                                                      fontSize: '18px',
                                                      fontWeight: 'bold'
                                                  }}>{item.follower.full_name}</span>
                                                    <span>Followers:{item.follower.follower_count}</span>
                                                </div>
                                            </div>
                                            <Button style={{
                                                margin: '0px',
                                                padding: '5px',
                                                backgroundColor: 'purple',
                                                color: 'white'
                                            }}
                                                    onClick={() => this.followUser(item.following.id)}
                                                    className={'btn btn-sm'}>Unfollow</Button>
                                        </div>
                                    </>
                                )
                            })
                            }

                        </div> :
                        <div>
                            <span>No Followers Found</span>
                        </div>
                    }

                </div>
            </Modal>
        )

    }
}

export default withRouter(withSnackbar(FollowersModal))