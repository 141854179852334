import React from 'react'
import './community.css'
import Services from "../../RemoteServices/RemoteServices";
import Loading from "../../components/Loading/Loading";

class Community extends React.PureComponent {
    state = {
        communityList: [],
        peopleList: [],
        loading: true,
        showAdd: false,
    }

    toggleState = (state) => {
        this.setState({
            [state]: !this.state[state],
        });
    };

    getCommunity = async (url) => {
        if (url) {
            Services.getCommunity(url).then((response) => {
                this.setState((prevState) => ({
                    ...prevState,
                    communityList: {
                        results: [...prevState.communityList.results, ...response.results]
                    }
                }));
            }).catch(e => {
                throw e
            })
        } else {
            Services.getCommunity('community/').then((response) => {
                this.setState({
                    loading: false,
                    communityList: response,
                });
            }).catch(e => {
                throw e
            })
        }
    }

    getPeopleYouMayKnow = async () => {
        Services.referredUser().then((response) => {
            this.setState((prevState) => ({
                ...prevState,
                peopleList: response,
            }));
        }).catch(e => {
            throw e
        })
    }


    componentDidMount() {
        this.getCommunity();
        this.getPeopleYouMayKnow();
    }

    render() {
        return (
            <React.Fragment>
                {!this.state.loading ?
                    <div fluid style={{
                        paddingLeft: '10px',
                        paddingRight: '10px',
                        minHeight: "calc(100vh - 76px - 118px)"
                    }}>
                        <div className={'community'}> Join our community to connect with English learners.</div>
                        <div className="loading">
                            <span> <strong>Hold tight! We're building something amazing for you...</strong></span>
                        </div>
                
                    </div>
                    :
                    <>
                        <Loading/>
                    </>
                }
            </React.Fragment>

        )
    }
}

export default Community
