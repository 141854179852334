import React, { memo, useContext, useState } from "react";
import { Modal, Input, FormFeedback, Button } from "reactstrap";
import { observer } from "mobx-react";
import { MobXContext } from "MobXProvider";

const CommentForm = (props) => {
  const {
    commentContent,
    closeCommentForm,
    editReview,
    editReply,
    replyReview,
  } = useContext(MobXContext);
  const [commentError, setCommentError] = useState(false);
  const [message, setMessage] = useState(() => commentContent.message);
  const handleComment = () => {
    if (message.length>=25) {
      setCommentError(false);
      if (commentContent.review && !commentContent.lesson) {
        editReply({
          id: commentContent.id,
          lesson: commentContent.lesson,
          user: commentContent.user.id,
          review: commentContent.review,
          message,
        });
      } else if (commentContent.id) {
        editReview({
          id: commentContent.id,
          lesson: commentContent.lesson,
          user: commentContent.user.id,
          message,
        });
      } else {
        replyReview(
        {offset:0,lesson:commentContent.lesson, message });
      }
    } else {
      setCommentError(true);
    }
  };
  return (
    <>
      <Modal
        className="modal-dialog-centered modal-lg"
        isOpen
        toggle={closeCommentForm}
        width="100%"
      >
        <div className="modal-header">
          <h2 className="modal-title" id="modal-title-default">
            Comment
          </h2>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={closeCommentForm}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Input
            invalid={commentError}
            type="textarea"
            name="text"
            rows={4}
            style={{ minHeight: 106 }}
            value={message}
            onChange={({ target: { value } }) => setMessage(value)}
          />
          {commentError && <FormFeedback>Your comment must be at least 25 characters.
          </FormFeedback>}
        </div>
        <div className="modal-footer">
          <Button color="primary" type="button" onClick={handleComment}>
            Comment
          </Button>
          <Button
            color="secondary"
            data-dismiss="modal"
            type="button"
            onClick={closeCommentForm}
          >
            Close
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default memo(observer(CommentForm));
