import React, {useState} from 'react';
import {Button, Input, Label, Modal} from "reactstrap";
import './rating.css'

const Services = require("../../RemoteServices/RemoteServices.js");


const RatingReview = (props) => {
    const [rating, setRating] = useState(5);
    const [comment, setComment] = useState('');
    const [commentError,setCommentError]=useState(false);

    // Function to handle star rating selection
    const handleRating = (value) => {
        console.log('asdfasdf',value)
        setRating(value === rating ? 0 : value);
    };

    // Function to handle comment input
    const handleComment = (event) => {
        setComment(event.target.value);
    };

    // Function to handle form submission
    const handleSubmit =async (event) => {
        const data = {
            message: comment,
            rating: rating,
            user: JSON.parse(localStorage.getItem('user'))?.id,
        };
        if(comment.length>=25) {
            await Services.submitReview(data)
                .then((response) => {
                    props.toggleModal('rateReview');
                })
                .catch((error) => {
                    console.log("reviewError", error);
                });
        }else{
            setCommentError(true)
        }

    };

    // Generate star icons for rating selection
    const renderStars = () => {
        const stars = [];
        for (let i = 1; i <= 5; i++) {
            const starClass = i <= rating ? 'fa fa-star active' : 'fa fa-star';
            stars.push(
                <i
                    key={i}

                    className={starClass}
                    style={{fontSize:'24px',marginLeft:'5px'}}
                    onClick={() => handleRating(i)}
                ></i>
            );
        }
        return stars;
    };

    return (
        <Modal
            keyboard={false}
            backdrop={'static'}
            className="modal-dialog-centered modal-lg"
            isOpen={props.rateReview}
            toggle={() => props.toggleModal("rateReview")}
        >
            <div className="modal-header">
                <h2 className="modal-title" id="modal-title-default">
                    Rate Us
                </h2>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => props.toggleModal('rateReview')}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className={'modal-body'} style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                <div className="rating-comment">
                    <Label className={'font-weight-bold mb-4'}>Rate By Clicking Stars</Label>
                    <div className="stars">{renderStars()}</div>
                    <Label className={'font-weight-bold'}>Write A Review</Label>
                    <Input
                        style={{border:commentError?'1px solid red':''}}
                        type={'textarea'}
                        row={3}
                        value={comment}
                        onChange={handleComment}
                        placeholder="Add a review..."
                    ></Input>
                    {commentError && <span
                        style={{textAlign: 'center', color: 'red', fontSize: '14px'}}>Your review must be at least 25 characters.</span>}

                </div>
            </div>
            <div className={'modal-footer'}>
                <Button disabled={!rating||!comment} onClick={() => handleSubmit()}
                        className={'float-right btn btn-success'}>Submit</Button>
                <Button onClick={() => props.toggleModal('rateReview')}
                        className={'float-right btn btn-danger'}>Close</Button>
            </div>
        </Modal>
    );
};

export default RatingReview;
