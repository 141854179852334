import React from 'react';
import {withSnackbar} from "notistack";
import {AvForm, AvGroup, AvInput} from "availity-reactstrap-validation";
import {Button, InputGroup, InputGroupAddon, InputGroupText, Modal} from "reactstrap";
import Services from "../../RemoteServices/RemoteServices";


class UserUpdateModal extends React.Component {
    state = {
        createAccount: false,
        userDetails: '',
    }

    handleSubmit = async (event, values) => {
        event.preventDefault();
        await this.setState({createAccount: true});
        await Services.updateStaff(this.state.userDetails.id, values)
            .then((response) => {
                localStorage.setItem("user", JSON.stringify(response))
                this.props.toggleModal("updateProfile")
            })
            .catch((error) => {
                this.setState({loggingIn: false,createAccount:false});
                this.props.enqueueSnackbar(error.message, {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                });
            });
    }
    handleInvalidSubmit = (event, errors) => {
        this.props.enqueueSnackbar(`Please enter ${errors.join(" and ")}`, {
            variant: "error",
            anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
            },
        });
    };

    async componentDidMount() {
        await this.setState(prevState=>({
            ...prevState,
            updateProfile:this.props.updateProfile
        }))
        const userDetail = JSON.parse(localStorage.getItem('user'));
        console.log('adsfasdf',userDetail)
        this.setState({
            userDetails: userDetail
        })

    }

    toggleModal = (state) => {
        this.setState({
            [state]: !this.state[state],
        });
    };

    render() {
        const {userDetails} = this.state;
        return (
            <Modal
                keyboard={false}
                backdrop={'static'}
                className="modal-dialog-centered modal-md"
                isOpen={this.props.updateProfile}
                toggle={() => this.props.toggleModal("updateProfile")}
            >
                <div className="modal-header">
                    <h2 className="modal-title" id="modal-title-default">
                        Update Your Profile
                    </h2>
                    {this.props.type === 'Header' || this.props.type==='Profile' ?
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => this.props.toggleModal('updateProfile')}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                        :
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            // onClick={() => this.props.toggleModal('updateProfile')}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    }
                </div>
                <div className={'modal-body'}>
                    <AvForm
                        onValidSubmit={this.handleSubmit}
                        onInvalidSubmit={this.handleInvalidSubmit}
                    >
                        <AvGroup className="mb-3">
                            <InputGroup className="input-group-alternative">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="fa fa-user"/>
                                    </InputGroupText>
                                </InputGroupAddon>
                                <AvInput
                                    placeholder="Full Name"
                                    type="text"
                                    name="full_name"
                                    value={userDetails.full_name}
                                    required
                                    autoFocus
                                />
                            </InputGroup>
                        </AvGroup>

                        <AvGroup className="mb-3">
                            <InputGroup className="input-group-alternative">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="ni ni-email-83"/>
                                    </InputGroupText>
                                </InputGroupAddon>
                                <AvInput
                                    placeholder="Email"
                                    type="email"
                                    name="email"
                                    disabled
                                    value={userDetails.email}
                                    required
                                    autoFocus
                                />
                            </InputGroup>
                        </AvGroup>

                        <AvGroup>
                            <InputGroup className='input-group-alternative mb-3' style={{background: '#ffffff'}}>
                                <InputGroupAddon addonType='prepend'>
                                    <InputGroupText>
                                        <i className='fa fa-mars'/>
                                    </InputGroupText>
                                </InputGroupAddon>
                                <div><AvInput type='select' name='gender' value={userDetails.gender}>
                                    <option value='' hidden>Select Gender</option>
                                    <option value={'Male'}>Male</option>
                                    <option value={'Female'}>Female</option>
                                    <option value={'Other'}>Other</option>
                                </AvInput>
                                </div>
                                <InputGroupAddon addonType='prepend' className={'ml-4'}>
                                    <InputGroupText>
                                        <i className='fa fa-birthday-cake'/>&nbsp;&nbsp;
                                        DOB
                                    </InputGroupText>
                                </InputGroupAddon>
                                <AvInput value={userDetails.dob} type={'date'} min={'1940-01-01'} max={'2022-01-01'} name={'dob'}/>
                            </InputGroup>
                        </AvGroup>


                        {/*<AvGroup>*/}
                        {/*  <InputGroup className="input-group-alternative">*/}
                        {/*    <InputGroupAddon addonType="prepend">*/}
                        {/*      <InputGroupText>*/}
                        {/*        <i className="ni ni-lock-circle-open"/>*/}
                        {/*      </InputGroupText>*/}
                        {/*    </InputGroupAddon>*/}
                        {/*    <AvInput*/}
                        {/*        placeholder="Password"*/}
                        {/*        type="password"*/}
                        {/*        name="password1"*/}
                        {/*        required*/}
                        {/*    />*/}
                        {/*  </InputGroup>*/}
                        {/*</AvGroup>*/}

                        {/*<AvGroup>*/}
                        {/*  <InputGroup className="input-group-alternative">*/}
                        {/*    <InputGroupAddon addonType="prepend">*/}
                        {/*      <InputGroupText>*/}
                        {/*        <i className="ni ni-lock-circle-open"/>*/}
                        {/*      </InputGroupText>*/}
                        {/*    </InputGroupAddon>*/}
                        {/*    <AvInput*/}
                        {/*        placeholder="Re-enter Password"*/}
                        {/*        type="password"*/}
                        {/*        name="password2"*/}
                        {/*    />*/}
                        {/*  </InputGroup>*/}
                        {/*</AvGroup>*/}

                        <AvGroup>
                            <InputGroup className="input-group-alternative">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="fa fa-phone"/>
                                    </InputGroupText>
                                </InputGroupAddon>
                                <AvInput
                                    placeholder="Phone Number"
                                    type="text"
                                    name="phone_number"
                                    value={userDetails?.phone_number}
                                    required
                                />
                            </InputGroup>
                        </AvGroup>
                        {this.props.type!=='Header'&&
                        <AvGroup>
                            <InputGroup className="input-group-alternative">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="fa fa-user"/>
                                    </InputGroupText>
                                </InputGroupAddon>
                                <AvInput
                                    placeholder="Referral Code"
                                    type="referralCode"
                                    name="referral_code"
                                    value={this.state.referral_code}
                                />
                            </InputGroup>
                        </AvGroup>
                        }
                        <AvGroup>
                            <InputGroup className="input-group-alternative">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <span>Bio</span>
                                    </InputGroupText>
                                </InputGroupAddon>
                                <AvInput
                                    maxLength={'100'}
                                    type="text"
                                    name="bio"
                                    value={userDetails?.bio}
                                    requiredbio
                                />
                            </InputGroup>
                        </AvGroup>
                        {/*<AvGroup>*/}
                        {/*  <InputGroup className="input-group-alternative">*/}
                        {/*    <InputGroupAddon addonType="prepend">*/}
                        {/*      <InputGroupText>*/}
                        {/*        <i className="fa fa-user"/>*/}
                        {/*      </InputGroupText>*/}
                        {/*    </InputGroupAddon>*/}
                        {/*    <AvInput*/}
                        {/*        placeholder="Referral Code"*/}
                        {/*        type="referralCode"*/}
                        {/*        name="referralCode"*/}
                        {/*    />*/}
                        {/*  </InputGroup>*/}
                        {/*</AvGroup>*/}

                        <div className="text-center">
                            <Button disabled={this.state.createAccount} className="my-4" color="primary">
                                {this.state.createAccount ? "Updating ..." : "Update Your Profile"}
                            </Button>
                        </div>

                    </AvForm>
                </div>
            </Modal>
        )

    }
}

export default withSnackbar(UserUpdateModal)