const axios = require("axios");

const  remote = {
  address: process.env.REACT_APP_BASE_URL,
};

function getRequests(api) {
  let token = localStorage.getItem("token");
  const apiUrl = remote.address + api;
  let promise = new Promise((resolve, reject) => {
    axios
      .get(apiUrl)
      .then((res) => {
        var data = res.data;
        resolve(data);
      })
      .catch((error) => {
        var errorMessage = error.response.data.Message;
        reject(errorMessage);
      });
  });
  return promise;
}

// function postRequests(api, data) {
//   let token = localStorage.getItem("token");
//   const apiUrl = remote.address + api;
//   let promise = new Promise((resolve, reject) => {
//     axios
//       .post(apiUrl, data, { headers: { Authorization: `Bearer ${token}` } })
//       .then((res) => {
//         var data = res.data;
//         resolve(data);
//       })
//       .catch((error) => {
//         var errorMessage = error.response.data.Message;
//         reject(errorMessage);
//       });
//   });
//   return promise;
// }
//
// function patchRequests(api, data) {
//   let token = localStorage.getItem("token");
//   const apiUrl = remote.address + api;
//   let promise = new Promise((resolve, reject) => {
//     axios
//       .patch(apiUrl, data, { headers: { Authorization: `Bearer ${token}` } })
//       .then((res) => {
//         var data = res.data;
//         resolve(data);
//       })
//       .catch((error) => {
//         var errorMessage = error.response.data.Message;
//         reject(errorMessage);
//       });
//   });
//   return promise;
// }
//
// function deleteRequests(api) {
//   let token = localStorage.getItem("token");
//   const apiUrl = remote.address + api;
//   let promise = new Promise((resolve, reject) => {
//     axios
//       .delete(apiUrl, { headers: { Authorization: `Bearer ${token}` } })
//       .then((res) => {
//         var data = res.data;
//         resolve(data);
//       })
//       .catch((error) => {
//         var errorMessage = error.response.data.Message;
//         reject(errorMessage);
//       });
//   });
//   return promise;
// }
//
// function putRequests(api, data) {
//   let token = localStorage.getItem("token");
//   const apiUrl = remote.address + api;
//   let promise = new Promise((resolve, reject) => {
//     axios
//       .put(apiUrl, data, { headers: { Authorization: `Bearer ${token}` } })
//       .then((res) => {
//         var data = res.data;
//         resolve(data);
//       })
//       .catch((error) => {
//         var errorMessage = error.response.data.Message;
//         reject(errorMessage);
//       });
//   });
//   return promise;
// }

var PublicRemoteServices = {
  getLink: function (id) {
    const url = `link/?unique_id=${id}`;
    return getRequests(url);
  },
};

module.exports = PublicRemoteServices;
