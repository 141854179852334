import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media, DropdownMenu, DropdownItem, Button,
} from "reactstrap";
import UserUpdateModal from "../Modals/UserUpdateModal";
import './navbar.css'
import i18n from "../../translations/i18n";
import { withTranslation } from 'react-i18next';
import './CustomPopup.css';
import translationKeys from "../Sidebar/TranslationKey";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faPhone, faPhoneSlash } from "@fortawesome/free-solid-svg-icons";
import { Avatar } from "@material-ui/core";

class AdminNavbar extends React.Component {
  state = {
    updateProfile: false,
    dropdownOpen: false,

  }

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  countUniqueUsersByFullName = (messages) => {
    // Check if messages array is empty
    if (messages.length === 0) {
      return 0;
    }

    // Use a Set to store unique full_name values
    const uniqueUsers = new Set();

    // Iterate through each message
    messages.forEach(message => {
      // Add the full_name to the Set (duplicates will be ignored)
      uniqueUsers.add(message.full_name);
    });

    // Return the size of the Set, which represents the count of unique users
    return uniqueUsers.size;
  }

  // Output the result
  logoutUser = async () => {
    await localStorage.clear();
    this.props.history.push('/auth/login')
  }

  changeLanguage = async (lng) => {
    await i18n.changeLanguage(lng);
  }

  toggleDropdown = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  render() {
    const { t, opencall, onAccept, callerName, callerImg, onReject } = this.props
    let base = window.config.imgUrl;
    let base1 = window.config.imgUrl;

    // Styles for the overlay and popup
    const overlayStyle = {
      display: opencall ? 'block' : 'none',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // Overlay color
      zIndex: 1050, // Ensure the popup appears on top
      width: 'fit-content'
    };

    const popupStyle = {
      position: 'fixed',
      top: '3%', // Adjust based on your needs
      left: '50%',
      transform: 'translateX(-50%)',
      width: 'fit-content',
      backgroundColor: 'white',
      borderRadius: '35px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
      zIndex: 1060,
      padding: '20px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '10px',

      // width:'400px',
      height: '70px',
      justifyContent: 'space-between'

    };

    return (
      <>
        <Navbar className="navbar-top "
          style={{
            backgroundColor: '#4f2bab',
            margin: '0'
          }}
          expand="md" id="navbar-main">
          <Container fluid>
            <button className="toggle-btn-nav" onClick={() => this.props.togglesideFuns()} >
              <FontAwesomeIcon icon={faBars} size="xl" />
            </button>

            <Link
              className="h4 mb-0 text-white text-uppercase  d-lg-inline-block"
              to="/"
            >
              {t(translationKeys[this.props.brandText])}
            </Link>
            <Nav className="align-items-center d-none d-md-flex" navbar>
              <Link to={'/admin/MessageUs'} style={{ display: 'flex', flexDirection: 'row', gap: 10, cursor: 'pointer' }}>
                <div style={{ display: 'flex' }}>
                  <i className={'fa fa-comments'} style={{ color: 'white', fontSize: 20 }} />
                  <div style={{ marginTop: '-12px', display: 'flex', flexDirection: 'row', backgroundColor: 'white', borderRadius: 3, maxHeight: '15px', minWidth: '15px', justifyContent: 'center', alignItems: 'center', padding: 2 }}>
                    <span style={{ fontSize: '12px', color: 'indigo', fontWeight: 'bold' }}>{this.props.location.pathname === '/admin/MessageUs' ? 0 : this.props.message.length}</span>
                  </div>
                </div>
              </Link>&nbsp;&nbsp;&nbsp;&nbsp;

              <img style={{ cursor: 'pointer' }}
                alt="" src={require('../../assets/img/eng.png')} height={20} width={20} onClick={() => this.changeLanguage('eng')} />&nbsp;&nbsp;
              <div style={{ borderRight: '2px dotted white', height: '12px' }}></div>&nbsp;
              <img style={{ cursor: 'pointer' }}
                alt=''
                src={require('../../assets/img/np.png')} height={20} width={20} onClick={() => this.changeLanguage('np')} />

              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <img className="avatar avatar-sm rounded-circle"
                      height={40}
                      width={100}
                      style={{
                        contain: 'fill',
                        borderRadius: 50,
                      }}
                      alt="..."
                      src={
                        JSON.parse(localStorage.getItem('user'))?.profile_picture !== null && JSON.parse(localStorage.getItem('user'))?.profile_picture?.[0] === '/'
                          ? base.concat(JSON.parse(localStorage.getItem('user'))?.profile_picture) :
                          JSON.parse(localStorage.getItem('user'))?.profile_picture !== null && JSON.parse(localStorage.getItem('user'))?.profile_picture?.[0] === 'h'
                            ? JSON.parse(localStorage.getItem('user'))?.profile_picture :
                            JSON.parse(localStorage.getItem('user'))?.profile_picture !== null ?
                              base1.concat(JSON.parse(localStorage.getItem('user'))?.profile_picture)
                              : require("assets/img/theme/avatarnew.png")
                      } />
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold"
                        style={{
                          color: '#fff'
                        }}
                      >
                        {JSON.parse(localStorage.getItem('user'))?.full_name}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem onClick={() => this.setState({ updateProfile: true })}>
                    <span>Update Profile</span>
                  </DropdownItem>
                  <DropdownItem onClick={() => this.logoutUser()}>
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>


              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
        {this.state.updateProfile && (
          <UserUpdateModal
            updateProfile={this.state.updateProfile}
            toggleModal={this.toggleModal}
            type={'Header'}
          />
        )}
        {opencall && (
          <div style={overlayStyle}>
            <div
              style={popupStyle}
              onClick={(e) => e.stopPropagation()}
            >
              <Avatar src={callerImg} />
              <p className="tittle-call">{callerName} is calling you</p>

              <div>
                <Button style={{ boxShadow: 'none' }} onClick={onAccept}>
                  <FontAwesomeIcon
                    icon={faPhone}
                    size="xl"
                    style={{ marginRight: '10px', cursor: 'pointer', color: 'green' }}
                  />
                </Button>

                <Button style={{ boxShadow: 'none' }} onClick={onReject}>
                  <FontAwesomeIcon
                    icon={faPhoneSlash}
                    size="xl"
                    style={{ cursor: 'pointer', color: 'red' }}
                  />
                </Button>
                
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default withTranslation()(AdminNavbar);
