import _ from 'lodash';
import Emitter from './Emitter';

/**
 * Manage all media devices
 */
class MediaDevice extends Emitter {
  /**
   * Start media devices and send stream
   */
  start() {
    const constraints = {
      video: { width: 1280, height: 720 },
      audio: true
    };
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        this.stream = stream;
        this.emit('stream', stream);
      })
      .catch((err) => {
        if (err instanceof DOMException) {
          alert('Cannot open webcam and/or microphone');
        } else {
          console.log(err);
        }
      });
    return this;
  }

  /**
   * Turn on/off a device
   * @param {'Audio' | 'Video'} type - Type of the device
   * @param {Boolean} [on] - State of the device
   */
  toggle(type, on) {
    const len = arguments.length;
    if (this.stream) {
      this.stream[`get${type}Tracks`]().forEach((track) => {
        const state = len === 2 ? on : !track.enabled;
        _.set(track, 'enabled', state);
      });
    }
  }

  stopShareScreen(){
    if (this.stream) {
      this.stream.getTracks().forEach((track) => track.stop());
      this.start()
    }
    return this;
  }


  toggleScreenShare(isScreenSharing, pc) {
    if (isScreenSharing) {
      navigator.mediaDevices.getDisplayMedia({ video: true, audio:true })
        .then(screenStream => {
          this.stream = screenStream
          const screenTrack = screenStream.getVideoTracks()[0];
          this.replaceTrack(screenTrack, 'Video', pc);

          screenTrack.onended = () => {
            this.toggleScreenShare(false, pc);
          };
        })
        .catch(err => {
          console.error('Error sharing screen:', err);
        });
    } else {
      navigator.mediaDevices.getUserMedia({ video: true, audio:true })
        .then(webcamStream => {
          this.stream = webcamStream
          const webcamTrack = webcamStream.getVideoTracks()[0];
          this.replaceTrack(webcamTrack, 'Video', pc);
        })
        .catch(err => {
          console.error('Error reverting to webcam:', err);
        });
    }
  }

  /**
   * Replace a track in the current stream
   * @param {MediaStreamTrack} newTrack - The new track to replace the old one
   * @param {'Audio' | 'Video'} type - Type of the track to replace
   * @param {RTCPeerConnection} pc - The RTCPeerConnection instance
   */
  replaceTrack(newTrack, type, pc) {
    const sender = pc.pc.getSenders().find(s => s.track.kind === type.toLowerCase());
    if (sender) {
      sender.replaceTrack(newTrack);
    }
    this.emit('changeStream', this.stream);
  }

  /**
   * Stop all media track of devices
   */
  stop() {
    if (this.stream) {
      this.stream.getTracks().forEach((track) => track.stop());
    }
    return this;
  }
}

export default MediaDevice;