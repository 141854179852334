import React, {useState} from 'react';
import {Button, Input, Label, Modal} from "reactstrap";
import './rating.css'

const ShowShareModal = (props) => {
    return (
        <Modal
            keyboard={false}
            backdrop={'static'}
            className="modal-dialog-centered modal-md"
            isOpen={props.showShare}
            toggle={() => props.toggleModal("showShare")}
        >
            <div className="modal-header">
                <h2 className="modal-title" id="modal-title-default">
                    Share Your Code
                </h2>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => props.toggleModal('showShare')}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className={'modal-body'} style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                <div>
                    <Label>{props?.shareItem?.user?.full_name} has given {props?.shareItem.rating}</Label>
                    <Label>Message: {props?.shareItem.message}</Label>
                    <Label className={'font-weight-bold mb-4'}>Your Referral Code: {JSON.parse(localStorage.getItem('user'))?.id}</Label>
                </div>
            </div>
            <div className={'modal-footer'}>
                <Button onClick={() => props.toggleModal('showShare')}
                        className={'float-right btn btn-danger'}>Close</Button>
            </div>
        </Modal>
    );
};

export default ShowShareModal;