import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function ActionButton({icon, iconClass, className, onClick }) {
  return (
    <button
      className={className}
      type="button"
      onClick={onClick}
    >
      <FontAwesomeIcon icon={icon} className={iconClass}/>
    </button>
  );
}

ActionButton.defaultProps = {
  className: null,
  disabled: false
};

ActionButton.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
};