import React, {memo, useEffect, useRef, useState} from "react";
import Header from "components/Headers/Header.js";
import {Alert, Button, Col, Container, Row} from "reactstrap";
import {useHistory} from "react-router-dom";
import ReactToPrint, {useReactToPrint} from "react-to-print";
import {useTranslation} from "react-i18next";
import {withSnackbar} from "notistack";

const Services = require("RemoteServices/RemoteServices.js");

const Khalti = (props) => {
    const {t} = useTranslation()
    const [success, setSuccess] = useState(false);
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const history = useHistory();
    const params = new URLSearchParams(props.location.search);
    const pidx = params.get('pidx');
    const transactionId = params.get('transaction_id');
    const amount = params.get('amount') / 100;
    const mobile = params.get('mobile');
    const purchaseOrderId = params.get('purchase_order_id');
    const purchaseOrderName = params.get('purchase_order_name');
    const message = params.get('message')
    const category = params.get('category');
    const parent = params.get('parent');
    const id = params.get('id');
    useEffect(() => {
        (async () => {
            try {
                if (category) {
                    const response = await Services.setLinkWalletDetails({
                        payment_method: "Khalti",
                        amount_paid: Number(amount),
                        transaction_id: transactionId,
                        reference_no: transactionId,
                        category_object_id: parent,
                        category: category,
                        payment_button_id: id.split('?')[0]
                    }).then((response) => {
                        setSuccess(true)
                    }).catch((error) => {
                        this.props.enqueue('error', error.message)
                    });

                } else {
                    const response = await Services.savePayment({
                        payment_method: "KHALTI",
                        amount_paid: Number(amount),
                        transaction_id: transactionId,
                        reference_no: transactionId,
                        promocode:Number(localStorage.getItem('promoCode'))||null

                    }).then((response) => {
                        setSuccess(true)
                         localStorage.removeItem('promoCode')

                    }).catch((error) => {
                        this.props.enqueue('error', 'Error Occured')
                        setSuccess(false)
                        localStorage.removeItem('promoCode')
                    });
                }
            } catch (error) {
                setSuccess(false)
                localStorage.removeItem('promoCode')
            }
        })();
    }, []);
    return (
        <>
            <Header></Header>
            <Container
                fluid
                style={{backgroundColor: 'white', padding: 39, minHeight: "calc(100vh - 76px - 118px)"}}
            >
                {success && !message && (
                    <Row>
                        <Col xs={12} className={'mb-3'}>
                            <div style={{display: "flex", flexDirection: 'row', gap: '10px', alignItems: 'center'}}>
                                <img src={require('../../assets/img/brand/argon-logo.png')} height={50} width={140}/>
                                <span style={{fontSize: '20px'}} className={'font-weight-bold'}>English E and P Learning Hub Pvt. Ltd</span>
                                <ReactToPrint
                                    trigger={() => <i className={'fa fa-print'}
                                                      style={{fontSize: 20, color: 'purple', cursor: 'pointer'}}
                                                      onClick={() => handlePrint()}></i>}
                                    content={() => componentRef.current}
                                />
                            </div>


                        </Col>
                        <div style={{width: '100%'}} ref={componentRef}>
                            <Col xs={12} className=" mt-3 mb-3">
                                <div style={{display: 'flex', flexDirection: 'column', width: '100%', gap: '40px'}}>
                                    {/*<span style={{color: 'green'}}>Congratulation Your Payment was successfully done with payment method: ESEWA.</span>*/}
                                    <div style={{
                                        display: 'flex', flexDirection: 'row',
                                        borderBottom: '1px solid lightgrey',
                                        width: '100%'
                                    }}>
                                        <h2>Transaction Details</h2>
                                    </div>

                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        width: '100%'
                                    }}>
                                        <div style={{display: 'flex', flexDirection: 'column', width: '35%'}}>
                                            <span>Transaction Code</span>
                                            <span>{transactionId || ''}</span>
                                        </div>
                                        <div style={{display: 'flex', flexDirection: 'column', width: '35%'}}>
                                            <span>Date/Time</span>
                                            <span>{new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString()}</span>
                                        </div>

                                        <div style={{display: 'flex', flexDirection: 'column', width: '30%'}}>
                                            <span>Product Amount</span>
                                            <span>Rs. {amount}</span>
                                        </div>

                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'fiex-start'}}>
                                        <div style={{display: 'flex', flexDirection: 'column', width: '35%'}}>
                                            <span>Product Id</span>
                                            <span>English Hub</span>
                                        </div>
                                        <div style={{display: 'flex', flexDirection: 'column', width: '35%'}}>
                                            <span>Tax Amount</span>
                                            <span>Rs. {(amount - amount / 113 * 100).toFixed(2)}</span>
                                        </div>

                                        <div style={{display: 'flex', flexDirection: 'column', width: '30%'}}>
                                            <span>Service Name</span>
                                            <span>English Hub Course</span>
                                        </div>

                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start'}}>
                                        <div style={{display: 'flex', flexDirection: 'column', width: '35%'}}>
                                            <span>Product Service Charge</span>
                                            <span>Rs. 0.00</span>
                                        </div>
                                        <div style={{display: 'flex', flexDirection: 'column', width: '35%'}}>
                                            <span>Delivery Charge</span>
                                            <span>Rs. 0.00</span>
                                        </div>

                                        <div style={{display: 'flex', flexDirection: 'column', width: '30%'}}>
                                            <span>Unique Id</span>
                                            <span>{transactionId}</span>
                                        </div>

                                    </div>

                                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start'}}>
                                        <div style={{display: 'flex', flexDirection: 'column', width: '35%'}}>
                                            <span>Transaction Amount</span>
                                            <span>{amount}</span>
                                        </div>
                                        <div style={{display: 'flex', flexDirection: 'column', width: '35%'}}>
                                            <span>Status</span>
                                            <span><i className={'fa fa-check-circle'}
                                                     style={{color: 'green'}}/>&nbsp; Success</span>
                                        </div>
                                    </div>

                                </div>
                            </Col>
                        </div>

                        <div style={{display: 'flex', width: '100%', marginTop: '30px', justifyContent: 'center'}}>
                            <Button className={'mt-4 all-btn'} style={{width: '50%'}}
                                    onClick={() => history.push('/')}>Back To Dashboard</Button>
                        </div>


                    </Row>
                )}
                {!success && message && (
                    < div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Alert color="warning">
                            <strong>Payment Failed!</strong> Please Try again later
                        </Alert>

                        <Button className={'all-btn'} onClick={() => history.push('/')}> Back to Dashboard</Button>
                    </div>
                )}
            </Container>
        </>
    );
};

export default withSnackbar(memo(Khalti));
