import React, { memo, useEffect, useMemo, useRef } from "react";
import Header from "components/Headers/Header.js";
import { Container, Alert, Button, Row, Col } from "reactstrap";
import { useHistory } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { useReactToPrint } from 'react-to-print';
import { useTranslation } from "react-i18next";


const Services = require("RemoteServices/RemoteServices.js");

const Esewa = (props) => {
    const { t } = useTranslation()
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const history = useHistory();
    const params = new URLSearchParams(props.location.search);
    const category = params.get('category');
    const parent = params.get('parent');
    const id = params.get('id');
    const q = useMemo(() => params.get("q"), [params]);
    useEffect(() => {
        if (q === "su") {
            if (category) {
                console.log('i am inside link page')
            } else {
                console.log('i am main paymentpage')
            }
            const refId = params.get("refId");
            const amt = params.get("amt");
            (async () => {
                try {
                    if (category) {
                        await Services.setLinkWalletDetails({
                            payment_method: "ESEWA",
                            amount_paid: Number(amt),
                            transaction_id: refId,
                            reference_no: refId,
                            category_object_id: parent,
                            category: category,
                            payment_button_id: id
                        });
                    } else {
                        await Services.savePayment({
                            payment_method: "ESEWA",
                            amount_paid: Number(amt),
                            transaction_id: refId,
                            reference_no: refId,
                            promocode: Number(localStorage.getItem('promoCode')) || null,
                        });
                        await localStorage.removeItem('promoCode')
                    }
                } catch (error) {
                    await localStorage.removeItem('promoCode')
                }
            })();
        }
    }, [params, q]);
    return (
        <>
            <Header></Header>
            <Container
                fluid
                style={{ backgroundColor: 'white', padding: 39, minHeight: "calc(100vh - 76px - 118px)" }}
            >
                {q === "su" && (
                    <Row>
                        <Col xs={12} className={'mb-3'}>
                            <div style={{ display: "flex", flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
                                <img src={require('../../assets/img/brand/argon-logo.png')} height={50} width={140} />
                                <span style={{ fontSize: '20px' }} className={'font-weight-bold'}>English E and P Learning Hub Pvt. Ltd</span>
                                <ReactToPrint
                                    trigger={() => <i className={'fa fa-print'}
                                        style={{ fontSize: 20, color: 'purple', cursor: 'pointer' }}
                                        onClick={() => handlePrint()}></i>}
                                    content={() => componentRef.current}
                                />
                            </div>


                        </Col>
                        <div style={{ width: '100%' }} ref={componentRef}>
                            <Col xs={12} className=" mt-3 mb-3">
                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '40px' }}>
                                    {/*<span style={{color: 'green'}}>Congratulation Your Payment was successfully done with payment method: ESEWA.</span>*/}
                                    <div style={{
                                        display: 'flex', flexDirection: 'row',
                                        borderBottom: '1px solid lightgrey',
                                        width: '100%'
                                    }}>
                                        <h2>Transaction Details</h2>
                                    </div>

                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        width: '100%'
                                    }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', width: '35%' }}>
                                            <span>Transaction Code</span>
                                            <span>{params.get("refId") || ''}</span>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', width: '35%' }}>
                                            <span>Date/Time</span>
                                            <span>{params.get("date") || new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString()}</span>
                                        </div>

                                        <div style={{ display: 'flex', flexDirection: 'column', width: '30%' }}>
                                            <span>Product Amount</span>
                                            <span>{params.get("amt") || ''}</span>
                                        </div>

                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'fiex-start' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', width: '35%' }}>
                                            <span>Product Id</span>
                                            <span>English Hub</span>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', width: '35%' }}>
                                            <span>Tax Amount</span>
                                            <span>{((params.get('amt') - (params.get("amt") / 113) * 100)).toFixed(2) || ''}</span>
                                        </div>

                                        <div style={{ display: 'flex', flexDirection: 'column', width: '30%' }}>
                                            <span>Service Name</span>
                                            <span>English Hub Course</span>
                                        </div>

                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', width: '35%' }}>
                                            <span>Product Service Charge</span>
                                            <span>Rs. 0.00</span>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', width: '35%' }}>
                                            <span>Delivery Charge</span>
                                            <span>Rs. 0.00</span>
                                        </div>

                                        <div style={{ display: 'flex', flexDirection: 'column', width: '30%' }}>
                                            <span>Unique Id</span>
                                            <span>{params.get("refId") || ''}</span>
                                        </div>

                                    </div>

                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', width: '35%' }}>
                                            <span>Transaction Amount</span>
                                            <span>{params.get("amt") || ''}</span>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', width: '35%' }}>
                                            <span>Status</span>
                                            <span><i className={'fa fa-check-circle'}
                                                style={{ color: 'green' }} />&nbsp; Success</span>
                                        </div>
                                    </div>

                                </div>
                            </Col>
                        </div>

                        {category ?
                            <div style={{ display: 'flex', width: '100%', marginTop: '30px', justifyContent: 'center' }}>
                                <Button className={'mt-4 all-btn'} style={{ width: '50%' }}
                                    onClick={() => history.push('/')}>Back To Dashboard</Button>
                            </div>
                            :
                            <div style={{ display: 'flex', width: '100%', marginTop: '30px', justifyContent: 'center' }}>
                                <Button className={'mt-4 all-btn'} style={{ width: '50%' }}
                                    onClick={() => history.push('/admin/Lessons')}>Back To Lesson</Button>
                            </div>
                        }


                    </Row>
                )}
                {q === "fu" && (
                    < div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Alert color="warning">
                            <strong>Payment Failed!</strong> Please Try again later
                        </Alert>

                        <Button className={'all-btn'} onClick={() => history.push('/')}> Back to Dashboard</Button>
                    </div>
                )}
            </Container>
        </>
    );
};

export default memo(Esewa);
