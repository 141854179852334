import React, { useContext, useEffect, useState } from "react";
import { SocketContext } from "layouts/SocketContext";
import { Container } from "reactstrap";
import _ from "lodash";
import { PeerConnection } from "../../utils/video-call/PeerConnection";
import MainWindow from "../../components/VideoCall/MainWindow";
import CallWindow from "../../components/VideoCall/CallWindow";
import CallModal from "../../components/VideoCall/CallModal";
import { socketListen } from "utils/video-call/SocketListener";
import Draggable from "react-draggable";
import ActionButton from "components/VideoCall/ActionButton";
import PeerAvatar from "components/VideoCall/PeerAvatar";
import { faUpRightAndDownLeftFromCenter } from "@fortawesome/free-solid-svg-icons";
import "../../assets/css/FullScreenDialog.css";

const VideoCall = (props) => {
  const [callWindow, setCallWindow] = useState("");
  const [callModal, setCallModal] = useState("");
  const [callFrom, setCallFrom] = useState("");
  const [localSrc, setLocalSrc] = useState(null);
  const [peerSrc, setPeerSrc] = useState(null);

  const [pc, setPc] = useState({});
  const [config, setConfig] = useState(null);
  const [IsMinimize, setIsMinimize] = useState(false);
  const { socket } = useContext(SocketContext);

  const userId = JSON.parse(localStorage.getItem("user"))?.id;
  const full_name = JSON.parse(localStorage.getItem("user"))?.full_name;

  useEffect(() => {
    const listener = socketListen(socket);
    listener.on("call", "request", "user", (res) => {
      const { from } = res;
      setCallModal("active");
      setCallFrom(from);
    });
    listener.on("call", "call", "user", (res) => {
      if (res.sdp) {
        pc.setRemoteDescription(res.sdp);
        if (res.sdp.type === "offer") pc.createAnswer();
      } else pc.addIceCandidate(res.candidate);
    });
    listener.on("call", "end", "user", (res) => {
      endCall(res.isStarter);
    });
    socket.send(
      JSON.stringify({
        action: "call",
        action_type: "init",
        clientId: 123,
        calling_to: "",
        user_id: userId,
        me_id: userId,
        full_name: full_name,
      })
    );
  }, [socket, pc]);

  const startCall = (isCaller, callFrom, config) => {
    const conxn = new PeerConnection(socket)
      .on("localStream", (src) => {
        setCallWindow("active");
        setLocalSrc(src);
        if (!isCaller) {
          setCallModal("");
        }
      })
      .on("peerStream", (src) => setPeerSrc(src))
      .start(isCaller);

    setPc(conxn);
    setConfig(config);
  };

  const rejectCall = () => {
    socket.send(
      JSON.stringify({
        action: "call",
        action_type: "end",
        to: callFrom,
        calling_to: "admin",
        user_id: userId,
        me_id: userId,
        full_name: full_name,
      })
    );
    setCallModal("");
  };

  const endCall = (isStarter) => {
    if (_.isFunction(pc.stop)) {
      pc.stop(isStarter);
    }
    setPc({});
    setConfig(null);
    setCallModal("");
    setCallWindow("");
    setLocalSrc(null);
    setPeerSrc(null);
  };

  const handleMinMaxScreen = () => {
    setIsMinimize(!IsMinimize);
  };

  return (
    <Draggable position={IsMinimize ? "" : { x: 0, y: 0 }} cancel=".no-drag">
      <div className={`dialog-overlay ${IsMinimize ? "minimized" : ""}`}>
        <div className="dialog-content-videoframe">
          {IsMinimize ? (
            <div>
              <ActionButton
                className="btn-action"
                icon={faUpRightAndDownLeftFromCenter}
                onClick={handleMinMaxScreen}
              />
              <div className="dialog-min">
                <PeerAvatar />
              </div>
            </div>
          ) : (
            <div className="no-drag">
              {_.isEmpty(config) && <MainWindow startCall={startCall} />}

              {!_.isEmpty(config) && (
                <CallWindow
                  status={callWindow}
                  localSrc={localSrc}
                  peerSrc={peerSrc}
                  pc={pc}
                  config={config}
                  mediaDevice={pc.mediaDevice}
                  endCall={endCall}
                  handleMinMaxScreen={handleMinMaxScreen}
                />
              )}
              {/* <CallModal
                              status={callModal}
                              startCall={startCall}
                              rejectCall={rejectCall}
                              callFrom={callFrom}
                          /> */}
            </div>
          )}
        </div>
      </div>
    </Draggable>
  );
};

export default VideoCall;
