
import React, { useContext, useEffect, useRef, useState } from "react"
import _ from 'lodash'
import { PeerShareConnection } from '../../utils/video-call/PeerShareConnection'
import CallMeetingWindow from '../../components/VideoCall/CallMeetingWindow';
import PeerAvatar from "../../components/VideoCall/PeerAvatar";

import ActionButton from "components/VideoCall/ActionButton";
import { faExpand, faUpRightAndDownLeftFromCenter } from "@fortawesome/free-solid-svg-icons";
import "../../assets/css/FullScreenDialog.css";
import Draggable from "react-draggable";
import { useParams } from 'react-router-dom'
import Peer from 'simple-peer';


const MeetingRoom = () => {
    const [isMinimized, setIsMinimized] = useState(false);
    const [localSrc, setLocalSrc] = useState(null)
    const [mediaDevice, setMediaDevice] = useState(null)
    const  { id:meetingId } = useParams() 
 
    const peersRef = useRef({})
    const [updateKey, setUpdateKey] = useState(0)

    const [remoteStreams, setRemoteStreams] = useState([]);
    const [config, setConfig] = useState({})
    const configRef = useRef({})
    const userId = JSON.parse(localStorage.getItem("user"))?.id
    const full_name = JSON.parse(localStorage.getItem("user"))?.full_name
    
    const handleListener = (event, socket) => {
        console.log(peersRef.current)
        const message = JSON.parse(event.data);
        if (message.type === 'offer' && userId != message.peerId) {
            const peer = peersRef.current[message.peerId];
            peer.signal(message.signal);
        } else if (message.type === 'answer' && userId != message.peerId) {
            const peer = peersRef.current[message.peerId];
            peer.signal(message.signal);
        }else if (message.type === 'ice-candidate') {
            const peer = peersRef.current[message.peerId];
            if (peer) {
                peer.signal(message.candidate);
            }
        } else if(message.type === 'participants'){
              joinRoom(socket, message.peerIds)
        }else if(message.type === 'new_participant') {
            const newPeerId = message.peerId;
            if(!peersRef.current[newPeerId]) {
                addPeer(socket, newPeerId, false);
            }
        } else if(message.type === 'participant_left'){
            removePeer(message.peerId)
        }
    }

    const addPeer = (socket, peerId, initiator) => {
        const peer = initiator ?  new Peer({
            initiator: initiator,
            trickle: false,
            stream:localSrc
        }): new Peer({
            initiator: initiator,
            trickle: false,
        });
        peer.on('iceCandidate', (candidate) => {
            if (candidate) {
                socket.send(JSON.stringify({
                    type: 'ice-candidate',
                    peerId: peerId,
                    candidate: candidate
                }));
            }
        });
        peer.on('signal', (signal) => {
            console.log("offers")
            socket.send(JSON.stringify({
                type: signal.type,
                peerId: peerId,
                signal: signal,
            }));
        });
        peersRef.current = {
           ...peersRef.current,
           [peerId]: peer
        }
        setUpdateKey(prev => prev + 1);
    };

    const removePeer = (peerId) => {
        const peer = peersRef.current[peerId];
        if(peer){
            peer.destroy()
        }
        delete peersRef.current[peerId]  
        setUpdateKey(prev => prev + 1); 
    };
    
    // Create a new peer connection for each participant
    const joinRoom = (socket, peerIds) => {
        peerIds.forEach(peerId => {
            if (userId == peerId) {
                console.log('single hits -------')
                createPeer(socket,peerId,true);
            }else{
                addPeer(socket, peerId, false)
            }
        });
    };

    const createPeer = (socket, peerId,initiator) => {
        console.log("create peer hits one time")
        const peer = new Peer({
            initiator: initiator,
            trickle: false,
            stream:localSrc
        });
        peer.on('iceCandidate', (candidate) => {
            if (candidate) {
                socket.send(JSON.stringify({
                    type: 'ice-candidate',
                    peerId: peerId,
                    candidate: candidate
                }));
            }
        });
        peer.on('signal', (signal) => {
            socket.send(JSON.stringify({
                type:signal.type,
                peerId:peerId,
                signal: signal,
            }));
        });

        peersRef.current = {
            ...peersRef.current,
            [peerId]: peer
         }
         setUpdateKey(prev => prev + 1);
    };

    useEffect(() => {
        console.log(peersRef.current,remoteStreams)
    },[peersRef, remoteStreams])

    const initializeSocket = () => {
        return new Promise((resolve, reject) => {
            const socketi = new WebSocket(`${process.env.REACT_APP_SOCKET_URL}meeting/${meetingId}-${userId}/room/`);
            socketi.onopen = () => {
                resolve(socketi)
            };
            socketi.onerror = (error) => {
                reject('err', error)
            };
            socketi.onclose =  () => {
                reject('close socket connection')
            }
     })
    }


    useEffect(() => {
        new PeerShareConnection(meetingId)
        .on('localStream', (stream) => {
            console.log(stream, "kdoekd")
            setLocalSrc(stream)
        })
        .on('mediaDevice', (mediaDevice) => {
            setMediaDevice(mediaDevice)
        })
        .start()
    },[meetingId])

    useEffect(() => {
        if(localSrc){
            initializeSocket().then(socket => {
                setConfig({
                    audio:true,
                    video:true
                })
                socket.onmessage = (event) => handleListener(event, socket)
            })  
        }
    }, [localSrc])

    const endCall = () => {
        if (_.isFunction(configRef.current.peer.destroy)) {
            configRef.current.peer.destroy()
        }
        //setConfig(null)
        //setCallModal('')
        //setCallWindow('')
        setLocalSrc(null)
        //setCallFrom('')
        // add to both side after call is fininsed
        //localStorage.removeItem('callFrom')
    }

    const onClosefunc = () => {}



    const handleMinMaxScreen = () => {
        setIsMinimized(!isMinimized)
    }

    return (
        <>
            <div className={`dialog-overlay12`}>
                <div className="dialog-content-videoframe" >
                    {isMinimized ?
                        <div>
                            <ActionButton className='btn-action' icon={faUpRightAndDownLeftFromCenter} onClick={handleMinMaxScreen} />
                            <div className="dialog-min">
                                <PeerAvatar />

                            </div>
                        </div>
                        :
                        <div className="non-drageable">
                            {!_.isEmpty(config) && (
                                <CallMeetingWindow
                                    localSrc={localSrc}
                                    peers={peersRef.current}
                                    peerKey={updateKey}
                                    config={config}
                                    mediaDevice={mediaDevice}
                                    endCall={endCall}
                                    meId={userId}
                                    onClosefunc={onClosefunc}
                                    handleMinMaxScreen={handleMinMaxScreen}
                                />
                            )}
                
                        </div>

                    }
                </div>
            </div>
        </>

    )
};

export default MeetingRoom;