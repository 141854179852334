import React from 'react';
import routes from "routes.js";
import {Route, Switch} from "react-router-dom";
import Services from "../RemoteServices/PublicRemoteServices";
import AdSense from "react-adsense-ads";
import YouTube from "react-youtube";
import image from '../assets/img/subscribe-our-youtube-channel.png'
import ReactPlayer from "react-player/lazy";

class PublicLayout extends React.Component {

    state = {
        htmlElement: '',
        objects: ''
    }

    async componentDidMount() {
        const response = await Services.getLink(this.props.match.params.id);
        this.setState({
            objects: response[0].objects
        })
    }

    getRoutes = routes => {
        return routes.map((prop, key) => {
            if (prop.layout === "/:id") {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                    />
                );
            } else {
                return null;
            }
        });
    };

    render() {
        const optsDesktop = {
            width: "100%",
            height: "100%",
            playerVars: {
                autoplay: 1,
                frameborder: 0,
                modestbranding: 1,
                rel: 0,
                showinfo: 0,
            },
        };
        return (
            <>
                <div style={{
                    backgroundColor: 'white',
                    color: 'black',
                    fontWeight: 'bold',
                    padding: '20px'
                }}>
                    {/*<AdSense.Google*/}
                    {/*    client='ca-pub-5878735292537999'*/}
                    {/*    slot='5878735292537999'*/}
                    {/*    style={{display: 'block'}}*/}
                    {/*    format='auto'*/}
                    {/*    responsive='true'*/}
                    {/*/>*/}
                    {this.state.objects && this.state.objects.sort((a, b) => a.order - b.order).map((item, index) => {
                        console.log('asdfasdf', item)
                        return (
                            <div key={index} style={{marginLeft: "15%", marginRight: '15%'}}>
                                {item.text &&
                                    <div
                                        style={{textAlign: item.orientation,marginTop:'10px'}}
                                        dangerouslySetInnerHTML={{
                                            __html: item.text
                                        }}>
                                    </div>
                                }
                                {item.youtube_link &&
                                    <div style={{
                                        width: "100%",
                                        position: "relative",
                                        marginTop: '10px'
                                    }}
                                    >
                                        <ReactPlayer
                                            url="https://www.youtube.com/watch?v=u8GYOj1MOQI"
                                            className="react-player"
                                            // playing={true}
                                            width="100%"
                                            height='569px'
                                            controls={false}
                                            onEnded={() => this.setState({
                                                showAdd: false,
                                            })}
                                        />
                                    </div>
                                }
                                {item.image &&
                                    <div style={{
                                        marginTop: '10px',
                                        display: 'flex',
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        width: '100%'
                                    }}>
                                        <img style={{width: '100%', height: '40%', objectFit: 'contain'}} src={item.image}
                                             alt={''}/>

                                    </div>
                                }
                            </div>
                        )
                    })}

                </div>
            </>
        )
    }
}

export default PublicLayout;