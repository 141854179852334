/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Card, CardHeader, CardBody, Container, Row, Col, Button, Modal, InputGroup, InputGroupAddon, InputGroupText, Label } from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import Header from "../../components/Headers/Header";
import './styles.scss'
import camera from '../../assets/img/camera.png';
import avatar from '../../assets/img/theme/avatarnew.png'
import UserUpdateModal from "../../components/Modals/UserUpdateModal";
import PasswordChangeModal from "../../components/Modals/PasswordChangeModal";
import Services from "../../RemoteServices/RemoteServices";
import ReferralModal from "../../components/Modals/ReferralModal";
import RewardModal from "../../components/Modals/RewardModal";
import AnnouncementField from "../../components/Community/AnnouncementField";
import CommunityPostList from "../../components/Community/CommunityPostList";
import FollowersModal from "../../components/Modals/FollowersModal";
import FollowingModal from "../../components/Modals/FollowingModal";
import PeopleYouMayKnow from "../../components/Community/PeopleYouMayKnow";
import moment from "moment";
import { AvForm, AvGroup, AvInput } from "availity-reactstrap-validation";

class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userdetails: '',
            changePassword: false,
            updateProfile: false,
            showReferral: false,
            showReward: false,
            showWithdrawMoney: false,
            communityList: '',
            loading: true,
            showFollowing: false,
            showFollowers: false,
            followingList: '',
            peopleList: '',
            filteredData: '',
            verifyEmail: false,
            otp: '',
            loggingIn: false,
            otpError: false,
            otpErrorMessage:'',
            email:'',
            userId:'',
            otpLoading:false
            
        };
    }

    fetchRefer = async (tag) => {
        if (tag === '') {
            // if no category is selected, show all data
            this.setState({ filteredData: [] });
        } else {
            const filteredData = this.state.userReferrals.filter(
                (item) => item.payment_status === tag
            );
            this.setState(prevState => ({
                ...prevState,
                filteredData: filteredData,
            }));
        }
    }


    getPeopleYouMayKnow = async () => {
        Services.referredUser().then((response) => {
            this.setState((prevState) => ({
                ...prevState,
                peopleList: response,
            }));
        }).catch(e => {
            throw e
        })
    }

    getFollowingList = async () => {
        Services.getFollowingList('follower', this.state.userdetails.user.id).then((response) => {
            this.setState((prevState) => ({
                ...prevState,
                followingList: response,
            }));
        }).catch(e => {
            throw e
        })
    }


    getFollowerList = async () => {
        Services.getFollowingList('following', this.state.userdetails.user.id).then((response) => {
            this.setState((prevState) => ({
                ...prevState,
                followingList: response,
            }));
        }).catch(e => {
            throw e
        })
    }

    getCommunity = async (url) => {
        if (url) {
            Services.getCommunity(url).then((response) => {
                this.setState((prevState) => ({
                    ...prevState,
                    communityList: {
                        results: [...prevState.communityList.results, ...response.results]
                    }
                }));
            }).catch(e => {
                throw e
            })
        } else {
            const response = await Services.getCommunity(`community/?user=${JSON.parse(localStorage.getItem('user')).id}`);
            this.setState({
                communityList: response,
                loading: false,
            });
        }
    }

    toggleModal = (state) => {
        this.setState({
            [state]: !this.state[state],
        });
    };
    getUserDetails = async (id) => {
        const response = await Services.getUserDetails(id);
        const localStorageData = localStorage.getItem('user');
        if (localStorageData) {
            // Step 2: Parse the JSON data
            const parsedData = JSON.parse(localStorageData);

            // Step 3: Modify the data (change the value of the referred_count key)
            parsedData.referred_count = response.results[0]?.user.referred_count;
            parsedData.email_verified = response.results[0]?.user.email_verified;
            // Replace 15 with the new value you want

            // Step 4: Set the updated data back to localStorage
            localStorage.setItem('user', JSON.stringify(parsedData));
            console.log('asdfasdf', 'i am updated')
        } else {
            console.error('The key does not exist in localStorage.');
        }
        this.setState({
            userdetails: response.results[0]
        })
    }



    getReferralList = async () => {
        const response = await Services.getUserReferrals();
        this.setState({
            userReferrals: response
        })
    }

    componentDidMount() {
        const user = JSON.parse(localStorage.getItem("user"))
        this.setState({userId:user.id})
        this.getUserDetails(user.id);
        this.getReferralList();
        this.getCommunity();
        this.getPeopleYouMayKnow()
    }

    handleFile = async (e) => {
        let reader = new FileReader();
        let userDetails = this.state.userdetails
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = function () {
            let imageData = {
                profile_picture: reader.result
            }
            Services.changeProfilePicture(userDetails.id, imageData).then((response) => {
                const profile = JSON.parse(localStorage.getItem('user'));

                //in live 27
                Object.keys(response).forEach((key) => {
                    profile[key] = response[key].substring(27, 2000);
                });
                localStorage.setItem('user', JSON.stringify(profile));
                window.location.reload('/profile/personalProfile')
            }).catch = (err) => {
                console.log(err)
            };
        };
    }

    handleOtpSubmit = async (event, values) => {
        event.preventDefault();
        this.setState({otpLoading:true})
        await Services.verifyOtp({
            otp:values.otp,
            email:this.state.userdetails?.user?.email
        })
            .then((response) => {
                this.getUserDetails(this.state.userId)
                this.setState({
                    verifyEmail: false,
                    otpLoading: false,
                })
            })
            .catch((error) => {
                // this.props.history.push("/auth/changePassword", { email: this.state.email });
                this.setState({ otpError: true, otpErrorMessage:error.message ,otpLoading:false})
                setTimeout(() => {
                    this.setState({ otpError: false ,
                    });

                }, 3000)
            });
    }

    verifyEmail = async () => {
        this.setState({loggingIn:true});
        await Services.verifyEmail({ email: this.state.userdetails?.user?.email })
            .then((response) => {
                this.setState({verifyEmail:true,loggingIn:false})
            })
            .catch((error) => {
                this.setState({ otpError: true, otpErrorMessage:error.message,loggingIn:false})
                setTimeout(() => {
                    this.setState({ otpError: false
                    });
                }, 3000)
            });
    }

    render() {

        let userDetails = this.state.userdetails;
        console.log('asdfasdf', userDetails)
        let base = window.config.imgUrl

        const padWithZero = (number, length) => {

            let my_string = '' + number;
            while (my_string.length < length) {
                my_string = '0' + my_string;
            }

            return my_string;

        }
        return (
            <>
                <Row className={'m-0 p-0'} style={{ minHeight: "calc(100vh - 76px - 118px)" }}>
                    {/* Page content */}
                    <Col className={'m-0 p-0'} xl={9}>
                        <div className={'m-0 p-0'}>
                            <Row className={'m-0 p-0'}>
                                <Card className={'m-0 pb-2'} style={{
                                    justifyContent: 'flex-end',
                                    minHeight: '150px',
                                    // height: '20%',
                                    width: '100%',
                                    borderRadius: '0px', backgroundColor: '#6C32A0',
                                }}>
                                    <div className={'followers'} style={{
                                        display: "flex",
                                        flexDirection: 'row',
                                        height: '30px',
                                        // marginTop: '60px',
                                        // marginLeft: '20%',
                                        justifyContent: 'flex-start',
                                        // alignItems: 'center'
                                    }}>
                                        <div className={'profileImage'}>
                                            <div className={'imageAndDetail'}>
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    // marginTop: '-160px',
                                                    marginLeft: "14px"
                                                }}>
                                                    <img
                                                        style={{ contain: 'fill' }}
                                                        height={160}
                                                        width={160}
                                                        alt="..."
                                                        className="rounded-circle"
                                                        src={userDetails?.user?.profile_picture || avatar}
                                                    />
                                                    <div style={{
                                                        marginLeft: '-90px',
                                                        marginTop: '120px'
                                                    }}>
                                                        <label className="label">
                                                            <input type="file" onChange={this.handleFile} required />
                                                            <img height={40} width={40} alt={''} src={camera} />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div style={{ marginLeft: '-30px' }}>
                                            <Button className={'button-pad'} style={{
                                                backgroundColor: 'darkblue',
                                                color: 'white',
                                                borderRadius: '30px',
                                            }} size={'md'}>
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <span
                                                        onClick={
                                                            () => {
                                                                this.toggleModal('showFollowers');
                                                                this.getFollowerList();
                                                            }

                                                        }>Followers:{userDetails?.user?.follower_count}</span>
                                                </div>
                                            </Button>
                                            <Button className={'button-pad'} style={{
                                                backgroundColor: 'darkblue',
                                                color: 'white',
                                                borderRadius: '30px',
                                            }} size={'md'}>
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <span
                                                        onClick={() => {
                                                            this.toggleModal('showFollowing')
                                                            this.getFollowingList();
                                                        }}>Followings:{userDetails?.user?.following_count}</span>
                                                </div>
                                            </Button>
                                            {/*<Button style={{*/}
                                            {/*    width: '25%',*/}
                                            {/*    backgroundColor: 'darkblue',*/}
                                            {/*    color: 'white',*/}
                                            {/*    padding: '5px',*/}
                                            {/*    borderRadius: '30px'*/}
                                            {/*}} size={'md'}>*/}
                                            {/*    <div style={{display: 'flex', flexDirection: 'column'}}>*/}
                                            {/*        <span>Posts:{userDetails?.user?.post_count || 0}</span>*/}
                                            {/*    </div>*/}
                                            {/*</Button>*/}
                                            {/*<Button style={{*/}
                                            {/*    width: '25%',*/}
                                            {/*    backgroundColor: 'darkblue',*/}
                                            {/*    color: 'white',*/}
                                            {/*    padding: '5px',*/}
                                            {/*    borderRadius: '30px'*/}
                                            {/*}} size={'md'}>*/}
                                            {/*    <div style={{display: 'flex', flexDirection: 'column'}}>*/}
                                            {/*        <span>Likes:5000</span>*/}
                                            {/*    </div>*/}
                                            {/*</Button>*/}
                                        </div>

                                    </div>
                                </Card>
                            </Row>
                            <Row className={'change-pass'}>
                                <Col sm={12} md={12} xl={12}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: 'row',
                                        marginLeft: '19%',
                                        marginTop: '5px',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center'
                                    }}>
                                        <Button style={{
                                            backgroundColor: 'white',
                                            color: 'purple',
                                            padding: '5px',
                                        }} size={'md'}>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <span
                                                    onClick={() => this.toggleModal('updateProfile')}>Edit Details</span>
                                            </div>
                                        </Button>
                                        <Button style={{
                                            backgroundColor: 'white',
                                            color: 'purple',
                                            padding: '5px',
                                        }} size={'md'}>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <span
                                                    onClick={() => this.toggleModal('changePassword')}>Change Password</span>
                                            </div>
                                        </Button>
                                        {/*<Button style={{*/}
                                        {/*    backgroundColor: 'white',*/}
                                        {/*    color: 'purple',*/}
                                        {/*    padding: '5px',*/}
                                        {/*}} size={'md'}>*/}
                                        {/*    <div style={{display: 'flex', flexDirection: 'column'}}>*/}
                                        {/*        <span>Invite Your Friend</span>*/}
                                        {/*    </div>*/}
                                        {/*</Button>*/}
                                        {/*<Button style={{*/}
                                        {/*    backgroundColor: 'white',*/}
                                        {/*    color: 'purple',*/}
                                        {/*    padding: '5px',*/}
                                        {/*}} size={'md'}>*/}
                                        {/*    <div style={{display: 'flex', flexDirection: 'column'}}>*/}
                                        {/*        <span onClick={() => this.toggleModal('showReferral')}>Referral</span>*/}
                                        {/*    </div>*/}
                                        {/*</Button>*/}
                                        {/*<Button style={{*/}
                                        {/*    backgroundColor: 'white',*/}
                                        {/*    color: 'purple',*/}
                                        {/*    padding: '5px',*/}
                                        {/*}} size={'md'}>*/}
                                        {/*    <div style={{display: 'flex', flexDirection: 'column'}}>*/}
                                        {/*        /!*<span onClick={() => this.props.history.push('/admin/Reward')}>Rewards</span>*!/*/}
                                        {/*        <span onClick={() => this.toggleModal('showReward')}>Rewards</span>*/}
                                        {/*    </div>*/}
                                        {/*</Button>*/}

                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <div style={{
                                    width: '100%',
                                    marginTop: '10px',
                                    marginLeft: '20px',
                                    marginRight: '5px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'flex-start',
                                    padding: '1.5rem'
                                }}>
                                    <div className={'profile-id'}>
                                        <span style={{
                                            fontSize: 22,
                                            fontWeight: 'bold'
                                        }}>{userDetails.full_name}
                                            <span style={{
                                                color: 'purple'
                                            }}>
                                            </span>&nbsp;&nbsp;
                                            {userDetails?.user?.email_verified && <i style={{ color: 'blue' }} className={' fa fa-check-circle'}></i>}
                                        </span>
                                        <span style={{
                                            color: 'black',
                                            fontWeight: 'bold'
                                        }}>USER ID: {userDetails?.new_id}</span>
                                    </div>

                                    {/*<span style={{*/}
                                    {/*    fontSize: 16,*/}
                                    {/*    fontWeight: 'bold',*/}
                                    {/*    marginTop: '5px'*/}
                                    {/*}}>{userDetails?.bio || 'Please Update Your Bio'}</span>*/}

                                </div>
                            </Row>
                            <Row style={{ marginLeft: '5px', marginRight: '5px' }}>
                            {this.state.otpError &&
                                    <div  style={{marginLeft:'10px',marginRight:'10px',width:'100%'}} class="alert alert-danger alert-dismissible fade show" role="alert">
                                        <span class="alert-icon"><i class="ni ni-like-2"></i></span>
                                        <span> {this.state.otpErrorMessage}</span>
                                    </div>
                                }
                                <Card className={'profile-card ml-3 mr-3'}
                                >
                                    <CardBody>
                                        <div className={'profile-card-detail'}>
                                            <div className={'profile-detail'}>
                                                <span style={{
                                                    marginTop: 10,
                                                    fontSize: 16
                                                }}><i
                                                    className={'fa fa-phone'}></i> Contact: &nbsp;&nbsp;{userDetails.phone_number}</span>
                                                <span style={{
                                                    marginTop: 10,
                                                    fontSize: 16
                                                }}><i
                                                    className={' fa fa-birthday-cake'}></i> DOB: &nbsp;&nbsp;{userDetails?.dob || 'N/A'}</span>
                                                <span style={{
                                                    marginTop: 10,
                                                    fontSize: 16
                                                }}><i
                                                    className={`fa ${userDetails?.user?.gender === 'Male' ? 'fa-male' : 'fa-female'}`}></i>Gender: &nbsp;&nbsp;{userDetails?.user?.gender || 'N/A'}</span>
                                                <span style={{
                                                    marginTop: 10,
                                                    fontSize: 16
                                                }}><i
                                                    className={'fa fa-envelope'}></i>Email: &nbsp;&nbsp;{userDetails?.user?.email || 'N/A'}&nbsp;
                                                    {!userDetails?.user?.email_verified &&
                                                        <span onClick={() => this.verifyEmail()} style={{ cursor: 'pointer', fontSize: '12px', paddingLeft: '10px', paddingRight: '10px', backgroundColor: 'white', border: '1px solid red', height: '20px', borderRadius: '5px' }} >
                                                            <i style={{ color: 'red', width: '10px', fontSize: 12 }} className="fa fa-ban"></i>{this.state.loggingIn?'Wait...':'Verify'}
                                                        </span>
                                                    }
                                                </span>
                                            </div>

                                            <div className={'profile-detail'}>
                                                <span style={{
                                                    marginTop: 10,
                                                    fontSize: 16
                                                }}><i
                                                    className={'fa fa-people-carry'}></i>Total Referrals: &nbsp;&nbsp;{userDetails?.user?.referred_count || 'N/A'}</span>
                                                <span style={{
                                                    marginTop: 10,
                                                    fontSize: 16
                                                }}><i
                                                    className={'fa fa-money-check'}></i>Referral Code: &nbsp;&nbsp;{userDetails?.user?.referral_code || 'N/A'}</span>
                                                <span style={{
                                                    marginTop: 10,
                                                    fontSize: 16
                                                }}><i
                                                    className={'fa fa-calendar-times'}></i>Joined: &nbsp;&nbsp;{moment(userDetails?.user?.created_at).format('YYYY-MM-DD') || 'N/A'}</span>
                                                <span style={{
                                                    marginTop: 10,
                                                    fontSize: 16
                                                }}><i
                                                    className={'fa fa-address-card'}></i><span>From</span>: &nbsp;&nbsp;{userDetails?.user?.address || 'N/A'}</span>
                                            </div>

                                        </div>
                                    </CardBody>
                                </Card>
                            </Row>
                            <Row className={'m-0 p-0'}>
                                <Card
                                    style={{
                                        width: '100%',
                                        borderRadius: '20px',
                                        border: 'none',
                                        marginTop: '5px',
                                        alignItems: 'center',
                                        backgroundColor: 'transparent'
                                    }}
                                >
                                    <CardBody style={{ width: '100%' }}>
                                        <AnnouncementField getCommunity={this.getCommunity} style={{ padding: '5px' }} />
                                        <CommunityPostList
                                            getCommunity={this.getCommunity}
                                            communityList={this.state.communityList}
                                            communityLoading={this.state.loading}
                                        />
                                    </CardBody>
                                </Card>
                            </Row>
                        </div>
                    </Col>
                    <Col xl={3} className={'people-know'}>
                        <PeopleYouMayKnow
                            getPeopleYouMayKnow={this.getPeopleYouMayKnow}
                            peopleList={this.state.peopleList} />
                    </Col>
                </Row>
                {this.state.updateProfile && (
                    <UserUpdateModal
                        updateProfile={this.state.updateProfile}
                        toggleModal={this.toggleModal}
                        type={'Profile'}
                    />
                )}
                {this.state.changePassword && (
                    <PasswordChangeModal
                        changePassword={this.state.changePassword}
                        toggleModal={this.toggleModal}
                    />
                )}
                {this.state.showReferral && (
                    <ReferralModal
                        referral={this.state.userReferrals}
                        referredCount={this.state.userdetails.user.referred_count}
                        showReferral={this.state.showReferral}
                        fetchRefer={this.fetchRefer}
                        filteredData={this.state.filteredData}
                        toggleModal={this.toggleModal} />
                )}

                {this.state.showFollowers && (
                    <FollowersModal
                        referral={this.state.userdetails.user.referrer}
                        referredCount={this.state.userdetails.user.referred_count}
                        showReferral={this.state.showFollowers}
                        followingList={this.state.followingList}
                        toggleModal={this.toggleModal} />
                )}

                {this.state.showFollowing && (
                    <FollowingModal
                        referral={this.state.userdetails.user.referrer}
                        followingList={this.state.followingList}
                        referredCount={this.state.userdetails.user.referred_count}
                        showReferral={this.state.showFollowing}
                        toggleModal={this.toggleModal} />
                )}

                {this.state.showReward && (
                    <RewardModal
                        userDetail={this.state.userdetails}
                        showReward={this.state.showReward}
                        toggleModal={this.toggleModal}
                        showWithdrawMoney={this.state.showWithdrawMoney}
                    />
                )}

                {this.state.verifyEmail && (
                    <Modal
                        keyboard={false}
                        backdrop={'static'}
                        className="modal-dialog-centered modal-md"
                        isOpen={this.state.verifyEmail}
                        toggle={() => this.setState({ verifyEmail: false })}
                    >
                        <div className="modal-header">
                            <h2 className="modal-title" id="modal-title-default">
                                Enter Your OTP
                            </h2>
                            <button
                                aria-label="Close"
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => this.setState({ verifyEmail: false })}
                            >
                                <span aria-hidden={true}>×</span>
                            </button>
                        </div>
                        <div className={'modal-body'}>
                            <AvForm
                                onValidSubmit={this.handleOtpSubmit}
                                onInvalidSubmit={this.handleInvalidSubmit}
                            >
                                <AvGroup className="mb-3">
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fa fa-user" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <AvInput
                                            placeholder="Otp"
                                            type="text"
                                            name="otp"
                                            value={this.state.otp}
                                            required
                                            autoFocus
                                        />
                                    </InputGroup>
                                </AvGroup>
                                <div className="text-center">
                                    <Button disabled={this.state.otpLoading} className="my-4" color="primary">
                                        {this.state.loggingIn ? "Submitting ..." : "Submit Your OTP"}
                                    </Button>
                                </div>

                            </AvForm>
                        </div>
                    </Modal>
                )}
            </>
        );
    }
}

export default Profile;
