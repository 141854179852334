import {  faMicrophone, faThumbTack } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar } from '@material-ui/core';
import React from 'react';


export default function PeerAvatar({ MessageDis }) {
    return (
        <div className='peerAvater-whole-body'>
            <div className='btn-peer'>
                <FontAwesomeIcon icon={faMicrophone} />
                <FontAwesomeIcon icon={faThumbTack} />
            </div>
            <div className="peerAvater-div">
                <div className="sound-wave-container">
                    <div className="sound-wave"></div>
                    <div className="sound-wave"></div>
                    <div className="sound-wave"></div>
                    <Avatar
                        src={ require('../../assets/img/theme/avatar.png')}
                        alt="user"
                        id="peerAvater-frame"
                    />
                </div>
                <h1 className="peerAvater-name">Pradip Mahara</h1>
                <h1 className="peerAvater-name">{MessageDis}</h1>
            </div>
        </div>
    );
}