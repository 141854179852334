import React, { createContext } from "react";
import MobXStore from "./MobXStore";

export const MobXContext = createContext();

const MobXProvider = ({ children }) => {
  const store = new MobXStore();
  return <MobXContext.Provider value={store}>{children}</MobXContext.Provider>;
};

export default MobXProvider;
