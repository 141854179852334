import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import "./Message.css";
import {
  Container,
  AppBar,
  Toolbar,
  Typography,
  Box,
  IconButton,
  Avatar,
  Button,
  CircularProgress,
  TextareaAutosize,
  makeStyles,
} from "@material-ui/core";
import {
  CheckCircle,
  Call,
  Duo,
  ChatBubbleOutlineRounded,
  Send,
} from "@material-ui/icons";
import { MessageLeft, MessageRight } from "./MessageStyle";
const Services = require("../../RemoteServices/RemoteServices.js");

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    backgroundColor: "#f0f0f0",
    padding: 0,
    overflow: "none",
    position: "relative",
  },
  appBar: {
    padding: "0.5rem",
    backgroundColor: "#fff",
    color: "#000",
    boxShadow: "0 1px 3px rgba(0,0,0,0.12)",
    position: "sticky",
    top: 0,
    left: 0,
    right: 0,
    zIndex: theme.zIndex.appBar,
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    padding: 0,
  },
  headerBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  avatarBox: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  companyInfo: {
    display: "flex",
    flexDirection: "column",
  },
  companyTitle: {
    display: "flex",
    alignItems: "center",
    fontWeight: 500,
  },
  iconBox: {
    display: "flex",
    gap: theme.spacing(1),
  },
  contentWrapper: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    position: "relative",
  },
  messageContainer: {
    flex: 1,
    padding: "1rem",
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    backgroundColor: "#f0f0f0",
    overflowY: "auto",
    minHeight: 0,
  },
  inputContainer: {
    width: "100%",
    position: "sticky",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "#fff",
    borderTop: "1px solid #ddd",
    padding: "0.5rem",
    zIndex: theme.zIndex.appBar,
  },
  form: {
    display: "flex",
    justifyContent: "center",
    width: "95%",
    margin: "0 auto",
    gap: theme.spacing(1),
  },
  textArea: {
    width: "100%",
    resize: "none",
    padding: theme.spacing(1),
    border: "1px solid #ddd",
    borderRadius: theme.shape.borderRadius,
    fontFamily: theme.typography.fontFamily,
    "&:focus": {
      outline: "none",
      borderColor: theme.palette.primary.main,
    },
  },
  loadingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
  },
  noMessagesContainer: {
    height: "100%",
    minHeight: "400px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(3),
    gap: theme.spacing(2),
  },
  chatBubbleIcon: {
    fontSize: 48,
    color: "action.disabled",
    marginBottom: theme.spacing(1),
  },
}));

const MessageUs = (props) => {
  const navigate = useHistory();
  const messageContainerRef = useRef(null);
  const messageInputRef = useRef(null);
  const [isUserScrolling, setIsUserScrolling] = useState(false);
  const messageEndRef = React.useRef(null);

  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [adminTyping, setAdminTyping] = useState(false);
  const [fullName, setFullName] = useState("");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const pageSize = 10;
  const [socket, setSocket] = useState(props.socket ? props.socket : null);
  const [allMessagesLoaded, setAllMessagesLoaded] = useState(false); // Track if all messages are loaded
  const [nextUrl, setNextUrl] = useState("");
  const [chatConfig, setChatConfig] = useState("");
  const classes = useStyles();

  const getChatConfig = async () => {
    await Services.getChatConfig()
      .then((response) => {
        setChatConfig(response?.[0]);
      })
      .catch((error) => {
        throw error;
      });
  };

  const getChatMessage = async () => {
    if (props.socket) {
      props.resetMessage();
      await Services.getChatMessage(JSON.parse(localStorage.getItem("user")).id)
        .then((response) => {
          setMessages(response.results.reverse());
          setNextUrl(response?.next);
          let delivery;
          response.results
            .filter(
              (item) =>
                item.delivery_type === "sent" ||
                item.delivery_type === "delivered"
            )
            .map((item) => {
              delivery = {
                action: "delivery_status",
                id: item.id,
                delivery_type: "seen",
                me_id: JSON.parse(localStorage.getItem("user"))?.id,
                user_id: item.user,
              };
              return props?.socket?.send(JSON.stringify(delivery));
            });
        })
        .catch((error) => {
          throw error;
        });
    }
  };

  const updateDeliveryStatus = (incomingMessage) => {
    setMessages((prevMessages) => {
      // Create a new array with updated delivery_type
      const updatedMessages = prevMessages.map((msg) =>
        msg.id === incomingMessage.id
          ? { ...msg, delivery_type: incomingMessage.delivery_type }
          : msg
      );
      return updatedMessages;
    });
  };

  useEffect(() => {
    // Fetch initial messages
    getChatConfig();
    getChatMessage();
  }, []);

  useEffect(() => {
    setAdminTyping(props.adminTyping);
    setFullName(props.fullName);
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [props.messages, props.adminTyping, props.fullName]);

  useEffect(() => {
    const interval = setInterval(() => {
      // Trigger a re-render by updating the state
      setMessages((prevMessages) => [...prevMessages]);
    }, 60000); // Update every minute

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  const handleScroll = () => {
    if (
      messageContainerRef.current.scrollTop === 0 &&
      !loading &&
      !allMessagesLoaded
    ) {
      const prevHeight = messageContainerRef.current.scrollHeight;

      if (props.nextUrl) {
        props.getNext().then(() => {
          messageContainerRef.current.scrollTop =
            messageContainerRef.current.scrollHeight - prevHeight;
        });
      }
      setIsUserScrolling(true);
      setTimeout(() => {
        setIsUserScrolling(false);
      }, 20000);
    }
  };

  const handleInputChange = (e) => {
    if (e.keyCode !== 13) {
      console.log("working here");
      props.socket.send(
        JSON.stringify({
          full_name: JSON.parse(localStorage.getItem("user")).full_name,
          action: "typing",
          typing: true,
          user_id: 50843,
          me_id: JSON.parse(localStorage.getItem("user")).id,
        })
      );
      setTimeout(() => {
        props.socket.send(
          JSON.stringify({
            full_name: JSON.parse(localStorage.getItem("user")).full_name,
            action: "typing",
            typing: false,
            user_id: 50843,
            me_id: JSON.parse(localStorage.getItem("user")).id,
          })
        );
        setAdminTyping(false);
      }, 5000);
    }
    setMessage(e.target.value);
  };

  const sendMessage = async () => {
    if (message.trim() === "") return;
    const messages = {
      full_name: JSON.parse(localStorage.getItem("user")).full_name, // Replace with the sender's name
      icon: "",
      timestamp: new Date().toISOString(),
      state: "sent",
      me_id: JSON.parse(localStorage.getItem("user"))?.id,
      to_user_id: "admin",
      user_id: JSON.parse(localStorage.getItem("user"))?.id,
      user: JSON.parse(localStorage.getItem("user"))?.id,
      userImage:
        JSON.parse(localStorage.getItem("user"))?.profile_picture || null,
      message: message,
      action: "message",
      phone_number: localStorage.getItem("user")?.phone_number,
    };
    props.socket.send(JSON.stringify(messages));
    setIsUserScrolling(false);
    setMessage("");
    const textarea = messageInputRef.current;
    textarea.style.height = "60px"; // Reset height to initial value
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (e.shiftKey) {
        return;
      }
      e.preventDefault();
      sendMessage();
    }
  };

  const handleAudioVideoCall = (isVideoCall = true) => {
    navigate.push("/admin/call");
  };

  return (
    <>
      <Container maxWidth="" className={classes.container}>
        <AppBar component={"nav"} position="sticky" className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <Box className={classes.headerBox}>
              <Box className={classes.avatarBox}>
                <Avatar
                  src={require("../../assets/img/withoutNameEng.png")}
                  alt="Company Logo"
                  style={{ width: 40, height: 40 }}
                />
                <Box className={classes.companyInfo}>
                  <Box className={classes.companyTitle}>
                    <Typography variant="body1" component="span">
                      English Hub
                    </Typography>
                    <CheckCircle
                      color="primary"
                      fontSize="small"
                      style={{ marginLeft: 4 }}
                    />
                  </Box>
                  <Typography variant="caption" color="textSecondary">
                    Online
                  </Typography>
                </Box>
              </Box>

              <Box className={classes.iconBox}>
                <IconButton color="primary" onClick={handleAudioVideoCall}>
                  <Call />
                </IconButton>
                <IconButton color="primary" onClick={handleAudioVideoCall}>
                  <Duo />
                </IconButton>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>

        <Box className={classes.contentWrapper}>
          <Box
            className={classes.messageContainer}
            ref={messageContainerRef}
            onScroll={() => {
              if (!props.loading) {
                handleScroll();
              }
            }}
          >
            {props.loading ? (
              <Box className={classes.loadingContainer}>
                <CircularProgress />
              </Box>
            ) : !props.messages.length ? (
              <Box className={classes.noMessagesContainer}>
                <ChatBubbleOutlineRounded className={classes.chatBubbleIcon} />
                <Typography
                  variant="h6"
                  color="text.secondary"
                  align="center"
                  sx={{ fontWeight: 500 }}
                >
                  No messages yet
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  align="center"
                >
                  Start a conversation with our support team
                </Typography>
              </Box>
            ) : (
              props.messages.map((item, index) => {
                const timestamp =
                  item.to_user_id === "admin"
                    ? `${item.full_name} ${moment(item.timestamp).fromNow()}`
                    : `English Hub ${moment(item.timestamp).fromNow()}`;

                if (item?.to_user_id === "admin") {
                  return (
                    <MessageRight
                      key={index}
                      message={item.message}
                      timestamp={timestamp}
                      deliveryType={item.delivery_type}
                    />
                  );
                }
                return (
                  <>
                    <MessageLeft
                      key={index}
                      message={item.message}
                      timestamp={timestamp}
                      deliveryType={item.delivery_type}
                    />
                  </>
                );
              })
            )}

            {adminTyping ? (
              <span
                className="typing-indicator-subtle"
                style={{
                  maxWidth: "fit-content",
                }}
              >
                {props.fullName} is typing...
              </span>
            ) : null}

            <div ref={messageEndRef} />
          </Box>
        </Box>

        <Box className={classes.inputContainer}>
          <form className={classes.form} noValidate autoComplete="off">
            <TextareaAutosize
              maxRows={3}
              placeholder="Type a message"
              className={classes.textArea}
              onKeyDown={handleKeyDown}
              onChange={handleInputChange}
            />
            <Button
              style={{ maxHeight: "3rem" }}
              variant="contained"
              color="primary"
              disabled={!props.socket}
              onClick={() => sendMessage()}
            >
              <Send />
            </Button>
          </form>
        </Box>
      </Container>
    </>
  );
};

export default MessageUs;
